import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

import { servicesStore } from './store';

export const ServicesRoute: MinervaRoute<'services'> = {
  name: 'services',
  path: '/services',
  comp: () => lazy(() => import('./index')),
  activate: async () => {
    servicesStore.resetStore();
    servicesStore.loading = true;
    try {
      await servicesStore.fetchServices();
    } finally {
      servicesStore.loading = false;
    }
  },
  deactivate: () => {
    servicesStore.resetStore();
  },
};
