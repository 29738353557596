import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

import { implementationRecordsStore } from './store';

export const ImplementationRecordRoute: MinervaRoute<'implementation-record'> =
  {
    name: 'implementation-record',
    path: '/IR',
    comp: () => lazy(() => import('./index')),
    activate: async () => {
      await implementationRecordsStore.fetchRecordPeriods();
      if (implementationRecordsStore.chosenPeriod) {
        await implementationRecordsStore.fetchRecordCategories();
      }
    },
    deactivate: () => {
      implementationRecordsStore.resetAllCollapseState();
      implementationRecordsStore.resetDrawerState();
    },
  };
