import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ServiceHistoryIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect width="40" height="40" rx="4" fill="#E6F3FD" />
    <path
      d="M20 32C26.6274 32 32 26.6274 32 20C32 13.3726 26.6274 8 20 8C13.3726 8 8 13.3726 8 20C8 26.6274 13.3726 32 20 32Z"
      stroke="#00060F"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 12.8008V20.0008L23.6 23.6008"
      stroke="#00060F"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default ServiceHistoryIcon;
