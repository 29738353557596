import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { LinkFileDto } from 'src/api/api-types/files';
import { ImportContainerValue, InitializeData, Session } from 'src/api/api-types/import';
import { ImportApi } from 'src/api/import';
import { ItemValueType } from 'src/types';

import { storeFactory } from '../../utils/store';
import { importUploadStore } from './components/Tabs/ImportTab';
import { prepareMergedColumnsData } from './helpers/common';
import { ImportSession } from './models/import-session';
import { ImportSteps } from './type';

class ImportsStore {
  readonly api = new ImportApi();

  @observable importStep: ImportSteps = ImportSteps.Loading;
  @observable succeedImportStep: ImportSteps[] = [];

  @observable.ref currentSession?: ImportSession;
  @observable.ref createSessionReq: ApiReq<Session> = emptyValue;
  @observable.ref lastActiveSessionReq: ApiReq<Session> = emptyValue;

  timer?: NodeJS.Timeout;

  constructor() {
    makeObservable(this);

    reaction(
      () => [this.currentSession, this.currentSession?.state, this.currentSession?.stage],
      () => this.realTimeSession(),
    );
  }

  @computed get stepKeys() {
    return Object.keys(ImportSteps);
  }

  @computed get stepValues() {
    return Object.values(ImportSteps);
  }

  @computed get shouldListenToChanges() {
    if (!this.currentSession) {
      return false;
    }
    if (this.currentSession.sessionFailed) {
      return false;
    }
    if (this.currentSession.isFileUpload && this.currentSession.sessionInteractiveState) {
      return false;
    }
    if (this.currentSession.isColumnsMatching && this.currentSession.sessionInteractiveState) {
      return false;
    }
    if (this.currentSession.isValuesMatching && this.currentSession.sessionInteractiveState) {
      return false;
    }
    if (this.currentSession.isContainersMatching && this.currentSession.sessionInteractiveState) {
      return false;
    }
    if (this.currentSession.isImportReview && this.currentSession.sessionInteractiveState) {
      return false;
    }
    if (this.currentSession.isImportProcessing && !this.currentSession.sessionProcessing) {
      return false;
    }
    return true;
  }

  @action setSucceedImportStep(step: ImportSteps) {
    const position = this.stepValues.indexOf(step);
    this.succeedImportStep = this.stepValues.slice(0, position);
  }

  @action navigate(step: ImportSteps) {
    window.history.replaceState(step, step, `import?step=${step}`);
  }

  @action nextStep = (step: ImportSteps) => {
    this.setSucceedImportStep(step);
    this.importStep = step;

    return this.navigate(step);
  };

  @action submitSessionData = async (createData: InitializeData) => {
    this.createSessionReq = this.api.createImportSession(createData);
    const { data } = await this.createSessionReq;
    this.nextStep(ImportSteps.Upload);

    runInAction(() => {
      if (!!data) this.currentSession = new ImportSession(data);
    });

    if (!this.currentSession?.fileToken) return;

    return importUploadStore.upload({
      params: {},
      linkAction: this.linkFileToSession,
      customToken: this.currentSession?.fileToken
    });
  };

  @action createImportSession = async (createData: InitializeData) => {
    await this.submitSessionData(createData);
    runInAction(() => {
      if (this.currentSession?.isFileUploading && this.currentSession?.sessionSucceed) {
        this.nextStep(ImportSteps.MatchingColumns);
      }
    });
  };

  @action getLastActiveSession = async () => {
    this.lastActiveSessionReq = this.api.getLastActiveSession();
    const { data } = await this.lastActiveSessionReq;

    runInAction(() => {
      if (!!data) {
        this.currentSession = new ImportSession(data);
      } else {
        this.currentSession = undefined;
      }
      this.importRedirect();
    });
  };

  @action linkFileToSession = async (file: LinkFileDto) => {
    await this.currentSession?.linkFile(file);
  };

  @action submitMatchingContainers = async (sourceColumns: ImportContainerValue[]) => {
    if (!this.currentSession) return;
    await this.currentSession.mergeImportContainers(sourceColumns);
    return this.checkSessionState();
  };

  @action resetCurrentSession = () => {
    this.currentSession = undefined;
    this.succeedImportStep = [];
    importUploadStore.clear();
    this.importStep = ImportSteps.StartImport;
    return this.navigate(ImportSteps.StartImport);
  };

  clearTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  @action realTimeSession = async () => {
    const timeCheckInterval = 5000;

    if (!this.shouldListenToChanges) {
      this.clearTimer();
      return;
    }

    if (this.timer) {
      return;
    }
    this.timer = setInterval(() => {
      (async () => {
        if (!this.shouldListenToChanges) {
          this.clearTimer();
          return this.importRedirect();
        }
        await this.currentSession?.updateSessionData(true);
        return this.importRedirect();
      })();
    }, timeCheckInterval);
  };

  @action importRedirect = async () => {
    if (!this.currentSession) {
      return this.nextStep(ImportSteps.StartImport);
    }
    if (this.currentSession?.isFileUploading) {
      return this.nextStep(ImportSteps.Upload);
    }
    if (this.currentSession?.isColumnsMatching) {
      return (async () => {
        await this.getColumnsProcessingData();
        this.nextStep(ImportSteps.MatchingColumns);
      })();
    }
    if (this.currentSession?.isValuesMatching) {
      return (async () => {
        await this.currentSession?.getValuesForMerge();
        this.nextStep(ImportSteps.MatchingValues);
      })();
    }
    if (this.currentSession?.isContainersMatching) {
      return (async () => {
        await this.currentSession?.getContainersForMerge();
        this.nextStep(ImportSteps.MatchingContainers);
      })();
    }
    if (
      this.currentSession?.isImportReview ||
      this.currentSession?.isImportProcessing ||
      this.currentSession?.isImportAddressesStandardization
    ) {
      return (async () => {
        this.nextStep(ImportSteps.Complete);
      })();
    }
  };

  @action getColumnsProcessingData = async () => {
    if (!this.currentSession) return;
    return Promise.all([
      this.currentSession.getSourceColumns(),
      this.currentSession.getColumnsTemplates(),
    ]);
  };

  @action mergeImportColumns = async (sourceColumns: ItemValueType[]) => {
    const mergeResult = await this.currentSession?.mergeImportColumns(
      prepareMergedColumnsData(sourceColumns),
    );
    const checkSessionResult = await this.checkSessionState();

    await Promise.all([mergeResult, checkSessionResult]);

    this.currentSession?.clearSession();
  };

  @action checkSessionState = async () => {
    await this.currentSession?.updateSessionData(false);
    return this.importRedirect();
  };

  @action finishImport = async () => {
    await this.currentSession?.finishImport();
    importUploadStore.clear();
    this.nextStep(ImportSteps.StartImport);
  };
}

export const { store: importsStore, storeCtx: importsStoreCtx } = storeFactory(
  ImportsStore,
  'import',
);
