import { makeObservable } from 'mobx';
import { AccountsModel } from 'src/models/account/accounts-model';
import { storeFactory } from 'src/utils/store';

export class AccountsStore extends AccountsModel {
  constructor() {
    super();
    makeObservable(this);
  }
}

export const { store: accountsStore, storeCtx: accountsStoreCtx } =
  storeFactory(AccountsStore, 'accounts');
