import { styled } from '@mui/material';
import { Box } from '@mui/system';

export const TextWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.05)',
  border: '1px solid rgba(68, 69, 69, 0.1)s',
  backgroundColor: theme.palette.minervaColors.primaryWhite,
}));
