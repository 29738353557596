import { useEffect, useState, useRef } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DEFAULT_POPUP_AREA_WIDTH } from 'src/components/AssetsTable/helpers/common';
import { StyledTextArea } from 'src/components/StyledTextArea';
import { GRID_ROW_HEIGHT } from 'src/components/Table/constants';
import { useEFRTableStore } from 'src/pages/EdibleFoodRecovery/EFRTableProvider';
import { RenderCellComponentProps } from 'src/types';
import { isPending } from 'src/utils/common';
import { TextWrapper } from './styles';

export const DescriptionCell = observer(
  ({ id, hasFocus, row }: RenderCellComponentProps) => {
    const ref = useRef(null);
    const { selectEfr, selectedEFR, removeSelectedEFR } = useEFRTableStore();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const openPopover = () => {
      selectEfr(Number(id));
      !open && setAnchorEl(ref.current);
    };

    useEffect(() => {
      if (hasFocus) {
        setAnchorEl(ref.current);
        if (selectedEFR?.descriptionTruncated)
          selectedEFR?.getFullDescription();
      }
    }, [hasFocus, ref, selectedEFR]);

    const onClose = () => {
      removeSelectedEFR();
      setAnchorEl(null);
    };

    return (
      <>
        <Grid container flexDirection="row" wrap="nowrap">
          <Typography
            sx={{
              height: GRID_ROW_HEIGHT,
              alignItems: 'center',
              display: 'flex',
            }}
            ref={ref}
            noWrap>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {row?.description}
            </span>
          </Typography>
          <Grid item>
            {row.descriptionTruncated && (
              <IconButton size="small">
                <VisibilityIcon
                  onClick={openPopover}
                  sx={{ cursor: 'pointer' }}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
        {hasFocus && !!selectedEFR && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            // safari fix
            PaperProps={{
              sx: {
                zIndex: 1,
              },
            }}>
            <TextWrapper>
              {isPending(selectedEFR?.fullDescriptionReq) ? (
                <LinearProgress sx={{ width: DEFAULT_POPUP_AREA_WIDTH }} />
              ) : (
                <StyledTextArea
                  readOnly
                  value={selectedEFR?.description}
                  minRows={5}
                  maxRows={7}
                  sx={{ width: DEFAULT_POPUP_AREA_WIDTH }}
                />
              )}
            </TextWrapper>
          </Popover>
        )}
      </>
    );
  },
);
