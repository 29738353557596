import { PropsWithChildren } from 'react';

import { styled, SxProps, Theme } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';
import { useLinkToRoute } from 'mobx-router6';
import { RouteName, generalRoutes, adminRoutes } from 'src/routes';

interface Props {
  to: RouteName | string;
  params?:
    | typeof generalRoutes[number]['params']
    | typeof adminRoutes[number]['params'];
  onClick?: (e?: React.MouseEvent<any>) => void;
  sx?: SxProps<Theme>;
}

export const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  ...(theme.typography.link as CSSProperties),
}));

export function Link({
  to,
  params = {},
  children,
  onClick,
  ...rest
}: PropsWithChildren<Props>) {
  const props = useLinkToRoute({ name: to, params, onClick });
  if (!to) {
    // todo send error to logger
    return null;
  }

  return (
    <StyledLink {...props} {...rest}>
      {children}
    </StyledLink>
  );
}
