import { dotNameKeyToObject } from 'src/utils/string';
import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import { ExportDataType } from '../../api/exportTypes';
import { ExportTypes, Pageable } from '../../types';
import { ApiReq, emptyValue } from '../../api';
import { downloadBlob } from 'src/utils/api';
import { exportNameGenerator } from 'src/utils/common';
import { EXPORT_DEFAULT_OPTIONS } from 'src/utils/constants';
import { GridRowId, GridValidRowModel } from '@mui/x-data-grid-pro';
import { TablePagination } from '../pagination/pagination';
import { TableFilter } from '../filter/table-filter';

export class ExportEntity {
  @observable mode: ExportTypes = ExportTypes.WITH_FILTERS;
  @observable fileName: ExportDataType['fileName'] = '';
  @observable.ref exportReq: ApiReq<Blob> = emptyValue;
  @observable isExport = false;
  @observable type?: string;
  @observable loading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  exportParams<T extends Pageable, H, G extends GridValidRowModel>(
    hiddenColumns: string[],
    filter: TableFilter<H, G>,
    pagination: TablePagination<T>,
    selectedItems: GridRowId[],
    mode: ExportTypes
  ) {
    return {
      fileName: this.fileName,
      columns: mode !== ExportTypes.ALL ? hiddenColumns : [],
      q: {
        ...((mode === ExportTypes.CURRENT_PAGE || mode === ExportTypes.WITH_FILTERS || mode === ExportTypes.QUICK) && dotNameKeyToObject(filter.filterParams)),
        ...(mode === ExportTypes.SELECTED &&
          {
            activeMode: filter.activeMode,
          }),
      },
      page: mode === ExportTypes.CURRENT_PAGE ? {
        num: pagination.serialize.page,
        size: pagination.serialize.pageSize,
      } : null,
      ids: (mode === ExportTypes.SELECTED || mode === ExportTypes.QUICK) ? [...toJS(selectedItems)] : [],
    };
  }

  @action setParams(
    params: Omit<ExportDataType, 'mode'> & { mode: ExportTypes },
  ) {
    this.mode = Number(params.mode);
    this.fileName = params.fileName;
    this.type = params.type;
  }

  @action openExportModal = () => (this.isExport = true);
  @action cancelExport = () => (this.isExport = false);

  @action exportAction = async (
    getReportByType: ApiReq<Blob>,
    { fileName, mode, type }: ExportDataType,
  ) => {
    if (!type) return;
    this.loading = true;
    this.setParams({ ...EXPORT_DEFAULT_OPTIONS, fileName, mode, type });
    try {
      this.exportReq = getReportByType;
      const res = await this.exportReq;
      downloadBlob(
        res.data,
        exportNameGenerator(fileName, type, this.fileName),
      );
      runInAction(() => {
        if (this.exportReq.state === 'fulfilled' && this.isExport) {
          this.resetParams();
        }
      });
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  };

  @action resetParams() {
    this.mode = ExportTypes.WITH_FILTERS;
    this.fileName = '';
    this.type = undefined;
    this.isExport = false;
    this.exportReq = emptyValue;
  }
}
