import { useContext } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useController } from 'react-hook-form';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { isPending } from 'src/utils/common';

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

const JurisdictionAutocomplete = () => {
  const { field, fieldState } = useController({
    name: 'jurisdictions',
    defaultValue: [],
  });
  const { allJurisdictionsReq, fetchAllJurisdictionsLookups, allJurisdictions } =
    useContext(lookupsStoreCtx);
  const onOpen = async () => await fetchAllJurisdictionsLookups();

  return (
    <Box>
      <Typography variant="body1">Jurisdictions</Typography>
      <Autocomplete
        {...field}
        onChange={(_, data) => {
          field.onChange(data);
          return data;
        }}
        multiple
        limitTags={2}
        onOpen={onOpen}
        options={allJurisdictions}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) =>
          `${option.id}` === `${value.id}`
        }
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props} key={`${option.id}-${option.name}`}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            <ListItemText primary={option.name} />
          </MenuItem>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={`Select jurisdictions...`}
            sx={{ p: 0, border: 0 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isPending(allJurisdictionsReq) && (
                    <CircularProgress color="inherit" size={20} />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={!!fieldState?.error?.message}
            helperText={fieldState?.error?.message}
          />
        )}
      />
    </Box>
  );
};

export default observer(JurisdictionAutocomplete);
