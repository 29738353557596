import { lazy, Suspense } from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { observer } from 'mobx-react-lite';
import PrimaryCollapse from 'src/components/Collapsible/Primary';
import CollapsibleTitle from 'src/components/Collapsible/components/CollapsibleTitle';

import { isPending } from '../../../../utils/common';
import { useLocalStorage } from '../../../../utils/hooks/custom';
import { ComplianceMatrixStore } from '../../store';
import { stateMap } from '../MatrixCard';

const AB1826 = lazy(() => import('./AB1826'));

export interface AB1826CollapseProps {
  store: ComplianceMatrixStore;
}

const AB1826Collapse = ({ store }: AB1826CollapseProps) => {
  const { complianceMatrix } = store;
  const { ab1826MoreTotal } = complianceMatrix?.matrix || {};

  const [ab1826Collapse, changeAb1826Collapse] = useLocalStorage(
    'isOpen_ab1826',
    false,
  );
  return (
    <>
      <Box sx={{ width: '100%', m: theme => theme.spacing(1, 0) }}>
        {isPending(complianceMatrix?.matrixReq!) && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        {!!complianceMatrix?.ab1826 && (
          <PrimaryCollapse
            isOpen={ab1826Collapse}
            customHeader={
              <CollapsibleTitle
                title="AB1826 MORe"
                isOpen={ab1826Collapse}
                titleIcon={stateMap[ab1826MoreTotal?.id!]}
                handlerClick={() => changeAb1826Collapse(!ab1826Collapse)}>
              </CollapsibleTitle>
            }>
            <Suspense fallback={<LinearProgress />}>
              {ab1826Collapse && <AB1826 {...complianceMatrix?.ab1826} />}
            </Suspense>
          </PrimaryCollapse>
        )}
      </Box>
    </>
  );
};

export default observer(AB1826Collapse);
