import { useEffect, useState, useRef } from 'react';

import { Grid, IconButton, LinearProgress, Popover, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { GRID_ROW_HEIGHT } from '../../../../Table/constants';
import { useAssetsTableStore } from '../../../AssetsTableProvider';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { StyledTextArea } from 'src/components/StyledTextArea';
import { isPending } from 'src/utils/common';
import { TextWrapper } from '../../EditAsset/styles';
import { RenderCellComponentProps } from '../types';
import { DEFAULT_POPUP_AREA_WIDTH } from 'src/components/AssetsTable/helpers/common';

export const DescriptionCell = observer(
  ({ id, hasFocus, row }: RenderCellComponentProps) => {
    const ref = useRef(null);
    const { chosenAsset, selectAsset, removeChosenAsset } = useAssetsTableStore();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const openPopover = () => {
      selectAsset(Number(id))
      !open && setAnchorEl(ref.current);
    };

    useEffect(() => {
      if (hasFocus) {
        setAnchorEl(ref.current);
        if (chosenAsset?.descriptionTruncated)
          chosenAsset?.getFullDescription();
      }
    }, [hasFocus, ref, chosenAsset]);

    const onClose = () => {
      removeChosenAsset();
      setAnchorEl(null)
    }

    return (
      <>
        <Grid container flexDirection="row" wrap="nowrap">
          <Typography
            sx={{
              height: GRID_ROW_HEIGHT,
              alignItems: "center",
              display: "flex",
            }}
            ref={ref}
            noWrap>
            <span style={{  overflow:"hidden", textOverflow: "ellipsis" }}>
              {row?.description}
            </span>
          </Typography>
          <Grid item>
            {row.descriptionTruncated && <IconButton size="small" onClick={openPopover}><VisibilityIcon  sx={{cursor: "pointer"}}/></IconButton>}
          </Grid>
        </Grid>
        {hasFocus && !!chosenAsset && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            // safari fix
            PaperProps={{
              sx: {
                zIndex: 1
              }
            }}
          >
            <TextWrapper>
              {isPending(chosenAsset?.fullDescriptionReq) ? (
              <LinearProgress sx={{ width: DEFAULT_POPUP_AREA_WIDTH }} />
              ) : (
              <StyledTextArea
                readOnly
                value={chosenAsset?.description}
                minRows={5}
                maxRows={7}
                sx={{ width: DEFAULT_POPUP_AREA_WIDTH }}
              />
              )}
            </TextWrapper>
          </Popover>
        )}
      </>
    );
  },
);
