import { FileBase } from './base';
import { action, computed, makeObservable, observable, override } from 'mobx';
import { FileEntityType } from '../../api/api-types/files';
import { ApiReq, emptyValue } from '../../api';
import { AssetsApi } from '../../api/assets';
import { DownloadTokenResponse } from 'src/types';

export class AttachmentFile extends FileBase {
  readonly assetsApi = new AssetsApi();

  @observable.ref downloadTokenReq: ApiReq<DownloadTokenResponse> = emptyValue;

  constructor(props: FileEntityType) {
    super(props);
    makeObservable(this);
  }
  @computed get fileToken() {
    if (
      this.downloadTokenReq.state !== 'fulfilled' ||
      !this.downloadTokenReq.value
    ) {
      return '';
    }

    return this.downloadTokenReq.value.data?.token;
  }

  @action getFileDownloadToken = async (
    assetId: number,
    attachmentId: number,
  ) => {
    this.downloadTokenReq = this.assetsApi.getDownloadAttachmentToken(
      attachmentId,
      assetId,
    );
    await this.downloadTokenReq;
  };

  @override async fetchSrc() {
    if (!!this.src || !this.fileToken) return;
    this.filesReq = this.api.getFilesUrlByToken(this.fileToken);

    await this.filesReq;
  }
}
