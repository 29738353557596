import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { WithMinervaColors } from '../../../utils/theme/minerva-main';

export default ({
  color = 'primary',
  ...props
}: WithMinervaColors<SvgIconProps>) => (
  <SvgIcon
    width="18"
    height="18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M15.222 1H2.778C1.796 1 1 1.796 1 2.778v12.444C1 16.204 1.796 17 2.778 17h12.444c.982 0 1.778-.796 1.778-1.778V2.778C17 1.796 16.204 1 15.222 1ZM5.445 9h7.111"
      stroke="#767D86"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
