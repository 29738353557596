import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function TableLoader() {
  return (
    <Stack spacing={1}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item>
          <Skeleton variant="rectangular" width={210} height={48} />
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" width={210} height={48} />
        </Grid>
      </Grid>
      <Stack spacing={2} justifyContent="flex-end">
        <Skeleton variant="rectangular" height={20} />
        <Skeleton variant="rectangular" height={20} />
        <Skeleton variant="rectangular" height={20} />
        <Skeleton variant="rectangular" height={20} />
        <Skeleton variant="rectangular" height={20} width="80%" />
        <Skeleton variant="rectangular" height={20} width="80%" />
        <Skeleton variant="rectangular" height={20} width="60%" />
        <Skeleton variant="rectangular" height={20} width="60%" />
        <Skeleton variant="rectangular" height={20} width="60%" />
        <Skeleton variant="rectangular" height={20} width="40%" />
        <Skeleton variant="rectangular" height={20} width="40%" />
      </Stack>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item> </Grid>
        <Grid item>
          <Skeleton variant="rectangular" width={210} height={48} />
        </Grid>
      </Grid>
    </Stack>
  );
}
