import { ApiClient } from '.';
import * as signalR from '@microsoft/signalr';
import { authStore } from '../stores/auth';
import { ExportParams } from '../types';

export class SignalrApi extends ApiClient {
  connection?: signalR.HubConnection = undefined;

  constructor(props: { prefix: string } = { prefix: '' }) {
    super(props);
  }

  connect = async () => {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.apiBase + '/hubs/export', {
        accessTokenFactory: () => authStore.token,
      })
      .configureLogging(signalR.LogLevel.Information)
      .build();
    await this.connection.start();
    return this.connection;
  };

  getExportFile(id: string) {
    return this.requestExportGet<Blob, ExportParams>(`/export/${id}/file`);
  }
}
