import { SvgIcon, SvgIconProps } from "@mui/material";

const UploadFileIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="31"
    height="40"
    viewBox="0 0 31 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.4293 0C2.0674 0 0.953125 1.125 0.953125 2.5V37.5C0.953125 38.875 2.0674 40 3.4293 40H28.191C29.5529 40 30.6672 38.875 30.6672 37.5V10L20.7625 0H3.4293Z"
      fill="#444545"
      fillOpacity="0.1"
    />
    <path
      d="M23.2379 10H30.6664L20.7617 0V7.5C20.7617 8.875 21.876 10 23.2379 10Z"
      fill="#444545"
      fillOpacity="0.3"
    />
    <path
      d="M30.6668 17.5L23.2383 10H30.6668V17.5Z"
      fill="#444545"
      fillOpacity="0.1"
    />
    <path
      d="M12.9526 22.2298V30.7435C12.9526 31.1108 13.2503 31.4085 13.6176 31.4085H18.0858C18.4531 31.4085 18.7508 31.1108 18.7508 30.7435V22.2298H20.6505C21.2429 22.2298 21.5396 21.5136 21.1207 21.0947L16.8761 16.8501C16.3104 16.2844 15.3931 16.2844 14.8274 16.8501L10.5828 21.0947C10.1639 21.5136 10.4606 22.2298 11.053 22.2298H12.9526Z"
      fill="#444545"
      fillOpacity="0.3"
    />
    <path
      d="M10.6709 14.5141H21.0346C21.7503 14.5141 22.3305 13.9339 22.3305 13.2182V13.1826C22.3305 12.4669 21.7503 11.8867 21.0346 11.8867H10.6709C9.95515 11.8867 9.375 12.4669 9.375 13.1826V13.2182C9.375 13.9338 9.95515 14.5141 10.6709 14.5141V14.5141Z"
      fill="#444545"
      fillOpacity="0.3"
    />
  </SvgIcon>
);
export default UploadFileIcon;
