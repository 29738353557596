import { ContainerItem } from 'src/api/api-types/containers';
import { ItemType } from 'src/types';

export class Container {
  readonly id: number;
  readonly name: string;
  readonly jurisdiction: ItemType;
  readonly hauler: ItemType;
  readonly createdAt: string;
  readonly wasteMaterialTypes: ItemType[];

  constructor({
    id,
    name,
    jurisdiction,
    hauler,
    createdAt,
    wasteMaterialTypes,
  }: ContainerItem) {
    this.id = id;
    this.name = name;
    this.jurisdiction = jurisdiction;
    this.hauler = hauler;
    this.createdAt = createdAt;
    this.wasteMaterialTypes = wasteMaterialTypes;
  }
}
