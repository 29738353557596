import { ApiClient } from '.';
import {
  ContainersResponse,
  RequestContainersParams,
  UpdatedContainerResponse,
  UpdateMaterialTypes,
} from './api-types/containers';
import { dotNameKeyToString } from '../utils/string';
import { ExportParams, AsyncExportResponse } from 'src/types';

export class ContainersApi extends ApiClient {
  getContainersData({ page, pageSize, filters }: RequestContainersParams) {
    return this.requestGet<ContainersResponse>('containers', {
      'page.num': page,
      'page.size': pageSize,
      ...dotNameKeyToString(filters),
    });
  }

  updateWasteMaterialTypes({ id, wasteMaterialTypes }: UpdateMaterialTypes) {
    return this.requestPost<
      UpdatedContainerResponse,
      Pick<UpdateMaterialTypes, 'wasteMaterialTypes'>
    >(`containers/${id}/waste-material-types`, {
      wasteMaterialTypes,
    });
  }

  getAsyncReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `containers/export/${exportType}?flowType=2`,
      params,
    );
  }
}
