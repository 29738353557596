import { ApiError } from 'src/stores/global-vm';
import { ItemType } from 'src/types';
import { LinkFileDto } from './files';

export interface Session {
  id: number;
  jurisdiction: ItemType;
  hauler: ItemType;
  state: ItemType;
  stage: ItemType;
  createdAt: string;
  createdBy: ItemType;
  updatedAt: string;
  updatedBy: ItemType;
  errors: ApiError[];
  isCanceled: boolean;
  fileToken?: string;
  file?: LinkFileDto;
}

export type InitializeData = {
  jurisdiction: {
    id: number;
  };
  hauler: {
    id: number;
  };
  dataDate: Date | string;
};

export type LinkedFile = {
  id: number;
  file: LinkFileDto;
};

export enum ImportStates {
  Pending = 1,
  Running = 2,
  Failed = 3,
  Succeeded = 4,
}

export enum ImportStages {
  FileUpload = 1,
  FileProcessing = 2,
  ColumnsProcessing = 3,
  ColumnsMatching = 4,
  ValuesProcessing = 5,
  ValuesMatching = 6,
  DataProcessing = 9,
  TransformationsProcessing = 10,
  DataValidation = 11,
  AddressAdjustment = 7,
  ContainersProcessing = 12,
  ContainersMatching = 13,
  ImportReview = 14,
  DataTransferring = 8,
  AddressesStandardization = 15,
}

export enum MatchingValuesTypes {
  AccountType = 1000,
  UnitOfMeasure = 1001,
  RecycleOrganics = 1002,
  Boolean = 2,
  Numeric = 4,
  Integer = 3,
  Schedule = 7,
}

export type ImportStepState = { step: ImportStages; state: ImportStates };

export interface ImportFileColumn {
  id: number;
  name: string;
  order: number;
  type: string;
  dbType: string;
  required?: boolean;
  isChanged: boolean;
  value?: ItemType;
}

export type ColumnsFormTemplatesType = Omit<
  ImportFileColumn,
  'dbType' | 'type' | 'order' | 'value'
> & { value?: ItemType };

export type MergedColumns = {
  source: { id: number };
  template: { id: number };
};

export interface MergeColumnsData {
  id: number;
  columns: MergedColumns[];
}

export interface MergeValuesData {
  id: number;
  values: ImportValueItem[];
}

export type MergedContainersData = Array<{
  id: number;
  wasteMaterialTypes: ItemType[];
}>;

export interface MergeContainersData {
  id: number;
  data: MergedContainersData;
}

export type ImportValueItem = {
  id: number;
  sourceValue: string;
  templateValue: string | null;
  valueTypeId: number | null;
  selectedOptionId: string | null;
};

export type ImportValueOption = {
  id: string;
  name: string;
  value: string;
  valueTypeId: number;
  valueOption: boolean;
};

export type DestinationValuesLookup = {
  ignoreAvailable: boolean;
  overThresholdAvailable: boolean;
  items: ItemType[];
  withNumberInput: boolean;
};

export interface ImportFileValue {
  id: number;
  name: string;
  type: ItemType;
  values: ImportValueItem[];
  options: ImportValueOption[];
}

export type ImportStepStateType = {
  step: ImportStages;
  state: ImportStates;
};

export interface ImportContainerValue {
  id: number;
  name: string;
  isNew?: boolean;
  isUpdated?: boolean;
  wasteMaterialTypes?: ItemType[];
  value?: ItemType[];
}
