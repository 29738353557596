import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';

import { FoodsApi } from '../../api/foods';
import { ApiReq, emptyValue } from '../../api';
import {
  EfrDetailsType,
  EFRResponse,
  FoodFilterType,
  FoodRecoveryItem,
} from 'src/api/api-types/foods';
import { lookupsStore } from '../../stores/lookups';
import { GridRowId } from '@mui/x-data-grid-pro';
import {
  defaultFilterField,
  initialFoodsData,
} from '../../pages/EdibleFoodRecovery/helpers/common';
import { columns } from 'src/pages/EdibleFoodRecovery/helpers/gridColums';
import { OnStartEditType } from '../../components/Table/types';
import { TableFilter } from '../filter/table-filter';
import {
  EFRExportColumnsNameMap,
  EFRFilterNameMap,
  EFRHiddenColumnsType,
} from '../filter/constants';
import { userStore } from '../../stores/user';
import { EFRItem } from './efr-item';
import { EfrDetailsItem } from './efr-details-item';
import { TablePagination } from '../pagination/pagination';
import { ExportDataType } from 'src/api/exportTypes';
import { authStore } from 'src/stores/auth';
import { GridColumnsManager } from '../grid-columns-manager/grid-columns-manager';
import { AsyncExportEntity } from '../export/AsyncExportEntity';

export class EfrModel {
  api = new FoodsApi({ prefix: '' });

  @observable isSearchOpen = false;
  @observable isCreation = false;
  @observable editFoodModal = false;
  @observable deleteFoodModal = false;
  @observable isAdding = false;

  @observable filter = new TableFilter<FoodFilterType, FoodRecoveryItem>(
    columns,
    defaultFilterField(),
    EFRFilterNameMap,
    (column, filters) => this.api.getFoodsFilterOptions({ filters, column })
  );
  @observable selectedEFR?: EfrDetailsItem;
  @observable selectedFoods: Array<GridRowId> = [];
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable.ref foodsReq: ApiReq<EFRResponse> = emptyValue;
  @observable pagination = new TablePagination<EFRResponse>({
    requestRef: this.foodsReq,
  });
  @observable.ref foods: IObservableArray<EFRItem> = observable([]);
  @observable.ref modificationFood?: EfrDetailsItem;
  @observable columnsManager;

  @computed get isInitialPending() {
    return (
      this.foodsReq.state !== 'fulfilled' &&
      lookupsStore.tiersLookupsReq.state !== 'fulfilled' &&
      lookupsStore.edibleFoodGeneratorsTypesReq.state !== 'fulfilled' &&
      lookupsStore.foodRecoveriesTypesReq.state !== 'fulfilled' &&
      lookupsStore.jurisdictionsReq.state !== 'fulfilled'
    );
  }

  constructor() {
    makeObservable(this);
    this.columnsManager = new GridColumnsManager<
      EFRItem & { actions: boolean }
    >({
      columns: columns,
      columnsMap: this.columnsVisibleMap,
      visibilityModel: {
        admin: { description: false },
        jurisdiction: { description: false },
        hauler: { description: false },
      },
    });
    reaction(
      () => [userStore.clientType?.id],
      () => {
        const defaultField = defaultFilterField(userStore.clientType?.id);
        this.filter = new TableFilter<FoodFilterType, FoodRecoveryItem>(
          columns,
          defaultField,
          EFRFilterNameMap,
          (column, filters) => this.api.getFoodsFilterOptions({ filters, column })
        );
      },
    );
    this.pagination.setFetchingCallBack(this.fetchFoods);
  }

  @computed get columnsVisibleMap() {
    const mapParams = {
      ...(!authStore.fCfg.isEFRPPhase2 && {
        assetCounter: false,
        annualCapacity: false,
        totalPounds: false,
        description: false
      }),
    };
    return {
      admin: mapParams,
      jurisdiction: mapParams,
      hauler: mapParams,
      agency: mapParams
    };
  }

  @computed get foodsData() {
    if (this.foodsReq.state !== 'fulfilled' || !this.foodsReq.value) {
      return [];
    }
    return this.foodsReq.value.data?.items || [];
  }

  @action selectEfr = (id: number) => {
    const needle = this.foods.find(item => item.id === id);
    if (needle) {
      this.selectedEFR = new EfrDetailsItem(needle as any);
      return needle;
    }
    return undefined;
  };

  @action removeSelectedEFR = () => (this.selectedEFR = undefined);

  @action deleteFoods = () => {
    (async () => {
      await Promise.all(
        this.selectedFoods.map(async id => {
          const needle = this.foods.find(item => item.id === id);
          if (!!needle) {
            this.modificationFood = new EfrDetailsItem(needle);
            await this.modificationFood?.deleteFood();
          }
        }),
      );
      this.removeSelectedFoods();
      await this.fetchFoods();
    })();
  };

  @action prepareAssetMainValues = async () => {
    try {
      await lookupsStore.fetchFoodType();
    } catch (e) {
      throw e;
    }
  };

  @action addNewFood = (name?: string) => {
    this.removeSelectedFoods();
    this.modificationFood = new EfrDetailsItem({
      ...initialFoodsData,
      ...{ name: name || '' },
    });
    this.isCreation = true;
    (() => this.prepareAssetMainValues())();
  };

  @action setNewFood = (food: EfrDetailsType) => {
    this.isCreation = true;
    this.modificationFood = new EfrDetailsItem(food);
  };

  @action editEFRAction = (id: number) => {
    this.editFoodModal = true;
    this.chooseEfr(id);
  };

  @action modifyFood = async (entity: EfrDetailsType) => {
    try {
      this.modificationFood?.setEntity(entity);
      await this.modificationFood?.editFood();
      this.removeChosenFood();
      this.editFoodModal = false;
      await this.fetchFoods();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  @action activateFood = async (id: number) => {
    this.modificationFood = new EfrDetailsItem({ ...initialFoodsData, id });
    try {
      await this.modificationFood?.activateFood();
      this.removeChosenFood();
      this.closeAdding();
      await this.fetchFoods();
      this.removeSelectedFoods();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  @action toggleAdding = () => {
    this.isAdding = !this.isAdding;
  };

  @action openAdding = () => {
    this.isAdding = true;
  };

  @action closeAdding = () => {
    this.isAdding = false;
  };

  @action handleStartEditAction = async ({
    field,
  }: OnStartEditType<EFRItem>) => {
    if (field === 'type') return lookupsStore?.fetchFoodType();
    if (field === 'foodWillingTypes')
      return lookupsStore?.fetchFoodWillingType();
  };

  @action fetchFoods = async () => {
    runInAction(() => {
      this.foodsReq = this.api.getFoods({
        ...this.pagination.serialize,
        filters: this.filter.filterParams,
      });
    });

    await this.foodsReq;

    runInAction(() => {
      if (
        this.foodsReq.state !== 'fulfilled' ||
        this.foodsReq.value?.data === null
      )
        return;
      this.pagination.setPagination(this.foodsReq.value?.data);
      this.foods.replace(this.foodsData.map(data => new EFRItem(data)));
    });

    await this.foodsReq;
  };

  @action handleCancelEditAction = () => {
    this.foods.replace(this.foods);
  };

  @action removeChosenFood = () => {
    this.modificationFood = undefined;
  };

  @action setSelectedFoods = (items: GridRowId[]) => {
    this.selectedFoods = items;
  };

  @action removeSelectedFoods = () => {
    if (this.selectedFoods.length === 0) return;
    this.selectedFoods = [];
  };

  @action cancelEdit = () => {
    this.editFoodModal = false;
    this.removeChosenFood();
  };

  @action cancelCreation = () => {
    this.isCreation = false;
    this.removeChosenFood();
  };

  @action chooseEfr = (id: number) => {
    const needle = this.foods.find(item => item.id === id);
    if (needle) {
      this.modificationFood = new EfrDetailsItem(needle);
      return needle;
    }
    return undefined;
  };
  @action toggleDeleteModal = (force: boolean) => {
    this.deleteFoodModal = force;
  };

  @action creationEFR = async (data: Partial<EfrDetailsType>) => {
    this.modificationFood?.setEntity(data);
    this.isCreation = false;
    return this.modificationFood?.createFood();
  };

  @action saveNewFood = async (data: Partial<EfrDetailsType>) => {
    await this.creationEFR(data);
    this.closeAdding();
    await this.fetchFoods();
  };

  @action resetStore = () => {
    this.pagination.resetPagination();
    this.filter.resetFilters();
    this.filter.setSearchOpen(false);
    this.removeSelectedFoods();
  };

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncReportByType(
        this.exportEntity.exportParams<
          EFRResponse,
          FoodFilterType,
          FoodRecoveryItem
        >(
          this.columnsManager.hiddenColumns.map(
            el => EFRExportColumnsNameMap[el as EFRHiddenColumnsType],
          ),
          this.filter,
          this.pagination,
          this.selectedFoods
        ),
        data.type,
      )
    );
  };
}
