import {CreatedByType, UserAllowedEmailsResponse, UserItem} from "../../../api/api-types/user";
import {UsersApi} from "../../../api/users";
import {action, makeObservable, observable, runInAction} from "mobx";
import {ItemType} from "../../../types";
import {ApiReq, emptyValue} from "../../../api";
import {ADMIN_USER_ID} from "../helpers/common";
import {globalViewModelStore} from "../../../stores/global-vm";

export class ModifyUser {
  api = new UsersApi();
  readonly defaultState: UserItem;
  @observable createdBy: CreatedByType | null;
  @observable id: number;
  @observable firstName: string;
  @observable lastName: string;
  @observable email: string;
  @observable client: ItemType | null;
  @observable clientType: ItemType | null;
  @observable employer: string;
  @observable active: boolean;
  @observable jurisdictions: ItemType[] | null = [];
  @observable agencies: ItemType[] | null = [];
  @observable haulers: ItemType[] | null = [];
  @observable allowedEmailDomain?: string;
  @observable.ref createReq: ApiReq<UserItem> = emptyValue;
  @observable.ref editReq: ApiReq<UserItem> = emptyValue;
  @observable.ref editEmailReq: ApiReq<UserItem> = emptyValue;
  @observable.ref userStatusReq: ApiReq<UserItem> = emptyValue;
  @observable.ref userResetPasswordReq: ApiReq<UserItem> = emptyValue;
  @observable.ref allowedEmailsReq: ApiReq<UserAllowedEmailsResponse> = emptyValue;


  constructor(item: UserItem) {
    makeObservable(this);
    const {
      id,
      firstName,
      lastName,
      email,
      active,
      clientType,
      client,
      createdBy,
      jurisdictions,
      haulers,
      agencies,
      employer
    } = item;

    this.id = id;
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.email = email || '';
    this.active = active;
    this.client = client || null;
    this.jurisdictions = jurisdictions || null;
    this.haulers = haulers || null;
    this.agencies = agencies || null;
    this.clientType = clientType ?? null;
    this.employer = employer || '';
    this.createdBy = createdBy || null;
    this.defaultState = item
  }


  @action setEntity = async (clientFields: Partial<UserItem>) => {
    this.firstName = !!clientFields.firstName ? clientFields.firstName : this.firstName;
    this.lastName = !!clientFields.lastName ? clientFields.lastName : this.lastName;
    this.email = !!clientFields.email ? clientFields.email : this.email;
    this.client = clientFields.client ?? null;
    this.clientType = clientFields.clientType ?? null;
    this.jurisdictions = clientFields.jurisdictions ?? this.jurisdictions;
    this.haulers = clientFields.haulers ?? this.haulers;
    this.agencies = clientFields.agencies ?? this.agencies;
    this.employer = clientFields.employer ?? this.employer;
  };

  @action createUser = async () => {
    this.createReq = this.api.createNewUser({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      jurisdictions: this.jurisdictions,
      haulers: this.haulers,
      agencies: this.agencies,
      clientId: this.client?.id ?? ADMIN_USER_ID,
      employer: this.employer
    });
    const res = await this.createReq;
    runInAction(() => {
      if (res.data) {
        this.id = res.data?.id;
        this.createdBy = res.data?.createdBy;
      }
    });
    return this.createReq;
  };

  @action editInfo = async () => {
    this.editReq = this.api.updateUserInfo({
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      jurisdictions: this.jurisdictions,
      haulers: this.haulers,
      agencies: this.agencies,
      employer: this.employer
    });
    return this.editReq;
  };

  @action editEmail = async () => {
    this.editEmailReq = this.api.updateUserEmail({
      id: this.id,
      email: this.email,
    });
    return this.editReq;
  };

  @action changeStatus = async () => {
    this.userStatusReq = this.api.changeUserStatus({
      id: this.id,
      active: !this.active,
    });
    const res = await this.userStatusReq;
    runInAction(() => {
      if (res.data) {
        this.active = res.data?.active;
      }
    });
    return this.userStatusReq;
  };

  @action resetPassword = async () => {
    try {
      this.userResetPasswordReq = this.api.resetPasswordRequest(this.id);
      const res = await this.userResetPasswordReq;
      runInAction(() => {
        if (res.status === 200) {
          globalViewModelStore.setSuccessMessages([{
            message: 'Password successfully reseted',
            code: 'ResetPassword',
          }]);
        }
      });
      return this.userResetPasswordReq;
    } catch (e) {
      throw e;
    }
  };

  @action getUserAllowedEmails = async () => {
    this.allowedEmailsReq = this.api.getAllowedEmailsData();
    const res = await this.allowedEmailsReq;
    runInAction(() => {
      if (res.data) {
        this.allowedEmailDomain = res.data?.allowedEmailDomain;
      }
    });
    return this.allowedEmailsReq;
  };

}