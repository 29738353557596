import { useContext, useState, MouseEvent } from 'react';

import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import { observer } from 'mobx-react-lite';
import AccountChildren from 'src/components/Accounts/AccountChildren';

import {
  accountDetailsStore,
  accountDetailsStoreCtx,
} from '../../../../AccountDetails/store';

import Menu from './Menu';

function ChildOf({ id }: { id: number }) {
  const { account } = useContext(accountDetailsStoreCtx);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    (async () => {
      await accountDetailsStore.fetchAccountDetails(id);
      await accountDetailsStore.account?.fetchAccountChildren();
    })();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: '100%', p: 0 }}>
      <IconButton
        id="ChildOf"
        aria-controls={open ? 'ChildOf' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label="ChildOf"
        size="small"
        sx={{ p: '2px' }}
        onClick={handleClick}>
        <LastPageIcon
          sx={{
            width: '20px',
            height: '20px',
            cursor: 'pointer',
            color: 'primary.light',
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        title="Child Of">
        {account?.parent && <AccountChildren list={[account?.parent]} />}
      </Menu>
    </Box>
  );
}

export default observer(ChildOf);
