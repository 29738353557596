import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TextIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.17138 0C2.62662 0 2.18091 0.45 2.18091 1V15C2.18091 15.55 2.62662 16 3.17138 16H13.0761C13.6208 16 14.0665 15.55 14.0665 15V4L10.1047 0H3.17138Z"
      fill="#E2E5E7"
    />
    <path
      d="M11.0952 4H14.0666L10.1047 0V3C10.1047 3.55 10.5504 4 11.0952 4Z"
      fill="#B0B7BD"
    />
    <path d="M14.0664 7L11.095 4H14.0664V7Z" fill="#CAD1D8" />
    <path
      d="M12.0856 13C12.0856 13.275 11.8627 13.5 11.5903 13.5H0.695185C0.422807 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422807 7.5 0.695185 7.5H11.5903C11.8627 7.5 12.0856 7.725 12.0856 8V13Z"
      fill="#576D7E"
    />
    <path
      d="M3.31951 9.73367H2.62668C2.28199 9.73367 2.28199 9.22217 2.62668 9.22217H4.49965C4.85226 9.22217 4.85226 9.73367 4.49965 9.73367H3.84198V11.7522C3.84198 12.0997 3.31901 12.1082 3.31901 11.7522V9.73367H3.31951Z"
      fill="white"
    />
    <path
      d="M6.15584 10.1931L6.8452 9.32156C7.05122 9.05006 7.45087 9.39756 7.22554 9.65756C6.98783 9.94156 6.73873 10.2496 6.50052 10.5646L7.30874 11.5801C7.52615 11.8801 7.09084 12.1681 6.8769 11.8721L6.14841 10.9286L5.4318 11.8921C5.22975 12.1836 4.78206 11.8561 5.00838 11.5841L5.80372 10.5646C5.55462 10.2491 5.31295 9.94156 5.07127 9.65756C4.82168 9.35756 5.28521 9.05006 5.46697 9.33006L6.15584 10.1931Z"
      fill="white"
    />
    <path
      d="M8.4423 9.73355H7.74997C7.40528 9.73355 7.40528 9.22205 7.74997 9.22205H9.62245C9.97505 9.22205 9.97505 9.73355 9.62245 9.73355H8.96527V11.752C8.96527 12.0995 8.4423 12.108 8.4423 11.752V9.73355Z"
      fill="white"
    />
    <path
      d="M11.5904 13.5H2.18091V14H11.5904C11.8627 14 12.0856 13.775 12.0856 13.5V13C12.0856 13.275 11.8627 13.5 11.5904 13.5Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
export default TextIcon;
