import { useCallback } from 'react';

import MuiButton, { ButtonProps } from '@mui/material/Button';
import { observer } from 'mobx-react-lite';
import { IPromiseBasedObservable } from 'mobx-utils';
import { isPending } from 'src/utils/common';

import { StyledCircularProgress } from '../styles';

export interface Props extends ButtonProps {
  asyncAction?: IPromiseBasedObservable<any>;
}

const Button = observer<Props>(({ asyncAction, ...props }) => {
  const isInProgress = useCallback(
    () => asyncAction && isPending(asyncAction),
    [asyncAction],
  );
  return (
    <MuiButton
      disabled={isInProgress() ? true : props.disabled}
      {...props}
      disableRipple>
      {isInProgress() && <StyledCircularProgress size={15} color="inherit" />}
      {props.children}
    </MuiButton>
  );
});

export default Button;
