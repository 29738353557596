import { useContext } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';

import { EFGItem } from '../../../models/edible-food/efg-item';
import { EFGTransferringInfoContext } from '../store';

interface EFGInfoButtonProps {
  item: EFGItem;
}

export default observer(({ item }: EFGInfoButtonProps) => {
  const { openInfo, setEFGItem, getEFGInfo } = useContext(
    EFGTransferringInfoContext,
  );
  const handleClick = () => {
    setEFGItem(item);
    openInfo();

    return getEFGInfo(item.id);
  };

  return (
    <IconButton aria-label="EFGInfo" onClick={handleClick}>
      <InfoOutlinedIcon />
    </IconButton>
  );
});
