import { ExportTypes } from '../types';

export const ACCOUNT_TYPE_OBJECT = {
  id: 7,
  name: 'Account',
};

export const GENERATOR_TYPE_OBJECT = {
  id: 8,
  name: 'Generator',
};

export const FOOD_RECOVERY_TYPE_OBJECT = {
  id: 9,
  name: 'Food Recovery',
};

export const EXPORT_DEFAULT_OPTIONS = {
  fileName: '',
  mode: ExportTypes.WITH_FILTERS,
};

export const DEBOUNCE_DURATION = 750;

export const FADE_DURATION = 1000;

export const PAGINATION_DEFAULT_STATE = {
  page: 1,
  pageSize: 100,
  totalCount: 0,
};
export const PREV_PAGINATION_DEFAULT_STATE = {
  page: 1,
  pageSize: 50,
  totalCount: 0,
};

export const GENERATOR_ID_DEFINITION =
  'Unique identifier for a Generator which is all active Minerva accounts at a location.';
export const MINERVA_ID_DEFINITION =
  'Unique identifier for a Minerva account which is a unique combination of the hauler, account number, and service address.';
export const IMPORT_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxx";

export const TEMPORARY_USERS_CLIENT_ID = 29419;

export enum CardsType {
  'RecoveryOS' = 6,
  'TotalPounds' = 11
}

export const WeeksHeaderTemplate = [
  { name: 'S', index: 6},
  { name: 'M', index: 0}, 
  { name: 'T', index: 1}, 
  { name: 'W', index: 2}, 
  { name: 'T', index: 3}, 
  { name: 'F', index: 4}, 
  { name: 'S', index: 5}
];

export const DEFAULT_TEXTAREA_POPUP_WIDTH = 380;