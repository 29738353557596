
import InsipidDeMinimisIcon from '../../../../../assets/icons/Dashboard/Insipid/InsipidDeMinimisIcon';
import InsipidEdiblefoodIcon from '../../../../../assets/icons/Dashboard/Insipid/InsipidEdiblefoodIcon';
import InsipidPhysicalSpaceIcon from '../../../../../assets/icons/Dashboard/Insipid/InsipidPhysicalSpaceIcon';
import InsipidRecoveryOSIcon from '../../../../../assets/icons/Dashboard/Insipid/InsipidRecoveryOSIcon';
import InsipidRecyclingIcon from '../../../../../assets/icons/Dashboard/Insipid/InsipidRecyclingIcon';
import NotRecyclingIcon from '../../../../../assets/icons/Dashboard/NoyRecycleIcon';
import TotalPoundsIcon from '../../../../../assets/icons/Dashboard/TotalPoundsIcon';
//todo need more powerful realization
export const cardsIconMap: { [key: string]: JSX.Element } = {
  0: <InsipidRecyclingIcon />,
  1: <InsipidRecyclingIcon />,
  2: <NotRecyclingIcon />,
  3: <InsipidDeMinimisIcon />,
  4: <InsipidPhysicalSpaceIcon />,
  5: <InsipidEdiblefoodIcon />,
  6: <InsipidRecoveryOSIcon />,
  7: <InsipidRecyclingIcon />,
  8: <NotRecyclingIcon />,
  9: <InsipidRecyclingIcon />,
  10: <NotRecyclingIcon />,
  11: <TotalPoundsIcon />,
};

export const ignoreType = (entity: number, target: number | number[]) => {
  if (Array.isArray(target)) {
    return target.indexOf(entity) < 0;
  }
  return target !== entity;
};
