import {
	action,
	computed,
	makeObservable,
	observable,
} from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { DownloadTokenResponse } from 'src/types';
import { ClientsApi } from 'src/api/clients';


export class ClientLogoTokens {
	readonly clientId: number;
	readonly api = new ClientsApi();
	@observable.ref uploadLogoTokenReq: ApiReq<DownloadTokenResponse> = emptyValue;
	@observable.ref downloadingLogoTokenReq: ApiReq<DownloadTokenResponse> = emptyValue;

	constructor(clientId: number) {
		makeObservable(this);
		this.clientId = clientId;
	}

	@computed get isLoading() {
		return [this.uploadLogoTokenReq, this.downloadingLogoTokenReq].some(({state}) => state === 'pending');
	}

	@computed get uploadingLogoToken() {
		if (this.uploadLogoTokenReq.state !== 'fulfilled' || !this.uploadLogoTokenReq.value) return '';
		return this.uploadLogoTokenReq.value.data?.token;
	}

	@computed get downloadingLogoToken() {
		if (this.downloadingLogoTokenReq.state !== 'fulfilled' || !this.downloadingLogoTokenReq.value) return '';
		return this.downloadingLogoTokenReq.value.data?.token;
	}

	@action getUploadLogoToken = async () => {
		this.uploadLogoTokenReq = this.api.getUploadLogoToken(this.clientId);
		return this.uploadLogoTokenReq;
	};
	
	@action getDownloadLogoToken = async () => {
		this.downloadingLogoTokenReq = this.api.getDownloadLogoToken(this.clientId);
		await this.downloadingLogoTokenReq;
	};
}
