import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PPTIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.17178 0C2.62693 0 2.18115 0.45 2.18115 1V15C2.18115 15.55 2.62693 16 3.17178 16H13.078C13.6229 16 14.0687 15.55 14.0687 15V4L10.1062 0H3.17178Z"
      fill="#E2E5E7"
    />
    <path
      d="M11.0968 4H14.0687L10.1062 0V3C10.1062 3.55 10.552 4 11.0968 4Z"
      fill="#B0B7BD"
    />
    <path d="M14.0688 7L11.0938 4H14.0688V7Z" fill="#CAD1D8" />
    <path
      d="M12.0875 13C12.0875 13.275 11.8646 13.5 11.5921 13.5H0.695264C0.422842 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422842 7.5 0.695264 7.5H11.5921C11.8646 7.5 12.0875 7.725 12.0875 8V13Z"
      fill="#F15642"
    />
    <path
      d="M2.47498 9.47351C2.47498 9.34151 2.57798 9.19751 2.74389 9.19751H3.65859C4.17363 9.19751 4.63717 9.54551 4.63717 10.2125C4.63717 10.8445 4.17363 11.1965 3.65859 11.1965H2.99745V11.7245C2.99745 11.9005 2.88701 12 2.74438 12C2.61364 12 2.47547 11.9005 2.47547 11.7245V9.47351H2.47498ZM2.99745 9.70101V10.697H3.65859C3.92403 10.697 4.13401 10.4605 4.13401 10.2125C4.13401 9.93301 3.92403 9.70101 3.65859 9.70101H2.99745Z"
      fill="white"
    />
    <path
      d="M5.13745 9.47351C5.13745 9.34151 5.24046 9.19751 5.40686 9.19751H6.32156C6.8366 9.19751 7.30014 9.54551 7.30014 10.2125C7.30014 10.8445 6.8366 11.1965 6.32156 11.1965H5.66042V11.7245C5.66042 11.9005 5.54949 12 5.40686 12C5.27612 12 5.13745 11.9005 5.13745 11.7245V9.47351ZM5.65992 9.70101V10.697H6.32106C6.58651 10.697 6.79649 10.4605 6.79649 10.2125C6.79649 9.93301 6.58651 9.70101 6.32106 9.70101H5.65992Z"
      fill="white"
    />
    <path
      d="M8.54641 9.73355H7.85275C7.50742 9.73355 7.50742 9.22205 7.85275 9.22205H9.72879C10.0821 9.22205 10.0821 9.73355 9.72879 9.73355H9.07037V11.7525C9.07037 12.1 8.54641 12.1085 8.54641 11.7525V9.73355Z"
      fill="white"
    />
    <path
      d="M11.5921 13.5H2.18115V14H11.5921C11.8645 14 12.0874 13.775 12.0874 13.5V13C12.0874 13.275 11.8645 13.5 11.5921 13.5Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
export default PPTIcon;
