import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const WordIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.17138 0C2.62662 0 2.18091 0.45 2.18091 1V15C2.18091 15.55 2.62662 16 3.17138 16H13.0761C13.6208 16 14.0665 15.55 14.0665 15V4L10.1047 0H3.17138Z"
      fill="#E2E5E7"
    />
    <path
      d="M11.0952 4H14.0666L10.1047 0V3C10.1047 3.55 10.5504 4 11.0952 4Z"
      fill="#B0B7BD"
    />
    <path d="M14.0664 7L11.095 4H14.0664V7Z" fill="#CAD1D8" />
    <path
      d="M12.0856 13C12.0856 13.275 11.8627 13.5 11.5903 13.5H0.695185C0.422807 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422807 7.5 0.695185 7.5H11.5903C11.8627 7.5 12.0856 7.725 12.0856 8V13Z"
      fill="#65AFFF"
    />
    <path
      d="M2.07488 12C1.94414 12 1.80151 11.9275 1.80151 11.752V9.48152C1.80151 9.33752 1.94414 9.23352 2.07488 9.23352H2.98166C4.79124 9.23352 4.75113 12 3.01682 12H2.07488ZM2.32448 9.72102V11.512H2.98166C4.05087 11.512 4.09791 9.72102 2.98166 9.72102H2.32448Z"
      fill="white"
    />
    <path
      d="M6.26645 12.04C5.534 12.072 4.77332 11.58 4.77332 10.6005C4.77332 9.61704 5.5335 9.13354 6.26645 9.13354C6.95928 9.16904 7.68381 9.65304 7.68381 10.6005C7.68381 11.5485 6.95928 12.04 6.26645 12.04ZM6.22287 9.65354C5.77914 9.65354 5.29628 9.96954 5.29628 10.601C5.29628 11.229 5.77963 11.549 6.22287 11.549C6.67848 11.549 7.1653 11.229 7.1653 10.601C7.1653 9.96904 6.67848 9.65354 6.22287 9.65354Z"
      fill="white"
    />
    <path
      d="M8.1499 10.5966C8.1499 9.82512 8.62929 9.16162 9.54003 9.16162C9.88471 9.16162 10.1581 9.26562 10.4468 9.51762C10.5538 9.61712 10.5657 9.79312 10.4587 9.90512C10.3517 10.0006 10.1893 9.98912 10.0942 9.89312C9.93225 9.72112 9.76585 9.67362 9.54003 9.67362C8.93039 9.67362 8.63771 10.1096 8.63771 10.5971C8.63771 11.0931 8.92643 11.5486 9.54003 11.5486C9.76585 11.5486 9.97583 11.4561 10.1581 11.2926C10.2804 11.1966 10.4508 11.2441 10.5221 11.3406C10.5855 11.4286 10.6167 11.5766 10.4785 11.7161C10.2012 11.9766 9.86886 12.0286 9.53953 12.0286C8.58175 12.0286 8.1499 11.3681 8.1499 10.5966Z"
      fill="white"
    />
    <path
      d="M11.5904 13.5H2.18091V14H11.5904C11.8627 14 12.0856 13.775 12.0856 13.5V13C12.0856 13.275 11.8627 13.5 11.5904 13.5Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
export default WordIcon;
