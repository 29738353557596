import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InsipidRecyclingIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect width="40" height="40" rx="20" fill="#E5E9ED" />
    <path
      d="M30.1362 19.6901C30.1362 19.6901 31.5546 21.1043 30.9026 22.6154C30.2507 24.1264 28.1099 27.0208 28.1099 27.0208C27.7503 25.2367 26.9745 23.5627 25.8458 22.1351L24.5684 22.8356L25.9427 17.4609L31.1097 19.0029L30.1362 19.6901Z"
      fill="#767D86"
    />
    <path
      d="M27.483 28.1651C27.2616 28.5281 26.9643 28.8389 26.6115 29.0761C26.2587 29.3134 25.8588 29.4715 25.4391 29.5396C23.9943 29.7863 20.8184 29.6057 20.8184 29.6057L20.8756 24.8125L26.4346 24.8786C27.0913 25.8512 27.4552 26.9918 27.483 28.1651Z"
      fill="#767D86"
    />
    <path
      d="M14.3044 12.3079C14.3044 12.3079 14.7449 10.3563 16.3747 10.1051C18.0045 9.85403 21.5857 10.1316 21.5857 10.1316C20.2674 11.3851 19.2637 12.9323 18.6565 14.6472L19.9251 15.3565L14.6392 17.0526L13.1855 11.8629L14.3044 12.3079Z"
      fill="#767D86"
    />
    <path
      d="M22.8749 10.0667C23.3 10.0611 23.7213 10.1476 24.1098 10.3202C24.4983 10.4928 24.8449 10.7475 25.1258 11.0667C26.1037 12.1593 27.6366 14.9479 27.6366 14.9479L23.5488 17.4458L20.6504 12.7011C21.1264 11.6293 21.898 10.7154 22.8749 10.0667Z"
      fill="#767D86"
    />
    <path
      d="M15.0195 29.5603C15.0195 29.5603 13.0725 30.0317 12.1255 28.6792C11.1784 27.3267 9.84375 23.9962 9.84375 23.9962C11.5525 24.6215 13.3844 24.8358 15.1913 24.6218L15.2574 23.168L19.1205 27.1549L15.112 30.7542L15.0195 29.5603Z"
      fill="#767D86"
    />
    <path
      d="M9.21346 22.8685C9.01951 22.4907 8.90964 22.0753 8.89139 21.651C8.87314 21.2266 8.94694 20.8034 9.10774 20.4102C9.65835 19.0445 11.4776 16.4453 11.4776 16.4453L15.5257 19.0049L12.5568 23.7055C11.3853 23.7517 10.2251 23.4612 9.21346 22.8685Z"
      fill="#767D86"
    />
  </SvgIcon>
);
export default InsipidRecyclingIcon;
