import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import {
  GeneratorDetailsItem,
  GeneratorDetailsResponse,
} from 'src/api/api-types/generators';
import { GeneratorsApi } from 'src/api/generator';
import { ItemType } from 'src/types';
import { Generator } from './generator';

export class GeneratorModification {
  api = new GeneratorsApi({ prefix: '' });
  readonly usaAddress: string;
  readonly reference: string;
  readonly zone: ItemType | null;
  readonly haveNonEfgAccounts: boolean;
  readonly haveEfgAccounts: boolean;
  readonly jurisdiction: ItemType | null;
  readonly statusDate: Date | string | null;
  readonly isActive: boolean;

  @observable id: number;
  @observable rowId: string;
  @observable addressLine1: string;
  @observable addressLine2: string;
  @observable city: string;
  @observable state: string;
  @observable zipCode: string;
  @observable country: string;
  @observable parentClass?: Generator;
  @observable description?: string;
  @observable nickname: string;
  @observable defaultState: Partial<GeneratorDetailsItem>;
  @observable type: ItemType | null;
  @observable.ref generatorUpdateReq: ApiReq<GeneratorDetailsResponse> =
    emptyValue;
  @observable.ref updateDetailsReq: ApiReq<GeneratorDetailsResponse> =
    emptyValue;
  @observable.ref createReq: ApiReq<GeneratorDetailsResponse> = emptyValue;

  constructor(props: Partial<GeneratorDetailsItem>) {
    makeObservable(this);
    const {
      id,
      rowId,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      usaAddress,
      description,
      country,
      reference,
      zone,
      nickname,
      haveNonEfgAccounts,
      haveEfgAccounts,
      jurisdiction,
      statusDate,
      isActive,
      type
    } = props;

    this.id = id!;
    this.rowId = rowId!;
    this.addressLine1 = addressLine1 || '';
    this.addressLine2 = addressLine2 || '';
    this.city = city || '';
    this.state = state || '';
    this.zipCode = zipCode || '';
    this.usaAddress = usaAddress || '';
    this.reference = reference || '';
    this.description = description || '';
    this.country = country || '';
    this.zone = zone || null;
    this.jurisdiction = jurisdiction || null;
    this.nickname = nickname || '';
    this.haveNonEfgAccounts = !!haveNonEfgAccounts;
    this.haveEfgAccounts = !!haveEfgAccounts;
    this.defaultState = props;
    this.statusDate = statusDate || null;
    this.isActive = isActive || false;
    this.type = type || null;
  }

  @action setEntity = ({
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
  }: Partial<GeneratorDetailsItem>) => {
    this.addressLine1 = addressLine1 || this.addressLine1;
    this.addressLine2 = addressLine2 || this.addressLine2;
    this.city = city || this.city;
    this.state = state || this.state;
    this.zipCode = zipCode || this.zipCode;
  };

  @computed get serialized() {
    return {
      id: this.id,
      nickname: this.nickname,
      type: this.type
    };
  }

  @action createGenerator = async () => {
    this.createReq = this.api.creteGenerator({
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
    });
    return this.createReq;
  };

  @action setDescription = (value: GeneratorModification['description']) =>
    (this.description = value);

  @action submitDescription = async () => {
    this.generatorUpdateReq = this.api.updateGeneratorDescription({
      description: this.description || '',
      id: this.id,
    });
    await this.generatorUpdateReq;
    runInAction(() => {
      if (this.generatorUpdateReq.state === 'fulfilled') {
        this.description = this.generatorUpdateReq.value.data?.description;
      }
    });
  };

  @action setNickname = (nickname: string) => (this.nickname = nickname);

  @action setType = (type: ItemType | null) => (this.type = type);

  @action editDetails = async () => {
    this.updateDetailsReq = this.api.updateGenerator(this.serialized);
    await this.updateDetailsReq;
    runInAction(() => {
      this.defaultState = {
        ...this.defaultState,
        ...this.serialized,
      };
    });
    return this.updateDetailsReq;
  };

  @action resetFields = () => {
    this.nickname = this.defaultState.nickname ?? this.nickname;
  };
}
