import { lazy, Suspense } from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { observer } from 'mobx-react-lite';
import PrimaryCollapse from 'src/components/Collapsible/Primary';
import CollapsibleTitle from 'src/components/Collapsible/components/CollapsibleTitle';

import { useLocalStorage } from '../../../../utils/hooks/custom';
import { ComplianceMatrixStore } from '../../store';
import { stateMap } from '../MatrixCard';

const AB341 = lazy(() => import('./AB341'));

export interface AB341CollapseProps {
  store: ComplianceMatrixStore;
}

const AB341Collapse = ({ store }: AB341CollapseProps) => {
  const { complianceMatrix } = store;
  const { ab341McrTotal } = complianceMatrix?.matrix || {};

  const [ab341Collapse, changeAb341Collapse] = useLocalStorage(
    'isOpen_ab341',
    false,
  );
  return (
    <>
      <Box sx={{ width: '100%', m: theme => theme.spacing(1, 0) }}>
        {!!complianceMatrix?.ab341 && (
          <PrimaryCollapse
            isOpen={ab341Collapse}
            customHeader={
              <CollapsibleTitle
                titleIcon={stateMap[ab341McrTotal?.id!]}
                title="AB341 MCR"
                isOpen={ab341Collapse}
                handlerClick={() => changeAb341Collapse(!ab341Collapse)}>
              </CollapsibleTitle>
            }>
            <Suspense fallback={<LinearProgress />}>
              {ab341Collapse && <AB341 {...complianceMatrix?.ab341} />}
            </Suspense>
          </PrimaryCollapse>
        )}
      </Box>
    </>
  );
};

export default observer(AB341Collapse);
