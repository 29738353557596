import Grid from '@mui/material/Grid';
import { GridProps } from '@mui/material/Grid/Grid';
import { StandardTextFieldProps } from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography';
import { useController } from 'react-hook-form';

import { StyledTextField } from './style';

export interface Props extends StandardTextFieldProps {
  title?: string;
  name: string;
  error?: boolean;
  gridProps?: GridProps;
  onInputChange?: (value: string) => void;
}

const FormTextField = ({
  title,
  name,
  error,
  children,
  gridProps,
  onInputChange,
  ...props
}: Props) => {
  const { field, formState } = useController({ name });

  return (
    <Grid
      sx={{ m: '4px 0px' }}
      container
      item
      direction="column"
      {...gridProps}>
      <Typography variant="body1" textTransform="capitalize">
        {title ? title : name ? name : ''}
      </Typography>
      {children ? (
        children
      ) : (
        <StyledTextField
          {...field}
          {...props}
          onChange={event => {
            const value = event.target.value;
            onInputChange && onInputChange(value);
            field.onChange(value);
          }}
          error={!!formState.errors[name]?.message}
          sx={{
            '& .MuiOutlinedInput-root': {
              color: 'black',
            },
          }}
          helperText={
            !!formState.errors[name]?.message && (
              <Typography variant="body1" color="red">
                {formState.errors[name]?.message}
              </Typography>
            )
          }
        />
      )}
    </Grid>
  );
};

export default FormTextField;
