import Typography from '@mui/material/Typography';

import { RootContainer, MinervaLogo } from './styles';

const SystemError = () => (
  <RootContainer>
    <MinervaLogo fill="#2B59C3" />
    <Typography variant="h3" component="h3">
      Ooops, Something went wrong.
    </Typography>
  </RootContainer>
);

export default SystemError;
