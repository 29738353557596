import { createContext, PropsWithChildren, useContext } from 'react';

import { once } from 'lodash';
import { EfrDetailsItem } from 'src/models/edible-food/efr-details-item';


export interface EFRTableProps {
  selectedEFR: EfrDetailsItem | undefined;
  selectEfr: (id: number) => void;
  removeSelectedEFR: () => void;
}

export const createEFRTableCtx = once(() =>
  createContext<EFRTableProps | undefined>(undefined),
);

export const EFRTableProvider = ({
  children,
  ...props
}: PropsWithChildren<EFRTableProps>) => {
  const EFRTableContext = createEFRTableCtx();
  return (
    <EFRTableContext.Provider value={props}>
      {children}
    </EFRTableContext.Provider>
  );
};

/* Hook to use store in any functional component */
export const useEFRTableStore = () => {
  const context = useContext(createEFRTableCtx());

  if (!context) {
    throw new Error(
      'useEFRTableStore must be used within a EFRTableProvider',
    );
  }

  return context;
};
