import { lazy } from 'react';

import { authenticationParameters } from 'src/config/auth-config';
import { authStore } from 'src/stores/auth';
import { routerStore } from 'src/stores/router';
import { MinervaRoute } from 'src/utils/router';

export const AutoLoginRoute: MinervaRoute<'auto-login'> = {
  name: 'auto-login',
  path: '/auto-login',
  comp: () => lazy(() => import('./index')),

  activate: async () => {
    routerStore?.goTo({ name: 'index' });
  },
};

export const UnauthenticatedAutoLoginRoute: MinervaRoute<'auto-login'> = {
  name: 'auto-login',
  path: '/auto-login',
  comp: () => lazy(() => import('./index')),

  activate: async () => {
    await authStore.msalInstance.loginRedirect({
      ...authenticationParameters,
      prompt: 'select_account',
      redirectStartPage: '/',
    });
  },
};
