import { isValidElement, PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles/withStyles';

import CardTitle from '../../CardTitle';

import CollapsibleButton from './CollapsibleButton';

export interface CollapsibleTitleProps {
  title: string | JSX.Element;
  titleIcon?: JSX.Element;
  isOpen: boolean;
  style?: CSSProperties;
  handlerClick: () => void;
}

const CollapsibleTitle = ({
  title,
  titleIcon,
  isOpen,
  children,
  handlerClick,
  ...rest
}: PropsWithChildren<CollapsibleTitleProps>) => {
  return (
    <CollapsibleTitleWrapper {...rest}>
      {isValidElement(title) ? (
        title
      ) : (
        <CardTitle
          leftIcon={titleIcon}
          onClick={handlerClick}
          sx={{ width: children ? '70%' : '100%' }}>
          {title}
        </CardTitle>
      )}
      <div
        style={{
          paddingTop: '4px',
          marginRight: 8,
          display: 'flex',
          justifyContent: 'flex-end',
          width: children ? '35%' : 'auto',
        }}>
        {children}
        <CollapsibleButton open={isOpen} onClick={handlerClick} />
      </div>
    </CollapsibleTitleWrapper>
  );
};

export const CollapsibleTitleWrapper = styled('div')({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export default CollapsibleTitle;
