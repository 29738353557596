import { Pageable, RequestPaginationParams } from '../../types';
import {
  PAGINATION_DEFAULT_STATE,
  PREV_PAGINATION_DEFAULT_STATE,
} from '../../utils/constants';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { ApiReq } from '../../api';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';
import { authStore } from '../../stores/auth';

export class TablePagination<T extends Pageable> {
  defaultPagination: RequestPaginationParams = PAGINATION_DEFAULT_STATE;
  @observable page = 1;
  @observable pageSize = 100;
  @observable totalCount = 0;
  @observable totalPages = 0;

  @observable.ref request: ApiReq<T>;
  @observable fetchingCallBack?: () => Promise<void>;

  constructor({ requestRef }: { requestRef: ApiReq<T> }) {
    makeObservable(this);
    this.request = requestRef;

    reaction(
      () => [authStore.fCfg.isNewPagination],
      () => {
        this.pageSize = authStore.fCfg.isNewPagination ? 100 : 50;
        this.defaultPagination = authStore.fCfg.isNewPagination
          ? PAGINATION_DEFAULT_STATE
          : PREV_PAGINATION_DEFAULT_STATE;
      },
    );
  }

  @computed get serialize() {
    return {
      page: this.page,
      pageSize: this.pageSize,
      totalCount: this.totalCount,
    };
  }

  @computed get getPagination(): RequestPaginationParams {
    if (this.request.state !== 'fulfilled' || !this.request.value)
      return this.defaultPagination;
    return {
      page: !!this.request.value.data
        ? (this.request.value.data?.pagination?.num as number)
        : this.defaultPagination.page,
      pageSize: !!this.request?.value?.data?.pagination?.size
        ? (this.request.value?.data?.pagination?.size as number)
        : this.defaultPagination.pageSize,
      totalCount: this.request.value.data?.totalCount,
      totalPages: this.request.value.data?.totalPages,
    };
  }

  @action onPaginationModelChange = async ({
    page,
    pageSize,
  }: GridPaginationModel) => {
    runInAction(() => {
      this.page = page + 1;
      this.pageSize = pageSize;
    });
    this.fetchingCallBack && (await this.fetchingCallBack());
  };

  @action setPagination = ({
    pagination,
    totalPages,
    totalCount,
  }: Partial<Pageable>) => {
    this.page = pagination?.num ?? this.page;
    this.pageSize = pagination?.size ?? this.pageSize;
    this.totalCount = totalCount ?? this.pageSize;
    this.totalPages = totalPages ?? this.pageSize;
  };

  @action resetPagination = () => {
    this.page = PAGINATION_DEFAULT_STATE.page;
    this.pageSize = PAGINATION_DEFAULT_STATE.pageSize;
    this.totalCount = PAGINATION_DEFAULT_STATE.totalCount;
  };

  @action setFetchingCallBack = (func: any) => {
    this.fetchingCallBack = func;
  };
}
