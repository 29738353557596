import { useState, MouseEvent, SyntheticEvent, useContext } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ContactType } from 'src/api/api-types/address-book';
import { addressBookStoreCtx } from 'src/pages/AddressBook/store';

export default observer(({ contact }: { contact: ContactType }) => {
  const { deleteContact } = useContext(addressBookStoreCtx);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = async (e: SyntheticEvent) => {
    handleClose();
    if (e.currentTarget.id.includes('delete')) return deleteContact(contact);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem id="delete" onClick={handleAction}>
          Delete Contact
        </MenuItem>
      </Menu>
    </>
  );
});
