import { ChangeEvent, useEffect, useState, useCallback, useRef } from 'react';

import { Grid, Popover, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { TextWrapper } from 'src/pages/EdibleFoodGenerators/components/EfgComponents/styles';
import { useEfgEdit } from 'src/pages/EdibleFoodGenerators/components/efgEditContext';

import { isPending } from '../../../utils/common';
import { StyledTextArea } from '../../StyledTextArea';
import { GRID_ROW_HEIGHT } from '../../Table/constants';

export const DEFAULT_POPUP_AREA_WIDTH = 380;

interface TextAreaComponentProps {
  id: number | string;
  field: string;
  hasFocus: boolean;
}

export const EFGEditDescriptionCell = observer(
  ({ id, field, hasFocus }: TextAreaComponentProps) => {
    const apiRef = useGridApiContext();
    const ref = useRef(null);
    const { chosenFood } = useEfgEdit();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const changeCellHandler = useCallback(
      (fieldName: string, cellValue?: string) => {
        return apiRef.current.setEditCellValue({
          id,
          field: fieldName,
          value: cellValue,
        });
      },
      [apiRef, id],
    );

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
      chosenFood?.setEntity({ description: event.target.value });

    const onBlur = () => changeCellHandler(field, chosenFood?.description);
    const openPopover = () => !open && setAnchorEl(ref.current);

    useEffect(() => {
      if (hasFocus) {
        setAnchorEl(ref.current);
        if (chosenFood?.descriptionTruncated) chosenFood?.getFullDescription();
      }
    }, [hasFocus, ref, chosenFood]);

    return (
      <>
        <Grid overflow="hidden" textOverflow="ellipsis" onClick={openPopover}>
          <Typography
            sx={{
              height: GRID_ROW_HEIGHT,
              width: '100%',
            }}
            ref={ref}
            noWrap>
            {chosenFood?.description}
          </Typography>
        </Grid>
        {hasFocus && !!chosenFood && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <TextWrapper>
              {isPending(chosenFood?.fullDescriptionReq) ? (
                <LinearProgress sx={{ width: DEFAULT_POPUP_AREA_WIDTH }} />
              ) : (
                <StyledTextArea
                  onChange={handleChange}
                  onBlur={onBlur}
                  value={chosenFood?.description}
                  minRows={5}
                  maxRows={7}
                  sx={{ width: DEFAULT_POPUP_AREA_WIDTH }}
                />
              )}
            </TextWrapper>
          </Popover>
        )}
      </>
    );
  },
);
