import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MatrixIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect width="40" height="40" rx="4" fill="#FFD9D9" />
    <path
      d="M17.3333 8H8V17.3333H17.3333V8Z"
      stroke="#00060F"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9993 8H22.666V17.3333H31.9993V8Z"
      stroke="#00060F"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9993 22.6641H22.666V31.9974H31.9993V22.6641Z"
      stroke="#00060F"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.3333 22.6641H8V31.9974H17.3333V22.6641Z"
      stroke="#00060F"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default MatrixIcon;
