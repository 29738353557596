import { Box } from "@mui/material";
import { GridRenderEditCellParams, GridEditInputCell } from "@mui/x-data-grid-pro";
import { ErrorTooltip } from "../Table/styles";

export const EditNameInputCell = (props: GridRenderEditCellParams) => {
	const { error,  } = props;
	return (
	  <ErrorTooltip
		open={!!error}
		title={`${props.colDef.headerName} cannot be empty`}
		placement="bottom-start">
		<Box sx={{ height: 32, width: '100%' }}>
		  <GridEditInputCell {...props} />
		</Box>
	  </ErrorTooltip>
	);
};
