import { useState, MouseEvent, useContext } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ClientsItem } from 'src/api/api-types/clients';
import AdminComponent from 'src/components/AdminComp';
import { clientsStoreCtx } from '../store';
import { authStoreCtx } from 'src/stores/auth';

interface Props {
  client: ClientsItem;
}

interface ActionMenuProps extends Props {
  handleClose: () => void;
  anchorEl: null | HTMLElement;
}

export default observer((props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);


  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (<>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      {open && <ClientActionMenu {...props} handleClose={handleClose} anchorEl={anchorEl} />}
  </>);
});


const ClientActionMenu = observer(({ client, handleClose, anchorEl }: ActionMenuProps) => {
  const { fCfg } = useContext(authStoreCtx);
  const {
    setLogoModalRef,
    setChosenClient,
    changeClientStatusAction,
  } = useContext(clientsStoreCtx);

  const open = Boolean(anchorEl);

  const clientState = client.active ? 'Deactivate' : 'Activate';

  const handleAction = () => {
    changeClientStatusAction(client.id);
    handleClose();
  };
  const handleChangeLogoAction = () => {
    setLogoModalRef(anchorEl);
    setChosenClient(client.id);
    handleClose();
  };
  
  return (
    <Menu
      id="long-menu"
      MenuListProps={{
        'aria-labelledby': 'long-button',
      }}
      keepMounted
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      {fCfg.isClientLogo  && 
        <AdminComponent>
          <MenuItem onClick={handleChangeLogoAction}>
            Change Logo
          </MenuItem>
        </AdminComponent>
      }
      <MenuItem id="state" onClick={handleAction}>
        {clientState}
      </MenuItem>
    </Menu>
  );
})
