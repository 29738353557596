import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  AccountGroupType,
  AccountsResponse,
} from '../../api/api-types/accounts';
import { GridColumnVisibilityModel, GridRowParams } from '@mui/x-data-grid-pro';
import { returnIds } from 'src/utils/common';
import { AccountsApi } from '../../api/account';
import { ApiReq, emptyValue } from '../../api';
import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid/colDef/gridCheckboxSelectionColDef';
import { Account } from './account';

export class AccountsSetUp {
  api = new AccountsApi({ prefix: '' });
  @observable isGroupBy = false;
  @observable groupType: AccountGroupType = null;
  @observable groupedItems: Array<Account> = [];

  @observable createAccountGroupReq: ApiReq<AccountsResponse> = emptyValue;
  @observable createAccountRelatedReq: ApiReq<AccountsResponse> = emptyValue;

  @observable fetchingCallBack?: () => Promise<void>;
  @observable removeGroupItemCallBack?: (id: number) => void;
  @observable resetItemsCallBack?: () => void;
  @observable isCheckBoxVisible: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action createNewGroup = () => {
    this.isGroupBy = true;
  };

  @action chooseGroupType = (type: AccountGroupType) => {
    this.groupType = type;
    runInAction(() => {
      if (this.groupType?.includes('parent') && !!this.groupedItems.length)
        this.groupedItems = this.groupedItems.filter(
          account => !account.isParent && !account.parentId,
        );
    });
  };

  @action isRowSelectable = ({ row }: GridRowParams) => {
    if (this.groupType?.includes('parent'))
      return !row.isParent && !row.parentId;
    return true;
  };

  @action createParentGroup = async (parentId: number) => {
    this.createAccountGroupReq = this.api.createAccountParentGroup(
      parentId,
      returnIds(this.groupedItems),
    );

    await this.createAccountGroupReq;
    this.fetchingCallBack && (await this.fetchingCallBack());
  };

  @action createRelatedGroup = async (accountId: number) => {
    this.createAccountRelatedReq = this.api.createAccountRelatedGroup(
      accountId,
      returnIds(this.groupedItems),
    );

    await this.createAccountRelatedReq;
    this.fetchingCallBack && (await this.fetchingCallBack());
  };

  @action setGroupedItems = (items: Account[]) => (this.groupedItems = items);

  @action cancelGroupBy = () => {
    this.isGroupBy = false;
  };

  @action setFetchingCallBack = (func: any) => {
    this.fetchingCallBack = func;
  };

  @action setRemoveItemCallBack = (func: any) => {
    this.removeGroupItemCallBack = func;
  };

  @action setResetItemsCallBack = (func: any) => {
    this.resetItemsCallBack = func;
  };

  @action checkCheckBoxVisibility = (model: GridColumnVisibilityModel) => {
    const checkBoxState = model[GRID_CHECKBOX_SELECTION_FIELD];
    this.isCheckBoxVisible =
      typeof checkBoxState === 'boolean' ? checkBoxState : true;
  };

  @action finishGroupBy = () => {
    this.isGroupBy = false;
    this.groupType = null;
    this.resetItemsCallBack && this.resetItemsCallBack();
  };
}
