import { lazy } from 'react';

import { FoodParams } from 'src/types';
import { MinervaRoute } from 'src/utils/router';

import { lookupsStore } from '../../stores/lookups';
import { FOOD_RECOVERY_TYPE_OBJECT } from '../../utils/constants';
import { addressBookStore } from '../AddressBook/store';

import { foodDetailsStore } from './store';

export const EdibleFoodRecoveryDetails: MinervaRoute<
  'food-details',
  Partial<FoodParams>
> = {
  name: 'food-details',
  path: '/food-recovery-details/:id',
  comp: () => lazy(() => import('./index')),
  activate: async (_, params) => {
    if (!params?.id) return;
    await foodDetailsStore.fetchFoodDetails(params?.id);
    await lookupsStore.fetchFoodType();
    foodDetailsStore.assets.filter.setFilterValue({
      'q.relatedTo.id': String(params?.id),
    });
    addressBookStore.setRelatedToContact({
      relatedTypeId: FOOD_RECOVERY_TYPE_OBJECT.id,
      relatedId: params?.id,
    });
  },
  deactivate: () => foodDetailsStore.resetFoodDetails(),
};
