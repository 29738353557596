import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/styles';

export const OptionDescription = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 450,
    fontSize: 14,
    lineHeight: 1.5,
    lineBreak: 'auto',
  },
});
