import { action, makeObservable, observable, runInAction, toJS } from 'mobx';
import { ApiReq, emptyValue } from '../../api';
import { SignalrApi } from '../../api/signalr';
import { AsyncExportResponse, ExportTypes, Pageable } from '../../types';
import { downloadBlob } from '../../utils/api';
import { ExportDataType } from '../../api/exportTypes';
import { GridRowId, GridValidRowModel } from '@mui/x-data-grid-pro';
import { TableFilter } from '../filter/table-filter';
import { TablePagination } from '../pagination/pagination';
import { dotNameKeyToObject } from '../../utils/string';
import { globalViewModelStore } from '../../stores/global-vm';
import * as signalR from '@microsoft/signalr';

type AsyncExportStatus = 'Waiting' | 'Pending' | 'Complete' | 'Error';

export class AsyncExportEntity {
  api = new SignalrApi();
  @observable.ref signalrConnection = this.api.connection;
  @observable status: AsyncExportStatus = 'Waiting';
  @observable message: string = '';
  @observable type?: string;
  @observable mode: ExportTypes = ExportTypes.WITH_FILTERS;
  @observable.ref exportReq: ApiReq<Blob> = emptyValue;
  @observable isExport = false;
  @observable fileName: ExportDataType['fileName'] = '';
  @observable loading: boolean = false;
  @observable exportWithUnactiveRecords: boolean = false;

  constructor() {
    makeObservable(this);
  }

  exportParams<T extends Pageable, H, G extends GridValidRowModel>(
    hiddenColumns: string[],
    filter: TableFilter<H, G> | null,
    pagination: TablePagination<T>,
    selectedItems: GridRowId[]
  ) {
    return {
      fileName: this.fileName,
      columns: this.mode !== ExportTypes.ALL ? hiddenColumns.filter(el => el !== "__check__") : [],
      q: {
        ...(!!filter && this.mode === ExportTypes.ALL && this.exportWithUnactiveRecords && {activeMode: 2}),
        ...(!!filter && (this.mode === ExportTypes.CURRENT_PAGE || this.mode === ExportTypes.WITH_FILTERS || this.mode === ExportTypes.QUICK) && dotNameKeyToObject(filter.filterParams)),
        ...(!!filter && this.mode === ExportTypes.SELECTED &&
          {
            activeMode: filter.activeMode,
          }),
      },
      page: this.mode === ExportTypes.CURRENT_PAGE ? {
        num: pagination.serialize.page,
        size: pagination.serialize.pageSize,
      } : null,
      ids: (this.mode === ExportTypes.SELECTED || this.mode === ExportTypes.QUICK) ? [...toJS(selectedItems)] : [],
    };
  }

  @action initAsyncExport = async () => {
    this.setStatus('Pending');
    this.signalrConnection = await this.api.connect();
    return this.signalrConnection;
  };

  @action setExportWithUnactiveRecords = (value: boolean) => this.exportWithUnactiveRecords = value;

  @action setStatus = (value: AsyncExportStatus) => {
    this.status = value;
  };
  @action setFileName = (value: string) => {
    this.fileName = value;
  };

  @action asyncExportAction = async (
    request: ApiReq<AsyncExportResponse>
  ) => {
    this.loading = true;
    let connection: signalR.HubConnection | undefined;
    try {
      const res = await request;
      await this.initAsyncExport();
      connection = this.signalrConnection!;

      if (request.state !== 'fulfilled' || !res.data) {
        this.setStatus('Error');
        return;
      }
      const { id, fileName } = res.data;
      this.setFileName(fileName);

      const listenPromise = new Promise((res, rej) => {
        connection!.on('Error', (id, errors) => {
          this.setStatus('Error');
          this.message = errors[0].message;
          // eslint-disable-next-line no-console
          console.warn(id);
          rej(errors[0].message);
        });

        connection!.on('Complete', async () => {
          this.setStatus('Complete');
          res(id);
        });
      });

      await Promise.all([listenPromise, connection.invoke('subscribe', id)]);

      await this.downloadFile(id);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      globalViewModelStore.setApiErrors([
        {
          message: `${e}`,
          code: 'successUpdated',
        },
      ]);
      this.setStatus('Error');
    } finally {
      await connection?.stop();
      this.loading = false;
    }
  };

  @action downloadFile = async (id: string) => {
    this.exportReq = this.api.getExportFile(id);
    const file = await this.exportReq;
    if (this.exportReq.state !== 'fulfilled' || !file.data) {
      this.setStatus('Error');
      return;
    }
    downloadBlob(file.data, this.fileName);

    runInAction(() => {
      if (this.exportReq.state === 'fulfilled' && this.isExport) {
        this.resetParams();
      }
    });

    this.setStatus('Complete');
  };

  @action setParams(
    params: Omit<ExportDataType, 'mode'> & { mode: ExportTypes },
  ) {
    this.mode = Number(params.mode);
    this.fileName = params.fileName;
    this.type = params.type;
  }

  @action openExportModal = () => (this.isExport = true);
  @action cancelExport = () => (this.isExport = false);

  @action resetParams() {
    this.mode = ExportTypes.WITH_FILTERS;
    this.fileName = '';
    this.type = undefined;
    this.isExport = false;
    this.exportReq = emptyValue;
    this.exportWithUnactiveRecords = false;
  }
}
