import { PropsWithChildren } from 'react';

import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import { observer } from 'mobx-react-lite';

import CollapsibleTitle from '../components/CollapsibleTitle';

type PrimaryProps =
  | {
      customHeader?: false;
      title: string;
      titleIcon?: any;
      isOpen: boolean;
      handlerClick: () => void;
    }
  | {
      customHeader: JSX.Element;
      title?: string;
      titleIcon?: any;
      isOpen: boolean;
      handlerClick?: () => void;
    };

const PrimaryCollapse = observer(
  ({
    isOpen,
    children,
    customHeader,
    title,
    titleIcon,
    handlerClick,
  }: PropsWithChildren<PrimaryProps>) => {
    return (
      <Paper sx={{ width: '100%' }}>
        {customHeader ? (
          customHeader
        ) : (
          <CollapsibleTitle
            title={title!}
            isOpen={isOpen!}
            titleIcon={titleIcon!}
            handlerClick={handlerClick!}
          />
        )}
        <Collapse
          sx={{ overflowX: 'auto', overflowY: 'hidden' }}
          in={isOpen}
          timeout="auto"
          unmountOnExit>
          {children}
        </Collapse>
      </Paper>
    );
  },
);

export default PrimaryCollapse;
