import { GridColDef } from '@mui/x-data-grid-pro';
import { formattedUSDateTime } from 'src/utils/date';

export const gridColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    editable: true,
    flex: 1,
  },
  {
    field: 'shortName',
    headerName: 'Short Name',
    editable: true,
    flex: 1,
  },
  {
    field: 'state',
    headerName: 'State',
    flex: 1,
    valueGetter: ({ value }) => value.name,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    flex: 2,
    valueGetter: ({ value }) => formattedUSDateTime(value),
  },
];
