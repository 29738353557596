import { useContext } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';

import { breadcrumbItems } from '../../helpers/common';
import { importsStoreCtx } from '../../store';

export const BreadcrumbsComp = observer(() => {
  const { importStep, succeedImportStep, currentSession } =
    useContext(importsStoreCtx);
  if (!currentSession) return null;

  const {
    isImportProcessing,
    sessionSucceed,
    sessionProcessing,
    isFileUploading,
    showSessionError,
  } = currentSession;

  const importCompleted = isImportProcessing && sessionSucceed;
  const loaderTrigger =
    sessionProcessing && (isFileUploading || isImportProcessing);

  return (
    <Breadcrumbs key={importStep} separator="›" aria-label="breadcrumb">
      {breadcrumbItems.map(({ key, title }) => (
        <Box
          key={key}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Box sx={{ pt: 0.75 }}>
            {key === importStep && loaderTrigger && !showSessionError && (
              <CircularProgress size={15} color="success" />
            )}
            {succeedImportStep.includes(key) && (
              <CheckCircleIcon fontSize="small" color="success" />
            )}
            {key === importStep && showSessionError && (
              <ErrorIcon fontSize="small" color="error" />
            )}
          </Box>
          <Typography
            color="text.primary"
            sx={{
              ml: 1,
              cursor: 'pointer',
              fontWeight: importStep === key || importCompleted ? 700 : 500,
              color: theme =>
                succeedImportStep.includes(key)
                  ? theme.palette.success.main
                  : key === importStep && showSessionError
                  ? theme.palette.error.main
                  : theme.palette.text.primary,
            }}>
            {title}
          </Typography>
        </Box>
      ))}
    </Breadcrumbs>
  );
});
