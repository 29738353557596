import { styled } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import { ReportResolution } from 'src/types';

const statusColor = (status: ReportResolution) => {
    switch (status) {
        case 'completed': return <AssignmentTurnedInIcon color='success' sx={{ width: 14 }} />
        case 'notStarted': return <AccessTimeIcon sx={{ width: 14, color: 'text.secondary' }} />
        case 'postponed': return <RestorePageIcon color='info' sx={{ width: 14 }} />
    }
}

const Container = styled('div')(({ theme }) => ({
    textTransform: 'capitalize',
    borderRadius: 4,
    border: '1px solid rgba(118, 125, 134, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0.4),
    color: theme.palette.text.secondary,
    ...theme.typography.hint as CSSProperties
}))

const TableResolution = ({ row: { resolution } }: GridRenderCellParams) => (
    <Container>
        {statusColor(resolution)}
        {resolution}
    </Container>
)

export default TableResolution