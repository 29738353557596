import { action, computed, makeObservable, observable } from 'mobx';

type SectionsType = 'first' | 'second' | 'third' | 'fourth';

export class Sections {
  readonly defaultSectionsState = {
    first: true,
    second: true,
    third: false,
    fourth: false,
  };
  @observable sectionsHidden: Record<SectionsType, boolean> =
    this.defaultSectionsState;
  constructor() {
    makeObservable(this);
  }

  @computed get isCollapseAll() {
    return Object.values(this.sectionsHidden).every(element => element);
  }

  @action collapseAll = (force?: boolean) => {
    if (force !== undefined) {
      (Object.keys(this.sectionsHidden) as SectionsType[]).forEach(key => {
        this.sectionsHidden[key] = force;
      });
    } else {
      (Object.keys(this.sectionsHidden) as SectionsType[]).forEach(key => {
        this.sectionsHidden[key] = !this.sectionsHidden[key];
      });
    }
  };

  @action toggleSection = (name: SectionsType, force?: boolean) => {
    if (force !== undefined) {
      this.sectionsHidden[name] = force;
    } else {
      this.sectionsHidden[name] = !this.sectionsHidden[name];
    }
  };
}
