import { makeObservable } from 'mobx';

import { ServicesModel } from '../../models/servise/service-model';
import { storeFactory } from '../../utils/store';

export class ServicesStore extends ServicesModel {
  constructor() {
    super();
    makeObservable(this);
  }
}

export const { store: servicesStore, storeCtx: servicesStoreCtx } =
  storeFactory(ServicesStore, 'service');
