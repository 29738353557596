import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PNGIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.17138 0C2.62662 0 2.18091 0.45 2.18091 1V15C2.18091 15.55 2.62662 16 3.17138 16H13.0761C13.6208 16 14.0665 15.55 14.0665 15V4L10.1047 0H3.17138Z"
      fill="#E2E5E7"
    />
    <path
      d="M11.0952 4H14.0666L10.1047 0V3C10.1047 3.55 10.5504 4 11.0952 4Z"
      fill="#B0B7BD"
    />
    <path d="M14.0664 7L11.095 4H14.0664V7Z" fill="#CAD1D8" />
    <path
      d="M12.0856 13C12.0856 13.275 11.8627 13.5 11.5903 13.5H0.695185C0.422807 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422807 7.5 0.695185 7.5H11.5903C11.8627 7.5 12.0856 7.725 12.0856 8V13Z"
      fill="#A066AA"
    />
    <path
      d="M2.08228 9.47352C2.08228 9.34152 2.18479 9.19702 2.35119 9.19702H3.26638C3.78093 9.19702 4.24447 9.54502 4.24447 10.2125C4.24447 10.8445 3.78093 11.196 3.26638 11.196H2.60425V11.724C2.60425 11.9005 2.49431 12 2.35119 12C2.22045 12 2.08228 11.9005 2.08228 11.724V9.47352ZM2.60425 9.70102V10.6965H3.26588C3.53084 10.6965 3.74081 10.4605 3.74081 10.2125C3.74081 9.93252 3.53084 9.70102 3.26588 9.70102H2.60425Z"
      fill="white"
    />
    <path
      d="M4.74915 9.51352C4.74915 9.36902 4.78085 9.22952 4.98686 9.22952C5.129 9.22952 5.16119 9.26552 5.26766 9.36902L6.57805 11.0245V9.47352C6.57805 9.34152 6.69245 9.19702 6.82765 9.19702C6.97028 9.19702 7.10845 9.34152 7.10845 9.47352V11.724C7.10845 11.9005 7.00148 11.9675 6.89847 12C6.7603 12 6.69245 11.968 6.57805 11.8605L5.26766 10.181V11.7245C5.26766 11.901 5.16069 12.0005 5.01806 12.0005C4.87544 12.0005 4.74866 11.901 4.74866 11.7245V9.51352H4.74915Z"
      fill="white"
    />
    <path
      d="M10.0871 11.6925C9.80581 11.928 9.46113 12.0285 9.11298 12.0285C8.28099 12.0285 7.69116 11.5485 7.69116 10.597C7.69116 9.78949 8.31318 9.16199 9.14814 9.16199C9.46113 9.16199 9.80532 9.26999 10.051 9.51399C10.2926 9.75349 9.94398 10.1095 9.70677 9.89799C9.56018 9.75349 9.35416 9.64599 9.14814 9.64599C8.66925 9.64599 8.20621 10.0335 8.20621 10.597C8.20621 11.189 8.59397 11.5485 9.11298 11.5485C9.35416 11.5485 9.56018 11.4765 9.70677 11.3685V10.8775H9.11298C8.76037 10.8775 8.79603 10.389 9.11298 10.389H9.90486C10.0514 10.389 10.1857 10.5015 10.1857 10.6255V11.477C10.1857 11.5485 10.1545 11.616 10.0871 11.6925Z"
      fill="white"
    />
    <path
      d="M11.5904 13.5H2.18091V14H11.5904C11.8627 14 12.0856 13.775 12.0856 13.5V13C12.0856 13.275 11.8627 13.5 11.5904 13.5Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
export default PNGIcon;
