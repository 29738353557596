import { useState, useEffect, KeyboardEvent, ChangeEvent } from 'react';

import { Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { GridCallbackDetails, GridFooterContainer } from '@mui/x-data-grid-pro';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';

import CustomTablePagination from './CustomTablePagination';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides extends FooterProps {}
}

export interface FooterProps {
  paginationModel: GridPaginationModel;
  rowCount: number;
  totalPages: number;
  onPaginationModelChange: (
    model: GridPaginationModel,
    details: GridCallbackDetails,
  ) => void;
}

const StyledInput = styled(TextField)({
  height: 25,
  '& .MuiInput-input': {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    fontSize: 12,
    width: 30,
    textAlign: 'center',
    position: 'relative',
  },
  '& .MuiFormHelperText-root': {
    fontSize: 12,
    position: 'absolute',
    top: 25,
    width: 150,
    left: -45,
  },
});

const changeModelDetails = {
  reason: 'setPaginationModel',
};

export default ({
  paginationModel,
  rowCount,
  totalPages,
  onPaginationModelChange,
}: FooterProps) => {
  const { page, pageSize } = paginationModel;
  const [currPage, setCurrPage] = useState<number | string>(page + 1);
  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
    setCurrPage(page + 1);
  }, [page]);

  const onEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key.includes('Enter')) {
      e.preventDefault();
      (e.target as HTMLInputElement).blur();
    }
  };

  const changePageNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const pageNumber = Number(e.target.value);

    setCurrPage(pageNumber > 0 ? e.target.value : '');
    if (pageNumber <= totalPages) return setError('');
    setError('Incorrect page number');
  };

  const onBlur = () => {
    if (currPage <= totalPages && page + 1 !== currPage) {
      const count = Number(currPage);
      onPaginationModelChange(
        { page: count - 1, pageSize },
        changeModelDetails,
      );
    }
  };

  return (
    <GridFooterContainer>
      <Grid container justifyContent="flex-end">
        <Grid
          width="fit-content"
          item
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between">
          <Typography variant="hint">Page: </Typography>
          <StyledInput
            inputProps={{ min: 1 }}
            variant="standard"
            type="number"
            value={currPage}
            onChange={changePageNumber}
            onBlur={onBlur}
            onKeyDown={onEnterPress}
            error={!!error}
            helperText={error}
          />
          <Typography
            variant="hint"
            sx={{
              width: 'fit-content',
            }}>
            of: {totalPages}
          </Typography>
        </Grid>
        <CustomTablePagination
          paginationModel={paginationModel}
          rowCount={rowCount}
          onPaginationModelChange={onPaginationModelChange}
        />
      </Grid>
    </GridFooterContainer>
  );
};
