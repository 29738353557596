import { useContext } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { authStoreCtx } from 'src/stores/auth';

import { RootContainer, MinervaLogo } from './styles';

const AuthError = () => {
  const { msalInstance } = useContext(authStoreCtx);

  const handleLogout = () => {
    msalInstance.logoutRedirect();
  };

  return (
    <RootContainer>
      <MinervaLogo fill="#2B59C3" />
      <Typography variant="h3" component="h3">
        Access Denied
      </Typography>
      <Typography variant="h4" component="h4">
        Contact administrator
      </Typography>
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={handleLogout}>
        Logout
      </Button>
    </RootContainer>
  );
};

export default AuthError;
