import { ContactShortType } from 'src/api/api-types/address-book';
import { EFGItemType } from 'src/api/api-types/food-generators';
import { ItemType } from 'src/types';

export class EFGItem implements EFGItemType {
  readonly id: number;
  readonly contact: ContactShortType;
  readonly name: string;
  readonly createdAt: string;
  readonly reference: string;
  readonly usaAddress: string;
  readonly jurisdiction: ItemType;
  readonly onSiteFoodFacility: boolean;
  readonly rooms: number;
  readonly seats: number;
  readonly sqFeet: number;
  readonly beds: number;
  readonly tier: ItemType;
  readonly type: ItemType;
  readonly description: string;
  readonly descriptionTruncated: boolean
  readonly location: {
    id: number;
    reference: string;
  };
  readonly foodRecoveries: ItemType[];
  info?: boolean;

  constructor({
    id,
    reference,
    name,
    jurisdiction,
    type,
    contact,
    location,
    foodRecoveries,
    tier,
    usaAddress,
    createdAt,
    onSiteFoodFacility,
    rooms,
    seats,
    sqFeet,
    beds,
    description,
    descriptionTruncated
  }: EFGItemType) {
    this.id = id;
    this.reference = reference;
    this.name = name;
    this.type = type;
    this.jurisdiction = jurisdiction;
    this.onSiteFoodFacility = onSiteFoodFacility;
    this.rooms = rooms;
    this.seats = seats;
    this.sqFeet = sqFeet;
    this.beds = beds;
    this.contact = contact;
    this.location = location;
    this.foodRecoveries = foodRecoveries;
    this.tier = tier;
    this.usaAddress = usaAddress;
    this.createdAt = createdAt;
    this.description = description;
    this.descriptionTruncated = descriptionTruncated;
  }
}
