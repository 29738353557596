import { createContext, PropsWithChildren, useContext } from 'react';

import { EfgDetailsItem } from '../../../models/edible-food/efg-details-Item';

interface EfgEditContextProps {
  chosenFood?: EfgDetailsItem;
  selectEfg: (id: number) => void;
  removeChosenFood: () => void;
}

export const CreateEfgEditCtx = createContext<EfgEditContextProps>({
  selectEfg: () => {},
  removeChosenFood: () => {},
});

export const EfgEditProvider = ({
  children,
  ...props
}: PropsWithChildren<EfgEditContextProps>) => {
  return (
    <CreateEfgEditCtx.Provider value={props}>
      {children}
    </CreateEfgEditCtx.Provider>
  );
};

export const useEfgEdit = () => useContext(CreateEfgEditCtx);
