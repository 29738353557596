import { ApiClient } from './';
import {
  UserItem,
  UsersResponse,
  UserCreationRequest,
  UpdateUserInfoRequest,
  UpdateUserEmailRequest,
  UserStatusRequest,
  RequestUsersParams,
  UserAllowedEmailsResponse,
  UserProfileResponse,
} from './api-types/user';
import { dotNameKeyToString } from '../utils/string';
import { ExportParams, AsyncExportResponse } from 'src/types';

export class UsersApi extends ApiClient {
  getUsersData({ page, pageSize, filters }: RequestUsersParams) {
    return this.requestGet<UsersResponse>('users', {
      'page.num': page,
      'page.size': pageSize,
      ...dotNameKeyToString(filters),
    });
  }

  getUserData() {
    return this.requestGet<UserProfileResponse>(`users/profile/me`);
  }

  createNewUser(data: UserCreationRequest) {
    return this.requestPost<UserItem, UserCreationRequest>('users', data);
  }

  updateUserInfo(data: UpdateUserInfoRequest) {
    return this.requestPut<UserItem, UpdateUserInfoRequest>(
      `users/${data?.id}`,
      data,
    );
  }

  updateUserEmail({ id, email }: UpdateUserEmailRequest) {
    return this.requestPut<UserItem, UpdateUserEmailRequest>(
      `users/${id}/email`,
      {
        id,
        email,
      },
    );
  }

  changeUserStatus({ id, active }: UserStatusRequest) {
    return this.requestPut<UserItem, {}>(
      `users/${id}/${active ? 'activate' : 'deactivate'}`,
      {},
    );
  }

  resetPasswordRequest(id: number) {
    return this.requestPost<UserItem, {}>(`users/${id}/reset-password`, {});
  }

  getAllowedEmailsData() {
    return this.requestGet<UserAllowedEmailsResponse>('users/configuration');
  }

  getAsyncReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `users/export/${exportType}?flowType=2`,
      params,
    );
  }
}
