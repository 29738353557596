import { ChangeEvent } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { observer } from 'mobx-react-lite';
import { isPending } from 'src/utils/common';

import { ComplianceMatrixStore } from '../../store';

export default observer(({ store }: { store: ComplianceMatrixStore }) => {
  const {
    organicMaterials,
    updateOrganicMaterial,
    organicMaterialsReq,
    updateMaterialReq,
  } = store.organicWasteMaterials;
  const { complianceMatrix } = store;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateOrganicMaterial &&
      (async () => {
        //todo need refactoring
        complianceMatrix?.updateActual(false);

        await updateOrganicMaterial(event.target.value);
      })();
  };

  return (
    <Grid
      sx={{ padding: theme => theme.spacing(1, 2) }}
      container
      flexDirection="column">
      {(isPending(updateMaterialReq) || isPending(organicMaterialsReq)) && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {organicMaterials &&
        organicMaterials.map(({ name, checked, matchingId }, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                key={index}
                value={matchingId}
                checked={checked}
                onChange={handleChange}
              />
            }
            label={name}
          />
        ))}
    </Grid>
  );
});
