import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from 'mobx';

import { storeFactory } from '../utils/store';
import { UsersApi } from '../api/users';
import { ApiReq, emptyValue } from '../api';
import { UserProfileResponse } from 'src/api/api-types/user';
import { ClientTypes } from '../api/api-types/lookups';
import { ItemType } from 'src/types';
import { lookupsStore } from './lookups';
import { ClientLogo } from 'src/models/clients/client-logo';
import { authStore } from './auth';

class UserStore {
  api = new UsersApi({ prefix: '' });
  @observable logo: ClientLogo | undefined;
  @observable.ref userDataReq: ApiReq<UserProfileResponse> = emptyValue;
  @observable selectedJurisdictionId: ItemType | null = null;

  constructor() {
    makeObservable(this);
    this.selectedJurisdictionId =
      this.userJurisdiction ||
      JSON.parse(localStorage.getItem('selectedJurisdictionId') || 'null');
    autorun(() => {
      if (
        this.userData !== null &&
        this.userData.jurisdiction?.hasLogo &&
        authStore.fCfg.isClientLogo
      ) {
        this.setLogo();
      }
    });
  }

  @computed get isAdmin() {
    return this.clientType?.id === ClientTypes.Administrator;
  }

  @computed get isJurisdiction() {
    return this.clientType?.id === ClientTypes.Jurisdiction;
  }

  @computed get isHauler() {
    return this.clientType?.id === ClientTypes.Hauler;
  }

  @computed get isAgency() {
    return this.clientType?.id === ClientTypes.Agency;
  }

  @computed get isSelectedJurisdiction() {
    return this.selectedJurisdictionId !== null;
  }

  @computed get userData() {
    if (this.userDataReq.state !== 'fulfilled' || !this.userDataReq.value)
      return null;
    return this.userDataReq.value.data;
  }

  @computed get userJurisdiction() {
    if (
      this.userDataReq.state !== 'fulfilled' ||
      !this.userDataReq.value ||
      !this.userDataReq.value.data?.jurisdiction
    )
      return null;

    return this.userDataReq.value.data?.jurisdiction as ItemType;
  }

  @computed get clientType() {
    if (
      this.userDataReq.state !== 'fulfilled' ||
      !this.userDataReq.value ||
      !this.userDataReq.value.data
    )
      return null;

    return this.userDataReq.value.data.clientType;
  }

  @computed get clientId() {
    if (!this.userData) return null;
    return this.userData.client.id;
  }

  @action fetchUser = async () => {
    this.userDataReq = this.api.getUserData();

    await this.userDataReq;
    runInAction(() => {
      if (!!this.userJurisdiction) {
        this.selectedJurisdictionId = this.userJurisdiction;
      }
    });
    return this.userDataReq;
  };
  @action reset = () => {
    this.userDataReq = emptyValue;
    this.selectedJurisdictionId = null;
    localStorage.removeItem('selectedJurisdictionId');
  };
  @action selectJurisdiction = (value: ItemType | null) => {
    this.selectedJurisdictionId = value;
    localStorage.setItem('selectedJurisdictionId', JSON.stringify(value));
  };
  @action checkJurisdiction = async () => {
    if (!lookupsStore.jurisdictions.length)
      await lookupsStore.fetchJurisdictionsLookups();
    if (lookupsStore.jurisdictions.length === 1) {
      this.selectJurisdiction(lookupsStore.jurisdictions[0]);
    }
  };

  @action setLogo = (logo?: ClientLogo) => {
    this.logo =
      logo ||
      (this.userData?.jurisdiction?.id
        ? new ClientLogo({ clientId: this.userData?.jurisdiction?.id })
        : undefined);
  };

  @action removeLogo = (clientId: number) => {
    if (userStore.userData?.client.id === clientId) this.logo = undefined;
  };
}

export const { store: userStore, storeCtx: userGlobalStoreCtx } = storeFactory(
  UserStore,
  'user',
);
