import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

import { usersStore } from './store';

export const UsersRoute: MinervaRoute<'users'> = {
  name: 'users',
  path: '/users',
  comp: () => lazy(() => import('./index')),

  activate: async () => await usersStore.fetchUsersList(),
  deactivate: () => usersStore.resetStore(),
};
