import { storeFactory } from 'src/utils/store';
import {
  action,
  IObservableArray,
  observable,
  autorun,
  makeObservable,
} from 'mobx';
import uniqueId from 'lodash/uniqueId';
import { AxiosError } from 'axios';
interface GlobalMessageProps {
  text: string;
  autoDestruct?: boolean;
  autoDestructSeconds?: number;
}

interface ErrorProps extends GlobalMessageProps {
  host: IObservableArray<Error>;
}

interface SuccessProps extends GlobalMessageProps {
  host: IObservableArray<Success>;
}

// Passing interface but not message text for mapping error code to message
export interface ApiResult {
  message: string;
  code: string;
  type?: string;
}

export type ApiError = ApiResult;
export type SuccessError = ApiResult;

export interface MinervaError extends AxiosError<{ errors: ApiError[] }> {}

class GlobalMessage {
  readonly id: string;
  readonly text: string;
  readonly autoDestruct: boolean;
  readonly autoDestructSeconds: number;

  constructor({
    text,
    autoDestruct = false,
    autoDestructSeconds = 6,
  }: GlobalMessageProps) {
    this.id = uniqueId();
    this.text = text;
    this.autoDestruct = autoDestruct;
    this.autoDestructSeconds = autoDestructSeconds;
  }
}

class Error {
  readonly globalMessage: GlobalMessage;
  @observable.ref host: IObservableArray<Error>;

  constructor({ host, ...props }: ErrorProps) {
    this.globalMessage = new GlobalMessage({ ...props });
    this.host = host;

    if (this.globalMessage.autoDestruct) {
      autorun(this.remove, {
        name: `error-${this.globalMessage.id}-autodestruction`,
        delay: this.globalMessage.autoDestructSeconds * 1000,
      });
    }
  }

  @action remove = () => this.host.remove(this);
}

class Success {
  readonly globalMessage: GlobalMessage;
  @observable.ref host: IObservableArray<Success>;

  constructor({ host, ...props }: SuccessProps) {
    this.globalMessage = new GlobalMessage({ ...props });
    this.host = host;

    if (this.globalMessage.autoDestruct) {
      autorun(this.remove, {
        name: `success-${this.globalMessage.id}-autodestruction`,
        delay: this.globalMessage.autoDestructSeconds * 1000,
      });
    }
  }

  @action remove = () => this.host.remove(this);
}

// This store contains global view model attributes
// Keep in mind that this store is being used at API level
// so avoid any circular dependencies while importing store/injecting ctx
class GlobalViewModelStore {
  @observable leftBar = true;
  @observable apiErrors = observable([] as Error[]);
  @observable successMessages = observable([] as Success[]);

  constructor() {
    makeObservable(this);
  }

  @action setApiErrors = (errors: ApiError[]) => {
    this.apiErrors.replace(
      errors.map(
        e => {
          return new Error({
            text: e.message ?? 'An error ocurred',
            host: this.apiErrors,
            autoDestruct: true,
          })
        }
      ),
    );
  };

  @action toggleLeftBar = () => {
    this.leftBar = !this.leftBar;
  };

  @action setSuccessMessages = (successMessages: SuccessError[]) => {
    this.successMessages.replace(
      successMessages.map(
        successMessage =>
          new Success({
            text: successMessage.message ?? 'Request completed',
            host: this.successMessages,
            autoDestruct: true,
          }),
      ),
    );
  };
}

export const {
  store: globalViewModelStore,
  storeCtx: globalViewModelStoreCtx,
} = storeFactory(GlobalViewModelStore, 'globalvm');
