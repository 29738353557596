import { action, makeObservable, observable } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { ContainerItem, UpdatedContainerResponse } from 'src/api/api-types/containers';
import { ContainersApi } from 'src/api/containers';
import { ItemType } from 'src/types';

export class ContainerModification {
  api = new ContainersApi({prefix: ''});

  readonly id: number;
  @observable wasteMaterialTypes: ItemType[] = []

  @observable.ref updateWasteMaterialsReq: ApiReq<UpdatedContainerResponse> = emptyValue;

  constructor({id, wasteMaterialTypes}: Pick<ContainerItem, 'id' | 'wasteMaterialTypes'>) {
    makeObservable(this)
    this.id = id;
    this.wasteMaterialTypes = wasteMaterialTypes;
  }

  @action setWasteMaterialTypes = (items: ItemType[]) => this.wasteMaterialTypes = items;

  @action updateWasteMaterialTypes = async () => {
    this.updateWasteMaterialsReq = this.api.updateWasteMaterialTypes({
      id: this.id,
      wasteMaterialTypes: this.wasteMaterialTypes
    })

    await this.updateWasteMaterialsReq
    return this.updateWasteMaterialsReq
  }
}
