import { SvgIcon, SvgIconProps } from "@mui/material";

export default (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#141E30"
      fillOpacity="0.3"
    />
    <path
      d="M11.5559 5.33331L6.66705 10.2222L4.44482 7.99998"
      stroke="white"
      strokeLinecap="round"
      fill="none"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
