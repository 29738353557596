import { ChangeEvent, MutableRefObject } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import uniqueId from 'lodash/uniqueId';
import { observer } from 'mobx-react-lite';
import { FileSignType, FileUploadType } from 'src/api/api-types/files';
import ImportImg from 'src/assets/images/import-upload.png';
import VisuallyHidden from 'src/components/VisuallyHidden';
import { FileStatus } from 'src/types';
import { WithStore } from 'src/utils/store';

import FragAndDropZone from '../DropZone';
import FileCard from '../FileCard';

import ErrorMess from './ProcessMessages/ErrorMess';
import ProgressMess from './ProcessMessages/ProgressMess';
import { ImportUploadStore } from './store';

export interface Props {
  uploadInputRef?: MutableRefObject<HTMLInputElement | null>;
  showGenerator?: boolean;
  type: FileUploadType;
}

export interface UploadPreviewProps extends Omit<Props, 'type'> {
  showProgress: boolean;
  showError?: boolean;
}

export interface ImportUploadInstanceType {
  importUploadStore: ImportUploadStore;
  ImportDropZone: (props: Props) => JSX.Element;
  UploadPreview: (props: UploadPreviewProps) => JSX.Element;
}

export const ImportDropZone = observer(
  ({ uploadInputRef, store, type }: WithStore<Props, ImportUploadStore>) => {
    const { appendFiles, files, clear } = store;

    const handleFileAttach = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        appendFiles(event.target.files, type);
        event.target.value = '';
      }
    };

    return (
      <Box>
        <VisuallyHidden>
          <input
            accept=".csv,.xlsx"
            type="file"
            ref={uploadInputRef}
            onChange={handleFileAttach}
          />
        </VisuallyHidden>
        {files.length === 0 ? (
          <FragAndDropZone
            store={store}
            uploadInputRef={uploadInputRef}
            dropZoneDescription="You can upload any .csv, .xlsx spreadsheets"
          />
        ) : (
          <Box
            sx={{
              backgroundColor: theme =>
                theme.palette.minervaColors.secondaryBlue,
              width: 'fit-content',
              p: 1,
              borderRadius: 1,
            }}>
            {files[0].name}
            <IconButton onClick={clear}>
              <ClearIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  },
);

export const UploadPreview = observer(
  ({
    store,
    showGenerator = true,
    showError,
    showProgress,
  }: WithStore<UploadPreviewProps, ImportUploadStore>) => {
    const { files } = store;

    const fileUpload =
      files.length > 0 && files[0]?.status === FileStatus.LOADING;
    const fileProgress =
      showProgress && files[0]?.status === FileStatus.DONE && !showError;

    return (
      <div style={{ marginTop: '8px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption" fontWeight={600}>
            Your spreadsheet is uploading...
          </Typography>
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <img src={ImportImg} alt="import-img" />
          <Box
            sx={{
              width: '90%',
              p: theme => theme.spacing(0, 12),
            }}>
            {showError && <ErrorMess />}
            {fileProgress && (
              <ProgressMess title="Your import is in progress" />
            )}
            {fileUpload && (
              <Box sx={{ width: '550px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    backgroundColor: theme => theme.palette.text.disabled,
                    p: theme => theme.spacing(1.2, 1),
                  }}>
                  <Typography
                    sx={{ width: '88%' }}
                    variant="caption"
                    fontWeight={600}
                    color="gray">
                    File Name
                  </Typography>
                  <Typography
                    sx={{ width: '12%' }}
                    variant="caption"
                    fontWeight={600}
                    color="gray">
                    Status
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    position: 'relative',
                    overflow: 'auto',
                    marginBottom: theme => theme.spacing(6),
                  }}>
                  {files.map(file => (
                    <FileCard
                      key={uniqueId('card_')}
                      file={file}
                      store={store}
                      showGenerator={showGenerator}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    );
  },
);

export const createImportInstance = (
  fileUploadPlan?: FileSignType
): ImportUploadInstanceType => {
  const store = new ImportUploadStore(fileUploadPlan);

  return {
    importUploadStore: store,
    ImportDropZone: (props: Props) => (
      <ImportDropZone {...props} store={store} />
    ),
    UploadPreview: (props: UploadPreviewProps) => (
      <UploadPreview {...props} store={store} />
    ),
  };
};
