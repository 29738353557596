import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';

const StyledCircularProgress = styled(CircularProgress)({
    root: {
        marginRight: 10,
    },
})

export {
    StyledCircularProgress,
}