import * as React from 'react';

import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton/IconButton';
import {
  useGridSelector,
  gridPreferencePanelStateSelector,
  GridPreferencePanelsValue,
  useGridApiContext,
  useGridRootProps,
} from '@mui/x-data-grid-pro';

export const ColumnsButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function ColumnsButton(props, ref) {
    const { onClick, ...other } = props;
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const { open, openedPanelValue } = useGridSelector(
      apiRef,
      gridPreferencePanelStateSelector,
    );

    const showColumns = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (open && openedPanelValue === GridPreferencePanelsValue.columns) {
        apiRef.current.hidePreferences();
      } else {
        apiRef.current.showPreferences(GridPreferencePanelsValue.columns);
      }

      onClick?.(event);
    };

    // Disable the button if the corresponding is disabled
    if (rootProps.disableColumnSelector) {
      return null;
    }
    return (
      <IconButton
        ref={ref}
        size="small"
        aria-label={apiRef.current.getLocaleText('toolbarColumnsLabel')}
        {...other}
        onClick={showColumns}
        {...rootProps.slotProps?.baseButton}>
        {<rootProps.slots.columnSelectorIcon />}
      </IconButton>
    );
  },
);

export default ColumnsButton;
