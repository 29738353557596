import { ApiClient } from '.';
import {
  PortalConfiguration,
  FeaturesConfiguration,
} from './api-types/configuration';

export class FeaturesConfigurationApi extends ApiClient {
  getFeaturesConfiguration() {
    return this.requestGet<FeaturesConfiguration>(
      'configuration/feature-flags',
    );
  }

  getPortalConfiguration() {
    return this.requestGet<PortalConfiguration>('configuration/Portal');
  }
}
