import { GridColDef } from '@mui/x-data-grid-pro';
import { formattedUSDateTime } from 'src/utils/date';

import CommaSeparated from '../../../components/CommaSeparated';
import { GRID_CHECKBOX_SELECTION_COL } from '../../../components/Table/constants';
import { sortArrayLengthFn } from '../../../utils/sorting';
import { EditWasteMaterialTypes } from '../components/EditFields/EditWasteMaterialTypes';
import { Container } from '../models/container';

export const columns: GridColDef<Container>[] = [
  GRID_CHECKBOX_SELECTION_COL,
  {
    field: 'name',
    headerName: 'Container ID',
    flex: 1,
  },
  {
    field: 'jurisdiction',
    headerName: 'Jurisdiction',
    flex: 1,
    valueGetter: ({ value }) => value.name,
  },
  {
    field: 'hauler',
    headerName: 'Hauler',
    flex: 1,
    valueGetter: ({ value }) => value.name,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    flex: 1,
    valueGetter: ({ value }) => formattedUSDateTime(value),
  },
  {
    field: 'wasteMaterialTypes',
    headerName: 'Waste Material Types',
    flex: 2,
    editable: true,
    sortComparator: sortArrayLengthFn,
    renderCell: ({ value }) => <CommaSeparated types={value} />,
    renderEditCell: params => <EditWasteMaterialTypes {...params} />,
  },
];
