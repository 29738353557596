import { styled, Theme, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

export const useAutocompletStyles = makeStyles<Theme>(theme => ({
  autocompleteMain: {
    width: '250px',
    position: 'absolute',
    top: '0',
    "& .MuiOutlinedInput-root.Mui-focused>fieldset": {
      borderWidth: '0px',
    },
    "& .MuiAutocomplete-inputRoot.Mui-focused ": {
      border: `2px solid ${theme.palette.text.secondary}}`,
    }
  }
}));
