import FolderIcon from '@mui/icons-material/Folder';
import { Tooltip } from '@mui/material';
import {
  GridCellCheckboxRenderer,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import ActiveInactive from 'src/components/ActiveInactive';
import { Link } from 'src/components/Link';

import { AccountItem } from '../../../api/api-types/accounts';
import ActiveInactiveIcon from '../../../assets/icons/ActiveInactive/ActiveInactiveIcon';
import { GRID_CHECKBOX_SELECTION_COL } from '../../../components/Table/constants';
import { formattedDateOnly } from '../../../utils/date';
import CellUI from '../components/FastInterface';
import { accountsStore } from '../store';

export const columns: GridColDef<AccountItem>[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL,
    type: 'string',
    renderCell: params => (
      <div>
        <Tooltip
          disableHoverListener={
            (!params.row.isParent && !params.row.parentId) ||
            accountsStore.accountsSetUp.groupType?.includes('relatedTo')
          }
          title={
            params.row.isParent
              ? 'Account is parent'
              : 'Account already have a parent'
          }
          placement="right"
          enterDelay={300}>
          <div>
            <GridCellCheckboxRenderer {...params} />
          </div>
        </Tooltip>
      </div>
    ),
    disableColumnMenu: true,
  },
  {
    field: 'isActive',
    headerName: 'Inactive',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    disableColumnMenu: true,
    renderHeader: () => <ActiveInactive />,
    align: 'center',
    width: 45,
    renderCell: ({ value }) => (!value ? <ActiveInactiveIcon /> : null),
  },
  {
    field: 'id',
    headerName: 'Relation',
    disableColumnMenu: true,
    sortable: false,
    headerAlign: 'center',
    renderHeader: () => (
      <FolderIcon
        sx={{
          width: '18px',
          height: '18px',
          cursor: 'pointer',
          color: 'text.secondary',
        }}
      />
    ),
    align: 'center',
    width: 75,
    renderCell: ({ row }) => <CellUI {...row} />,
  },
  {
    field: 'reference',
    headerName: 'Minerva ID',
    disableColumnMenu: true,
    minWidth: 170,
    renderCell: ({ value, row }) => (
      <Link to="account" params={{ id: row.id }}>
        {value}
      </Link>
    ),
  },
  {
    field: 'jurisdiction',
    headerName: 'Jurisdiction',
    width: 150,
    filterable: true,
    valueGetter: ({ value }) => value?.name ?? '',
  },
  {
    field: 'hauler',
    headerName: 'Hauler',
    minWidth: 120,
    filterable: true,
    valueGetter: ({ value }) => value?.name ?? '',
  },
  {
    field: 'agency',
    headerName: 'Agency',
    sortable: false,
    minWidth: 120,
    filterable: true,
    renderCell: ({ value }) => value?.name || '',
  },
  {
    field: 'accountNumber',
    headerName: 'Account #',
    disableColumnMenu: true,
    minWidth: 170,
  },
  {
    field: 'dataDate',
    headerName: 'Data date',
    minWidth: 140,
    disableColumnMenu: true,
    valueGetter: ({ value }: GridRenderCellParams) => formattedDateOnly(value),
  },
  {
    field: 'type',
    headerName: 'Account Type',
    minWidth: 120,
    filterable: true,
    flex: 1,
    valueGetter: ({ value }) => value.name,
  },
  {
    field: 'name',
    headerName: 'Account Name',
    minWidth: 200,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'accountAddress',
    headerName: 'Account Address',
    minWidth: 300,
    disableColumnMenu: true,
    flex: 1,
    valueGetter: ({ value }) => value?.name ?? '',
  },
  {
    field: 'numberOfUnits',
    headerName: 'MF Units',
    disableColumnMenu: true,
    width: 100,
  },
  {
    field: 'typeOfBusiness',
    headerName: 'Account Sub-Type',
    disableColumnMenu: true,
    width: 250,
  },
  {
    field: 'naicsCode',
    headerName: 'NAICS Code',
    disableColumnMenu: true,
    width: 120,
  },
  {
    field: 'naicsDescription',
    headerName: 'NAICS Note',
    disableColumnMenu: true,
    width: 200,
  },
  {
    field: 'zone',
    headerName: 'Zone',
    width: 120,
    filterable: true,
    valueGetter: ({ value }) => value?.name ?? '',
  },
  {
    field: 'extensionField1',
    headerName: 'JDF1',
    width: 160,
    filterable: true,
    valueGetter: ({ value }) => value?.name ?? '',
  },
  {
    field: 'extensionField2',
    headerName: 'JDF2',
    width: 160,
    filterable: true,
    valueGetter: ({ value }) => value?.name ?? '',
  },
  {
    field: 'populationDensity',
    headerName: 'JDF3',
    disableColumnMenu: true,
    minWidth: 50,
  },
  {
    field: 'watershed',
    headerName: 'JDF4',
    disableColumnMenu: true,
    minWidth: 50,
  },
];
