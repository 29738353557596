import { lazy, Suspense } from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { observer } from 'mobx-react-lite';
import PrimaryCollapse from 'src/components/Collapsible/Primary';
import { useLocalStorage } from 'src/utils/hooks/custom';

import { isPending } from '../../../../utils/common';
import { ComplianceMatrixStore } from '../../store';
import { stateMap } from '../MatrixCard';

const Sb1383Slcp = lazy(() => import('./Sb1383Slcp'));

export interface Sb1383CollapsesProps {
  store: ComplianceMatrixStore;
}

export default observer(({ store }: Sb1383CollapsesProps) => {
  const { complianceMatrix } = store;
  const { sb1383SlcpCollectionSystemTotal } = complianceMatrix?.matrix || {};

  const [sb1383AutoCollapse, changeSb1383AutoCollapse] = useLocalStorage(
    'isOpen_sb1383',
    false,
  );

  return (
    <Box sx={{ width: '100%', m: theme => theme.spacing(1, 0) }}>
      {isPending(complianceMatrix?.matrixReq!) && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {!!complianceMatrix?.sb1383 && (
        <PrimaryCollapse
          title="SB 1383 SLCP Compliance"
          isOpen={sb1383AutoCollapse}
          handlerClick={() => changeSb1383AutoCollapse(!sb1383AutoCollapse)}
          titleIcon={stateMap[sb1383SlcpCollectionSystemTotal?.id!]}>
          <Suspense fallback={<LinearProgress />}>
            {sb1383AutoCollapse && <Sb1383Slcp {...complianceMatrix?.sb1383} />}
          </Suspense>
        </PrimaryCollapse>
      )}
    </Box>
  );
});
