import { format, parseISO } from 'date-fns';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import endOfDay from 'date-fns/endOfDay';
import formatFp from 'date-fns/fp/format';
import parseFp from 'date-fns/fp/parse';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';

export const removeUserTimezone = (date: Date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;

  return new Date(date.getTime() - userTimezoneOffset);
};

export const formatMinutesDuration = (minutes: number) => {
  if (minutes > 60) {
    const hoursInt = Math.floor(minutes / 60);
    const minutesInt = minutes % 60;

    return `${hoursInt.toString().padStart(2, '0')}h:${minutesInt
      .toString()
      .padStart(2, '0')}m`;
  }

  return `00h:${minutes.toString().padStart(2, '0')}m`;
};

export const removeTimezoneStartOfDay = (date: string) =>
  removeUserTimezone(startOfDay(new Date(date))).toISOString();

export const removeTimezoneEndOfDay = (date: string) =>
  removeUserTimezone(endOfDay(new Date(date))).toISOString();

export const browserDateFormat = 'yyyy-MM-dd';
export const toBrowserDateFormat = formatFp(browserDateFormat);
export const fromBrowserDateFormat = parseFp(new Date(), browserDateFormat);

const currentWeekStartDate = toBrowserDateFormat(
  startOfWeek(new Date(), { weekStartsOn: 1 }),
);
const currentWeekEndDate = toBrowserDateFormat(
  addWeeks(new Date(currentWeekStartDate), 1),
);

export const currentWeek = {
  startDate: currentWeekStartDate,
  endDate: currentWeekEndDate,
};

const currentMonthStartDate = toBrowserDateFormat(startOfMonth(new Date()));
const currentMonthEndDate = toBrowserDateFormat(
  addMonths(new Date(currentMonthStartDate), 1),
);

export const currentMonth = {
  startDate: currentMonthStartDate,
  endDate: currentMonthEndDate,
};

export const today = {
  startDate: toBrowserDateFormat(new Date()),
  endDate: toBrowserDateFormat(new Date()),
};

export const formatToHours = (data?: Date | string | null) => {
  if (!data) {
    return format(new Date(), 'HH:mm');
  }
  return format(new Date(data), 'HH:mm');
};

export const dateFromHours = (value?: Date | string | null) => {
  const date = new Date();
  if (!value) return date;

  const time = `${value}`.split(':');
  if (time.length > 0) {
    date.setHours(Number(time[0]), Number(time[1].slice(0, 2)));
    return new Date(date);
  }
  return date;
};

export const formattedUSDate = (date: string | Date) =>
  date ? format(new Date(date), 'MM/dd/yyyy') : '';

export const formattedUSDateTime = (date: string | Date) =>
  date ? format(new Date(date), 'MM/dd/yyyy hh:mm a') : '';

export const formattedDateOnly = (date: string) => {
  return date ? format(parseISO(date), 'MM/dd/yyyy') : '';
};

export const dateToISO = (date: string | Date) => new Date(date).toISOString();

export const isValidMilliseconds = (ms: any) =>
  typeof ms === 'number' &&
  !isNaN(ms) &&
  Number.isInteger(ms) &&
  ms >= 0 &&
  ms <= Number.MAX_SAFE_INTEGER;
