import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

import { haulersStore } from './store';

export const HaulersRoute: MinervaRoute<'haulers'> = {
  name: 'haulers',
  path: '/haulers',
  comp: () => lazy(() => import('./index')),
  activate: async () => {
    await haulersStore.fetchHaulers();
  },
};
