import Skeleton from '@mui/material/Skeleton';

import TableLoader from '../TableLoader';

export default function LayoutLoader() {
  return (
    <div style={{ display: 'flex', width: '98%' }}>
      <div style={{ width: 300 }}>
        <Skeleton variant="text" width={210} />
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="rectangular" width={210} height="88vh" />
      </div>
      <div style={{ width: '95%' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '12px',
          }}>
          <Skeleton variant="rectangular" width="100%" height={40} />
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{ mx: theme => theme.spacing(4) }}
          />
        </div>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={10}
          sx={{ mb: 1 }}
        />

        <TableLoader />
      </div>
    </div>
  );
}
