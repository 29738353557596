import Table from './components/Table';
import { ServiceHistoryStore } from './store';

export const createServiceHistory = () => {
  const store = new ServiceHistoryStore();

  return {
    serviceHistoryStore: store,
    ServiceHistoryTable: () => <Table store={store} />,
  };
};
