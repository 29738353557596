import { Account } from '../../../../models/account/account';

import ChildOf from './components/ChildOf';
import PartnerFor from './components/PartnerFor';
import Relations from './components/Relations';

const CellUI = ({ isParent, parentId, isRelated, id }: Account) => {
  return (
    <>
      {isParent ? <PartnerFor id={id} /> : parentId ? <ChildOf id={id} /> : ''}
      {isRelated && <Relations id={id} />}
    </>
  );
};

export default CellUI;
