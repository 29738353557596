import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { LinkFileDto } from 'src/api/api-types/files';
import {
  ImportContainerValue,
  ImportFileColumn,
  ImportFileValue,
  ImportStages,
  ImportStates,
  MergedColumns,
  Session,
} from 'src/api/api-types/import';
import { ImportApi } from 'src/api/import';
import { ApiError, MinervaError } from 'src/stores/global-vm';
import { lookupsStore } from 'src/stores/lookups';
import { ItemType } from 'src/types';

import { prepareMergedContainersData, prepareMergedValuesData } from '../helpers/common';
import { ImportMergeColumn } from './import-merge-column';
import { AsyncExportEntity } from 'src/models/export/AsyncExportEntity';

export class ImportSession {
  api = new ImportApi();

  readonly id: number;
  readonly jurisdiction: ItemType;
  readonly hauler: ItemType;
  readonly createdAt: string;
  readonly createdBy: ItemType;
  readonly updatedAt: string;
  readonly updatedBy: ItemType;
  readonly fileToken?: string;
  readonly isCanceled: boolean;
  @observable errors?: ApiError[] = [];
  @observable state: ItemType;
  @observable stage: ItemType;
  @observable file?: LinkFileDto;
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable.ref mergingValuesData: IObservableArray<ImportMergeColumn> = observable([]);
  @observable.ref chosenColumnValues?: ImportMergeColumn;

  @observable.ref linkFileReq: ApiReq<Session> = emptyValue;
  @observable.ref sessionDataReq: ApiReq<Session> = emptyValue;
  @observable.ref sessionDataPollingReq: ApiReq<Session> = emptyValue;
  @observable.ref cancelSessionReq: ApiReq<Session> = emptyValue;
  @observable.ref sourceColumnsReq: ApiReq<ImportFileColumn[]> = emptyValue;
  @observable.ref columnsTemplatesReq: ApiReq<ImportFileColumn[]> = emptyValue;
  @observable.ref mergeImportColumnsReq: ApiReq<Session> = emptyValue;
  @observable.ref mergingValueReq: ApiReq<ImportFileValue[]> = emptyValue;
  @observable.ref mergeImportValuesReq: ApiReq<Session> = emptyValue;
  @observable.ref importContainersReq: ApiReq<ImportContainerValue[]> = emptyValue;
  @observable.ref mergeImportContainersReq: ApiReq<Session> = emptyValue;
  @observable.ref confirmImportReq: ApiReq<Session> = emptyValue;
  @observable.ref finishImportReq: ApiReq<Session> = emptyValue;

  constructor({
    id,
    jurisdiction,
    hauler,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    fileToken,
    stage,
    state,
    file,
    isCanceled,
    errors,
  }: Session) {
    makeObservable(this);

    this.id = id;
    this.jurisdiction = jurisdiction;
    this.hauler = hauler;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.updatedAt = updatedAt;
    this.updatedBy = updatedBy;
    this.fileToken = fileToken;
    this.stage = stage;
    this.state = state;
    this.file = file;
    this.isCanceled = isCanceled;
    this.errors = errors || [];
  }

  @computed get sessionInteractiveState() {
    return this.state.id === ImportStates.Pending ||
      this.state.id === ImportStates.Failed;
  }

  @computed get sessionProcessing() {
    return this.state.id === ImportStates.Pending ||
      this.state.id === ImportStates.Running;
  }

  @computed get sessionSucceed() {
    return this.state.id === ImportStates.Succeeded;
  }

  @computed get sessionFailed() {
    return this.state.id === ImportStates.Failed;
  }

  @computed get isFileUploading() {
    return this.stage.id === ImportStages.FileUpload ||
      this.stage.id === ImportStages.FileProcessing;
  }

  @computed get isFileUpload() {
    return this.stage.id === ImportStages.FileUpload;
  }

  @computed get isColumnsMatching() {
    return this.stage.id === ImportStages.ColumnsMatching;
  }

  @computed get isValuesMatching() {
    return this.stage.id === ImportStages.ValuesMatching;
  }

  @computed get isContainersPreparing() {
    return [
      ImportStages.DataProcessing,
      ImportStages.TransformationsProcessing,
      ImportStages.DataValidation,
      ImportStages.AddressAdjustment,
      ImportStages.ContainersProcessing,
    ].includes(this.stage.id);
  }

  @computed get isContainersMatching() {
    return this.stage.id === ImportStages.ContainersMatching;
  }

  @computed get isImportReview() {
    return this.stage.id === ImportStages.ImportReview;
  }

  @computed get isImportProcessing() {
    return this.stage.id === ImportStages.DataTransferring;
  }

  @computed get isImportAddressesStandardization() {
    return this.stage.id === ImportStages.AddressesStandardization;
  }

  @computed get isImportConfirm() {
    return (this.isContainersMatching && this.sessionSucceed) ||
      (this.isImportReview && this.sessionProcessing);
  }

  @computed get isImportRunning() {
    return (this.isImportReview && this.sessionSucceed) ||
      (this.isImportProcessing && this.sessionProcessing) ||
      (this.isImportAddressesStandardization && this.sessionProcessing);
  }

  @computed get isImportFinished() {
    return this.isImportProcessing && this.sessionSucceed;
  }

  @computed get sourceColumns() {
    if (this.sourceColumnsReq.state !== 'fulfilled' || !this.sourceColumnsReq.value.data) {
      return [];
    }
    return this.sourceColumnsReq.value.data;
  }

  @computed get columnsFormTemplates() {
    if (this.columnsTemplatesReq.state !== 'fulfilled' || !this.columnsTemplatesReq.value.data) {
      return [];
    }
    return this.columnsTemplatesReq.value.data?.map(({ id, name, required, value, isChanged }) => ({
      id,
      name,
      value,
      required,
      isChanged,
    }));
  }

  @computed get isColumnsChanged() {
    return this.columnsFormTemplates.some(({ isChanged }) => isChanged);
  }

  @computed get containersFormTemplates() {
    if (this.importContainersReq.state !== 'fulfilled' || !this.importContainersReq.value.data) {
      return [];
    }
    return this.importContainersReq.value.data?.map(({ id, name, wasteMaterialTypes }) => ({
      id,
      name,
      value: wasteMaterialTypes,
    }));
  }

  @computed get showSessionError() {
    return this.errors && this.errors.length > 0;
  }

  @action linkFile = async (file: LinkFileDto) => {
    try {
      this.linkFileReq = this.api.linkFileToSession(this.id, file);
      const { data } = await this.linkFileReq;
      runInAction(() => {
        if (!data) return;
        this.file = data.file;
        this.state = data.state;
        this.stage = data.stage;
      });
    } catch (error) {
      this.reqErrorHandler(error as MinervaError);
    }
    return this.linkFileReq;
  };

  @action updateSessionData = async (polling: boolean) => {
    let req: ApiReq<Session>;
    if (polling) {
      this.sessionDataPollingReq = this.api.getSessionState(this.id);
      req = this.sessionDataPollingReq;
    } else {
      this.sessionDataReq = this.api.getSessionState(this.id);
      req = this.sessionDataReq;
    }

    const { data } = await req;
    runInAction(() => {
      if (!data) return;
      this.state = data.state;
      this.stage = data.stage;
      this.errors = data.errors;
    });
  };

  @action cancelSession = async () => {
    this.cancelSessionReq = this.api.cancelSession(this.id);
    await this.cancelSessionReq;
    return this.cancelSessionReq;
  };

  @action getSourceColumns = async () => {
    this.sourceColumnsReq = this.api.getSourceColumns(this.id);
    return this.sourceColumnsReq;
  };

  @action getColumnsTemplates = async () => {
    this.columnsTemplatesReq = this.api.getColumnsTemplates(this.id);
    return this.columnsTemplatesReq;
  };

  @action mergeImportColumns = async (columns: MergedColumns[]) => {
    try {
      this.mergeImportColumnsReq = this.api.mergeImportColumns({
        id: this.id,
        columns,
      });
      const { data } = await this.mergeImportColumnsReq;
      runInAction(() => {
        if (!data) return;
        this.state = data.state;
        this.stage = data.stage;
      });
    } catch (error) {
      this.reqErrorHandler(error as MinervaError);
    }
    return this.mergeImportColumnsReq;
  };

  @action getValuesForMerge = async () => {
    this.mergingValueReq = this.api.getValuesForMerge(this.id);
    const value = await this.mergingValueReq;
    runInAction(() => {
      if (!value || !value?.data) return;
      this.mergingValuesData.replace(
        value?.data.map(value => {
          return new ImportMergeColumn(value);
        }),
      );
    });


    return this.mergingValueReq;
  };

  @action getContainersForMerge = async () => {
    this.importContainersReq = this.api.getContainersForMerge(this.id);
    await this.importContainersReq;
    await lookupsStore.fetchWasteMaterialTypes();
  };

  @action choseColumnValues = (columnId: number) => {
    this.chosenColumnValues = this.mergingValuesData.find(
      ({ id }) => id === columnId,
    );
  };

  @action mergeImportValues = async () => {
    try {
      this.mergeImportValuesReq = this.api.mergeImportValues({
        id: this.id,
        values: prepareMergedValuesData(this.mergingValuesData),
      });
      const { data } = await this.mergeImportValuesReq;
      runInAction(() => {
        if (!data) return;
        this.state = data.state;
        this.stage = data.stage;
      });
    } catch (error) {
      this.reqErrorHandler(error as MinervaError);
    }
    return this.mergeImportValuesReq;
  };

  @action mergeImportContainers = async (columns: ImportContainerValue[]) => {
    try {
      this.mergeImportContainersReq = this.api.mergeImportContainers({
        id: this.id,
        data: prepareMergedContainersData(columns),
      });
      const { data } = await this.mergeImportContainersReq;
      runInAction(() => {
        if (!data) return;
        this.state = data.state;
        this.stage = data.stage;
      });
    } catch (error) {
      this.reqErrorHandler(error as MinervaError);
    }
    return this.mergeImportContainersReq;
  };

  @action confirmImport = async () => {
    try {
      this.confirmImportReq = this.api.confirmImport(this.id);
      const { data } = await this.confirmImportReq;
      runInAction(() => {
        if (!data) return;
        this.state = data.state;
        this.stage = data.stage;
      });
    } catch (error) {
      this.reqErrorHandler(error as MinervaError);
    }
    return this.confirmImportReq;
  };

  @action exportBadRows = async () => {
    await this.exportEntity.asyncExportAction(
      this.api.exportBadRows(this.id)
    );
  };

  @action finishImport = async () => {
    this.finishImportReq = this.api.finishImport(this.id);
    const { data } = await this.finishImportReq;
    runInAction(() => {
      if (!data) return;
      this.state = data.state;
      this.stage = data.stage;
    });
    return this.finishImportReq;
  };

  @action reqErrorHandler = (error: MinervaError) => {
    const reqErrors = error?.response?.data.errors;
    if (!reqErrors || !this.errors) return;
    this.errors = [...reqErrors, ...this.errors];
  };

  @action clearSession = () => {
    this.columnsTemplatesReq = emptyValue;
  };
}
