import * as React from 'react';

import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton/IconButton';

import ExportIcon from '../../../../assets/icons/TableActionsIcons/ExportIcon';

export const ExportButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function GridToolbarColumnsButton(props, ref) {
    const { onClick, ...other } = props;
    return (
      <IconButton
        ref={ref}
        size="small"
        {...other}
        onClick={onClick}
        aria-label="Filter Menu">
        <ExportIcon style={{ padding: '2px' }} />
      </IconButton>
    );
  },
);

export default ExportButton;
