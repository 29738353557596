import { useState, useRef } from 'react';

import { Grid, IconButton, Popover, Typography } from '@mui/material';


import { GRID_ROW_HEIGHT } from '../../../../Table/constants';
import { useAssetsTableStore } from '../../../AssetsTableProvider';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { StyledTextArea } from 'src/components/StyledTextArea';
import { TextWrapper } from '../../EditAsset/styles';
import { RenderCellComponentProps } from '../types';
import { DEFAULT_POPUP_AREA_WIDTH } from 'src/components/AssetsTable/helpers/common';

export const TitleCell = ({ id, hasFocus, row }: RenderCellComponentProps) => {
  const ref = useRef<HTMLSpanElement | null>(null);
  const { chosenAsset, selectAsset, removeChosenAsset } = useAssetsTableStore();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const openPopover = () => {
    selectAsset(Number(id))
    !open && setAnchorEl(ref.current);
  };

  const onClose = () => {
    removeChosenAsset();
    setAnchorEl(null)
  }

  return (
    <>
      <Grid container flexDirection="row" wrap="nowrap">
        <Typography
          sx={{
            height: GRID_ROW_HEIGHT,
            alignItems: "center",
            display: "flex",
          }}
          ref={ref}
          noWrap>
          <span style={{  overflow:"hidden", textOverflow: "ellipsis" }}>
            {row?.shortText}
          </span>
        </Typography>
        <Grid item>
          {row.shortText.length > 50 && <IconButton size="small"><VisibilityIcon onClick={openPopover} sx={{cursor: "pointer"}}/></IconButton>}
        </Grid>
      </Grid>
  {hasFocus && !!chosenAsset && (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <TextWrapper>
        <StyledTextArea
          readOnly
          value={chosenAsset?.shortText}
          minRows={5}
          maxRows={7}
          sx={{ width: DEFAULT_POPUP_AREA_WIDTH }}
        />
      </TextWrapper>
    </Popover>
  )}
    </>
  );
};
