import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

import { lookupsStore } from '../../stores/lookups';
import { userStore } from '../../stores/user';
import {
  CallListType,
} from '../../utils/pageInitialization';

import { generatorStore } from './store';

export const GeneratorsRoute: MinervaRoute<'generators'> = {
  name: 'generators',
  path: '/generators',
  comp: () => lazy(() => import('./index')),
  activate: async () => {
    generatorStore.resetStore();

    generatorStore.loading = true;
    try {
      await generatorStore.fetchGenerators();
    } finally {
      generatorStore.loading = false;
    }
  },
  deactivate: () => {
    userStore.isAdmin && lookupsStore.resetJurisdictions();
  },
};

export const GeneratorRequestOrderList: CallListType[] = [
  {
    request: lookupsStore.fetchJurisdictionsLookups,
    target: {
      jurisdiction: 'jurisdictions',
    },
  },
  {
    request: lookupsStore.fetchHaulersLookup,
    target: { hauler: 'haulersLookups' },
  },
  {
    request: lookupsStore.fetchAgenciesLookupWithBlank,
    target: { agency: 'agenciesLookup' },
  },
  {
    request: lookupsStore.fetchAccountTypes,
    target: { accountType: 'accountTypes' },
  },
  {
    request: lookupsStore.fetchCollectionSystems,
    target: { collectionSystem: 'collectionSystemsLookup' },
  },
  {
    request: lookupsStore.fetchLocationTypes,
    target: { types: 'locationTypes' },
  },
];
