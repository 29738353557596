import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';

import LinearDeterminate from '../LinearDeterminate';

const ExportLoaderOverlay = ({ loading }: { loading: boolean }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      open={loading}>
      <Box
        sx={{
          width: 300,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignContent: 'center',
        }}>
        Exporting ...
        <LinearDeterminate />
      </Box>
    </Backdrop>
  );
};
export default ExportLoaderOverlay;
