import { action, computed, makeObservable, observable } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { AddressBookApi } from 'src/api/AddressBook';
import { ContactType } from 'src/api/api-types/address-book';
import { ItemType } from 'src/types';

export class Contact {
  public api = new AddressBookApi();

  readonly id: number;
  @observable fullName: string;
  @observable type: ItemType | null;
  @observable relatedTo: ItemType | null;
  @observable relatedToType: ItemType | null;
  @observable email: string;
  @observable phone: string;
  @observable addressLine1: string;
  @observable addressLine2: string;
  @observable country: string;
  @observable city: string;
  @observable state: string;
  @observable zipCode: string;

  @observable.ref createReq: ApiReq<ContactType> = emptyValue;
  @observable.ref editReq: ApiReq<ContactType> = emptyValue;
  @observable.ref deleteReq: ApiReq<ContactType> = emptyValue;

  constructor(contact: Partial<ContactType>) {
    const {
      id,
      fullName,
      addressLine1,
      addressLine2,
      type,
      relatedTo,
      email,
      phone,
      relatedToType,
      country,
      city,
      state,
      zipCode,
    } = contact

    makeObservable(this);
    this.id = id!
    this.fullName = fullName || '';
    this.addressLine1 = addressLine1 || '';
    this.addressLine2 = addressLine2 || '';
    this.type = type || null;
    this.relatedTo = relatedTo || null;
    this.relatedToType = relatedToType || null;
    this.email = email || '';
    this.phone = phone || '';
    this.country = country || '';
    this.city = city || '';
    this.state = state || '';
    this.zipCode = zipCode || '';
  }

  @computed get serialized() {
    return {
      fullName: this.fullName,
      type: this.type,
      relatedTo: this.relatedTo,
      relatedToType: this.relatedToType,
      email: this.email,
      phone: this.phone,
      country: this.country,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
    };
  }

  @action setEntity = (fields: Partial<ContactType>) => {
    this.fullName = fields.fullName || this.fullName
    this.phone = fields.phone || this.phone
    this.email = fields.email || this.email
    this.country = fields.country || this.country
    this.city = fields.city || this.city
    this.state = fields.state || this.state
    this.zipCode = fields.zipCode || this.zipCode
    this.addressLine1 = fields.addressLine1 || this.addressLine1
    this.addressLine2 = fields.addressLine2 || this.addressLine2
    this.type = fields.type || this.type
  }

  @action createContact = async () => {
    this.createReq = this.api.createContact(this.serialized)
    await this.createReq
    return this.createReq
  }

  @action editContact = async () => {
    this.editReq = this.api.editContact(this.id, this.serialized)
    await this.editReq
    return this.editReq
  }

  @action deleteContact = async () => {
    this.deleteReq = this.api.deleteContact(this.id)
    await this.deleteReq
    return this.deleteReq
  }
}
