import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DeleteIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1 4H2.5H14.5"
      stroke="#2B59C3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.75 4V2.5C4.75 1.67157 5.42157 1 6.25 1H9.25C10.0784 1 10.75 1.67157 10.75 2.5V4M13 4V14.5C13 15.3284 12.3284 16 11.5 16H4C3.17157 16 2.5 15.3284 2.5 14.5V4H13Z"
      stroke="#2B59C3"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default DeleteIcon;
