import { MouseEventHandler } from 'react';

import { observer } from 'mobx-react-lite';
import { OriginActiveInactiveIcon } from 'src/assets/icons/ActiveInactive/ActiveInactiveIcon';
import { theme } from 'src/utils/theme/minerva-main';

import { ActiveFilterType } from '../../models/filter/constants';

interface ActiveInactiveType {
  activeMode: ActiveFilterType;
  handleClick?: MouseEventHandler;
}

const ActiveInactiveIcon = observer(
  ({ activeMode, handleClick }: ActiveInactiveType) => {
    return (
      <OriginActiveInactiveIcon
        sx={{
          width: '16px',
          height: '16px',
          cursor: 'pointer',
        }}
        onClick={handleClick}
        fill={
          activeMode === ActiveFilterType.ActiveOnly
            ? theme.palette.text.secondary
            : theme.palette.primary.main
        }
      />
    );
  },
);

export default ActiveInactiveIcon;
