import { SearchPanelProps } from '.';

import { createContext, PropsWithChildren, useContext } from 'react';

import { once } from 'lodash';
import { Filter } from 'src/models/filter';

export const createSearchPanelCtx = once(<T extends object>() =>
  createContext<Filter<T> | undefined>(undefined),
);

export const SearchProvider = <T extends object>({
  children,
  filterCtx,
}: PropsWithChildren<Partial<SearchPanelProps<T>>>) => {
  const SearchContext = createSearchPanelCtx<T>();
  return (
    <SearchContext.Provider value={filterCtx}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchPanel = <T extends object>() =>
  useContext(createSearchPanelCtx<T>());
