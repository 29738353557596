import { FC, isValidElement, MouseEventHandler } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import EditIcon from '../../assets/icons/Generator/EditIcon';

interface Props extends BoxProps {
  leftIcon?: JSX.Element;
  edit?: MouseEventHandler | JSX.Element | null;
  create?: MouseEventHandler | JSX.Element | null;
}
const Title = styled(Box)<Props>(({ theme, edit }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: !edit ? 'flex-start' : 'space-between',
  alignItems: 'center',
}));

const CardTitle: FC<Props> = ({
  children,
  leftIcon,
  edit,
  create,
  ...rest
}) => {
  return (
    <Title edit={edit || create} {...rest}>
      {!!leftIcon && leftIcon}
      <Typography variant="h4" component="div" sx={{ px: 1, fontWeight: 600 }}>
        {children}
      </Typography>
      {isValidElement(edit) ? (
        edit
      ) : typeof edit === 'function' ? (
        <IconButton onClick={edit}>
          <EditIcon />
        </IconButton>
      ) : null}
      {isValidElement(create) ? (
        create
      ) : typeof create === 'function' ? (
        <IconButton onClick={create}>
          <AddIcon />
        </IconButton>
      ) : null}
    </Title>
  );
};

export default CardTitle;
