import { Tooltip } from '@mui/material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const OriginActiveInactiveIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_6416_49026)">
      <path
        d="M8.9946 1.96773C9.49096 1.25546 9.50925 1.03372 9.13881 0.0526457C9.6451 0.186001 10.1315 0.270198 10.583 0.440683C13.5747 1.56453 15.3966 3.70448 15.9034 6.86265C16.5717 11.0212 13.7011 15.0412 9.56829 15.8471C5.33618 16.6729 1.2676 14.0779 0.226293 9.88536C-0.646254 6.37106 1.06801 2.58796 4.27397 0.927037C5.39563 0.341878 6.63745 0.0246943 7.90209 0.000349594C8.48257 -0.0132474 8.91937 0.371129 8.94183 0.918147C8.96378 1.44634 8.55833 1.87517 7.97994 1.88929C6.99088 1.91334 6.05511 2.12932 5.17525 2.58796C2.79012 3.83313 1.4698 6.50442 2.0111 9.13805C2.52208 11.6242 4.0399 13.2496 6.48251 13.9127C9.87865 14.8341 13.3704 12.5844 13.9979 9.13701C14.6076 5.7843 12.4529 2.64078 9.10433 1.99702C9.07402 1.99126 9.04424 1.98133 8.9946 1.96773Z"
        fill={props.fill ?? '#767D86'}
      />
      <path
        d="M8.9388 4.35772C9.46703 3.78247 9.53443 3.17374 9.15876 2.47872C9.25751 2.49912 9.32335 2.5101 9.38761 2.52579C11.5439 3.04875 13.2618 4.94501 13.5743 7.14458C13.9886 10.0606 12.2116 12.7533 9.39022 13.4829C5.81069 14.409 2.28445 11.619 2.35656 7.91438C2.37601 6.64184 2.82472 5.41327 3.62982 4.42819C4.43491 3.44312 5.54909 2.75942 6.79139 2.48814C7.16234 2.41376 7.53873 2.36986 7.91682 2.35687C8.48163 2.32863 8.91581 2.72922 8.94037 3.26735C8.96388 3.79031 8.56313 4.21914 7.9999 4.23849C6.68272 4.28451 5.62782 4.82107 4.88119 5.91196C3.32419 8.18685 4.74587 11.3591 7.47846 11.7252C8.46769 11.8607 9.47017 11.5973 10.2655 10.9931C11.0608 10.3889 11.5838 9.49332 11.7195 8.50324C11.9676 6.69536 10.8349 4.9356 9.08509 4.41054C9.04538 4.39799 9.00463 4.38126 8.9388 4.35772Z"
        fill={props.fill ?? '#767D86'}
      />
      <path
        d="M8.80438 6.81586C9.40263 6.32584 9.54108 5.71345 9.24327 4.97137C9.31798 4.99857 9.37075 5.01373 9.41987 5.03674C10.7721 5.67423 11.5135 7.15683 11.2266 8.64831C11.084 9.35871 10.7115 10.0023 10.1666 10.4793C9.62165 10.9564 8.93482 11.2403 8.21241 11.2872C6.46993 11.3918 4.99809 10.1921 4.73476 8.45691C4.48502 6.80592 5.57596 5.202 7.23484 4.7988C7.47637 4.74311 7.72319 4.71366 7.97102 4.71094C8.21089 4.71542 8.43998 4.81149 8.61141 4.97949C8.78283 5.14749 8.88364 5.37471 8.89318 5.61465C8.90273 5.85459 8.8203 6.08911 8.66276 6.27022C8.50522 6.45133 8.28449 6.56533 8.04574 6.58889C7.41876 6.62184 6.95427 6.88593 6.70191 7.46747C6.60668 7.69732 6.57423 7.94839 6.60789 8.19493C6.64155 8.44148 6.74011 8.67463 6.89347 8.87048C7.04682 9.06633 7.24946 9.21783 7.4806 9.30947C7.71174 9.4011 7.96306 9.42955 8.20881 9.39192C8.45456 9.35429 8.68589 9.25192 8.87908 9.0953C9.07227 8.93869 9.22038 8.73347 9.30821 8.50069C9.39605 8.2679 9.42046 8.01592 9.37893 7.77058C9.3374 7.52524 9.23143 7.29537 9.07189 7.10453C8.99352 7.01144 8.90522 6.92463 8.80438 6.81586Z"
        fill={props.fill ?? '#767D86'}
      />
    </g>
    <defs>
      <clipPath id="clip0_6416_49026">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
const ActiveInactiveIcon = (props: SvgIconProps) => (
  <Tooltip title="Inactive">
    <div
      style={{
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <OriginActiveInactiveIcon
        sx={{
          width: '16px',
          height: '16px',
        }}
        {...props}
      />
    </div>
  </Tooltip>
);

export default ActiveInactiveIcon;
