import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export const BooleanView = ({ value }: { value: boolean | null }) => (
  <>
    {typeof value === 'boolean' ? (
      <>{value ? <CheckIcon color="success" /> : <ClearIcon color="error" />}</>
    ) : null}
  </>
);
