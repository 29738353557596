import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import MatrixCard from 'src/components/ComplianceMatrix/components/MatrixCard';
import { isPending } from 'src/utils/common';

import { ComplianceMatrixStore } from '../store';

const CardWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(220px, 4fr));',
  padding: theme.spacing(1),
  gridColumnGap: theme.spacing(2),
  gridRowGap: theme.spacing(2),
}));

export default observer(({ store }: { store: ComplianceMatrixStore }) => {
  const { sb1383SlcpCollectionSystemTotal, ab341McrTotal, ab1826MoreTotal } =
    store.complianceMatrix?.matrix || {};

  return (
    <CardWrapper>
      <MatrixCard
        loading={isPending(store.complianceMatrix?.matrixReq!)}
        title="SB 1383 SLCP"
        subTitle="Compliance"
        stateId={sb1383SlcpCollectionSystemTotal?.id}
      />
      <MatrixCard
        loading={isPending(store.complianceMatrix?.matrixReq!)}
        title="AB1826 MORe"
        stateId={ab1826MoreTotal?.id}
      />
      <MatrixCard
        loading={isPending(store.complianceMatrix?.matrixReq!)}
        title="AB341 MCR"
        stateId={ab341McrTotal?.id}
      />
    </CardWrapper>
  );
});
