import { useContext } from 'react';

import { SelectChangeEvent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import CategoryItem from 'src/components/CategoryItem';
import { OptionDescription } from 'src/components/Lookups/styles';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { ItemType } from 'src/types';
import { isPending } from 'src/utils/common';

interface SelectCategoryProps {
  id: number | string;
  value: ItemType;
  field: string;
}

export const SelectCategoryComponent = observer(
  ({ id, field, value }: SelectCategoryProps) => {
    const apiRef = useGridApiContext();
    const { fetchAssetSubCategories, assetCategories, categoryReq } =
      useContext(lookupsStoreCtx);

    const changeCellHandler = (fieldName: string, cellValue: ItemType) => {
      return apiRef.current.setEditCellValue({
        id,
        field: fieldName,
        value: cellValue,
      });
    };

    const handleChange = async (_event: SelectChangeEvent, child?: any) => {
      const newCategory = {
        id: child?.props.value,
        name: child?.props?.children,
      };
      await changeCellHandler(field, newCategory);
      const res = await fetchAssetSubCategories(Number(newCategory.id));
      res.data && (await changeCellHandler('subCategory', res.data?.[0]));
    };

    return (
      <Select
        value={String(value?.id)}
        fullWidth
        onChange={handleChange}
        endAdornment={
          <>
            {isPending(categoryReq) ? (
              <CircularProgress color="inherit" size={20} />
            ) : null}
          </>
        }>
        {assetCategories?.map(({ id, name, description }) => (
          <MenuItem key={id} value={id}>
            <OptionDescription
              key={id}
              placement="right"
              disableHoverListener={!description}
              title={description || ''}>
              <div>
                {field.includes('type') ? name : <CategoryItem name={name} />}
              </div>
            </OptionDescription>
          </MenuItem>
        ))}
      </Select>
    );
  },
);
