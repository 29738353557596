import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { ServiceHistoryItem, ServiceHistoryResponse, ServicesFilter } from 'src/api/api-types/services';
import { ServicesApi } from 'src/api/services';

import { ServiceHistory } from 'src/models/servise/service-history';
import { TablePagination } from '../../models/pagination/pagination';
import { historyColumns } from './helpers/columns';
import { GridRowId } from '@mui/x-data-grid-pro';
import { AsyncExportEntity } from 'src/models/export/AsyncExportEntity';
import { GridColumnsManager } from 'src/models/grid-columns-manager/grid-columns-manager';
import { defaultFilterFields, serviceHistoryVisibilityModel } from './helpers/common';
import { ExportDataType } from 'src/api/exportTypes';
import { TableFilter } from 'src/models/filter/table-filter';
import { ServiceHistoryExportColumnsNameMap } from 'src/models/filter/constants';

type FilterFields = Record<keyof ServicesFilter, string | number>;

export class ServiceHistoryStore {
  api = new ServicesApi({ prefix: '' });

  @observable filter = new TableFilter<FilterFields, ServiceHistoryItem>(
    historyColumns,
    defaultFilterFields,
    {},
  );
  @observable servicesHistoryReq: ApiReq<ServiceHistoryResponse> = emptyValue;
  @observable.ref serviceHistory: IObservableArray<ServiceHistory> = observable([]);
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable columnsManager;
  @observable selectedServices: Array<GridRowId> = [];
  @observable pagination = new TablePagination<ServiceHistoryResponse>({
    requestRef: this.servicesHistoryReq,
  });


  constructor() {
    makeObservable(this);
    this.pagination.setFetchingCallBack(this.fetchServiceHistory);
    this.columnsManager = new GridColumnsManager<ServiceHistoryItem>({
      columns: historyColumns,
      columnsMap: {},
      visibilityModel: {
        admin: serviceHistoryVisibilityModel,
        jurisdiction: serviceHistoryVisibilityModel,
        hauler: serviceHistoryVisibilityModel,
      },
    });
  }

  @computed get servicesHistoryData() {
    if (this.servicesHistoryReq.state !== 'fulfilled' || !this.servicesHistoryReq.value) {
      return [];
    }
    return this.servicesHistoryReq.value.data?.items || [];
  }


  @action fetchServiceHistory = async () => {
    this.servicesHistoryReq = this.api.getServiceHistoryData({
      ...this.pagination.serialize,
      filters: this.filter.filterParams,
    });
    await this.servicesHistoryReq;

    runInAction(() => {
      if (
        this.servicesHistoryReq.state !== 'fulfilled' ||
        this.servicesHistoryReq.value?.data === null
      )
        return;
      this.serviceHistory.replace(
        this.servicesHistoryData.map(service => {
          return new ServiceHistory(service);
        }),
      );
      this.pagination.setPagination(this.servicesHistoryReq.value?.data);
    });
    return this.servicesHistoryReq;
  };

  @action setSelectedServices = (items: GridRowId[]) => {
    this.selectedServices = items;
  };

  @action removeSelectedServices = () => {
    if (this.selectedServices.length === 0) return;
    this.selectedServices = [];
  };

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncServicesHistoryReportByType(
        this.exportEntity.exportParams<
          ServiceHistoryResponse,
          FilterFields,
          ServiceHistoryItem
        >(
          ServiceHistoryExportColumnsNameMap(this.columnsManager.hiddenColumns as IObservableArray<keyof ServiceHistoryItem>),
          this.filter,
          this.pagination,
          this.selectedServices
        ),
        data.type,
      )
    );
  };

  @action resetStore = () => {
    this.pagination.resetPagination();
    this.serviceHistory.replace([]);
    this.filter.resetFilters();
    this.filter.setSearchOpen(false);
  };
}
