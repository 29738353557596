import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EditIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1475 2.66406L21.3327 7.84925L7.8512 21.3307H2.66602V16.1455L16.1475 2.66406Z"
      stroke="#767D86"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default EditIcon;
