import { ChangeEvent } from 'react';

import { Checkbox } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { EFGTypesMeta } from 'src/api/api-types/food-generators';

import { useEfgEdit } from '../efgEditContext';

import { NoField } from './NoField';

const EditFoodFacility = () => {
  const { chosenFood } = useEfgEdit();
  if (!chosenFood) return null;
  const dependingValue = chosenFood?.edibleFoodGeneratorType as EFGTypesMeta;

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    chosenFood?.setEntity({ onSiteFoodFacility: event.target.checked });
  };

  if (
    !dependingValue ||
    !dependingValue.hasOwnProperty('onSiteFoodFacilityEnabled') ||
    !dependingValue.onSiteFoodFacilityEnabled
  ) {
    return <NoField />;
  }

  return (
    <Checkbox
      sx={{
        '& .MuiFormControlLabel-label.Mui-disabled': {
          color: 'black',
        },
        alignItems: 'center',
      }}
      checked={Boolean(chosenFood?.onSiteFoodFacility)}
      onChange={changeHandler}
    />
  );
};

export default observer(EditFoodFacility);
