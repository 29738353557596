import {
  EFGDetailsRequired,
  EFGDetailsType,
  EFGTypesMeta,
} from '../../api/api-types/food-generators';

import { FullDescriptionResponse, ItemType } from 'src/types';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from '../../api';
import { isEmpty } from 'lodash';
import { FoodGeneratorsApi } from 'src/api/foodGenerators';
import { ContactType } from '../../api/api-types/address-book';
import { accountDetailsStore } from '../../pages/AccountDetails/store';

export class EfgDetailsItem {
  readonly api = new FoodGeneratorsApi({ prefix: '' });
  readonly jurisdiction?: ItemType;

  @observable id: number;
  @observable name: string;
  @observable edibleFoodGeneratorType: EFGTypesMeta | ItemType | null;
  @observable edibleFoodGeneratorTier: ItemType | null;
  @observable email: string;
  @observable sqFeet: number;
  @observable seats: number;
  @observable state: string;
  @observable onSiteFoodFacility: boolean;
  @observable beds: number;
  @observable addressLine1: string;
  @observable addressLine2: string;
  @observable rooms: number;
  @observable contact: Partial<ContactType>;
  @observable country: string;
  @observable city: string;
  @observable createdAt: string;
  @observable createdBy: ItemType | null;
  @observable zipCode: string;
  @observable isActive: boolean;
  @observable isEfg: boolean;
  @observable relatedTo: ItemType | null;
  @observable relatedToType: ItemType | null;
  @observable fullName: string;
  @observable phone: string;
  @observable updatedAt: string;
  @observable updatedBy: ItemType | null;
  @observable description: string;
  @observable descriptionTruncated: boolean;

  @observable.ref fullDescriptionReq: ApiReq<FullDescriptionResponse> = emptyValue;
  @observable.ref updateDetailsReq: ApiReq<EFGDetailsType> = emptyValue;
  @observable.ref deleteEfgReq: ApiReq<EFGDetailsType> = emptyValue;

  constructor({
    id,
    name,
    edibleFoodGeneratorType,
    edibleFoodGeneratorTier,
    sqFeet,
    seats,
    jurisdiction,
    onSiteFoodFacility,
    beds,
    isEfg,
    rooms,
    contact,
    description,
    descriptionTruncated
  }: EFGDetailsRequired) {
    makeObservable(this);
    const accountContact = accountDetailsStore?.account
      ?.location as Partial<ContactType>;

    const originName =
      name ||
      accountDetailsStore?.account?.location?.nickname ||
      accountDetailsStore?.account?.name ||
      '';

    this.id = id;
    this.name = originName;
    this.edibleFoodGeneratorType = edibleFoodGeneratorType || null;
    this.edibleFoodGeneratorTier = edibleFoodGeneratorTier || null;
    this.email = contact?.email || '';
    this.sqFeet = sqFeet || 0;
    this.seats = seats || 0;
    this.onSiteFoodFacility = onSiteFoodFacility || false;
    this.beds = beds || 0;
    this.rooms = rooms || 0;
    this.jurisdiction =
      jurisdiction || (accountDetailsStore?.account?.jurisdiction as ItemType);
    this.isEfg = isEfg || false;
    this.contact = contact || accountContact;
    this.country = accountContact?.country || '';
    this.addressLine1 =
      contact?.addressLine1 || accountContact?.addressLine1 || '';
    this.addressLine2 =
      contact?.addressLine2 || accountContact?.addressLine2 || '';
    this.city = contact?.city || accountContact?.city || '';
    this.state = contact?.state || accountContact?.state || '';
    this.createdAt = contact?.createdAt || accountContact?.createdAt || '';
    this.createdBy = contact?.createdBy || accountContact?.createdBy || null;
    this.zipCode = contact?.zipCode || accountContact?.zipCode || '';
    this.isActive = contact?.isActive || accountContact?.isActive || false;
    this.relatedTo = contact?.relatedTo || accountContact?.relatedTo || null;
    this.relatedToType =
      contact?.relatedToType || accountContact?.relatedToType || null;
    this.fullName = contact?.fullName || accountContact?.fullName || '';
    this.phone = contact?.phone || accountContact?.phone || '';
    this.updatedAt = contact?.updatedAt || accountContact?.updatedAt || '';
    this.updatedBy = contact?.updatedBy || accountContact?.updatedBy || null;
    this.description = description || '';
    this.descriptionTruncated = descriptionTruncated || false;
  }

  @computed get isEditable() {
    return (
      !isEmpty(this.edibleFoodGeneratorTier) &&
      !isEmpty(this.edibleFoodGeneratorType)
    );
  }

  @computed get defaultFormValue() {
    return {
      id: this.id,
      edibleFoodGeneratorType: this.edibleFoodGeneratorType,
      edibleFoodGeneratorTier: this.edibleFoodGeneratorTier?.name,
      sqFeet: this.sqFeet,
      beds: this.beds,
      rooms: this.rooms,
      seats: this.seats,
      isEfg: this.isEfg,
      onSiteFoodFacility: this.onSiteFoodFacility,
      jurisdiction: this.jurisdiction,
      fullName: this.fullName,
      name: this.name,
      phone: this.phone,
      email: this.email,
      country: this.country,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
    };
  }

  @computed get editReqData() {
    return {
      id: this.id,
      name: this.name,
      sqFeet: this.sqFeet,
      beds: this.beds,
      rooms: this.rooms,
      seats: this.seats,
      onSiteFoodFacility: this.onSiteFoodFacility,
      edibleFoodGeneratorType: this.edibleFoodGeneratorType,
      contact: {
        fullName: this.fullName,
        phone: this.phone,
        email: this.email,
      },
      description: this.description,
      descriptionTruncated: this.descriptionTruncated
    };
  }

  @computed get fullDescription() {
    if (
      this.fullDescriptionReq.state !== 'fulfilled' ||
      !this.fullDescriptionReq.value
    ) {
      return '';
    }

    return this.fullDescriptionReq.value.data?.description || '';
  }

  @action setEntity = (data: Partial<EFGDetailsType>) => {
    this.id = data?.id ?? this.id;
    this.name = data?.name ?? this.name;
    this.edibleFoodGeneratorType =
      data?.edibleFoodGeneratorType ?? this.edibleFoodGeneratorType;
    this.edibleFoodGeneratorTier =
      data?.edibleFoodGeneratorTier ?? this.edibleFoodGeneratorTier;
    this.email = data.contact?.email ?? this.email;
    this.sqFeet = data?.sqFeet ?? this.sqFeet;
    this.seats = data?.seats ?? this.seats;
    this.state = data.contact?.state ?? this.state;
    this.onSiteFoodFacility =
      data?.onSiteFoodFacility ?? this.onSiteFoodFacility;
    this.beds = data?.beds ?? this.beds;
    this.addressLine1 = data.contact?.addressLine1 ?? this.addressLine1;
    this.addressLine2 = data.contact?.addressLine2 ?? this.addressLine2;
    this.rooms = data?.rooms ?? this.rooms;
    this.contact = data?.contact ?? this.contact;
    this.country = data.contact?.country ?? this.country;
    this.city = data.contact?.city ?? this.city;
    this.zipCode = data.contact?.zipCode ?? this.zipCode;
    this.isActive = data.contact?.isActive ?? this.isActive;
    this.relatedTo = data.contact?.relatedTo ?? this.relatedTo;
    this.relatedToType = data.contact?.relatedToType ?? this.relatedToType;
    this.fullName = data.contact?.fullName ?? this.fullName;
    this.phone = data.contact?.phone ?? this.phone;
    this.description = data.description ?? this.description;
  };

  @action editDetails = async () => {
    this.updateDetailsReq = this.api.updateFoodGenerator(this.editReqData);
    await this.updateDetailsReq;
  };

  @action deleteEFG = async () => {
    this.deleteEfgReq = this.api.deleteEFG(this.id);
    await this.deleteEfgReq;
  };

  @action getFullDescription = async () => {
    if (this.descriptionTruncated) {
      this.fullDescriptionReq = this.api.getFullDescription(this.id);
      await this.fullDescriptionReq;
      runInAction(() => {
        this.descriptionTruncated = false;
        this.description = this.fullDescription;
      });
    }
  };
}
