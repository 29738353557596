import Highcharts from 'highcharts';
import { ClickOnLegendType, ClickOnPointType } from '../../types';

export const getLineChartExportRasterImageProps = (type: string) => {
  return {
    exportOptions: {
      type,
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: 'Helvetica',
            },
          },
        },
      },
      // 2400*900
      sourceWidth: 1200,
      sourceHeight: 450,
      scale: 2,
    },
    chartOptions: {
      chart: {
        style: {
          fontFamily: 'Helvetica',
        },
      },
    },
  };
};

export const getLineChartExportSVGProps = (type: string) => {
  return {
    exportOptions: {
      type,
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: 'Helvetica',
            },
          },
        },
      },
      // 2400*900
      sourceWidth: 1200,
      sourceHeight: 450,
      scale: 2,
      style: {
        fontFamily: 'Helvetica',
      },
    },
    chartOptions: {
      chart: {
        style: {
          fontFamily: 'Helvetica',
        },
      },
      rangeSelector: {
        selected: 0,
        inputEnabled: false,
        allButtonsEnabled: false,
        buttons: [],
      },
    },
  };
};

export const getPieChartExportSVGProps = (type: string) => {
  return {
    exportOptions: {
      type,
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: 'Helvetica',
            },
          },
        },
      },
      // 1200*900
      sourceWidth: 800,
      sourceHeight: 600,
      scale: 1.5,
      style: {
        fontFamily: 'Helvetica',
      },
    },
    chartOptions: {
      chart: {
        style: {
          fontFamily: 'Helvetica',
        },
      },
      rangeSelector: {
        selected: 0,
        inputEnabled: false,
        allButtonsEnabled: false,
        buttons: [],
      },
    },
  };
};

export const getPieChartExportRasterImageProps = (type: string) => {
  return {
    exportOptions: {
      type,
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            style: {
              fontFamily: 'Helvetica',
            },
          },
        },
      },
      // 1200*900
      sourceWidth: 800,
      sourceHeight: 600,
      scale: 1.5,
    },
    chartOptions: {
      chart: {
        style: {
          fontFamily: 'Helvetica',
        },
      },
    },
  };
};

export const PRINT_SCALE = 0.32;

export const downloadPdf = (chartRef: any, width: number, height: number) => {
  const beforePrintOptions = {
    chart: {
      width: chartRef.chartWidth,
      height: chartRef.chartHeight,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
  };

  chartRef.update(
    {
      chart: {
        width,
        height,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
          },
        },
      },
    },
    false,
    false,
    false,
  );

  chartRef.exportChartLocal({ type: 'application/pdf' });

  chartRef.update(beforePrintOptions, false, false, false);
};

export abstract class PrintableChart {
  abstract chartRef?: any;
  onPrint?: () => void;
  beforePrintOptions?: any;
  printWidth: number;
  printHeight: number;
  printScale: number;

  constructor(printWidth: number, printHeight: number, printScale: number) {
    this.printWidth = printWidth;
    this.printHeight = printHeight;
    this.printScale = printScale;
  }

  public onBeforePrint = async (): Promise<any> => {
    if (!this.chartRef) {
      return;
    }
    // const options = getLineChartExportRasterImageProps('application/pdf');

    this.beforePrintOptions = {
      chart: {
        width: this.chartRef.chartWidth,
        height: this.chartRef.chartHeight,
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
        },
      },
    };

    this.chartRef.update(
      {
        chart: {
          width: this.printWidth,
          height: this.printHeight,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      },
      false,
      false,
      false,
    );

    this.chartRef.container.style.transform = `scale(${
      this.printScale * PRINT_SCALE
    })`;
    this.chartRef.container.style.transformOrigin = 'left top';
  };

  public onAfterPrint = () => {
    if (!this.chartRef) {
      return;
    }
    this.chartRef.update(this.beforePrintOptions, false, false, false);
    this.beforePrintOptions = undefined;
    this.chartRef.container.style.transform = '';
    this.chartRef.container.style.transformOrigin = undefined;
  };
}

export function setSeriesOpacity(clickedSeries: Highcharts.Series) {
  let chart = clickedSeries.chart;
  chart.series.forEach(function (series) {
    if (series !== clickedSeries) {
      series.update(
        {
          opacity: 0.1,
          zIndex: 1,
        } as any,
        false,
      );
    } else {
      series.update(
        {
          opacity: 1,
          zIndex: 999,
        } as any,
        false,
      );
    }
  });
  chart.redraw();
}

export function legendItemClick(
  data: Highcharts.SeriesLegendItemClickEventObject,
  clickOnLegend: ClickOnLegendType | undefined,
) {
  data.preventDefault();
  const chartTitle = data.target.chart.options.title?.text ?? 'None';
  if (!!clickOnLegend && data.target.options.id) {
    clickOnLegend(data.target.options.id, data.target.name, chartTitle, data.target.userOptions?.custom?.total);
  }
}

export function pointItemClick(
  data: Highcharts.PointClickEventObject,
  clickOnPoint: ClickOnPointType | undefined,
) {
  const { point } = data as Highcharts.PointClickEventObject & {
    point: Highcharts.Point & { id: string };
  };
  const chartTitle = data.point.series.chart.options.title?.text ?? 'None';

  if (!!clickOnPoint && point.series.options.id && point.x) {
    clickOnPoint(
      point.series.options.id,
      point.series.name,
      chartTitle,
      point.id,
      point.y,
      Highcharts.dateFormat('%m/%d/%y', Number(point.x)),
    );
  }
}
