export const defaultFilterField = {
  'q.location.id': '',
  'q.generator.id': '',
  'q.hauler.id': '',
  'q.jurisdictions': '',
  'q.accountTypes': '',
  'q.haulers': '',
  'q.serviceWasteTypes': '',
  'q.unitsOfMeasure': '',
};

export const ServicesVisibleModel = {
  generatorId: false,
  jurisdiction: false,
  startDate: false,
  accountType: false,
  location: false,
  schedule: false,
  onCall: false,
  site: false,
  containerGroup: false,
};
