import { useState, MouseEvent } from 'react';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { observer } from 'mobx-react-lite';

import { Asset } from '../../../../models/assets/asset';
import { useAssetsTableStore } from '../../AssetsTableProvider';

import CountItem from './CountItem';
import FileList from './List';

interface AttachmentListProps {
  asset: Asset;
}

export default observer(({ asset }: AttachmentListProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { loadAttachments, chosenAsset, uploadAssets, fetchAssets } =
    useAssetsTableStore();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (asset?.id !== chosenAsset?.id) loadAttachments(asset?.id);
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeletion = () => {
    if (!chosenAsset?.attachments.totalAttachments) setAnchorEl(null);
    fetchAssets();
  };
  return (
    <Box sx={{ ml: 1 }}>
      <CountItem collapsed={open} asset={asset} handleOpenList={handleClick} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <FileList
          asset={chosenAsset}
          uploadHandler={id => uploadAssets(id)}
          onClose={handleClose}
          chosenAsset={chosenAsset}
          onDeletion={onDeletion}
        />
      </Menu>
    </Box>
  );
});
