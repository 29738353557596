export const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const lineColors: MultiLineColors = {
  '765105e1-4a3f-4716-b878-4bbe00de541a': '#50A1DE',
  '7732204d-81d9-476c-b2b0-041b23852309': '#FF0000',
};

export interface MultiLineColors {
  [guid: string]: string;
}

export const multiLineColors: MultiLineColors = {
  '712c3c74-4864-4787-9592-1eecabfbf4cd': '#00060F',
  '944223c8-2853-43fe-bacb-fec02dd99520': '#66D5A7',
  'aa5c11c4-8408-4221-8e8d-af4c7f14e14c': '#FF0000',
  '765105e1-4a3f-4716-b878-4bbe00de541a': '#BDEDD9',
  '7732204d-81d9-476c-b2b0-041b23852309': '#FBC3C3',
};

export const pieColors = [
  '#C5E0B5',
  '#06B050',
  '#ADB1B6',
  '#D6D8DB',
  '#FF0000',
];
