import * as React from 'react';
import { useContext } from 'react';

import FolderIcon from '@mui/icons-material/Folder';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { observer } from 'mobx-react-lite';
import AccountChildren from 'src/components/Accounts/AccountChildren';
import { accountsStoreCtx } from 'src/pages/Accounts/store';
import { isPending } from 'src/utils/common';

import {
  accountDetailsStore,
  accountDetailsStoreCtx,
} from '../../../../AccountDetails/store';

import Menu from './Menu';

function PartnerFor({ id }: { id: number }) {
  const { account } = useContext(accountDetailsStoreCtx);
  const { fetchAccounts } = useContext(accountsStoreCtx);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    (async () => {
      await accountDetailsStore.fetchAccountDetails(id);
      await accountDetailsStore.account?.fetchAccountChildren();
    })();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteHandler = async (id: number) => {
    await account?.deleteAccountChildren(id);
    await fetchAccounts();
  };

  return (
    <Box sx={{ width: '100%', p: 0 }}>
      <IconButton
        id="PartnerFor"
        aria-controls={open ? 'PartnerFor' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label="PartnerFor"
        size="small"
        onClick={handleClick}>
        <FolderIcon
          sx={{
            width: '18px',
            height: '18px',
            cursor: 'pointer',
            color: 'text.secondary',
          }}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        title="Parent Of">
        {account && (
          <>
            {isPending(account.fetchChildrenReq) && <LinearProgress />}
            <AccountChildren
              list={
                account.accountsChildren.length > 0
                  ? account.accountsChildren
                  : []
              }
              deleteHandler={deleteHandler}
            />
          </>
        )}
      </Menu>
    </Box>
  );
}

export default observer(PartnerFor);
