import styled from '@emotion/styled';

import { MinervaLogo as Logo } from '../../MinervaLogo';

const RootContainer = styled('div')({
  padding: 30,

  '& > h3': {
    fontSize: '1.5rem',
    padding: '30px 0',
  },
});
const MinervaLogo = styled(Logo)({
  width: 167,
  height: 57,
});

export { RootContainer, MinervaLogo };
