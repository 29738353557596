import { FoodGeneratorsFilter } from 'src/api/api-types/food-generators';
import { ColumnsVisibilityModel } from 'src/api/api-types/gridColumnsManager';

export const defaultEFGSearch = {
  'q.name': '',
  'q.reference': '',
  'q.usaAddress': '',
  'q.location.reference': '',
} as FoodGeneratorsFilter;

const efgColVisibleModel = {
  sqFeet: false,
  rooms: false,
  beds: false,
  seats: false,
  onSiteFoodFacility: false,
  description: false,
};

export const visibilityDefaultState: Partial<ColumnsVisibilityModel> = {
  admin: {
    ...efgColVisibleModel,
  },
};
