import { AsyncExportResponse, DownloadTokenResponse, ExportParams, FullDescriptionResponse, ItemType, ListResponseType, Pageable, ParamsWithFilters } from 'src/types';
import { dotNameKeyToObject } from 'src/utils/string';
import { ApiClient } from './';
import {
  AssetCreationReq,
  AssetItem,
  AssetsResponse,
  AssetAttachmentsResponse,
  RequestAssetsParams,
  AssetFiltersType,
} from './api-types/assets';
import { LinkedFileResponse, LinkFileDto } from './api-types/files';

export class AssetsApi extends ApiClient {
  getAssets({ page, pageSize, filters }: RequestAssetsParams) {
    return this.requestPost<
      AssetsResponse,
      ParamsWithFilters<AssetFiltersType>
    >('assets/list', {
      q: dotNameKeyToObject(filters),
      page: {
        num: page,
        size: pageSize,
      },
    });
  }

  async getAssetsFilterOptions({ filters, column }: { filters: Partial<AssetFiltersType>, column: string }) {
    const filterMap: { [key: string]: string } = {
      hauler: 'hauler.name',
      jurisdiction: 'jurisdiction.name',
      type: 'type.name',
      category: 'category.name',
      subCategory: 'subCategory.name',
      accountType: 'accountType.name',
    };
    const filterColumn = filterMap[column] ?? column;
    const response = await this.requestPost<
      ListResponseType<ItemType> & Pageable,
      ParamsWithFilters<AssetFiltersType> & { column: string }
    >('assets/list/filter-options', {
      q: dotNameKeyToObject(filters),
      page: {
        num: 1,
        size: 100000,
      },
      column: filterColumn,
    });

    return response.data!.items;
  }

  updateAsset(item: Partial<AssetCreationReq & { id: number }>) {
    return this.requestPut<AssetsResponse, Partial<AssetCreationReq>>(
      `assets/${item.id}`,
      item,
    );
  }

  createAsset(item: AssetCreationReq) {
    return this.requestPost<AssetItem, AssetCreationReq>(`assets`, item);
  }

  createIRAsset(item: AssetCreationReq) {
    return this.requestPost<AssetItem, AssetCreationReq>(
      `assets/implementation-record`,
      item,
    );
  }

  deleteAsset(id: number) {
    return this.requestDelete<AssetsResponse>(`assets/${id}`);
  }

  getAttachments(id: number) {
    return this.requestGet<AssetAttachmentsResponse>(
      `assets/${id}/attachments`,
    );
  }

  deleteAttachment(id: number, attachmentId: number) {
    return this.requestDelete<AssetsResponse>(
      `assets/${id}/attachments/${attachmentId}`,
    );
  }

  getFullDescription(id: number) {
    return this.requestGet<FullDescriptionResponse>(`assets/${id}/description`);
  }

  getDownloadAttachmentToken(id: number, attachmentId: number) {
    return this.requestGet<DownloadTokenResponse>(
      `assets/${id}/attachments/${attachmentId}/download-token`,
    );
  }

  getAsyncReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `assets/export/${exportType}?flowType=2`,
      params,
    );
  }

  
  linkFileToAsset(assetId: number, dto: LinkFileDto) {
    const mappedDto = {
      ...dto,
      name: dto.fileName,
    };
    return this.requestPost<LinkedFileResponse, typeof mappedDto>(
      `assets/${assetId}/attachments`,
      mappedDto,
    );
  }
}
