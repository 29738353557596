import { GeneratorItem } from 'src/api/api-types/generators';
import { ItemType } from '../../types';

export class Generator {
  readonly id: number;
  readonly rowId: string;
  readonly agency: ItemType | null;
  readonly accountId: number;
  readonly accountAddress: ItemType;
  readonly addressLine1: string;
  readonly addressLine2: string;
  readonly city: string;
  readonly country: string;
  readonly createdAt: Date | string;
  readonly createdBy: ItemType;
  readonly zone: ItemType;
  readonly state: string;
  readonly jurisdiction?: ItemType;
  readonly updatedAt: Date | string | null;
  readonly updatedBy: ItemType | null;
  readonly zipCode: string;
  readonly reference: string;
  readonly cefgTier: ItemType;
  readonly collectionSystem: ItemType;
  readonly deMinimisWaiver: boolean;
  readonly departmentWaiver: boolean;
  readonly foodAndSoiledPaperGenerates: boolean;
  readonly foodAndSoiledPaperRecycling: boolean;
  readonly greenMaterialGenerates: boolean;
  readonly greenMaterialRecycling: boolean;
  readonly landscapeAndPrunningGenerates: boolean;
  readonly landscapeAndPrunningRecycling: boolean;
  readonly lumberGenerates: boolean;
  readonly lumberRecycling: boolean;
  readonly manureGenerates: boolean;
  readonly manureRecycling: boolean;
  readonly mcrNotRecycling: boolean;
  readonly mcrRequired: boolean;
  readonly moreNotRecycling: boolean;
  readonly moreRequired: boolean;
  readonly nonOrganicGenerates: boolean;
  readonly nonOrganicRecycling: boolean;
  readonly paperProductsGenerates: boolean;
  readonly paperProductsRecycling: boolean;
  readonly printingAndWritingPaperGenerates: boolean;
  readonly printingAndWritingPaperRecycling: boolean;
  readonly recycling: boolean;
  readonly spaceWaiver: boolean;
  readonly subjectTo: boolean;
  readonly textilesGenerates: boolean;
  readonly textilesRecycling: boolean;
  readonly waiver: boolean;
  readonly woodGenerates: boolean;
  readonly woodRecycling: boolean;
  readonly accountMinervaId: string;
  readonly accountNumber: string;
  readonly accountType: ItemType;
  readonly hauler: ItemType;
  readonly accountName: string;
  readonly nickname: string;
  readonly isCefg: boolean;
  readonly isActive: boolean;
  readonly statusDate: Date | string | null;
  readonly types: ItemType | null;
  
  constructor(props: GeneratorItem) {
    this.id = props.id;
    this.rowId = props.rowId;
    this.agency = props.agency || null;
    this.accountId = props.accountId;
    this.accountAddress = props.accountAddress;
    this.addressLine1 = props.addressLine1 || '';
    this.addressLine2 = props.addressLine2 || '';
    this.city = props.city || '';
    this.country = props.country || '';
    this.createdAt = props.createdAt || '';
    this.createdBy = props.createdBy || null;
    this.zone = props.zone || null;
    this.state = props.state || '';
    this.updatedAt = props.updatedAt || null;
    this.updatedBy = props.updatedBy || null;
    this.jurisdiction = props.jurisdiction
      ? props.jurisdiction
      : { id: 0, name: '' };
    this.zipCode = props.zipCode || '';
    this.reference = props.reference || '';
    this.cefgTier = props.cefgTier ? props.cefgTier : { id: 0, name: '' };
    this.collectionSystem = props.collectionSystem
      ? props.collectionSystem
      : { id: 0, name: '' };
    this.deMinimisWaiver = props.deMinimisWaiver;
    this.departmentWaiver = props.departmentWaiver;
    this.foodAndSoiledPaperGenerates = props.foodAndSoiledPaperGenerates;
    this.foodAndSoiledPaperRecycling = props.foodAndSoiledPaperRecycling;
    this.greenMaterialGenerates = props.greenMaterialGenerates;
    this.greenMaterialRecycling = props.greenMaterialRecycling;
    this.landscapeAndPrunningGenerates = props.landscapeAndPrunningGenerates;
    this.landscapeAndPrunningRecycling = props.landscapeAndPrunningRecycling;
    this.lumberGenerates = props.lumberGenerates;
    this.lumberRecycling = props.lumberRecycling;
    this.manureGenerates = props.manureGenerates;
    this.manureRecycling = props.manureRecycling;
    this.mcrNotRecycling = props.mcrNotRecycling;
    this.mcrRequired = props.mcrRequired;
    this.moreNotRecycling = props.moreNotRecycling;
    this.moreRequired = props.moreRequired;
    this.nonOrganicGenerates = props.nonOrganicGenerates;
    this.nonOrganicRecycling = props.nonOrganicRecycling;
    this.paperProductsGenerates = props.paperProductsGenerates;
    this.paperProductsRecycling = props.paperProductsRecycling;
    this.printingAndWritingPaperGenerates =
      props.printingAndWritingPaperGenerates;
    this.printingAndWritingPaperRecycling =
      props.printingAndWritingPaperRecycling;
    this.recycling = props.recycling;
    this.spaceWaiver = props.spaceWaiver;
    this.subjectTo = props.subjectTo;
    this.textilesGenerates = props.textilesGenerates;
    this.textilesRecycling = props.textilesRecycling;
    this.waiver = props.waiver;
    this.woodGenerates = props.woodGenerates;
    this.woodRecycling = props.woodRecycling;
    this.accountMinervaId = props.accountMinervaId;
    this.accountNumber = props.accountNumber;
    this.accountType = props.accountType;
    this.hauler = props.hauler;
    this.accountName = props.accountName;
    this.nickname = props.nickname;
    this.isCefg = props.isCefg;
    this.isActive = props.isActive;
    this.statusDate = props.statusDate;
    this.types = props.type;
  }
}
