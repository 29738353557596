import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GeneratorsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2218 7.54545C14.2218 12.6364 7.99957 17 7.99957 17C7.99957 17 1.77734 12.6364 1.77734 7.54545C1.77734 3.9305 4.56313 1 7.99957 1C11.436 1 14.2218 3.9305 14.2218 7.54545Z"
      stroke="#00060F"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99957 9.72692C9.22687 9.72692 10.2218 8.75008 10.2218 7.5451C10.2218 6.34011 9.22687 5.36328 7.99957 5.36328C6.77227 5.36328 5.77734 6.34011 5.77734 7.5451C5.77734 8.75008 6.77227 9.72692 7.99957 9.72692Z"
      stroke="#00060F"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default GeneratorsIcon;
