import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { UploadFile } from 'src/models/file/upload-file';
import { FileStatus } from 'src/types';
import { FileSignType, FileUploadType, UploadData } from 'src/api/api-types/files';
import { isPending } from 'src/utils/common';

export class ImportUploadStore {
  @observable files: IObservableArray<UploadFile> = observable([]);
  @observable isOpen: boolean = false;
  @observable fileUploadPlan: FileSignType = 'UploadImport';

  constructor(fileUploadPlan?: FileSignType) {
    makeObservable(this);

    this.fileUploadPlan = fileUploadPlan || 'UploadImport';
  }

  @computed get isValid() {
    return this.files.every(file => file.canBeSent);
  }

  @computed get isSelectedFiles() {
    return this.files.length > 0;
  }

  @computed get isUploading() {
    return this.files.some(({ status }) => status === FileStatus.PENDING);
  }

  @computed get allUploadsDone() {
    return (
      this.files.length > 0 &&
      this.files.every(({ status }) => status === FileStatus.DONE)
    );
  }

  @computed get currentlyUploadingReq() {
    return this.files.find(file => isPending(file.uploadReq))?.uploadReq;
  }

  @computed get toolbarTitle() {
    if (this.isUploading) {
      return 'Processing...';
    }

    if (this.allUploadsDone) {
      return 'Files were successfully uploaded';
    }

    return 'Upload Files';
  }



  @action appendFiles = (files: FileList, type: FileUploadType) => {
    let category: number | undefined = 0;
    switch (type) {
      case FileUploadType.file:
        category = 0;
        break;
    }

    this.files.replace(
      [...this.files.slice(),
        ...[...files].map(
          item =>
            new UploadFile({
              file: item,
              fileUploadPlan: this.fileUploadPlan,
              category,
            }),
        ),
      ],
    );

  };

  @action upload = async ({ params, linkAction, customToken }: UploadData) => {
    if (this.files.length <= 0 || !this.isValid) return;
      if(!!customToken)
        await this.files[0].uploadWithCustomToken(params, customToken);
      else 
        await this.files[0].upload(params);
      await linkAction(this.files[0].linkFileDTO)
  };

  @action clear = () => this.files.clear();

  @action removeFile = (file: UploadFile) => this.files.remove(file);
}
