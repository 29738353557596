import {
  LookupInfo,
  MatrixItemType,
  MatrixResponse,
  OptionsResponse,
} from '../../api/api-types/matrix';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { MatrixApi } from '../../api/matrix';
import { ApiReq, emptyValue } from '../../api';
import { ItemType } from '../../types';
import { AxiosResponse } from 'axios';

export class MatrixItem {
  readonly api = new MatrixApi();
  readonly complianceStatusItemType: ItemType;
  readonly systemCompliance: ItemType;
  readonly overallCompliance: ItemType;
  readonly userCompliance: ItemType;
  readonly locationId: number;
  readonly overallComplianceText: string;
  public getMatrix;

  @observable userValue: any;
  @observable selectedUserOptions: LookupInfo | null = null;
  @observable options: IObservableArray<ItemType> = observable([]);

  @observable.ref optionsReq: ApiReq<OptionsResponse> = emptyValue;
  @observable.ref updateMatrixReq: ApiReq<MatrixResponse> = emptyValue;

  constructor(
    item: MatrixItemType & {
      locationId: number;
      getMatrix?: (id: number) => Promise<AxiosResponse<MatrixResponse>>;
    },
  ) {
    makeObservable(this);

    this.complianceStatusItemType = item.complianceStatusItemType;
    this.userValue = item.userValue;
    this.systemCompliance = item.systemCompliance;
    this.userCompliance = item.userCompliance;
    this.overallCompliance = item.overallCompliance;
    this.locationId = item.locationId;

    this.getMatrix = item.getMatrix;
    this.overallComplianceText = item.overallComplianceText;
  }

  @computed get serialized() {
    if (!this.complianceStatusItemType?.id) {
      return undefined;
    }
    return {
      complianceStatusItemType: {
        id: this.complianceStatusItemType.id,
      },
      userValue:
        this.userValue === null
          ? null
          : {
              id: this.userValue.id,
            },
      location: {
        id: this.locationId,
      },
    };
  }
  @action reFetchMatrix = async () => {
    if (!this.getMatrix) return;
    await this.getMatrix(this.locationId);
  };

  @action getOptions = async () => {
    this.optionsReq = this.api.getUserOptions(
      this.complianceStatusItemType?.id,
    );
    await this.optionsReq;
    runInAction(() => {
      if (
        this.optionsReq.state === 'fulfilled' &&
        this.optionsReq?.value.data &&
        this.optionsReq?.value.data?.length > 0
      ) {
        this.options.replace(this.optionsReq?.value.data);
      }
    });
    return this.optionsReq;
  };

  @action selectUserValue = (value: ItemType | null) => {
    this.userValue = value;
  };

  @action updateMatrix = async () => {
    if (!this.serialized) return;
    this.updateMatrixReq = this.api.updateMatrixOptions(this.serialized);

    await this.updateMatrixReq;
  };
}
