import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import {
  OrganicMaterialType,
  OrganicWasteMaterialsResponse,
} from 'src/api/api-types/generators';
import { GeneratorsApi } from 'src/api/generator';

export class OrganicWasteMaterials {
  readonly api = new GeneratorsApi();
  @observable.ref organicMaterialsReq: ApiReq<OrganicWasteMaterialsResponse> =
    emptyValue;
  @observable.ref updateMaterialReq: ApiReq<OrganicMaterialType> = emptyValue;
  @observable.ref organicMaterials: IObservableArray<OrganicMaterialType> =
    observable([]);

  @observable id = 0;

  constructor() {
    makeObservable(this);
  }

  @computed get organicMaterialsData() {
    if (
      this.organicMaterialsReq.state !== 'fulfilled' ||
      !this.organicMaterialsReq.value
    ) {
      return [];
    }
    return this.organicMaterialsReq.value.data;
  }

  @action getOrganicMaterials = async (id: number) => {
    this.organicMaterialsReq = this.api.getOrganicWasteMaterials(id);
    this.id = id;
    await this.organicMaterialsReq;
    runInAction(() => {
      this.organicMaterialsData &&
        this.organicMaterials.replace(this.organicMaterialsData);
    });
    return this.organicMaterialsReq;
  };

  @action updateOrganicMaterial = async (id: string) => {
    let chosenMaterial = this.organicMaterialsData?.find(
      material => material.matchingId === id,
    );

    if (!chosenMaterial) return;
    chosenMaterial = { ...chosenMaterial, checked: !chosenMaterial.checked };

    this.updateMaterialReq = this.api.updateOrganicWasteMaterial(
      this.id,
      chosenMaterial,
    );
    await this.updateMaterialReq;
    await this.getOrganicMaterials(this.id);
  };
}
