import { Grid, styled, Typography } from '@mui/material';
import { WeeksHeaderTemplate } from 'src/utils/constants';

type HeaderSize = 'small' | 'medium';

interface ScheduleHeaderProps {
  withOutHeader?: boolean;
  headerSize?: HeaderSize;
}

const ScheduleItem = styled('div')<{ size: HeaderSize }>(({ theme, size }) => ({
  color: theme.palette.text.secondary,
  fontSize: size.includes('small') ? 12 : 16,
  fontWeight: 700,
}));

const ItemsContainer = styled(Grid)<{ size: HeaderSize }>(({ size }) => ({
  gridTemplateColumns: `repeat(7, ${size.includes('small') ? 25 : 35}px)`,
  gridTemplateRows: '25px',
  alignItems: 'center',
  justifyItems: 'center',
}));

export const ScheduleHeader = ({
  withOutHeader,
  headerSize = 'small'
}: ScheduleHeaderProps) => {
  return (
    <Grid container flexDirection="column" wrap="nowrap">
      {!withOutHeader && (
        <Typography textAlign="center" variant="link" color="text.secondary">
          Weekly Schedule
        </Typography>
      )}
      <ItemsContainer size={headerSize} display="grid">
        {WeeksHeaderTemplate.map(({index, name}) => (
          <ScheduleItem size={headerSize} key={index}>
            {name}
          </ScheduleItem>
        ))}
      </ItemsContainer>
    </Grid>
  );
};
