import { useRef } from 'react';

import { observer } from 'mobx-react-lite';
import { FileUploadType } from 'src/api/api-types/files';

import { createFileDialogInstance } from '../../../File/FileUploadDialog';
import { useAssetsTableStore } from '../../AssetsTableProvider';

export const fileStore = createFileDialogInstance('AssetAttachment');
const { fileDialogStore, AttachmentsDialog } = fileStore;

export const CreationFlow = observer(() => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const { chosenAsset, fetchAssets, cancelCreation } = useAssetsTableStore();

  if (chosenAsset === undefined) return null;

  const handleClose = () => {
    cancelCreation();
    fileDialogStore.clear();
  };

  const handleClearAll = () => {
    handleClose();
  };

  const handleDone = async () => {
    fileDialogStore.clear();
    await fetchAssets();
  };
  return (
    <AttachmentsDialog
      uploadInputRef={uploadInputRef}
      onDone={handleDone}
      onClear={handleClearAll}
      onClose={handleClose}
      type={FileUploadType.file}
      linkAction={chosenAsset.linkFile}
      asset={chosenAsset}
    />
  );
});

export default CreationFlow;
