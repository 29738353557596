import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { ClientsResponse, ClientsItem, ClientsFilter } from 'src/api/api-types/clients';
import { ClientsApi } from 'src/api/clients';

import { storeFactory } from '../../utils/store';
import {TablePagination} from "../../models/pagination/pagination";
import { AsyncExportEntity } from 'src/models/export/AsyncExportEntity';
import { GridRowId } from '@mui/x-data-grid-pro';
import { gridColumns } from './helpers/gridColumns';
import { GridColumnsManager } from 'src/models/grid-columns-manager/grid-columns-manager';
import { ExportDataType } from 'src/api/exportTypes';

class HaulerStore {
  api = new ClientsApi({ prefix: '' });
  @observable selectedHaulers: Array<GridRowId> = [];
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable haulersReq: ApiReq<ClientsResponse> = emptyValue
  @observable.ref haulers: IObservableArray<ClientsItem> = observable([]);
  @observable pagination = new TablePagination<ClientsResponse>({
    requestRef: this.haulersReq,
  });
  @observable columnsManager;

  constructor() {
    makeObservable(this);
    this.pagination.setFetchingCallBack(this.fetchHaulers);
    this.columnsManager = new GridColumnsManager<
      ClientsItem
    >({
      columns: gridColumns,
      columnsMap: {},
      visibilityModel: {}
    });
  }

  @computed get haulersData() {
    if (this.haulersReq.state !== 'fulfilled' || !this.haulersReq.value) {
      return [];
    }
    return this.haulersReq.value.data?.items || [];
  }

  @action fetchHaulers = async () => {
    this.haulersReq =  this.api.getHaulers(this.pagination.serialize)
    await this.haulersReq

    runInAction(() => {
      if (
          this.haulersReq.state !== 'fulfilled' ||
          this.haulersReq.value?.data === null
      )
        return;
      this.haulers.replace(this.haulersData);
      this.pagination.setPagination(this.haulersReq.value?.data);
    });

    return this.haulersReq;
  }

  @action setSelectedHaulers = (items: GridRowId[]) => {
    this.selectedHaulers = items;
  };

  @action removeSelectedHaulers = () => {
    if (this.selectedHaulers.length === 0) return;
    this.selectedHaulers = [];
  };

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncHaulersReportByType(
        this.exportEntity.exportParams<
          ClientsResponse,
          ClientsFilter,
          ClientsItem
        >(
          this.columnsManager.hiddenColumns,
          null,
          this.pagination,
          this.selectedHaulers  
        ),
        data.type,
      )
    );
  };

  @action resetStore = () => {
   this.pagination.resetPagination();
  }
}

export const { store: haulersStore, storeCtx: haulersStoreCtx } = storeFactory(
  HaulerStore,
  'haulers',
);
