import { lazy } from 'react';

import { AccountParams } from 'src/types';
import { MinervaRoute } from 'src/utils/router';

import { lookupsStore } from '../../stores/lookups';

import { complianceMatrixStore } from './components/ComplianceMatrix';
import { accountEFGStore } from './components/EFG/store';
import { accountEFRStore } from './components/EFR/store';
import { serviceHistoryStore } from './components/ServiceHistory';
import { accountDetailsStore } from './store';
import { addressBookStore } from '../AddressBook/store';
import { ACCOUNT_TYPE_OBJECT } from 'src/utils/constants';

export const AccountDetailsRoute: MinervaRoute<
  'account',
  Partial<AccountParams>
> = {
  name: 'account',
  path: '/account/:id',
  comp: () => lazy(() => import('./index')),
  activate: async (_, params) => {
    if (!params?.id) return;
    serviceHistoryStore.filter.resetFilters();
    serviceHistoryStore.filter.setPersistedFilters({
      'q.generator.id': params?.id,
    });
    accountEFGStore.efgModel.filter.setPersistedFilters({
      'q.account.id': String(params?.id),
    });
    addressBookStore.filter.setPersistedFilters({
      'q.relatedTo.id': params?.id,
      'q.relatedToType.id': ACCOUNT_TYPE_OBJECT.id
    });
    await accountDetailsStore.pageInitCall(params?.id);
    if (accountDetailsStore.account?.location?.id) {
      complianceMatrixStore.fetchMatrix(
        accountDetailsStore.account?.location?.id,
      );
      await complianceMatrixStore.fetchOrganicMaterials(
        accountDetailsStore.account?.location?.id,
      );
    }
    await Promise.all([
      accountEFGStore.fetchEFGTypesMeta(),
      lookupsStore.fetchAccountsIsNotEFG(String(params?.id)),
      lookupsStore.fetchEdibleFoodGeneratorsTypes(),
      lookupsStore.fetchFoodType(),
    ])
  },
  deactivate: () => {
    accountEFGStore.reset();
    accountEFGStore.efgModel.removeSelectedFoods();
    accountEFRStore.resetIsLinking();
    accountEFRStore.removeSelectedFoods();
    accountDetailsStore.reset();
    lookupsStore.resetJurisdictions();
  },
};
