import { FC } from 'react';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Menu from '@mui/material/Menu';
import { observer } from 'mobx-react-lite';

export interface MenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  title: string;
}

const WrappedMenu: FC<MenuProps> = ({
  children,
  anchorEl,
  open,
  title,
  handleClose,
}) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      sx={{ width: '100%', p: 0 }}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}>
      <List
        sx={{
          width: '100%',
          p: 0,
          overflow: 'auto',
          maxHeight: 300,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '16px',
              color: 'minervaColors.secondaryGrey',
              pt: 0,
              pb: 1,
            }}>
            {title}
          </ListSubheader>
        }>
        {children}
      </List>
    </Menu>
  );
};

export default observer(WrappedMenu);
