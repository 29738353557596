import * as yup from 'yup';

import { ExportTypes } from '../../../types';

export type ExportOptionsType = {
  id: number;
  name: string;
  path: string;
};
export const exportOptions: ExportOptionsType[] = [
  {
    id: 0,
    name: 'SB 1383 SLCP Commercial Businesses Subject to Compliance Review',
    path: 'SlcpSubjectLineChart',
  },
  { id: 1, name: 'SB 1383 SLCP Collection Systems', path: 'SlcpPieChart' },
  {
    id: 2,
    name: 'SB 1383 SLCP Electronic Annual Report',
    path: 'Cards',
  },
  {
    id: 3,
    name: 'SB 1383 SLCP Recycling Compliance',
    path: 'SlcpComplianceLineChart',
  },
  {
    id: 4,
    name: 'SB 1383 SLCP Generators NOT Recycling by Material Type',
    path: 'SlcpNonPieChart',
  },
  { id: 5, name: 'AB 1826 MORe', path: 'MOReCards' },
  { id: 6, name: 'AB 341 MCR', path: 'McrCards' },
];

export const dashboardExportDefaultValue = {
  dashboard: [],
  mode: ExportTypes.WITH_FILTERS,
};

export const earLocationExportDefaultValue = {
  fileName: '',
  mode: ExportTypes.WITH_FILTERS,
};

export const validationSchema = yup.object({
  dashboard: yup.array().min(1, 'Please choose export types'),
});

export const generateFilterTitle = (
  chartTitle: string,
  legendTitle: string,
  pointAmmount?: number,
  date?: string,
) =>
  date
    ? `${pointAmmount} ${chartTitle} - ${legendTitle} - ${date}`
    : `${pointAmmount} ${chartTitle} - ${legendTitle}`;

export const activeStates = [
  { id: 1, name: 'Active', state: true },
  { id: 2, name: 'Inactive', state: false },
];
