import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  DataGridProProps,
  GridCellModes,
  GridCellModesModel,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid-pro';
import { GridEventListener } from '@mui/x-data-grid/models/events';

import CustomFooter from './components/CustomFooter';
import CustomColumnMenu from './components/CustomMenuItem';
import { CustomNoRowsOverlay } from './components/NoRows';
import TableResolution from './components/TableResolution';
import TableStatus from './components/TableStatus';
import { Table } from './style';
import { OnFinishEditType, OnStartEditType, OnUpdateAsyncType } from './types';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { renderToStaticMarkup } from 'react-dom/server';
import { debounce } from 'lodash';

const FilterAltIconHtml = renderToStaticMarkup(<FilterAltIcon style={{ fontSize: '1.25rem' }} />);
const FilterAltOutlinedIconHtml = renderToStaticMarkup(<FilterAltOutlinedIcon style={{ fontSize: '1.25rem' }} />);

interface TableProps extends DataGridProProps {
  inlineCreate?: boolean;
  onCreateAction?: () => void;
  openSearchAction?: () => void;
  openExportAction?: () => void;
  onStartEditAction?: (props: OnStartEditType<any>) => Promise<any> | void;
  isRowEditable?: (props: OnStartEditType<any>) => boolean;
  onUpdateAsyncAction?: (props: OnUpdateAsyncType) => Promise<void>;
  onDeleteAsyncAction?: () => void;
  onEditeAsyncAction?: () => void;
  onFinishEditAction?: (props: OnFinishEditType) => void;
  rowClick?: GridEventListener<'rowClick'>;
  createLoading?: boolean;
  totalPages?: number;
  sx?: SxProps<Theme>;
  controlledEdit?: 'row' | 'cell';
  filteredColumns?: string[]
}

export default (props: TableProps) => {
  const {
    onFinishEditAction,
    onCreateAction,
    onStartEditAction,
    onUpdateAsyncAction,
    onDeleteAsyncAction,
    onEditeAsyncAction,
    inlineCreate,
    controlledEdit,
    rowSelectionModel,
    slots,
    openSearchAction,
    openExportAction,
    rowCount,
    totalPages,
    createLoading,
    loading,
    slotProps,
    paginationModel,
    onPaginationModelChange,
    isRowEditable,
    filteredColumns,
    columns,
    columnVisibilityModel
  } = props;

  const apiRef = useRef<GridApiPro>() as MutableRefObject<GridApiPro>; 

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const rowModeState = useMemo(() => {
    return Object.values(rowModesModel).some(({ mode }) =>
      mode.includes(GridRowModes.Edit),
    );
  }, [rowModesModel]);

  const cellModeState = useMemo(() => {
    return Object.values(cellModesModel).some(props =>
      Object.values(props).some(({ mode }) =>
        mode?.includes(GridCellModes.Edit),
      ),
    );
  }, [cellModesModel]);

  const editedCellsModel = useCallback(
    (id: number | string, ignoreModifications?: boolean) =>
      Object.keys(cellModesModel[id] || {}).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: { mode: GridCellModes.View, ignoreModifications },
        };
      }, {}),
    [cellModesModel],
  );

  const startCellHandler = useCallback(
    async (id: number | string, field?: string, editable?: boolean) => {
      const rowWithCellsEditState = Object.values(
        cellModesModel[id] || {},
      ).some(({ mode }) => mode?.includes(GridCellModes.Edit));

      if (cellModeState && !rowWithCellsEditState) return;

      if (onStartEditAction) await onStartEditAction({ id, field, editable });

      setCellModesModel({
        [id]: {
          ...cellModesModel[id],
          ...editedCellsModel(id),
          [field!]: { mode: GridCellModes.Edit },
        },
      });
    },
    [cellModeState, cellModesModel, editedCellsModel, onStartEditAction],
  );

  const startRowHandler = useCallback(
    async (
      id: number | string,
      field?: string,
      rowData?: unknown,
      editable?: boolean,
    ) => {
      if (rowModeState) return;
      if (onStartEditAction)
        await onStartEditAction({ id, field, rowData, editable });
      setRowModesModel({ [id]: { mode: GridRowModes.Edit } });
    },
    [onStartEditAction, rowModeState],
  );

  const startEditHandler = useCallback(
    <T extends object>({
      id,
      field,
      rowData,
      editable,
    }: OnStartEditType<T>) => {
      const isEditable = isRowEditable
        ? isRowEditable({ id, field, rowData, editable })
        : true;
      if (!isEditable) return;
      if (controlledEdit?.includes('cell') && editable)
        startCellHandler(id, field, editable);
      if (controlledEdit?.includes('row'))
        startRowHandler(id, field, rowData, editable);
    },
    [controlledEdit, startCellHandler, isRowEditable, startRowHandler],
  );

  const stopEditHandler = useCallback(
    ({
      ignoreModifications = false,
      isDelete = false,
    }: {
      ignoreModifications?: boolean;
      isDelete?: boolean;
    }) => {
      const id =
        Object.keys(rowModesModel)[0] || Object.keys(cellModesModel)[0];

      onFinishEditAction &&
        !isDelete &&
        onFinishEditAction({ id: Number(id), canceled: ignoreModifications });

      if (controlledEdit?.includes('row'))
        return setRowModesModel({
          [Number(id)]: { mode: GridRowModes.View, ignoreModifications },
        });

      setCellModesModel({
        [id]: editedCellsModel(id, ignoreModifications),
      });
    },
    [
      cellModesModel,
      controlledEdit,
      editedCellsModel,
      onFinishEditAction,
      rowModesModel,
    ],
  );

  const createAction = useCallback(() => {
    if (onCreateAction) onCreateAction();
    if (inlineCreate) startEditHandler({ id: 0 });
  }, [inlineCreate, onCreateAction, startEditHandler]);

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      try {
        const editState = controlledEdit?.includes('row')
          ? rowModeState
          : cellModeState;
        if (onUpdateAsyncAction && !editState) {
          await onUpdateAsyncAction({ newRow, oldRow });
        }
        return newRow;
      } catch (e) {
        startRowHandler(newRow.id);
      }
    },
    [
      cellModeState,
      controlledEdit,
      onUpdateAsyncAction,
      rowModeState,
      startRowHandler,
    ],
  );

  const saveAction = () => stopEditHandler({ ignoreModifications: false });
  const cancelAction = () => stopEditHandler({ ignoreModifications: true });

  const deleteAction = useCallback(() => {
    if (onDeleteAsyncAction) {
      onDeleteAsyncAction();
    }
    stopEditHandler({ ignoreModifications: true, isDelete: false });
  }, [onDeleteAsyncAction, stopEditHandler]);

  const editeAction = useCallback(() => {
    if (onEditeAsyncAction) {
      onEditeAsyncAction();
    }
    stopEditHandler({ ignoreModifications: true, isDelete: false });
  }, [onEditeAsyncAction, stopEditHandler]);

  useEffect(() => {
    if (rowModeState || cellModeState) cancelAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleEditModelChanging = (
    type: 'row' | 'cell',
    model: GridRowModesModel | GridCellModesModel,
  ) => {
    if (rowModeState || cellModeState) return;
    if (type.includes('row'))
      return setRowModesModel(model as GridRowModesModel);
    setCellModesModel(cellModesModel as GridCellModesModel);
  };

  const refreshFilterIcons = useCallback(() => {
    setTimeout(() => {
      columns.forEach(({ field }) => {
        const headerElement = apiRef!.current.getColumnHeaderElement(field);
        if (!headerElement) {
          return;
        }

        const active = filteredColumns?.includes(field) ?? false;

        const headerElementClasses = headerElement.classList;
        const svgElement = headerElement.getElementsByClassName('MuiDataGrid-menuIcon')?.[0]?.children[0];
        if (!svgElement) {
          return;
        }

        if (active) {
          svgElement.innerHTML = FilterAltIconHtml;
          headerElementClasses?.add('active-filter');
        } else {
          svgElement.innerHTML = FilterAltOutlinedIconHtml;
          headerElementClasses?.remove('active-filter');
        }
      });
    }, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, filteredColumns, columnVisibilityModel]); 

  useLayoutEffect(() => {
    const unsubscribe = apiRef!.current.subscribeEvent('scrollPositionChange', debounce(refreshFilterIcons, 250));
    refreshFilterIcons();
    return unsubscribe;
  }, [refreshFilterIcons]);

  useLayoutEffect(() => {
    if (!props.apiRef) {
      return;
    }
    props.apiRef.current = apiRef.current;
  }, [props.apiRef]);

  return (
    <Table
      pagination
      paginationMode="server"
      density="compact"
      rowModesModel={rowModesModel}
      cellModesModel={cellModesModel}
      processRowUpdate={processRowUpdate}
      onRowModesModelChange={rowModesModel =>
        handleEditModelChanging('row', rowModesModel)
      }
      onCellModesModelChange={cellModesModel =>
        handleEditModelChanging('cell', cellModesModel)
      }
      onCellDoubleClick={({ id, field, isEditable, row }) => {
        startEditHandler({ id, field, rowData: row, editable: isEditable });
      }}
      // eslint-disable-next-line no-console
      onProcessRowUpdateError={error => console.error('Edit error', error)}
      isRowSelectable={() => !(rowModeState || cellModeState)}
      {...{
        ...props,
        rowCount: rowCount || 0,
        slots: {
          noRowsOverlay: CustomNoRowsOverlay,
          footer: CustomFooter,
          columnMenu: CustomColumnMenu,
          columnMenuIcon: FilterAltOutlinedIcon,
          ...slots,
        },
        slotProps: {
          toolbar: {
            createAction: createAction,
            rowMode: rowModeState || cellModeState,
            saveAction,
            cancelAction,
            deleteAction,
            editeAction,
            openSearchAction,
            openExportAction,
            selectedItems: rowSelectionModel,
            createLoading,
            paginationProps: {
              onPaginationModelChange,
              paginationModel,
              rowCount,
              totalPages,
            },
          },
          footer: {
            onPaginationModelChange,
            paginationModel,
            rowCount,
            totalPages,
          },
          ...slotProps,
        },
      }}
      apiRef={apiRef}
    />
  );
};

export { TableStatus, TableResolution };
