import { useState, MouseEvent, SyntheticEvent, useContext } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';

import { User } from '../models/user';
import { usersStoreCtx } from '../store';

interface Props {
  user: User;
}

export default observer(({ user }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const apiRef = useGridApiContext();
  const open = Boolean(anchorEl);
  const { userMenuActionHandler, fetchUsersList, onStartEditUser } =
    useContext(usersStoreCtx);

  const userState = user?.active ? 'Deactivate' : 'Activate';

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = async (e: SyntheticEvent) => {
    handleClose();
    if (e.currentTarget?.id.includes('edit')) {
      apiRef.current.startRowEditMode({ id: user.id });
      await onStartEditUser({ id: user.id });
    }
    if (
      e.currentTarget?.id.includes('state') ||
      e.currentTarget?.id.includes('reset')
    ) {
      await userMenuActionHandler(user.id, e.currentTarget?.id);
      await fetchUsersList();
    }
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem id="edit" onClick={handleAction}>
          Edit
        </MenuItem>
        <MenuItem id="state" onClick={handleAction}>
          {userState}
        </MenuItem>
        <MenuItem id="reset" onClick={handleAction}>
          Reset Password
        </MenuItem>
      </Menu>
    </>
  );
});
