import { ApiClient } from './';
import { UploadFileResponse } from 'src/api/api-types/files';

export class FilesApi extends ApiClient {
  getFilesUrl(key: number | string) {
    return this.requestFiles<Blob>(`files/${key}`);
  }

  getFilesUrlByToken(token?: string) {
    return this.requestFiles<Blob>(`files`, { token });
  }

  deleteFile(key: number | string) {
    return this.requestDelete<UploadFileResponse>(`files/${key}`);
  }
  deleteFileFromService(key: number | string) {
    return this.requestFilesDelete<UploadFileResponse>(`files/${key}`);
  }

  uploadFile<P>(
    url: string,
    file: File,
    params?: P,
    progressCallback?: (progress: number) => void,
  ) {
    return this.requestUploadFile<UploadFileResponse, P>(
      url,
      file,
      params,
      progressCallback,
    );
  }
}
