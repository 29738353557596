import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CSVIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2.85667 0C2.33286 0 1.9043 0.45 1.9043 1V15C1.9043 15.55 2.33286 16 2.85667 16H12.3804C12.9042 16 13.3327 15.55 13.3327 15V4L9.52324 0H2.85667Z"
      fill="#E2E5E7"
    />
    <path
      d="M10.4758 4H13.3329L9.52344 0V3C9.52344 3.55 9.952 4 10.4758 4Z"
      fill="#B0B7BD"
    />
    <path d="M13.3337 7L10.4766 4H13.3337V7Z" fill="#CAD1D8" />
    <path
      d="M11.4284 13C11.4284 13.275 11.2141 13.5 10.9522 13.5H0.476184C0.214283 13.5 0 13.275 0 13V8C0 7.725 0.214283 7.5 0.476184 7.5H10.9522C11.2141 7.5 11.4284 7.725 11.4284 8V13Z"
      fill="#0077B6"
    />
    <path
      d="M1 10.5021C1 10.2128 1.06538 9.95549 1.19613 9.73018C1.32688 9.50209 1.50849 9.32545 1.74094 9.20028C1.97629 9.07232 2.24216 9.00834 2.53853 9.00834C2.90174 9.00834 3.21264 9.10014 3.47124 9.28373C3.57604 9.35812 3.66675 9.44348 3.7434 9.5398C3.91784 9.75904 3.7133 10.0348 3.43313 10.0348C3.27221 10.0348 3.13376 9.93277 3.01201 9.82755C2.98049 9.8003 2.94615 9.77619 2.909 9.75522C2.79859 9.69124 2.67219 9.65925 2.52982 9.65925C2.30027 9.65925 2.11431 9.73574 1.97193 9.88873C1.82956 10.0417 1.75837 10.2462 1.75837 10.5021C1.75837 10.758 1.82956 10.9624 1.97193 11.1154C2.11431 11.2684 2.30027 11.3449 2.52982 11.3449C2.67219 11.3449 2.79859 11.3129 2.909 11.249C2.94615 11.228 2.98049 11.2039 3.01201 11.1766C3.13376 11.0714 3.27221 10.9694 3.43313 10.9694C3.7133 10.9694 3.91784 11.2451 3.7434 11.4644C3.66675 11.5607 3.57604 11.646 3.47124 11.7204C3.21264 11.9013 2.90174 11.9917 2.53853 11.9917C2.24216 11.9917 1.97629 11.9291 1.74094 11.8039C1.50849 11.6759 1.32688 11.4993 1.19613 11.274C1.06538 11.0487 1 10.7914 1 10.5021Z"
      fill="white"
    />
    <path
      d="M5.55336 12C5.32962 12 5.12913 11.9652 4.95189 11.8957C4.77465 11.8261 4.63227 11.7232 4.52476 11.5869C4.50341 11.5591 4.48412 11.5301 4.46689 11.5C4.35174 11.2987 4.55098 11.0946 4.78293 11.0946H4.86897C5.03015 11.0946 5.1487 11.2388 5.27006 11.3449C5.33689 11.4006 5.42406 11.4284 5.53156 11.4284C5.64198 11.4284 5.72915 11.4047 5.79307 11.3574C5.857 11.3074 5.88896 11.2392 5.88896 11.153C5.88896 11.0807 5.86281 11.0209 5.81051 10.9736C5.76111 10.9263 5.69864 10.8873 5.62309 10.8567C5.55045 10.8261 5.44585 10.7914 5.30928 10.7524C5.1117 10.694 4.95044 10.6356 4.8255 10.5772C4.70055 10.5188 4.59304 10.4325 4.50297 10.3185C4.4129 10.2045 4.36786 10.0556 4.36786 9.87204C4.36786 9.59944 4.47101 9.38665 4.67731 9.23366C4.88361 9.07789 5.15238 9 5.48362 9C5.82067 9 6.09235 9.07789 6.29865 9.23366C6.38912 9.30075 6.46116 9.37961 6.51475 9.47025C6.63264 9.66963 6.43538 9.87622 6.20375 9.87622H6.07804C5.93505 9.87622 5.82677 9.74377 5.71462 9.65508C5.64779 9.59944 5.56207 9.57163 5.45747 9.57163C5.3674 9.57163 5.29475 9.59527 5.23955 9.64256C5.18434 9.68707 5.15674 9.75243 5.15674 9.83866C5.15674 9.93324 5.20323 10.007 5.29621 10.0598C5.38919 10.1127 5.53447 10.1697 5.73205 10.2309C5.92964 10.2949 6.08945 10.3561 6.21148 10.4145C6.33643 10.4729 6.44393 10.5577 6.53401 10.669C6.62408 10.7803 6.66912 10.9235 6.66912 11.0987C6.66912 11.2656 6.62408 11.4172 6.53401 11.5535C6.44684 11.6898 6.31899 11.7983 6.15047 11.879C5.98194 11.9597 5.7829 12 5.55336 12Z"
      fill="white"
    />
    <path
      d="M9.47272 9.04172C9.72831 9.04172 9.90561 9.29649 9.81681 9.53616L9.07677 11.5335C8.97938 11.7963 8.7287 11.9708 8.44839 11.9708C8.16809 11.9708 7.9174 11.7963 7.82001 11.5335L7.08087 9.53858C6.99164 9.29774 7.16981 9.04172 7.42665 9.04172C7.5842 9.04172 7.72435 9.14182 7.77545 9.29085L8.44839 11.2531L9.12582 9.28903C9.17687 9.14104 9.31617 9.04172 9.47272 9.04172Z"
      fill="white"
    />
    <path
      d="M10.9518 13.5H1.9043V14H10.9518C11.2137 14 11.428 13.775 11.428 13.5V13C11.428 13.275 11.2137 13.5 10.9518 13.5Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
export default CSVIcon;
