import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InsipidDeMinimisIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x="0.5" width="40" height="40" rx="20" fill="#E5E9ED" />
    <g clipPath="url(#clip0_3494_48635)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.8024 12.8266C31.3713 12.8266 31.8327 12.3669 31.8327 11.8001C31.8327 11.2333 31.3713 10.7737 30.8024 10.7737H25.6509V7.6944C25.6509 7.1276 25.1895 6.66797 24.6206 6.66797H16.3781C15.8092 6.66797 15.3478 7.1276 15.3478 7.6944V10.7737H10.1963C9.62739 10.7737 9.16602 11.2333 9.16602 11.8001C9.16602 12.3669 9.62739 12.8266 10.1963 12.8266H30.8024ZM30.6169 16.0701L27.8557 32.493C27.7665 32.9791 27.342 33.3328 26.846 33.3346H17.3912C17.7662 32.6809 17.9808 31.9219 17.9808 31.1124C17.9808 28.6578 16.0076 26.668 13.5734 26.668C13.0941 26.668 12.6326 26.7451 12.2006 26.8878L10.3818 16.0701C10.2907 15.5105 10.6723 14.9833 11.2338 14.8926C11.286 14.8841 11.3387 14.8798 11.3915 14.8794H29.5866C30.1556 14.872 30.6227 15.3257 30.6301 15.8925C30.6309 15.952 30.6264 16.0114 30.6169 16.0701ZM13.4993 33.3346C13.6343 33.3346 13.7665 33.3232 13.8952 33.3012C13.9034 33.3033 13.9116 33.3054 13.9199 33.3073C14.541 33.2084 15.0759 32.8479 15.4099 32.3411C15.6763 31.9619 15.8327 31.4999 15.8327 31.0013C15.8327 29.7126 14.788 28.668 13.4993 28.668C12.2107 28.668 11.166 29.7126 11.166 31.0013C11.166 32.29 12.2107 33.3346 13.4993 33.3346ZM21.8485 19.2421C22.2679 18.8826 22.8992 18.9312 23.2586 19.3505C23.618 19.7699 23.5694 20.4012 23.1501 20.7606L19.3693 24.0013L23.1501 27.2421C23.5694 27.6015 23.618 28.2328 23.2586 28.6521C22.8992 29.0714 22.2679 29.12 21.8485 28.7606L17.1819 24.7606C16.9602 24.5706 16.8327 24.2932 16.8327 24.0013C16.8327 23.7094 16.9602 23.432 17.1819 23.2421L21.8485 19.2421Z"
        fill="#767D86"
      />
    </g>
    <defs>
      <clipPath id="clip0_3494_48635">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(4.5 4)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default InsipidDeMinimisIcon;
