import { useRef, useEffect } from 'react';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';
import FileIcon from 'src/components/File/FileIcon';
import FileInfoStatus from 'src/components/File/FileInfoStatus';
import { FileUploadDialogStore } from 'src/components/File/FileUploadDialog/store';
import { UploadFile } from 'src/models/file/upload-file';
import { FileStatus } from 'src/types';
import { WithStore } from 'src/utils/store';

import { ImportUploadStore } from '../ImportUpload/store';

import { useStyles } from './styles';

interface Props {
  file: UploadFile;
  onDelete?: (file: UploadFile) => void;
  showGenerator: boolean;
}

const FileCard = ({
  file,
  onDelete,
}: WithStore<Props, FileUploadDialogStore | ImportUploadStore>) => {
  const classes = useStyles();

  const handleDelete = () => onDelete && onDelete(file);

  const myRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => {
    if (!!myRef && !!myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (!file.canBeSent) {
      executeScroll();
    }
  }, [file.canBeSent]);

  return (
    <Card className={classes.root}>
      <CardActions className={classes.cardActions} disableSpacing>
        <IconButton aria-label="add to favorites">
          <FileIcon type={file.file.name.split('.').pop()!} />
        </IconButton>
        <div className={classes.title}>{file.file.name}</div>
        <FileInfoStatus fileItem={file} />
        {file.status === FileStatus.DONE || !onDelete ? null : (
          <IconButton onClick={handleDelete} style={{ marginLeft: 'auto' }}>
            <HighlightOffIcon fontSize="small" />
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
};

export default observer(FileCard);
