import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import RefreshIcon from '@mui/icons-material/Refresh';
import { styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react-lite';
import NothingToShow from 'src/components/NothingToShow';
import { isFulfilled } from 'src/utils/common';

import { WithStore } from '../../utils/store';

import AB341Collapse from './components/AB341/AB341Collapse';
import AB1826Collapse from './components/AB1826/AB1826Collapse';
import OrganicMaterialsComponent from './components/OrganicMaterialsComponent';
import CompactMenu from './components/OrganicMaterialsComponent/CompactMenu';
import Sb1383Collapses from './components/Sb1383/Sb1383Collapses';
import ShortPreview from './components/ShortPreview';
import { ComplianceMatrixStore } from './store';

export interface ComplianceMatrixProps {
  open?: boolean;
}

export interface MatrixMaterialsProps extends ComplianceMatrixProps {
  disabled?: boolean;
}

const Holder = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1),
  paddingTop: 0,
}));

const GridFrame = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1.5),
  margin: theme.spacing(2, 0),
  backgroundColor: theme.palette.secondary.main,
  borderRadius: 1,
}));

const Charger = () => (
  <Grid item container sx={{ width: '30%' }} alignItems="center">
    <HourglassTopIcon
      sx={{
        color: theme => theme.palette.minervaColors.blue2,
        fontSize: 40,
      }}
    />
    <div>
      <Typography variant="body2">Pending update</Typography>
      <Typography variant="hint">Please refresh the section</Typography>
    </div>
  </Grid>
);

const ComplianceMatrix = observer(
  (data: WithStore<ComplianceMatrixProps, ComplianceMatrixStore>) => {
    const { complianceMatrix, fetchMatrix } = data.store;

    if (
      isFulfilled(complianceMatrix?.matrixReq!) &&
      (!data || !complianceMatrix?.matrix)
    ) {
      return <NothingToShow />;
    }

    return (
      <Holder>
        {!complianceMatrix?.isActual && (
          <GridFrame
            container
            justifyContent="space-between"
            alignItems="center">
            <Charger />
            <Button
              variant="contained"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={() =>
                complianceMatrix && fetchMatrix(complianceMatrix?.id)
              }>
              Refresh
            </Button>
          </GridFrame>
        )}
        <Sb1383Collapses store={data.store} />
        <AB1826Collapse store={data.store} />
        <AB341Collapse store={data.store} />
      </Holder>
    );
  },
);

export const createComplianceMatrix = () => {
  const store = new ComplianceMatrixStore();

  return {
    complianceMatrixStore: store,
    ComplianceMatrix: (props: ComplianceMatrixProps) => (
      <ComplianceMatrix {...props} store={store} />
    ),
    ShortPreview: (props: ComplianceMatrixProps) => (
      <ShortPreview {...props} store={store} />
    ),
    OrganicMaterialsComponent: (props: ComplianceMatrixProps) => (
      <OrganicMaterialsComponent {...props} store={store} />
    ),
    Materials: (props: MatrixMaterialsProps) => (
      <CompactMenu {...props} store={store} />
    ),
  };
};
