import { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import MenuList from '@mui/material/MenuList';
import { observer } from 'mobx-react-lite';
import { AccountLookup } from 'src/api/api-types/lookups';
import { DeleteModal } from 'src/components/DeleteModal';

import GroupItem from './GroupItem';

interface Props {
  loading?: boolean;
  list: AccountLookup[];
  pagination?: JSX.Element | boolean;
  deleteHandler?: (childId: number) => Promise<any>;
}

const AccountChildren: FC<Props> = observer(
  ({ loading, list, deleteHandler, pagination }) => {
    const [deleteState, setDeleteState] = useState(false);
    const [deleteChildren, setDeleteChildren] = useState<null | number>(null);

    const deleteChildrenHandler = async () => {
      if (!deleteChildren) return;
      deleteModalHandler();
      !!deleteHandler && (await deleteHandler(deleteChildren));
    };

    const deleteModalHandler = () => setDeleteState(!deleteState);
    if (list.length <= 0) {
      return null;
    }
    return (
      <Box sx={{ width: '100%' }}>
        <MenuList sx={{ p: 0 }}>
          {loading ? (
            <LinearProgress />
          ) : (
            list.map(({ id, accountNumber, name }) => (
              <GroupItem
                key={id}
                name={accountNumber || name}
                onClick={() => {
                  window.history.pushState({}, '', 'account');
                  window.location.replace(`/account/${id}`);
                }}
                deleteAction={
                  !!deleteHandler
                    ? () => {
                        deleteModalHandler();
                        setDeleteChildren(id);
                      }
                    : undefined
                }
              />
            ))
          )}
        </MenuList>
        {!!pagination && (
          <Grid container justifyContent="flex-end" pb={1}>
            {pagination}
          </Grid>
        )}
        <DeleteModal
          open={deleteState}
          closeAction={deleteModalHandler}
          submitAction={deleteChildrenHandler}
          title="Delete selected children ?"
          description="There is no undo for this action"
        />
      </Box>
    );
  },
);

export default AccountChildren;
