import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

export const HaulerWorkspaceRoute: MinervaRoute<'hauler-workspace'> = {
  name: 'hauler-workspace',
  path: '/',
  comp: () => lazy(() => import('src/components/HaulerWorkspace/WelcomePage')),
  wrapper: pseudoComponent => pseudoComponent.children,
};
