import {
  action,
  observable,
  makeObservable,
  computed,
  runInAction,
} from 'mobx';
import { FileStatus } from 'src/types';
import { FilesApi } from 'src/api/files';
import {
  UploadFileResponse,
  FileSignType,
  UploadParams,
} from 'src/api/api-types/files';
import { ApiReq, emptyValue } from 'src/api';
import get from 'lodash/get';
import last from 'lodash/last';
import { isPending } from 'src/utils/common';
import { UploadFileAssetToken } from './asset-file-token';

export interface UploadFileUpdateProps {
  file: File;
  assetId: number;
  category?: number;
}

export enum FILE_UPLOAD_ERRORS {
  UNKNOWN = 0,
  TYPE_NOT_SUPPORTED = 19,
  MAX_FILE_SIZE = 20,
}

const videoFormats = [
  'wmv',
  'asf',
  'mp4',
  'mpeg',
  'avi',
  'mov',
  'qt',
  'webm',
  'asf',
  'rm',
  'rmvb',
  'ts',
  'dat',
  'vob',
];
const imageFormats = [
  'jpg',
  'jpeg',
  'gif',
  'png',
  'tiff',
  'bmp',
  'svg',
  'webp',
  'heif',
  'heic',
];
const officeFormats = [
  'docx',
  'xlsx',
  'pptx',
  'odt',
  'ods',
  'odp',
  'pages',
  'numbers',
  'keynote',
];
const flatFileFormats = ['txt', 'csv', 'prn', 'tab'];
const pdfFormats = ['pdf'];

const allowedFileTypes = [
  ...videoFormats,
  ...imageFormats,
  ...officeFormats,
  ...flatFileFormats,
  ...pdfFormats,
];

export class UploadFile  {
  readonly api = new FilesApi();
  readonly file: File;
  readonly sendToIntegration: boolean = false;
  readonly canBeSentToIntegration: boolean = false;
  readonly uploadAssetToken = new UploadFileAssetToken();

  @observable error?: FILE_UPLOAD_ERRORS;
  @observable id: string = '0';
  @observable progress: number = 0;
  @observable fileLocationId?: number;
  @observable assetId?: number;
  @observable fileUploadPlan: FileSignType = 'AssetAttachment';
  @observable category: number | null = null;
  @observable.ref uploadReq: ApiReq<UploadFileResponse> = emptyValue;
  @observable.ref linkToMessageReq: ApiReq<unknown> = emptyValue;
  @observable.ref linkToQuestionnaireReq: ApiReq<unknown> = emptyValue;
  constructor(
    props: {
      file: File;
      fileUploadPlan?: FileSignType;
    } & Partial<UploadFileUpdateProps>,
  ) {
    makeObservable(this);
    this.file = props.file;
    this.fileUploadPlan = props.fileUploadPlan || 'AssetAttachment';
    this.assetId = props.assetId;
    if (props.category !== null && props.category !== undefined) {
      this.category = props.category;
    }
    this.validate();
  }

  get name() {
    return this.file.name;
  }

  get extension() {
    return last(this.name.split('.'))?.toLowerCase() || '';
  }

  @computed get percentage() {
    if (!this.progress) return 0;

    return Math.round(this.progress * 100);
  }

  @computed get canBeSent() {
    return !this.error;
  }

  @computed get errorLabel() {
    if (this.error === FILE_UPLOAD_ERRORS.MAX_FILE_SIZE) {
      return 'Max file size reached';
    }

    if (this.error === FILE_UPLOAD_ERRORS.TYPE_NOT_SUPPORTED) {
      return 'File type is not allowed';
    }

    return 'Upload failed';
  }

  @computed get status() {
    if (this.uploadReq.state === 'rejected' || this.error) {
      return FileStatus.FAILED;
    }

    if (isPending(this.uploadReq)) {
      return FileStatus.LOADING;
    }

    if (this.uploadReq.state === 'fulfilled' && this.uploadReq.value.data) {
      return FileStatus.DONE;
    }

    return FileStatus.PENDING;
  }

  @computed get uploadResponse() {
    if (this.uploadReq.state !== 'fulfilled') return null;

    return this.uploadReq.value.data;
  }

  @computed get linkFileDTO() {
    if (!this.uploadResponse) {
      throw new Error('Cannot link file before upload is succeeded');
    }

    return {
      fileName: this.uploadResponse.fileName,
      id: this.uploadResponse.fileId,
      fileServiceId: this.uploadResponse.fileId,
      createdDate: new Date(),
      sendToIntegration: this.sendToIntegration,
      ...(this.category && { categoryId: Number(this.category) }),
    };
  }

  @action setError = (error: FILE_UPLOAD_ERRORS) => {
    this.error = error;
  };

  @action updateCategory = (category: number) => {
    this.category = category;
  };

  @action updateFileGenerator = (id: number) => {
    this.fileLocationId = id;
  };

  @action upload = async (params: Omit<UploadParams, 'token'>) => {
    if(!this.uploadAssetToken.customToken && !!this.assetId) await this.uploadAssetToken.getUploadToken(this.assetId);
    this.uploadReq = this.api.uploadFile(
      `files?fileSign=${this.fileUploadPlan}`,
      this.file,
      {
        ...params,
        token: this.uploadAssetToken.customToken,
      },
      progress => {
        this.progress = progress;
      },
    );

    try {
      const fileResponse = await this.uploadReq;

      runInAction(() => {
        if (fileResponse.data) {
          this.id = fileResponse.data.fileId;
        }
      });
    } catch (e: any) {
      const code = get<number>(e, 'response.data.code', 0);

      this.setError(code);
    }
  };

  @action uploadWithCustomToken = async (params: Omit<UploadParams, 'token'>, customToken: string) => {
    if(customToken) 
      this.uploadAssetToken.setUploadToken(customToken);
    await this.upload(params)
  }

  @action validate = () => {
    if (!allowedFileTypes.includes(this.extension)) {
      this.error = FILE_UPLOAD_ERRORS.TYPE_NOT_SUPPORTED;
    }
  };
}
