import { Box } from '@mui/system';
import {
  GridColDef,
  GridEditInputCell,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { ClientsItem } from 'src/api/api-types/clients';
import { ClientTypeSelectCell } from 'src/pages/Users/components/EditUser/ClientTypeSelectCell';
import { ErrorTooltip } from 'src/pages/Users/components/Table/styles';
import { formattedUSDateTime } from 'src/utils/date';

import { GRID_CHECKBOX_SELECTION_COL } from '../../../components/Table/constants';
import ClientActionMenu from '../components/ClientActionMenu';

const StateComponent = observer(({ client }: { client: ClientsItem }) => {
  return <>{client.active ? 'Yes' : 'No'}</>;
});

const EditNameInputCell = (props: GridRenderEditCellParams) => {
  const { error } = props;

  return (
    <ErrorTooltip
      open={!!error}
      title={'Name field is required'}
      placement="bottom-start">
      <Box sx={{ height: 32, width: '100%' }}>
        <GridEditInputCell {...props} />
      </Box>
    </ErrorTooltip>
  );
};

export const gridColumns: GridColDef[] = [
  GRID_CHECKBOX_SELECTION_COL,
  {
    field: 'name',
    headerName: 'Name',
    editable: true,
    flex: 1,
    renderEditCell: EditNameInputCell,
    preProcessEditCellProps: params => {
      const hasError = params.props.value.length === 0;
      return {
        ...params.props,
        error: hasError,
      };
    },
  },
  {
    field: 'shortName',
    headerName: 'Short Name',
    editable: true,
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 2,
    editable: true,
    valueGetter: ({ value }) => value?.name ?? '',
    renderEditCell: ({ id, value, row, field }) => (
      <ClientTypeSelectCell
        id={id}
        isNew={row.isNew}
        value={value}
        field={field}
      />
    ),
  },
  {
    field: 'active',
    headerName: 'Active',
    flex: 1,
    renderCell: ({ row }) => <StateComponent client={row} />,
  },
  {
    field: 'totalUsers',
    headerName: 'Users Count',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    flex: 2,
    valueGetter: ({ value }) => formattedUSDateTime(value),
  },
  {
    field: 'createdBy',
    headerName: 'Created by',
    flex: 1,
    valueGetter: ({ value }) => value.name,
  },
  {
    field: 'Actions',
    type: 'actions',
    disableColumnMenu: true,
    width: 50,
    align: 'center',
    renderCell: ({ row }) => <ClientActionMenu client={row} />,
  },
];
