import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ErrorMess = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      p: theme => theme.spacing(1, 2),
      backgroundColor: theme => theme.palette.minervaColors.secondaryOrange,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
      borderRadius: 1,
    }}>
    <Box
      sx={{
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        mr: 2,
        backgroundColor: theme => theme.palette.error.main,
      }}>
      <WarningAmberIcon />
    </Box>
    <div>
      <Typography variant="body2">Your import is Failed</Typography>
      <Typography variant="hint">
        Please delete this file and try uploading a different one
      </Typography>
    </div>
  </Box>
);

export default ErrorMess;
