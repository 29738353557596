import { FC } from 'react';

import { Checkbox, Grid, styled, Typography } from '@mui/material';
import { ServiceItem } from 'src/api/api-types/services';

interface ScheduleComponentProps {
  schedule: ServiceItem['schedule'];
  scheduleDefined: ServiceItem['scheduleDefined'];
}

const ItemsContainer = styled(Grid)({
  gridTemplateColumns: 'repeat(7, 25px)',
  alignItems: 'center',
  justifyItems: 'center',
});

export const ScheduleComponent: FC<ScheduleComponentProps> = ({
  schedule,
  scheduleDefined,
}) => {
  if (!scheduleDefined)
    return (
      <Grid container justifyContent="center">
        <Typography>Not defined</Typography>
      </Grid>
    );

  return (
    <ItemsContainer display="grid">
      <Grid>
        <Checkbox checked={schedule.sunday} />
      </Grid>
      <Grid>
        <Checkbox checked={schedule.monday} />
      </Grid>
      <Grid>
        <Checkbox checked={schedule.tuesday} />
      </Grid>
      <Grid>
        <Checkbox checked={schedule.wednesday} />
      </Grid>
      <Grid>
        <Checkbox checked={schedule.thursday} />
      </Grid>
      <Grid>
        <Checkbox checked={schedule.friday} />
      </Grid>
      <Grid>
        <Checkbox checked={schedule.saturday} />
      </Grid>
    </ItemsContainer>
  );
};
