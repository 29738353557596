import { PropsWithChildren, useState } from 'react';

import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import OpenIcon from 'src/assets/icons/Filters/CollapseInIcon';
import CloseIcon from 'src/assets/icons/Filters/CollapseOutIcon';

import { RootContainer, StyledPanel, StyledButton } from './styles';

interface Props {
  open: boolean;
  title: string;
  Icon: (props?: any) => JSX.Element;
  disableCollapse?: boolean;
}

export default ({
  children,
  open = false,
  title,
  Icon,
  disableCollapse,
}: PropsWithChildren<Props>) => {
  const [isOpen, setOpen] = useState(open);

  const handleExpandClick = () => {
    if (!disableCollapse) setOpen(!isOpen);
  };

  return (
    <RootContainer open={isOpen}>
      <StyledPanel onClick={handleExpandClick}>
        <Icon
          sx={{
            fontSize: '1.2rem',
          }}
        />
        <Typography sx={{ ml: 1 }} variant="subtitle1">
          {title}
        </Typography>
        {!disableCollapse && (
          <StyledButton
            disableFocusRipple
            disableRipple
            open={isOpen}
            onClick={handleExpandClick}>
            {!isOpen ? <OpenIcon /> : <CloseIcon />}
          </StyledButton>
        )}
      </StyledPanel>
      <Collapse in={isOpen}>{children}</Collapse>
    </RootContainer>
  );
};
