import AttachmentIcon from '@mui/icons-material/Attachment';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import ActiveInactive from 'src/components/ActiveInactive';
import CategoryItem from 'src/components/CategoryItem';
import { Link } from 'src/components/Link';
import { formattedUSDate } from 'src/utils/date';

import ActiveInactiveIcon from '../../../assets/icons/ActiveInactive/ActiveInactiveIcon';
import { Asset } from '../../../models/assets/asset';
import { GRID_CHECKBOX_SELECTION_COL } from '../../Table/constants';
import { ColoredItemType } from '../../TypesElement/СoloredTypeItem';
import AttachmentList from '../components/AttachmentList';
import { AssetDescription } from '../components/EditAsset/AssetDescription';
import { AssetText } from '../components/EditAsset/AssetText';
import { SelectAccountComponent } from '../components/EditAsset/SelectAccountComponent';
import { SelectCategoryComponent } from '../components/EditAsset/SelectCategoryComponent';
import { SelectSubCategoryComponent } from '../components/EditAsset/SelectSubCategoryComponent';
import { SelectTypeComponent } from '../components/EditAsset/SelectTypeComponent';
import { DescriptionCell } from '../components/RenderCell/DescriptionCell';
import { TitleCell } from '../components/RenderCell/TitleCell';

export const columns: GridColDef<Asset>[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL,
    type: 'boolean',
    disableColumnMenu: true,
  },
  {
    field: 'isActive',
    headerName: 'Inactive',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    disableColumnMenu: true,
    renderHeader: () => <ActiveInactive />,
    width: 45,
    renderCell: ({ value }) => (!value ? <ActiveInactiveIcon /> : null),
  },
  {
    field: 'totalAttachments',
    width: 100,
    headerName: 'Total Attachments',
    headerAlign: 'center',
    renderHeader: () => (
      <AttachmentIcon
        fontSize="small"
        sx={{
          transform: 'rotate(-45deg)',
          color: 'text.secondary',
        }}
      />
    ),
    disableColumnMenu: true,
    renderCell: params => <AttachmentList asset={params.row} />,
  },
  {
    field: 'minervaId',
    headerName: 'Minerva ID',
    disableColumnMenu: true,
    width: 170,
    renderCell: ({ value, row }) => (
      <Link to="account" params={{ id: row.account?.id || 0 }}>
        {value}
      </Link>
    ),
  },
  {
    field: 'generatorId',
    headerName: 'Generator ID',
    disableColumnMenu: true,
    width: 170,
    renderCell: ({ value, row }) => (
      <Link to="generator" params={{ id: row.generator?.id || 0 }}>
        {value}
      </Link>
    ),
  },
  {
    field: 'type',
    width: 200,
    headerName: 'Asset Type',
    editable: true,
    filterable: true,
    renderCell: ({ value }: GridRenderCellParams) => (
      <ColoredItemType id={value.id} name={value.name} flow="asset" />
    ),
    renderEditCell: ({ id, field, value }) => (
      <SelectTypeComponent id={id} value={value} field={field} />
    ),
  },
  {
    field: 'category',
    width: 200,
    headerName: 'Asset Category',
    filterable: true,
    renderCell: ({ value }: GridRenderCellParams) => (
      <CategoryItem name={value.name} />
    ),
    editable: true,
    renderEditCell: ({ id, value, field }) => (
      <SelectCategoryComponent id={id} value={value} field={field} />
    ),
  },
  {
    field: 'subCategory',
    width: 200,
    editable: true,
    filterable: true,
    renderEditCell: ({ id, value, field }) => (
      <SelectSubCategoryComponent id={id} value={value} field={field} />
    ),
    headerName: 'Asset Sub-Category',
    renderCell: ({ value }: GridRenderCellParams) =>
      value?.name && <CategoryItem name={value?.name} />,
  },
  {
    field: 'dateIssued',
    headerName: 'Date Issued',
    editable: true,
    type: 'date',
    width: 145,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => formattedUSDate(value),
    renderCell: ({ value }) => formattedUSDate(value),
  },
  {
    field: 'jurisdiction',
    headerName: 'Jurisdiction',
    width: 150,
    filterable: true,
    renderCell: ({ value }) => value?.name || '',
  },
  {
    field: 'hauler',
    headerName: 'Hauler',
    width: 120,
    filterable: true,
    renderCell: ({ value }) => value?.name || '',
  },
  {
    field: 'account',
    headerName: 'Account #',
    width: 170,
    editable: true,
    disableColumnMenu: true,
    renderEditCell: ({ id, row, field, value }) => (
      <SelectAccountComponent
        id={id}
        generatorId={String(row.generator?.id)}
        value={value}
        field={field}
      />
    ),
    renderCell: ({ value }) => value?.accountNumber || '',
  },
  {
    field: 'accountType',
    headerName: 'Account Type',
    width: 120,
    filterable: true,
    renderCell: ({ value }) => value?.name || '',
  },
  {
    field: 'accountName',
    headerName: 'Account Name',
    width: 270,
    disableColumnMenu: true,
    renderCell: ({ row }) => row?.account?.name || '',
  },
  {
    field: 'generator',
    headerName: 'Account Address',
    disableColumnMenu: true,
    width: 300,
    renderCell: ({ value }) => value?.name || '',
  },
  {
    field: 'createdAt',
    width: 145,
    disableColumnMenu: true,
    headerName: 'Created Date',
    valueFormatter: ({ value }) => formattedUSDate(value),
  },
  {
    field: 'createdBy',
    width: 200,
    editable: false,
    disableColumnMenu: true,
    headerName: 'Created By',
    valueGetter: ({ value }) => value.name,
  },
  {
    field: 'shortText',
    headerName: 'Asset Title',
    disableColumnMenu: true,
    width: 390,
    editable: true,
    renderCell: ({ id, hasFocus, row }) => (
      <TitleCell id={id} hasFocus={hasFocus} row={row} />
    ),
    renderEditCell: ({ id, field, hasFocus }) => (
      <AssetText id={id} field={field} hasFocus={hasFocus} />
    ),
  },
  {
    field: 'description',
    headerName: 'Asset Description',
    disableColumnMenu: true,
    width: 390,
    editable: true,
    renderCell: ({ id, hasFocus, row }) => (
      <DescriptionCell id={id} hasFocus={hasFocus} row={row} />
    ),
    renderEditCell: ({ id, field, hasFocus }) => (
      <AssetDescription id={id} field={field} hasFocus={hasFocus} />
    ),
  },
];
