import { HighchartsChartType } from 'src/api/api-types/diagrams';
import {
  ClickOnLegendType,
  ClickOnPointType,
  ExportCallBackType,
} from 'src/types';
import { multiLineColors } from '../../pages/Dashboard/components/Charts/config/constant';
import { StockChart } from './StockChart';

export class MultiStockChart extends StockChart {
  constructor(
    lineChart: HighchartsChartType,
    callBackClickClickOnLegend: ClickOnLegendType,
    callBackClickOnPoint: ClickOnPointType,
    exportCallBack: ExportCallBackType,
  ) {
    super(
      lineChart,
      callBackClickClickOnLegend,
      callBackClickOnPoint,
      exportCallBack,
    );
    this.options = {
      ...this.options,
      title: {
        ...this.options.title,
        text: lineChart.title,
      },
      series: this.addMarkerToSeries(
        this.formatToSeries(lineChart.values, multiLineColors),
      ),
    };
  }
}
