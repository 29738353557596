import { useContext } from 'react';

import { SelectChangeEvent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import CategoryItem from 'src/components/CategoryItem';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { ItemType } from 'src/types';
import { isPending } from 'src/utils/common';

interface SelectSubCategoryProps {
  id: number | string;
  value: ItemType;
  field: string;
}

export const SelectSubCategoryComponent = observer(
  ({ id, field, value }: SelectSubCategoryProps) => {
    const apiRef = useGridApiContext();
    const { assetSubCategories, subCategoryReq } = useContext(lookupsStoreCtx);

    const handleChange = async (_event: SelectChangeEvent, child?: any) => {
      const newType = { id: child?.props.value, name: child?.props?.children };
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: newType,
      });
    };

    if (assetSubCategories.length === 0)
      return (
        <Typography variant="subtitle2" m="0 auto">
          Not related subcategories
        </Typography>
      );

    return (
      <Select
        value={String(value?.id)}
        fullWidth
        onChange={handleChange}
        endAdornment={
          <>
            {isPending(subCategoryReq) ? (
              <CircularProgress color="inherit" size={20} />
            ) : null}
          </>
        }>
        {assetSubCategories?.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {field.includes('type') ? name : <CategoryItem name={name} />}
          </MenuItem>
        ))}
      </Select>
    );
  },
);
