import {action, computed, IObservableArray, makeObservable, observable, runInAction} from 'mobx';

import {storeFactory} from '../../utils/store';
import {ClientsApi} from '../../api/clients';
import {ApiReq, emptyValue} from '../../api';
import {ClientCreationType, ClientsFilter, ClientsItem, ClientsResponse} from 'src/api/api-types/clients';
import {conversFilterParams, filters, initialClientData} from './helpers/common';
import {Lookups} from 'src/stores/lookups';
import {userStore} from 'src/stores/user';
import {TablePagination} from "../../models/pagination/pagination";
import { Client } from 'src/models/clients/client';
import { GridRowId } from '@mui/x-data-grid-pro';
import { AsyncExportEntity } from 'src/models/export/AsyncExportEntity';
import { gridColumns } from './helpers/gridColumns';
import { GridColumnsManager } from 'src/models/grid-columns-manager/grid-columns-manager';
import { TableFilter } from 'src/models/filter/table-filter';
import { ExportDataType } from 'src/api/exportTypes';

class ClientsStore {
  api = new ClientsApi({prefix: ''});
  lookups = new Lookups();
  @observable logoModalRef: null | HTMLElement = null;
  @observable filter = new TableFilter<ClientsFilter, ClientsItem>(
    gridColumns,
    filters,
    {}
  );
  @observable selectedClients: Array<GridRowId> = [];
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable.ref clientsReq: ApiReq<ClientsResponse> = emptyValue;
  @observable.ref clients: IObservableArray<Client> = observable([]);
  @observable chosenClient?: Client = undefined;
  @observable pagination = new TablePagination<ClientsResponse>({
    requestRef: this.clientsReq,
  });
  @observable columnsManager;

  constructor() {
    makeObservable(this);
    this.pagination.setFetchingCallBack(this.fetchClients);
    this.columnsManager = new GridColumnsManager<
      ClientsItem & { actions: boolean }
    >({
      columns: gridColumns,
      columnsMap: {},
      visibilityModel: {}
    });
  }

  selectClient(id: number) {
    const needle = this.clients.find(item => item.id === id);
    if (needle) {
      this.chosenClient = needle;
      return needle;
    }
    return undefined;
  }

  @action setLogoModalRef = (element:  null | HTMLElement) => this.logoModalRef = element;

  @computed get clientsData() {
    if (this.clientsReq.state !== 'fulfilled' || !this.clientsReq.value) {
      return [];
    }

    return this.clientsReq.value.data?.items || [];
  }

  @action setChosenClient = async (id: number) => {
    const needle = this.clients.find(item => item.id === id);
    if (needle) {
      this.chosenClient = needle;
    }
  }

  @action modifyClient = async (entity: Partial<ClientCreationType>) => {
    try {
      if (entity.isNew) {
        await this.chosenClient?.setEntity(entity)
        await this.chosenClient?.createClient()
        await this.fetchClients()
        this.resetChosenClient()
      }
      if (!entity.isNew && entity?.id && entity?.id !== 0) {
        this.selectClient(entity?.id)
        if (!!this.chosenClient) {
          await this.chosenClient?.setEntity(entity)
          await this.chosenClient?.editClient()
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      throw err
    }
  }

  @action addNewClient = async () => {
    this.chosenClient = new Client({
      ...initialClientData,
      createdBy: {
        id: 0,
        name: userStore?.userData ?
          `${userStore?.userData?.firstName} ${userStore?.userData?.lastName}`
          : '',
      },
    })
    this.clients.replace([this.chosenClient, ...this.clients.slice()]);
    await this.lookups.fetchClientTypes()
  }

  @action setSelectedClients = (items: GridRowId[]) => {
    this.selectedClients = items;
  };

  @action removeSelectedClients = () => {
    if (this.selectedClients.length === 0) return;
    this.selectedClients = [];
  };

  @action fetchClients = async () => {
    runInAction(() => {
      this.clientsReq = this.api.getClients({
        ...this.pagination.serialize,
        filters: this.filter.filterParams,
      });
    });

    await this.clientsReq;

    runInAction(() => {
      if (
        this.clientsReq.state !== 'fulfilled' ||
        this.clientsReq.value?.data === null
      ) return;
      this.clients.replace(
        this.clientsData.map(clientData => {
          return new Client(clientData);
        }),
      );
      this.pagination.setPagination(this.clientsReq.value?.data);
    });

    return this.clientsReq;
  };


  @action handleClientChangeAction = (clientId: number, isCancel?: boolean) => {
    this.selectClient(clientId)
    if (this.chosenClient?.isNew && isCancel) {
      this.resetChosenClient()
      return this.clients.replace(this.clients.filter(({id}) => id !== clientId))
    }
  }

  @action resetChosenClient = () => {
    this.chosenClient = undefined
  }

  @action changeClientStatusAction = async (clientId: number) => {
    this.selectClient(clientId)
    await this.chosenClient?.changeStatus();
  }

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncReportByType(
        this.exportEntity.exportParams<
          ClientsResponse,
          ClientsFilter,
          ClientsItem
        >(
          this.columnsManager.hiddenColumns,
          conversFilterParams(this.filter),
          this.pagination,
          this.selectedClients
        ),
        data.type,
      )
    );
  };

  @action resetStore = () => {
    this.pagination.resetPagination()
    this.filter.resetFilters()
    this.filter.setSearchOpen(false)
  }
}

export const {store: clientsStore, storeCtx: clientsStoreCtx} = storeFactory(
  ClientsStore,
  'client',
);
