import { ChangeEvent, MutableRefObject } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import uniqueId from 'lodash/uniqueId';
import { observer } from 'mobx-react-lite';
import {
  FileSignType,
  FileUploadType,
  LinkFileCallback,
} from 'src/api/api-types/files';
import UploadLabelIcon from 'src/assets/icons/File/UploadLabelIcon';
import VisuallyHidden from 'src/components/VisuallyHidden';
import { WithStore } from 'src/utils/store';

import NoFilesIcon from '../../../assets/icons/File/NoFilesIcon';
import AttachmentsDialog from '../AttachmentsDialog';
import FragAndDropZone from '../DropZone';
import FileCard from '../FileCard';

import { FileUploadDialogStore } from './store';
import { AssetModification } from 'src/models/assets/asset-modification';

export interface Props {
  open?: boolean;
  onClose?: () => void;
  onDone?: () => void;
  onClear?: () => void;
  uploadInputRef?: MutableRefObject<HTMLInputElement | null>;
  showGenerator?: boolean;
  type: FileUploadType;
  linkAction: LinkFileCallback;
  asset: AssetModification
}

export interface FileDialogInstanceType {
  fileDialogStore: FileUploadDialogStore;
  FileUploadDialog: (props: Omit<Props, 'linkAction'>) => JSX.Element;
  AttachmentsDialog: (props: Props) => JSX.Element;
}

export const FileUploadDialog = observer(
  ({
    uploadInputRef,
    store,
    showGenerator = true,
    type,
    asset
  }: WithStore<Omit<Props, 'linkAction'>, FileUploadDialogStore>) => {
    const { appendFiles, files, removeFile } = store;
    const handleFileAttach = (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        store.dependingToAsset(asset)
        appendFiles(event.target.files, type);
      }
    };

    return (
      <>
        <VisuallyHidden>
          <input
            type="file"
            multiple
            ref={uploadInputRef}
            onChange={handleFileAttach}
          />
        </VisuallyHidden>
        <FragAndDropZone store={store} uploadInputRef={uploadInputRef} />

        <div style={{ marginTop: '8px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UploadLabelIcon style={{ padding: '4px' }} />
            <Typography variant="subtitle1">Selected files</Typography>
          </div>
          {files.length <= 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                flex: 1,
                width: '100%',
              }}>
              <NoFilesIcon style={{ width: '100%', height: '100px' }} />
              <Typography variant="subtitle1">No files here yet</Typography>
            </div>
          )}
          <Box
            sx={{
              width: '100%',
              position: 'relative',
              maxHeight: 300,
              overflow: 'auto',
              marginBottom: theme => theme.spacing(6),
            }}>
            {files.map(file => (
              <FileCard
                key={uniqueId('card_')}
                file={file}
                store={store}
                onDelete={removeFile}
                showGenerator={showGenerator}
              />
            ))}
          </Box>
        </div>
      </>
    );
  },
);

export const createFileDialogInstance = (
  fileUploadPlan?: FileSignType,
): FileDialogInstanceType => {
  const store = new FileUploadDialogStore(fileUploadPlan);

  return {
    fileDialogStore: store,
    FileUploadDialog: (props: Omit<Props, 'linkAction'>) => (
      <FileUploadDialog {...props} store={store} />
    ),
    AttachmentsDialog: (props: Props) => (
      <AttachmentsDialog {...props} store={store} />
    ),
  };
};
