import { NotFoundRoute } from './pages/404/route';
import { AccountDetailsRoute } from './pages/AccountDetails/route';
import { AccountsRoute } from './pages/Accounts/route';
import { AssetsRoute } from './pages/Assets/route';
import { AutoLoginRoute } from './pages/AutoLogin/route';
import { ClientsRoute } from './pages/Clients/route';
import { ContainersRoute } from './pages/Containers/route';
import { DashboardRoute } from './pages/Dashboard/route';
import { EdibleFoodRoute } from './pages/EdibleFood/route';
import { EdibleFoodRecoveryDetails } from './pages/EdibleFoodRecoveryDetails/route';
import { GeneratorsRoute } from './pages/Generators/route';
import { GeneratorDetailsRoute } from './pages/GeneratorsDetails/route';
import { HaulersRoute } from './pages/Hauler/route';
import { ImplementationRecordRoute } from './pages/ImplementationRecord/route';
import { ImportRoute } from './pages/Import/route';
import { ServicesRoute } from './pages/Services/route';
import { UsersRoute } from './pages/Users/route';

export const generalRoutes = [
  NotFoundRoute,
  ContainersRoute,
  DashboardRoute,
  EdibleFoodRoute,
  AssetsRoute,
  AutoLoginRoute,
  AccountsRoute,
  AccountDetailsRoute,
  EdibleFoodRecoveryDetails,
  GeneratorsRoute,
  GeneratorDetailsRoute,
  ImplementationRecordRoute,
  ServicesRoute,
] as const;

export const adminRoutes = [
  ...generalRoutes,
  UsersRoute,
  ClientsRoute,
  ImportRoute,
  HaulersRoute,
] as const;

export const jurisdictionRoutes = [
  ...generalRoutes,
] as const;

export const haulerRoutes = [
  ...generalRoutes,
] as const;

export const agencyRoutes = [
  ...generalRoutes,
] as const;

export type RoutesUnion =
  | typeof generalRoutes[number]
  | typeof adminRoutes[number]
  | typeof jurisdictionRoutes[number]
  | typeof haulerRoutes[number]
  | typeof agencyRoutes[number];

export type RouteName = RoutesUnion['name'];
