import * as yup from 'yup';

import { GeneratorsFilterType } from '../../../api/api-types/generators';
import { ColumnsVisibilityModel } from '../../../api/api-types/gridColumnsManager';
import { ExportTypes } from '../../../types';

export const generatorDefaultValue = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  locationAddresses: '',
};

export const validationSchema = yup
  .object({
    addressLine1: yup.string().label('Street address 1').required(),
    addressLine2: yup.string().label('Street address 2'),
    city: yup.string().label('City').required(),
    state: yup.string().label('State').required(),
    zipCode: yup.string().label('Zip code').required(),
  })
  .required();

export const exportDefaultValue = {
  fileName: '',
  mode: ExportTypes.WITH_FILTERS,
};

export const mapColorGuid = {
  food: {
    guid: '6f6cec7d-770c-4781-b014-727e5e4405ff',
    color: '#8b4512',
  },
  greenMaterial: {
    guid: 'c2eefc6a-5d82-4ac4-a670-b2244eb55d7c',
    color: '#228b22',
  },
  landscape: {
    guid: 'b599bcee-a416-44a4-a0d6-dd9055003b3d',
    color: '#006400',
  },
  lumber: {
    guid: '39de010d-4a08-4504-9b80-46a7664e1989',
    color: '#568203',
  },
  manure: {
    guid: '8118aa41-0385-4b8c-8909-44330d1a0719',
    color: '#85bb65',
  },
  textiles: {
    guid: 'a65b6468-ce55-435b-aca5-1575c9dd9e6c',
    color: '#8db600',
  },
  products: {
    guid: 'f80b8e05-9264-403d-ba35-5d8719584eca',
    color: '#1e90ff',
  },
  printing: {
    guid: '49b302bd-f4cc-4dbe-9d6c-99664bec0f90',
    color: '#73c2fb',
  },
  wood: {
    guid: '080099c8-ec79-442a-b4ce-4d038edc6813',
    color: '#4cbb13',
  },
  nonOrganic: {
    guid: '09842cc9-e19e-4b7c-93e2-4650bd429182',
    color: '#4a69e1',
  },
};

export const colorStyleForBoolean = {
  '& .MuiDataGrid-booleanCell[data-value="false"]': {
    color: 'red',
  },
  '& .MuiDataGrid-booleanCell[data-value="true"]': {
    color: 'green',
  },
  '* > .MuiDataGrid-panelWrapper': {
    visible: 'hidden',
  },

  '& .MuiDataGrid-pinnedColumnHeaders--right': {
    width: 117,
  },
  ...Object.keys(mapColorGuid).reduce(
    (entity, key) => ({
      ...entity,
      [`.${key}`]: {
        backgroundColor: mapColorGuid[key as keyof typeof mapColorGuid].color,
      },
    }),
    {},
  ),
};

const defaultVisibilityColumns = {
  accountType: false,
  deMinimisWaiver: false,
  spaceWaiver: false,
  departmentWaiver: false,
  isCefg: false,
  moreRequired: false,
  moreNotRecycling: false,
  mcrRequired: false,
  mcrNotRecycling: false,
  foodAndSoiledPaperGenerates: false,
  foodAndSoiledPaperRecycling: false,
  greenMaterialGenerates: false,
  greenMaterialRecycling: false,
  landscapeAndPrunningGenerates: false,
  landscapeAndPrunningRecycling: false,
  lumberGenerates: false,
  lumberRecycling: false,
  manureGenerates: false,
  manureRecycling: false,
  textilesGenerates: false,
  textilesRecycling: false,
  paperProductsGenerates: false,
  paperProductsRecycling: false,
  printingAndWritingPaperGenerates: false,
  printingAndWritingPaperRecycling: false,
  woodGenerates: false,
  woodRecycling: false,
  nonOrganicGenerates: false,
  nonOrganicRecycling: false,
};

export const visibilityDefaultState: Partial<ColumnsVisibilityModel> = {
  admin: {
    jurisdiction: false,
    ...defaultVisibilityColumns,
  },
  hauler: {
    hauler: false,
    ...defaultVisibilityColumns,
  },
};

export const defaultGeneratorSearch = {
  'q.jurisdiction.id': '',
  'q.account.accountNumber': '',
  'q.account.name': '',
  'q.account.reference': '',
  'q.account.usaAddress': '',
  'q.reference': '',
} as GeneratorsFilterType;
