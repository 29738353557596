import { ClientsFilter, ClientsItem } from "src/api/api-types/clients";
import { gridColumns } from "./gridColumns";
import { TableFilter } from "src/models/filter/table-filter";

export const initialClientData = {
  name: '',
  state: {
    name: 'Active',
    id: 0,
  },
  createdAt: '',
  type: null,
  shortName: '',
  id: 0,
  totalUsers: 0,
  updatedBy: '',
  active: true,
  isNew: true,
  hasLogo: false
};

export const filters = {
  'q.name': '',
  'q.shortName': '',
  'q.types': '',
  'q.states': '',
};

export const conversFilterParams = (filter: TableFilter<ClientsFilter, ClientsItem>): TableFilter<ClientsFilter, ClientsItem> => {
  const params = filter.filterParams;
  const res = {
    ...params,
    ...(!!params['q.types'] && {'q.types': [params['q.types'].id]}),
    ...(!!params['q.states'] && {'q.states': [params['q.states'].id]})
  };
  const t =  new TableFilter<ClientsFilter, ClientsItem>(
    gridColumns,
    filters,
    {},
  );
  t.setFilterValue(res)
  return t;
}