import { ChangeEvent } from 'react';

import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import {
  namesMap,
  NameType,
} from 'src/pages/AccountDetails/components/EFG/components/EFGForm/DependingField';

import { EfgDetailsItem } from '../../../../models/edible-food/efg-details-Item';
import { useEfgEdit } from '../efgEditContext';

import { NoField } from './NoField';

interface EditDependingFieldProps {
  field: Partial<keyof EfgDetailsItem>;
}

const EditDependingField = ({ field }: EditDependingFieldProps) => {
  const { chosenFood } = useEfgEdit();
  if (!chosenFood) return null;

  const dependingValue = chosenFood?.edibleFoodGeneratorType as any;
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    chosenFood?.setEntity({ [field]: event.target.value });
  };

  if (!dependingValue) return <NoField />;

  if (dependingValue[namesMap[field as NameType]]) {
    return (
      <TextField value={chosenFood?.[field] || ''} onChange={changeHandler} />
    );
  }
  return <NoField />;
};

export default observer(EditDependingField);
