import {
  AssetCategory,
  AssetItem,
  AssetSubCategory,
} from 'src/api/api-types/assets';
import { AccountLookup } from 'src/api/api-types/lookups';
import { CreatedByType } from 'src/api/api-types/user';
import { ItemType } from '../../types';

export class Asset {
  readonly id: number;
  readonly generatorId: string;
  readonly minervaId: string;
  readonly account: AccountLookup;
  readonly createdAt: Date | string;
  readonly createdBy?: CreatedByType;
  readonly updatedAt?: Date | string;
  readonly updatedBy?: CreatedByType;
  readonly client: ItemType;
  readonly generator: ItemType;
  readonly jurisdiction: ItemType;
  readonly hauler: ItemType;
  readonly accountType: ItemType;

  readonly updateDescription?: boolean;
  readonly isActive?: boolean;
  readonly category?: AssetCategory;
  readonly subCategory?: AssetSubCategory;
  readonly shortText: string;
  readonly type?: ItemType;
  readonly description: string;
  readonly descriptionTruncated: boolean;
  readonly relatedLookupsName?: string;
  readonly dateIssued: Date | string | null;
  readonly relatedTo?: ItemType | null;
  readonly relatedToType?: ItemType | null;
  readonly totalAttachments: number;
  readonly canUpdate: boolean;
  readonly isRelatedEntityActive: boolean;

  constructor(asset: AssetItem) {
    const {
      id,
      type,
      category,
      subCategory,
      shortText,
      description,
      descriptionTruncated,
      relatedTo,
      relatedToType,
      client,
      hauler,
      jurisdiction,
      totalAttachments,
      updatedAt,
      updatedBy,
      createdAt,
      createdBy,
      dateIssued,
      minervaId,
      generatorId,
      isActive,
      account,
      generator,
      accountType,
      canUpdate,
      isRelatedEntityActive,
    } = asset;

    this.id = id;
    this.minervaId = minervaId;
    this.generatorId = generatorId;
    this.type = type;
    this.isActive = isActive;
    this.generator = generator;
    this.account = account;
    this.category = category;
    this.subCategory = subCategory;
    this.shortText = shortText || '';
    this.description = description || '';
    this.descriptionTruncated = descriptionTruncated ?? false;
    this.dateIssued = dateIssued || null;
    this.createdAt = createdAt || '';
    this.createdBy = createdBy;
    this.updatedAt = updatedAt || '';
    this.updatedBy = updatedBy;
    this.client = client;
    this.hauler = hauler;
    this.accountType = accountType;
    this.jurisdiction = jurisdiction;
    this.totalAttachments = totalAttachments;
    this.relatedTo = relatedTo || null;
    this.relatedToType = relatedToType || null;
    this.canUpdate = canUpdate;
    this.isRelatedEntityActive = isRelatedEntityActive;
  }
}
