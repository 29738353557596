import {
  ClientCreatedBy, ClientCreationType,
  ClientInfoResponse,
  ClientLogoResponse,
  ClientsItem,
  ClientState,
  ClientType,
  DeleteLogoCallback,
} from '../../api/api-types/clients';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from '../../api';
import { ClientsApi } from '../../api/clients';
import { ClientLogo } from './client-logo';
import { UploadClientLogoResponse } from 'src/api/api-types/files';

export class Client {
  public type: ClientType | null;
  public state: ClientState | null;
  public createdAt: Date | string;
  public createdBy: ClientCreatedBy;
  public updatedBy?: string;
  public totalUsers: number;
  public isNew: boolean;
  public api = new ClientsApi();
  @observable hasLogo: boolean;
  @observable active: boolean;
  @observable id: number;
  @observable name: string;
  @observable shortName: string;
  @observable logo: ClientLogo | undefined;
  @observable.ref createReq: ApiReq<ClientInfoResponse> = emptyValue;
  @observable.ref editReq: ApiReq<ClientInfoResponse> = emptyValue;
  @observable.ref clientStatusReq: ApiReq<ClientInfoResponse> = emptyValue;
  @observable.ref logoReq: ApiReq<ClientLogoResponse> = emptyValue;
  
  constructor({
    id,
    name,
    shortName,
    type,
    state,
    createdAt,
    createdBy,
    updatedBy,
    totalUsers,
    active,
    isNew,
    hasLogo
  }: ClientsItem & { isNew?: boolean }) {
    makeObservable(this);
    this.id = id;
    this.name = name || '';
    this.shortName = shortName || '';
    this.type = type || null;
    this.state = state || null;
    this.createdAt = createdAt || '';
    this.createdBy = createdBy || null;
    this.updatedBy = updatedBy || '';
    this.totalUsers = totalUsers;
    this.active = active;
    this.isNew = !!isNew;
    this.hasLogo = hasLogo || false;
    this.logo = !!this.id ? new ClientLogo({ clientId: this.id}) : undefined;
  }

  @action setEntity = async (clientFields: Partial<ClientCreationType>) => {
    this.name = !!clientFields.name ? clientFields.name : this.name
    this.shortName = !!clientFields.shortName ? clientFields.shortName : this.shortName;
    this.type = clientFields.type ?? null;
  }

  @action createClient = async () => {
    this.createReq = this.api.createClient({
      name: this.name,
      shortName: this.shortName,
      type: this.type
    })

    const res = await this.createReq
    runInAction(() => {
      if (res.data) {
        this.id = res.data?.id
        this.isNew = false
      }
    })
    return this.createReq
  }

  @action editClient = async () => {
    this.editReq = this.api.updateClient({
      id: this.id,
      name: this.name,
      shortName: this.shortName
    })
    return this.editReq
  }

  @action changeStatus = async () => {
    this.clientStatusReq = this.api.changeClientStatusAction({
      id: this.id,
      active: !this.active
    })
    const res = await this.clientStatusReq
    runInAction(() => {
      if (res.data) {
        this.active = res.data?.active
      }
    });
    return this.clientStatusReq
  }

  @action getLogoData = async () => {
    if(!this.hasLogo || !this.id) return;
		this.logoReq = this.api.getLogo(this.id);
    await this.logoReq;
    runInAction(() => {
      if (this.logoReq.state === 'fulfilled' && !!this.logoReq?.value.data) {
          this.logo?.setData({
            ...this.logoReq?.value.data, 
            clientId: this.id
          })
      }
    });
    return this.logoReq;
	};

  @action deleteLogo = async (callbackAction: DeleteLogoCallback) => {
    if (!this.logo?.id) return;
    const res =  await this.api.deleteLogo(this.id);
    if(res.status === 200) {
        this.setHaslogo(false);
        await callbackAction();
    }
  };

  @action linkLogo = async (data: UploadClientLogoResponse, callback: () => void) => {
    const res = await this.logo?.linkLogo(data);
    if(res?.status === 200) {
      const dto = {
        ...data,
        name: data.fileName
      }
      this.logo?.setData(dto);
      // load new token in case of using the "Upload New Logo" button to upload an updated logo
      if(this.hasLogo) this.logo?.logoTokens.getDownloadLogoToken();
      if(!this.hasLogo) this.setHaslogo(true);
      callback();
    }
  }

  @action setHaslogo  = (value: boolean) => this.hasLogo = value;
}
