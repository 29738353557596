import CollapseClose from 'src/assets/icons/Generator/Actions/CollapseClose';
import CollapseOpen from 'src/assets/icons/Generator/Actions/CollapseOpen';

const CollapsibleButton = ({
  open,
  onClick,
}: {
  open: boolean;
  onClick?: () => void;
}) => {
  return (
    <>
      {open ? (
        <CollapseClose
          onClick={onClick}
          sx={{ width: 20, m: theme => theme.spacing(0, 1) }}
        />
      ) : (
        <CollapseOpen
          onClick={onClick}
          sx={{ width: 20, m: theme => theme.spacing(0, 1) }}
        />
      )}
    </>
  );
};
export default CollapsibleButton;
