import * as React from 'react';
import { useContext, useState } from 'react';

import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import { ExportFileType } from 'src/api/exportTypes';
import ExportIcon from 'src/assets/icons/TableActionsIcons/ExportIcon';
import ExportTypesMenuItems from 'src/components/ExportModal/ModalContent/ExportTypesMenuItems';
import { authStoreCtx } from 'src/stores/auth';

type ExportButtonProps = {
  quickExportAction: (type: ExportFileType) => void;
};

export default function ExportButtonWithMenu({
  quickExportAction,
}: ExportButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { fCfg } = useContext(authStoreCtx);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuHandler = () => {
    setAnchorEl(null);
  };

  const handleTypeClick = (type: ExportFileType) => {
    quickExportAction(type);
    closeMenuHandler();
  };
  if (!fCfg.isExportEFR) return null;
  return (
    <div>
      <IconButton
        size="small"
        onClick={handleClick}
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="Filter Menu">
        <ExportIcon style={{ padding: '2px' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={!!anchorEl}
        onClose={closeMenuHandler}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
        <ExportTypesMenuItems handleAction={handleTypeClick} />
      </Menu>
    </div>
  );
}
