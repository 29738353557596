import { styled } from '@mui/material';
import TextField from '@mui/material/TextField';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  border: 'none',
  outline: 'none',
  resize: 'none',
  borderRadius: 8,
  fontSize: 16,
  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.text.primary,
  },
  '& ': {
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    'input[type=number]': {
      MozAppearance: 'textfield',
    },
  },
}));
export const Counter = styled('div')<{ error?: boolean }>(
  ({ theme, error }) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: error ? 'rgba(255, 0, 0, 0.5)' : 'rgba(118, 125, 134, 0.5)',
    paddingLeft: theme.spacing(1),
  }),
);
export const Border = styled('div')<{ error?: boolean }>(({ error }) => ({
  borderRadius: 8,
  borderWidth: '1px',
  borderColor: error ? 'rgba(255, 0, 0, 0.5)' : 'rgba(118, 125, 134, 0.5)',
  borderStyle: 'solid',
}));
