import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { GridRenderEditCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { EFGItemType } from 'src/api/api-types/food-generators';
import CommaSeparated from 'src/components/CommaSeparated';
import DescriptionCell from 'src/components/EFGcomponents/DescriptionCell';
import { EFGEditDescriptionCell } from 'src/components/EFGcomponents/DescriptionCell/EditDescription';
import EFGInfoButton from 'src/components/EFGcomponents/EFGInfoButton';
import { Link } from 'src/components/Link';
import { EmailReportLink } from 'src/components/Link/EmailReportLink';
import ColoredItemType from 'src/components/TypesElement/СoloredTypeItem';
import { nameOf } from 'src/utils/common';

import { GRID_CHECKBOX_SELECTION_COL } from '../../../components/Table/constants';
import { EfgDetailsItem } from '../../../models/edible-food/efg-details-Item';
import { EFGItem } from '../../../models/edible-food/efg-item';
import { sortArrayLengthFn } from '../../../utils/sorting';
import { BooleanView } from '../components/EfgComponents/BooleanView';
import EditDependingField from '../components/EfgComponents/EditDependingField';
import { EditEfgType } from '../components/EfgComponents/EditEfgType';
import EditFoodFacility from '../components/EfgComponents/EditFoodFacility';

const renderDependedField = ({ field }: GridRenderEditCellParams) => (
  <EditDependingField field={field as keyof EfgDetailsItem} />
);

export const columns: GridColDef<EFGItem>[] = [
  GRID_CHECKBOX_SELECTION_COL,
  {
    field: nameOf<EFGItemType>('location'),
    disableColumnMenu: true,
    headerName: 'Generator ID',
    headerAlign: 'center',
    align: 'center',
    width: 150,
    renderCell: ({ value }: GridRenderCellParams) => (
      <Link to="generator" params={{ id: value.id }}>
        {value.reference}
      </Link>
    ),
  },
  {
    field: nameOf<EFGItemType>('reference'),
    disableColumnMenu: true,
    headerName: 'Minerva ID',
    headerAlign: 'center',
    width: 150,
    align: 'center',
    renderCell: ({ row, value }: GridRenderCellParams) => (
      <Link to="account" params={{ id: row.id }}>
        {value}
      </Link>
    ),
  },
  {
    field: nameOf<EFGItemType>('jurisdiction'),
    headerName: 'Jurisdiction',
    width: 150,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: nameOf<EFGItemType>('name'),
    headerName: 'Name',
    width: 145,
    disableColumnMenu: true,
    editable: true,
  },
  {
    field: nameOf<EFGItemType>('usaAddress'),
    disableColumnMenu: true,
    headerName: 'Address',
  },
  {
    field: nameOf<EFGItemType>('type'),
    headerName: 'EFG Type',
    editable: true,
    minWidth: 220,
    renderCell: ({ value }: GridRenderCellParams) => (
      <ColoredItemType {...value} />
    ),
    renderEditCell: () => <EditEfgType />,
  },
  {
    field: nameOf<EFGItemType>('tier'),
    headerName: 'CEFG Tier',
    renderCell: ({ value }: GridRenderCellParams) => (
      <ColoredItemType id={value.id} name={value.name} />
    ),
  },
  {
    field: 'fullName',
    disableColumnMenu: true,
    editable: true,
    headerName: 'Contact Name',
    flex: 1,
    minWidth: 150,
    valueGetter: ({ row }) => row?.contact?.fullName,
  },
  {
    field: 'phone',
    disableColumnMenu: true,
    editable: true,
    headerName: 'Phone',
    flex: 1,
    minWidth: 150,
    valueGetter: ({ row }) => row?.contact?.phone,
  },
  {
    field: 'email',
    disableColumnMenu: true,
    headerName: 'Email',
    editable: true,
    flex: 1,
    minWidth: 150,
    valueGetter: ({ row }) => row?.contact?.email,
    renderCell: ({ value }: GridRenderCellParams) => (
      <EmailReportLink to={value}>{value}</EmailReportLink>
    ),
  },
  {
    field: nameOf<EFGItemType>('foodRecoveries'),
    disableColumnMenu: true,
    headerName: 'Recoverer Name(s)',
    flex: 1,
    minWidth: 350,
    sortComparator: sortArrayLengthFn,
    renderCell: ({ value }) => <CommaSeparated types={value} />,
  },
  {
    field: nameOf<EFGItemType>('sqFeet'),
    disableColumnMenu: true,
    editable: true,
    headerName: 'Sq Feet',
    renderEditCell: renderDependedField,
  },
  {
    field: nameOf<EFGItemType>('rooms'),
    disableColumnMenu: true,
    editable: true,
    headerName: 'Rooms',
    renderEditCell: renderDependedField,
  },
  {
    field: nameOf<EFGItemType>('beds'),
    disableColumnMenu: true,
    editable: true,
    headerName: 'Beds',
    renderEditCell: renderDependedField,
  },
  {
    field: nameOf<EFGItemType>('seats'),
    disableColumnMenu: true,
    editable: true,
    headerName: 'Seats',
    renderEditCell: renderDependedField,
  },
  {
    field: nameOf<EFGItemType>('onSiteFoodFacility'),
    disableColumnMenu: true,
    editable: true,
    type: 'boolean',
    align: 'center',
    headerName: 'On-site Food Facility',
    renderCell: ({ value }) => <BooleanView value={value} />,
    renderEditCell: () => <EditFoodFacility />,
  },
  {
    field: 'info',
    disableColumnMenu: true,
    align: 'center',
    headerName: 'Info',
    width: 60,
    flex: 2,
    renderCell: params => <EFGInfoButton item={params.row} />,
  },
  {
    field: 'description',
    headerName: 'Description',
    disableColumnMenu: true,
    width: 390,
    editable: true,
    renderCell: ({ id, hasFocus, row }) => (
      <DescriptionCell id={id} hasFocus={hasFocus} row={row} />
    ),
    renderEditCell: ({ id, field, hasFocus }) => (
      <EFGEditDescriptionCell id={id} field={field} hasFocus={hasFocus} />
    ),
  },
];
