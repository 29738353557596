import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { ItemType } from 'src/types';

interface CommaSeparatedProps {
  types: ItemType[];
}

export default ({ types }: CommaSeparatedProps) => (
  <Tooltip title={<TooltipComponent types={types} />} placement="bottom-start">
    <div
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
      }}>{`${types?.map(({ name }: ItemType) => ` ${name}`)}`}</div>
  </Tooltip>
);

export const TooltipComponent = ({ types }: CommaSeparatedProps) => (
  <List sx={{ maxHeight: 250, overflowY: 'auto' }} dense>
    {types.map(({ id, name }) => (
      <ListItem key={`${id}-${name}`}>
        <ListItemText primary={name} />
      </ListItem>
    ))}
  </List>
);
