import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const NothingToShowIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="216"
    height="212"
    viewBox="0 0 216 212"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10.4286 175.808H209.02L209.328 150.624C211.063 108.858 163.934 77.0445 124.805 93.5676L77.9472 111.803L67.6646 105.103C42.5153 87.5387 4.99636 108.836 7.99764 138.992C7.99682 138.991 10.4286 175.808 10.4286 175.808Z"
      fill="#F6F7F9"
    />
    <path
      d="M2.59375 175.805V134.152H23.5856V152.001H96.0061V134.762H147.687V152.001H216V175.856L2.59375 175.805Z"
      fill="#F5F6F7"
    />
    <path
      d="M152.482 116.21L149.754 116.721C149.514 116.766 149.268 116.773 149.027 116.74L134.824 114.758L134.334 117.424L147.19 121.392C148.661 121.948 151.281 121.532 152.828 121.482L152.482 116.21Z"
      fill="#FFD9D9"
    />
    <path
      d="M150.258 115.847L149.621 122.215L154.022 121.694L152.825 115.059L150.258 115.847Z"
      fill="#FFD9D9"
    />
    <path
      d="M134.824 114.757C134.824 114.757 132.851 112.722 132.642 112.952C132.434 113.181 133.133 114.148 133.006 114.225C132.879 114.302 128.827 114.174 128.879 114.505C128.932 114.836 129.683 117.179 130.307 117.332C130.93 117.485 134.336 117.423 134.336 117.423L134.824 114.757Z"
      fill="#FFD9D9"
    />
    <path
      d="M160.936 113.715L151.629 115.947L152.036 122.212L160.512 121.339L160.936 113.715Z"
      fill="#047DDB"
    />
    <path
      d="M171.579 170.867L171.72 172.005L169.105 174.667C168.953 174.822 168.85 175.018 168.81 175.23C168.77 175.441 168.794 175.66 168.879 175.859L175.469 173.133L173.806 169.945L171.579 170.867Z"
      fill="#03045E"
    />
    <path
      d="M152.659 131.598L147.557 144.124C146.265 145.414 147.129 170.102 146.895 172.235H150.725L154.913 147.98L164.188 133.864L152.659 131.598Z"
      fill="#00060F"
    />
    <path
      d="M158.052 143.202L157.516 136.773C157.516 136.773 151.526 146.783 151.427 148.433C151.328 150.083 149.053 172.898 149.053 172.898H151.82C152.624 169.067 155.593 145.973 158.05 143.202"
      fill="#03045E"
    />
    <path
      d="M157.1 131.77L158.407 147.439C157.802 148.595 170.531 170.808 171.058 172.237L174.449 170.492L166.972 147.997L168.23 134.258L157.1 131.77Z"
      fill="#00060F"
    />
    <path
      d="M157.494 134.946C157.494 134.946 160.95 139.82 169.063 141.268L169.477 135.611L157.867 134.125L157.498 134.948"
      fill="#03045E"
    />
    <path
      d="M147.509 172.234L147.188 173.336L143.725 174.793C143.523 174.878 143.351 175.019 143.23 175.199C143.109 175.379 143.045 175.589 143.045 175.805H150.197L149.926 172.234H147.509Z"
      fill="#03045E"
    />
    <path
      d="M168.04 109.618C165.54 114.402 159.718 111.863 162.385 106.775C164.574 101.713 170.234 105.112 168.04 109.618Z"
      fill="#FFD9D9"
    />
    <path
      d="M161.072 107.54L167.187 107.849V106.277L162.461 104.766L161.072 107.54Z"
      fill="#FFD9D9"
    />
    <path
      d="M168.15 109.371C168.15 109.371 165.108 114.96 164.913 116.94C164.913 116.94 162.707 116.625 161.072 115.176C161.072 115.176 164.611 110.838 164.913 109.609L168.15 109.371Z"
      fill="#FFD9D9"
    />
    <path
      d="M163.482 112.023C163.482 112.023 164.147 112.318 165.348 112.023C165.348 112.023 164.42 113.483 162.861 112.874L163.482 112.023Z"
      fill="#FFD9D9"
    />
    <path
      d="M162.697 105.49C162.697 105.49 163.979 106.425 166.364 107.254C166.766 109.098 164.542 109.887 167.152 111.295C168.623 109.838 172.101 106.597 167.833 104.179C165.894 103 163.987 103.336 162.697 105.49Z"
      fill="#00060F"
    />
    <path
      d="M167.041 109.417C166.639 110.29 165.251 109.651 165.678 108.79C166.08 107.913 167.469 108.555 167.041 109.417Z"
      fill="#FFD9D9"
    />
    <path
      d="M163.938 103.579L168.177 110.376C173.66 107.792 167.637 100.241 163.938 103.579Z"
      fill="#047DDB"
    />
    <path
      d="M166.107 107.056C162.489 105.872 161.273 106.299 159.643 102.477L163.937 103.577L166.107 107.056Z"
      fill="#00060F"
    />
    <path
      d="M169.725 105.34C169.471 105.552 168.675 104.509 168.953 104.329C169.207 104.118 170.003 105.16 169.725 105.34Z"
      fill="#00060F"
    />
    <path
      d="M169.741 120.901C169.954 116.595 167.126 113.862 163.39 113.609C160.496 113.041 157.517 114.999 156.311 118.26L150.793 133.193L168.974 136.766L169.741 120.901Z"
      fill="#047DDB"
    />
    <path
      d="M162.967 113.551L163.498 114.709C164.122 115.799 165.471 115.17 166.393 114.409L165.694 113.993L162.967 113.551Z"
      fill="white"
    />
    <path
      d="M162.968 113.55L161.776 114.192C161.392 114.377 161.133 113.938 161.333 113.622C161.868 113.494 162.423 113.47 162.968 113.55Z"
      fill="white"
    />
    <path
      d="M163.945 125.732L164.68 126.509L168.899 120.938C168.899 120.938 165.815 128.406 164.873 128.635C163.931 128.864 160.273 128.365 160.273 128.365L163.945 125.732Z"
      fill="#047DDB"
    />
    <path
      d="M159.885 121.569L159.501 121.207L163.944 117.418L154.53 123.089L153.955 124.645L157.21 123.958L159.885 121.569Z"
      fill="#047DDB"
    />
    <path
      d="M159.884 121.566L157.213 123.955L141.709 127.226L142.271 129.739L158.25 128.938C160.227 128.947 162.438 126.762 163.948 125.73L159.884 121.566Z"
      fill="#FFD9D9"
    />
    <path
      d="M158.434 121.691L161.486 128.164L165.041 125.142L160.273 120.441L158.434 121.691Z"
      fill="#FFD9D9"
    />
    <path
      d="M141.855 127.27C141.855 127.27 139.165 126.312 139.084 126.607C139.002 126.903 140.064 127.464 139.983 127.589C139.901 127.714 136.219 129.371 136.416 129.644C136.613 129.917 138.351 131.686 138.977 131.551C139.604 131.415 142.796 129.642 142.796 129.642L141.855 127.27Z"
      fill="#FFD9D9"
    />
    <path
      d="M113.75 101.719L96.7313 112.022L88.9531 91.8502L105.972 81.5469L113.75 101.719Z"
      fill="#047DDB"
    />
    <path
      d="M126.034 101.145L113.754 101.72L105.977 81.5468L118.257 80.9727L126.034 101.145Z"
      fill="#047DDB"
    />
    <path
      d="M99.3372 88.0527L97.0468 89.4407L96.2656 87.4153L98.556 86.0273L99.3372 88.0527Z"
      fill="#FFF6EE"
    />
    <path
      d="M115.029 83.1928L112.304 83.3002L111.523 81.2748L114.245 81.1602L115.029 83.1928Z"
      fill="#FFF6EE"
    />
    <path
      d="M96.7324 112.024L109.441 110.928L126.035 101.145L113.755 101.719L96.7324 112.024Z"
      fill="#03045E"
    />
    <path
      d="M74.1699 125.18C74.1699 125.18 81.7691 115.099 99.2866 103.723L100.327 105.519C100.327 105.519 87.8608 112.337 74.1699 125.18Z"
      fill="white"
    />
    <path
      d="M103.828 116.75C103.828 116.75 119.19 110.171 129.609 107.764L129.127 104.637C129.127 104.637 117.291 108.638 103.828 116.75Z"
      fill="white"
    />
    <path
      d="M71.7852 133.13C71.7852 133.13 80.6787 124.131 92.7435 115.992L93.7003 117.864C93.7003 117.864 85.9843 121.801 71.7852 133.13Z"
      fill="white"
    />
    <path
      d="M80.1872 151.043H59.2559V175.858H80.1872V151.043Z"
      fill="#047DDB"
    />
    <path
      d="M71.3396 151.043H68.1055V153.451H71.3396V151.043Z"
      fill="#FFF6EE"
    />
    <path
      d="M91.1206 151.043H80.1875V175.858H91.1206V151.043Z"
      fill="#047DDB"
    />
    <path
      d="M87.6263 151.043H84.8555V153.451H87.6263V151.043Z"
      fill="#FFF6EE"
    />
    <path d="M22.769 160.863H10.1191V175.86H22.769V160.863Z" fill="#047DDB" />
    <path
      d="M17.4213 160.863H15.4668V162.319H17.4213V160.863Z"
      fill="#FFF6EE"
    />
    <path d="M29.3768 160.863H22.7695V175.86H29.3768V160.863Z" fill="#047DDB" />
    <path
      d="M27.2641 160.863H25.5898V162.319H27.2641V160.863Z"
      fill="#FFF6EE"
    />
    <path
      d="M19.8217 145.918H7.17188V160.914H19.8217V145.918Z"
      fill="#047DDB"
    />
    <path
      d="M14.4721 145.918H12.5176V147.373H14.4721V145.918Z"
      fill="#FFF6EE"
    />
    <path
      d="M26.4275 145.918H19.8203V160.914H26.4275V145.918Z"
      fill="#047DDB"
    />
    <path
      d="M24.3149 145.918H22.6406V147.373H24.3149V145.918Z"
      fill="#FFF6EE"
    />
    <path d="M19.82 160.742H10.1191V160.982H19.82V160.742Z" fill="#047DDB" />
    <path
      d="M58.2902 127.766L59.5257 131.527L72.8447 136.233L71.3952 138.077C70.8314 137.851 55.5569 136.153 55.686 135.469C54.2152 134.555 54.2683 132.69 54.3059 131.189L55.1565 128.373L58.2902 127.766Z"
      fill="#FFD9D9"
    />
    <path
      d="M48.9676 172.234L49.2879 173.34L52.7517 174.797C52.9536 174.882 53.1256 175.023 53.2464 175.203C53.3673 175.383 53.4317 175.593 53.4315 175.809H46.2793L46.5506 172.238L48.9676 172.234Z"
      fill="#03045E"
    />
    <path
      d="M30.184 168.78L29.9217 169.896L32.2366 172.813C32.3716 172.983 32.4527 173.188 32.47 173.402C32.4873 173.617 32.4399 173.832 32.3339 174.021L26.0723 170.63L28.0677 167.633L30.184 168.78Z"
      fill="#03045E"
    />
    <path
      d="M59.1389 105.859C59.3382 106.142 59.9209 108.202 59.0008 109.172L58.2891 106.365L59.1389 105.859Z"
      fill="#00060F"
    />
    <path
      d="M52.05 107.912C51.1103 113.388 57.8908 114.614 59.0029 109.171C59.9401 103.696 53.1597 102.469 52.05 107.912Z"
      fill="#FFD9D9"
    />
    <path
      d="M59.2561 107.81C56.4149 110.369 53.9374 107.417 53.2715 105.164L58.8075 106.057L59.2561 107.81Z"
      fill="#FFD9D9"
    />
    <path
      d="M52.1069 107.641C52.1069 107.641 51.2424 114.11 50.1973 115.883C50.1973 115.883 52.2401 116.919 54.4929 116.661C54.4929 116.661 54.1766 110.947 54.6751 109.74L52.1069 107.641Z"
      fill="#FFD9D9"
    />
    <path
      d="M54.3968 112.609C54.3968 112.609 53.6614 112.464 52.8336 111.512C52.8336 111.512 52.7201 113.28 54.3968 113.686V112.609Z"
      fill="#FFD9D9"
    />
    <path
      d="M54.1839 105.22C54.3473 107.99 53.5302 108.508 51.7113 110.175C51.0976 108.01 49.711 106.09 52.6403 104.344L54.1839 105.22Z"
      fill="#00060F"
    />
    <path
      d="M51.9214 108.17C51.713 109.309 53.4739 109.627 53.6945 108.491C53.9152 107.355 52.1404 107.032 51.9214 108.17Z"
      fill="#FFD9D9"
    />
    <path
      d="M53.638 105.593C53.638 105.593 55.9562 107.128 57.3527 107.653L56.3141 106.352C56.3141 106.352 58.3463 107.635 60.3556 107.723C60.3556 107.723 60.4087 104.779 58.075 103.667L59.4641 103.897C57.6664 102.605 53.4632 100.569 52.2734 104.601L53.638 105.593Z"
      fill="#00060F"
    />
    <path
      d="M54.1754 105.941C54.1754 105.941 52.7552 105.25 52.2715 104.602C52.2747 104.602 51.532 105.354 54.1754 105.941Z"
      fill="#00060F"
    />
    <path
      d="M57.0791 118.133L59.1555 127.597L54.7422 128.454L57.0791 118.133Z"
      fill="#047DDB"
    />
    <path
      d="M56.9771 122.347C58.2298 118.216 56.1518 114.9 52.581 113.78C49.909 112.55 46.5392 113.754 44.5732 116.641L36.3203 130.192L52.3767 137.582L56.9771 122.347Z"
      fill="#047DDB"
    />
    <path
      d="M56.4383 124.136C56.2543 124.047 56.102 123.905 56.0015 123.73C55.901 123.554 55.857 123.353 55.8753 123.152L56.4759 116.676C55.9971 115.704 55.3434 115.303 55.3434 115.303L54.4208 122.486C54.4115 122.558 54.3848 122.627 54.3428 122.687C54.3009 122.747 54.2449 122.797 54.1796 122.831C54.1143 122.866 54.0415 122.884 53.9674 122.886C53.8932 122.887 53.8198 122.871 53.7533 122.839C47.0145 119.568 49.0826 121.68 50.1081 113.309C49.7158 113.325 49.3264 113.382 48.9461 113.478C48.2924 115.125 47.6461 119.374 46.1124 120.493C42.37 124.598 33.8964 128.691 34.3695 135.315H34.3802C35.395 155.462 36.5439 150.177 27.3398 168.175L30.2733 169.834L41.1451 157.28C43.0179 155.356 43.6201 151.988 44.2877 149.503C45.1719 151.507 46.0854 170.034 46.3215 172.613L49.59 172.791C49.693 171.92 53.3692 151.944 53.1322 151.603L52.3821 137.58H52.3895H52.3821C52.3682 137.568 56.4399 124.149 56.4383 124.136Z"
      fill="#00060F"
    />
    <path
      d="M44.2871 149.499L44.9522 140.809C51.8978 151.118 47.2851 163.814 46.3209 172.609L44.2871 149.499Z"
      fill="#03045E"
    />
    <path
      d="M35.3136 129.216C34.9051 137.008 52.5034 134.309 54.0257 134.1L40.4615 130.709L39.1271 125.898L35.3136 129.216Z"
      fill="#03045E"
    />
    <path
      d="M48.3154 121.733C47.8831 122.673 46.3886 121.985 46.8487 121.059C47.2809 120.118 48.7754 120.806 48.3154 121.733Z"
      fill="#03045E"
    />
    <path
      d="M55.2685 124.933C54.8363 125.872 53.3418 125.185 53.8018 124.257C54.2341 123.318 55.7269 124.005 55.2685 124.933Z"
      fill="#03045E"
    />
    <path
      d="M48.1571 116.082L45.5194 125.759L38.8477 124.39C38.8477 124.39 40.0194 115.454 48.9456 113.477L48.1571 116.082Z"
      fill="#047DDB"
    />
    <path
      d="M51.0703 113.335C51.0703 113.335 52.6228 115.924 54.4205 114.637C54.4156 114.637 52.26 113.109 51.0703 113.335Z"
      fill="#FFD9D9"
    />
    <path
      d="M51.0694 113.338L52.8671 114.933C50.9877 115.854 50.8562 114.821 50.4043 113.304C50.6266 113.294 50.8493 113.306 51.0694 113.338Z"
      fill="white"
    />
    <path
      d="M52.8672 114.929L53.5054 115.706C54.2481 116.379 54.6354 115.523 54.8838 114.926C54.7335 114.821 54.5771 114.724 54.4156 114.637L52.8672 114.929Z"
      fill="white"
    />
    <path
      d="M44.5465 125.562L44.1167 127.318L57.9399 132.564L57.6302 134.71L42.2986 132.493C41.3549 132.357 40.4887 131.903 39.8472 131.21C38.0667 129.592 39.0988 126.496 39.348 124.496L44.5465 125.562Z"
      fill="#FFD9D9"
    />
    <path
      d="M45.0729 126.646L38.2695 126.749L38.6013 123.668L45.2265 124.453L45.0729 126.646Z"
      fill="#FFD9D9"
    />
    <path
      d="M58.669 110.245C58.669 110.245 56.0208 109.617 55.5811 109.925C55.1415 110.233 55.725 112.499 56.3443 112.784C56.5771 112.89 56.8367 112.926 57.0904 112.888C57.3441 112.85 57.5806 112.739 57.7702 112.569C58.0153 112.348 58.669 110.245 58.669 110.245Z"
      fill="#00060F"
    />
    <path
      d="M58.0773 110.807L56.6816 110.582C56.6816 110.582 57.4138 111.193 58.0773 110.807Z"
      fill="white"
    />
    <path
      d="M72.1907 136.003C72.1907 136.003 76.1015 135.264 76.0459 135.843C76.0247 136.056 74.6037 136.484 74.6037 136.484C74.6037 136.484 77.5314 137.299 77.5314 137.513C77.5314 137.728 75.4559 139.505 74.4721 139.162C73.4883 138.819 71.3965 138.076 71.3965 138.076L72.1907 136.003Z"
      fill="#FFD9D9"
    />
    <path
      d="M57.9399 132.568C57.9399 132.568 60.9567 132.05 61.0883 132.361C61.2198 132.672 59.5545 132.992 59.5872 133.153C59.6199 133.315 62.774 134.335 62.6384 134.61C62.5027 134.885 60.0824 136.054 59.6534 135.936C59.2244 135.819 57.1465 134.643 57.1465 134.643L57.9399 132.568Z"
      fill="#FFD9D9"
    />
    <path d="M198.9 158.75H181.508V175.817H198.9V158.75Z" fill="#55C7F4" />
    <path d="M191.611 158.75H188.799V160.615H191.611V158.75Z" fill="#EBF8FF" />
    <path d="M196.629 141.684H179.236V158.75H196.629V141.684Z" fill="#55C7F4" />
    <path
      d="M189.339 141.684H186.527V143.549H189.339V141.684Z"
      fill="#EBF8FF"
    />
    <path
      opacity="0.25"
      d="M196.629 158.75L181.508 161.86V158.75H196.629Z"
      fill="#047DDB"
    />
    <path d="M203.278 141.684H196.629V158.75H203.278V141.684Z" fill="#E6F3FD" />
    <path d="M205.485 158.75H198.836V175.817H205.485V158.75Z" fill="#E6F3FD" />
    <path
      d="M0 175.858C70.6115 175.59 144.377 175.588 214.984 175.858C144.373 176.129 70.6074 176.127 0 175.858Z"
      fill="#03045E"
    />
  </SvgIcon>
);
export default NothingToShowIcon;
