import { ServiceHistoryItem } from 'src/api/api-types/services';
import { Service } from './service';

export class ServiceHistory extends Service {
  readonly serviceId: number;
  readonly activated: string;
  readonly deactivated: string;
  constructor(props: ServiceHistoryItem) {
    super(props);
    this.serviceId = props.serviceId;
    this.activated = props.activated;
    this.deactivated = props.deactivated;
  }
}
