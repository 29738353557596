import { GridRowId } from '@mui/x-data-grid-pro';
import { EFGItem } from 'src/models/edible-food/efg-item';
import { EFRItem } from 'src/models/edible-food/efr-item';

import { ExportDashboardFormType } from '../api/exportTypes';

export type ReportStatus = 'closed' | 'warning' | 'info';
export type ReportResolution = 'completed' | 'notStarted' | 'postponed';

export type ListResponseType<T> = {
  items: T[];
};

export interface ItemType {
  id: number;
  name: string;
}

export type ItemDescType = ItemType & {
  description: string;
};

export interface ItemValueType {
  id: number | string;
  name: string;
  value?: any;
}

export interface FoodRecoveriesType {
  id: number;
  name: string;
  type?: Partial<ItemType>;
}

export type RequestPaginationParams = {
  page: number;
  pageSize: number;
  totalCount?: number;
  totalPages?: number;
};

export interface Pageable {
  pagination: Pagination;
  totalCount: number;
  totalPages: number;
}

export interface Pagination {
  num: number;
  size: number;
}

//todo change all this type on ListResponseType
export type MinervaPageableResponse<T> = T & Pageable;

export enum FileStatus {
  PENDING = 0,
  LOADING = 1,
  DONE = 2,
  FAILED = 3,
}

export enum ExportTypes {
  ALL = 0,
  WITH_FILTERS = 1,
  CURRENT_PAGE = 2,
  SELECTED = 3,
  QUICK = 4
}

export interface GeneratorParams {
  id: number;
}

export interface AccountParams {
  id: number;
}

export interface FoodParams {
  id: number;
}

export type ExportParams = {
  fileName?: string | null;
  q: any;
  columns?: string[];
  ids: GridRowId[],
  page: { num: number; size: number } | null;
};

export interface AsyncExportResponse {
  id: string;
  createdAt: string;
  state: ItemType;
  errors: [];
  fileName: string;
}

export interface ParamsWithFilters<T> {
  q?: Partial<T>;
  page: { num: number; size: number };
}

export type CommonObj = { [key: string]: any };

export type ClickOnLegendType = (
  filterId: string,
  legendTitle: string,
  chartTitle: string,
  pointAmmount?: number,
  date?: string,
) => void;

export type ClickOnPointType = (
  pointId: string,
  ...args: Parameters<ClickOnLegendType>
) => void;

export type ExportCallBackType = (data: ExportDashboardFormType) => void;

export type DownloadTokenResponse = { token: string } & Pageable;

export type FullDescriptionResponse = { description: string } & Pageable;

export interface RenderCellComponentProps {
  id: number | string;
  hasFocus: boolean;
  row: EFGItem | EFRItem;
}
