import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';

export const GRID_CHECKBOX_SELECTION_COL = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  headerName: 'Checkbox',
  sortable: false,
  filterable: false,
};

export const GRID_ROW_HEIGHT = 35;
