import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const RedIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx="24" cy="24" r="24" fill="#FCE3E1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 12C12.8954 12 12 12.8954 12 14L12 34C12 35.1046 12.8954 36 14 36H34C35.1046 36 36 35.1046 36 34V14C36 12.8954 35.1046 12 34 12L14 12Z"
      fill="#F07167"
    />
    <path
      d="M16.9277 28.2434L19.7562 31.0718L23.9984 26.8296L28.2417 31.073L31.0702 28.2446L26.8268 24.0012L31.0699 19.7581L28.2414 16.9297L23.9984 21.1728L19.7565 16.9309L16.928 19.7593L21.1699 24.0012L16.9277 28.2434Z"
      fill="white"
    />
  </SvgIcon>
);
export default RedIcon;
