import { PropsWithChildren, useContext } from 'react';
import { StyledLink } from '.';
import { authStoreCtx } from 'src/stores/auth';

interface Props {
  to: string;
}

export function EmailReportLink({
  to,
  children,
}: PropsWithChildren<Props>) {
	const { fCfg } = useContext(authStoreCtx);
  	if (!to) return null;
	if(fCfg?.isEmailLinks)
		return <StyledLink href={`mailto:${to}`}>{children}</StyledLink>
	else 
		return <>{children}</>
}
