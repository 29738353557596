import { lazy } from 'react';

import { lookupsStore } from 'src/stores/lookups';
import { MinervaRoute } from 'src/utils/router';

import { assetsStore } from './store';

export interface AssetsParams {
  implementationRecordSubCategoryId?: string;
}

export const AssetsRoute: MinervaRoute<'assets', AssetsParams> = {
  name: 'assets',
  path: '/assets?parentLocation',
  comp: () => lazy(() => import('./index')),

  activate: async (_, params) => {
    assetsStore.filter.resetFilters();
    assetsStore.filter.resetPersistedFilters();
    assetsStore.loading = true;
    try {
      if (!!params?.implementationRecordSubCategoryId) {
        assetsStore.filter.setFilterValue({
          'q.implementationRecordSubCategory.id':
            params?.implementationRecordSubCategoryId,
        });
      }
      await assetsStore.fetchAssets();
    } finally {
      assetsStore.loading = false;
    }
  },
  deactivate: () => {
    assetsStore.resetStore();
    lookupsStore.resetJurisdictions();
  },
};
