import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const InsipidPhysicalSpaceIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x="0.5" width="40" height="40" rx="20" fill="#E5E9ED" />
    <g clipPath="url(#clip0_3494_48652)">
      <path
        d="M31.1667 9.33203H9.83333V11.9987H31.1667V9.33203ZM32.5 22.6654V19.9987L31.1667 13.332H9.83333L8.5 19.9987V22.6654H9.83333V30.6654H12.5V22.6654H23.1667H28.5V30.6654H31.1667V22.6654H32.5Z"
        fill="#767D86"
      />
      <path
        d="M16.0562 18.1111C14.957 18.1111 14.054 18.9512 13.9543 20.0243C13.2877 20.3792 12.834 21.0811 12.834 21.8889V24.1111C12.834 24.9152 13.2836 25.6144 13.9451 25.9708V27.4444C13.9451 27.9762 14.1417 28.4621 14.4663 28.8333C14.1417 29.2045 13.9451 29.6904 13.9451 30.2222C13.9451 31.3882 14.8903 32.3333 16.0562 32.3333C17.2221 32.3333 18.1673 31.3882 18.1673 30.2222C18.1673 29.9893 18.1296 29.7651 18.0599 29.5556H22.9414C22.8717 29.7651 22.834 29.9893 22.834 30.2222C22.834 31.3882 23.7792 32.3333 24.9451 32.3333C26.111 32.3333 27.0562 31.3882 27.0562 30.2222C27.0562 29.6904 26.8596 29.2045 26.535 28.8333C26.8596 28.4621 27.0562 27.9762 27.0562 27.4444V25.9708C27.7177 25.6144 28.1673 24.9152 28.1673 24.1111V21.8889C28.1673 21.0811 27.7136 20.3792 27.047 20.0243C26.9473 18.9512 26.0443 18.1111 24.9451 18.1111L23.4715 18.1111C23.115 17.4496 22.4159 17 21.6118 17H19.3895C18.5854 17 17.8863 17.4496 17.5298 18.1111L16.0562 18.1111ZM18.1673 24H22.834V24.2222H18.1673V24Z"
        fill="#767D86"
        stroke="#E5E9ED"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_3494_48652">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(4.5 4)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default InsipidPhysicalSpaceIcon;
