import * as React from 'react';

import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { unstable_useId as useId, useForkRef } from '@mui/material/utils';
import {
  GridDensity,
  GridMenu,
  gridDensityValueSelector,
  useGridSelector,
  GridDensityOption,
  useGridApiContext,
  useGridRootProps,
} from '@mui/x-data-grid-pro';

export const DensityButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function DensityButton(props, ref) {
    const { onClick, ...other } = props;
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const densityValue = useGridSelector(apiRef, gridDensityValueSelector);
    const densityButtonId = useId();
    const densityMenuId = useId();

    const [open, setOpen] = React.useState(false);
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const handleRef = useForkRef(ref, buttonRef);

    const densityOptions: GridDensityOption[] = [
      {
        icon: <rootProps.slots.densityCompactIcon />,
        label: apiRef.current.getLocaleText('toolbarDensityCompact'),
        value: 'compact' as GridDensity,
      },
      {
        icon: <rootProps.slots.densityStandardIcon />,
        label: apiRef.current.getLocaleText('toolbarDensityStandard'),
        value: 'standard' as GridDensity,
      },
      {
        icon: <rootProps.slots.densityComfortableIcon />,
        label: apiRef.current.getLocaleText('toolbarDensityComfortable'),
        value: 'comfortable' as GridDensity,
      },
    ];

    const starticon = React.useMemo<React.ReactElement>(() => {
      switch (densityValue) {
        case 'compact' as GridDensity:
          return <rootProps.slots.densityCompactIcon />;
        case 'comfortable' as GridDensity:
          return <rootProps.slots.densityComfortableIcon />;
        default:
          return <rootProps.slots.densityStandardIcon />;
      }
    }, [densityValue, rootProps]);

    const handleDensitySelectorOpen = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      setOpen(true);
      onClick?.(event);
    };
    const handleDensitySelectorClose = () => setOpen(false);
    const handleDensityUpdate = (newDensity: GridDensity) => {
      apiRef.current.setDensity(newDensity);
      setOpen(false);
    };

    // Disable the button if the corresponding is disabled
    if (rootProps.disableDensitySelector) {
      return null;
    }

    const densityElements = densityOptions.map<React.ReactElement>(
      (option, index) => (
        <MenuItem
          key={index}
          onClick={() => handleDensityUpdate(option.value)}
          selected={option.value === densityValue}>
          <ListItemIcon>{option.icon}</ListItemIcon>
          {option.label}
        </MenuItem>
      ),
    );

    return (
      <React.Fragment>
        <IconButton
          ref={handleRef}
          size="small"
          aria-label={apiRef.current.getLocaleText('toolbarDensityLabel')}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          aria-controls={densityMenuId}
          id={densityButtonId}
          {...other}
          onClick={handleDensitySelectorOpen}
          {...rootProps.slotProps?.baseButton}>
          {starticon}
        </IconButton>
        <GridMenu
          open={open}
          target={buttonRef.current}
          onClickAway={handleDensitySelectorClose}
          position="bottom-start">
          <MenuList
            id={densityMenuId}
            aria-labelledby={densityButtonId}
            autoFocusItem={open}>
            {densityElements}
          </MenuList>
        </GridMenu>
      </React.Fragment>
    );
  },
);

export default DensityButton;
