import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { userStore } from '../../stores/user';
import {
  ColumnsVisibilityModel,
  ColumnVisibleType,
  GridColumnsManagerParams,
} from '../../api/api-types/gridColumnsManager';
import { authStore } from 'src/stores/auth';

export class GridColumnsManager<T> {
  readonly columns: GridColDef<T>[];
  readonly columnsMap:
    | Partial<ColumnVisibleType<T>>
    | (() => Partial<ColumnVisibleType<T>>);
  readonly visibilityModel: Partial<ColumnsVisibilityModel>;

  @observable hiddenColumns: IObservableArray<string> = observable([]);
  @observable columnVisibilityModel: GridColumnVisibilityModel = {};

  constructor({
    columns,
    columnsMap,
    visibilityModel,
  }: GridColumnsManagerParams<T>) {
    makeObservable(this);
    this.columns = columns;
    this.columnsMap = columnsMap;
    this.visibilityModel = visibilityModel;
    this.columnVisibilityModel = this.columnsVisibilityModel;
  }

  @computed get gridColumns(): GridColDef<T>[] {
    let columns = this.columns;
    if (userStore.isJurisdiction) {
      columns = this.generateColumns('jurisdiction');
    }
    if (userStore.isHauler) {
      columns = this.generateColumns('hauler');
    }
    if (userStore.isAgency) {
      columns = this.generateColumns('agency');
    }
    if (userStore.isAdmin) {
      columns = this.generateColumns('admin');
    }
    if (!authStore.fCfg.isEFRPPhase2)
      columns = columns.filter(({ field }) => {
        return field !== 'info';
      });
    if (!authStore.fCfg.isAgencyWorkspace)
      columns = columns.filter(({ field }) => {
        return field !== 'agency';
      });
    if (!authStore.fCfg.isInactiveLists)
      columns = columns.filter(({ field }) => {
        return field !== 'isActive';
      });

    if (!authStore.fCfg.isGeneratorType)
      columns = columns.filter(({ field }) => {
        return field !== 'types';
      });

    return columns;
  }

  @computed get columnsVisibilityModel(): GridColumnVisibilityModel {
    const { admin, jurisdiction, hauler, agency } = this.visibilityModel;
    if (userStore.isJurisdiction && jurisdiction) return jurisdiction;
    if (userStore.isHauler && hauler) return hauler;
    if (userStore.isAgency && agency) return agency;
    return admin || {};
  }

  @action setHiddenColumns = (model: GridColumnVisibilityModel) => {
    runInAction(() => {
      const shownColumns = Object.keys(model).filter(key => model[key]);

      let columns = this.gridColumns.filter(
        value => value.field !== '__check__',
      );
      Object.keys(model).forEach(key => {
        columns = columns.filter(({ field }) => field !== key);
      });

      if (authStore.fCfg.isExportFilteredColumns) {
        this.hiddenColumns.replace([
          ...columns.map(column => column.field),
          ...shownColumns,
        ]);
      }
    });
  };

  @action setColumnVisibilityModel = (model: GridColumnVisibilityModel) => {
    this.columnVisibilityModel = model;
  };

  private generateColumns = (role: keyof ColumnVisibleType<T>) => {
    return this.columns.filter(({ field }) => {
      let columnMap: Partial<ColumnVisibleType<T>>;
      if (typeof this.columnsMap === 'function') {
        columnMap = this.columnsMap();
      } else {
        columnMap = this.columnsMap;
      }
      const columnState = columnMap?.[role]?.[field as keyof T];

      if (typeof columnState === 'boolean') return columnState;
      return true;
    });
  };
}
