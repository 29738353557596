import { useContext } from 'react';

import { observer } from 'mobx-react-lite';
import { useWatch } from 'react-hook-form';

import FormTextField from '../../../../../../components/FormTextFields/FormTextField';
import { accountDetailsEFGStoreCtx } from '../../store';

export type NameType =
  | 'sqFeet'
  | 'beds'
  | 'rooms'
  | 'seats'
  | 'onSiteFoodFacility';
export const namesMap: Record<NameType, string> = {
  sqFeet: 'sqFeetEnabled',
  beds: 'bedsEnabled',
  rooms: 'roomsEnabled',
  seats: 'seatsEnabled',
  onSiteFoodFacility: 'onSiteFoodFacilityEnabled',
};

const DependingField = ({
  name,
  title,
}: {
  name: NameType;
  title?: string;
}) => {
  const type = useWatch({
    name: 'edibleFoodGeneratorType',
  });
  const { fetchEfgTier } = useContext(accountDetailsEFGStoreCtx);

  if (!type) {
    return null;
  }
  if (type[namesMap[name]]) {
    return (
      <FormTextField
        name={name}
        title={title}
        type="number"
        gridProps={{ xs: 3 }}
        onInputChange={value => fetchEfgTier({ [name]: value })}
      />
    );
  }
  return null;
};

export default observer(DependingField);
