import { observable, action, runInAction, makeObservable } from "mobx";

import { FilesApi } from "src/api/files";

export class UploadFileAssetToken  {
  readonly api = new FilesApi();
  @observable customToken: undefined | string;
  constructor(){
    makeObservable(this);
  }
  @action setUploadToken = (customToken: string) => 
    this.customToken = customToken;
  

  @action getUploadToken =  async (assetId: number) => {
    if(!assetId) return;
    const res = await this.api.getUploadToken(assetId);
    runInAction(() => res.data?.token && this.setUploadToken(res.data?.token));
    return res;
  }
}