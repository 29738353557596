import {
	action,
	computed,
	makeObservable,
	observable
} from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { LinkedFileResponse, UploadClientLogoResponse } from 'src/api/api-types/files';
import { ClientLogoResponse, ClientLogoType } from 'src/api/api-types/clients';
import { ClientsApi } from 'src/api/clients';
import { ClientLogoTokens } from './client-logo-tokens';

export class ClientLogo {
	readonly clientId: number;
	public canUpdate?: boolean;
	public fileServiceId?: number;
	public id?: number;
	public name?: string;
  
	readonly api = new ClientsApi();
	@observable.ref logoTokens: ClientLogoTokens;
	@observable.ref fileReq: ApiReq<Blob> = emptyValue;
	@observable.ref linkLogoReq: ApiReq<LinkedFileResponse> = emptyValue;
	@observable.ref logoReq: ApiReq<ClientLogoResponse> = emptyValue;

	constructor({
		clientId, 
		canUpdate, 
		fileServiceId,
		id,
		name
	}: ClientLogoType) {
		makeObservable(this);
		this.clientId = clientId;
		this.canUpdate = canUpdate || true;
		this.fileServiceId = fileServiceId;
		this.id = id;
		this.name = name;
		this.logoTokens = new ClientLogoTokens(clientId);
	}

	@computed get isLoading() {
		return [this.fileReq, this.logoReq, this.linkLogoReq].some(({state}) => state === 'pending') || this.logoTokens.isLoading;
	  }

	@computed get logoData() {
		if (this.logoReq.state !== 'fulfilled' || !this.logoReq.value) return null;
		return this.logoReq.value.data || null;
	}

	@computed get src() {
		if (this.fileReq.state === 'fulfilled' && !!this.fileReq.value?.data) {
		  return window.URL.createObjectURL(
			new Blob([this.fileReq.value.data as Blob]),
		  );
		}
		return '';
	}
	
	@action setData = async (data: Partial<ClientLogoType>) => {
		this.canUpdate = data.canUpdate || true;
		this.fileServiceId = data.fileServiceId;
		this.id = data.id;
		this.name = data.name;
	}

	@action getLogoTokens = async () => {
		if(!this.id) return;
		this.logoTokens = new ClientLogoTokens(this.id);
	}
	  
	@action async fetchSrc() {
		if (!this.logoTokens.downloadingLogoToken) return;
		this.fileReq = this.api.getLogoUrl(this.logoTokens.downloadingLogoToken);
		await this.fileReq;
	}

	@action linkLogo = async (file: UploadClientLogoResponse) => {
		if (!this.clientId) return;
		this.linkLogoReq = this.api.linkLogoToClient(this.clientId, file)
		await this.linkLogoReq;
		return this.linkLogoReq;
  	};

	@action downloadLogo = async () => {
		if(!this.src) await this.fetchSrc();
		const element = document.createElement('a');
		element.setAttribute('href', this.src);
		element.setAttribute('download', this.name || 'logo');
		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		element.remove();
	};
	  
}
