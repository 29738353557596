import { ChangeEvent, useContext } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { ItemType } from 'src/types';
import { isPending } from 'src/utils/common';

interface ContactTypeFieldProps {
  id: number | string;
  value: ItemType;
}

export const ContactTypeField = observer(
  ({ id, value }: ContactTypeFieldProps) => {
    const { contactTypesReq, contactTypesLookup, fetchContactTypes } =
      useContext(lookupsStoreCtx);
    const apiRef = useGridApiContext();

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
      await apiRef.current.setEditCellValue({
        id,
        field: 'type',
        value: { id: event.target.value },
      });
    };

    const onFocusAction = async () => {
      if (contactTypesLookup.length === 0) await fetchContactTypes();
    };

    return (
      <TextField
        fullWidth
        select
        value={value.id}
        onChange={handleChange}
        InputProps={{
          endAdornment: isPending(contactTypesReq) && <CircularProgress />,
        }}
        onFocus={onFocusAction}>
        {contactTypesLookup.length !== 0 ? (
          contactTypesLookup.map(({ id, name }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem key={value?.id} value={value?.id}>
            {value?.name}
          </MenuItem>
        )}
      </TextField>
    );
  },
);
