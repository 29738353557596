import { GridRowId } from '@mui/x-data-grid-pro';
import {
  ItemType,
  ListResponseType,
  Pageable,
  RequestPaginationParams,
} from 'src/types';
import { AddressItem, ShortGeneratorItem } from './generators';
import { AccountLookup } from './lookups';
import { AddressInclude, ContactAddress } from './address-book';

export interface AccountItem extends ContactAddress {
  id: number;
  name: string;
  agency: ItemType | null;
  accountNumber: string;
  typeOfBusiness: string;
  naicsCode: string;
  reference: string;
  isActive: boolean;
  isParent: boolean;
  isRelated: boolean;
  canEdit: boolean;
  numberOfUnits: number | null;
  accountAddress: ItemType;
  location: Generator | null;
  type: Type;
  origin: Origin;
  jurisdiction: Jurisdiction;
  hauler: Hauler;
  createdAt: Date;
  createdBy: ItemType;
  parent: AccountLookup | null;
  parentId: number | null;
  extensionField1: ItemType | null;
  extensionField2: ItemType | null;
  naicsDescription: string | null;
  populationDensity: string | null;
  watershed: string | null;
  zone: ItemType | null;
  dataDate: string;
  nickname: string;
}

export interface EdibleFoodItem {
  reference: string;
  id: number;
  name: string;
  hauler: Hauler;
  location: Generator;
  tier: ItemType;
  type: ItemType;
  produceEdibleRecoverableFood: boolean;
  foodRecoveries: ItemType[];
  createdAt: string;
}

export type AccountAddressField = Pick<AccountItem, AddressInclude>;

export interface Generator extends ItemType {}

export interface Type extends ItemType {}

export interface Type extends ItemType {}

export interface Origin extends ItemType {}

export interface Jurisdiction extends ItemType {}

export interface Hauler extends ItemType {}

type Exclude = 'location' | 'type' | 'hauler';

export interface AccountDetailsItem extends Omit<AccountItem, Exclude> {
  description: string;
  jurisdiction: ItemType;
  location: Partial<ShortGeneratorItem> | null;
  address: AddressItem;
  type: ItemType | null;
  hauler: ItemType | null;
  isActive: boolean;
  addressGeneratorId: number | null;
}

type EditFields = 'id' | 'naicsCode' | 'typeOfBusiness' | 'numberOfUnits';

export interface AccountEditFields extends Pick<AccountItem, EditFields> {
  type: { id: number } | null;
}

type CreateFields = 'name' | 'naicsCode' | 'numberOfUnits';

export interface AccountCreationType extends Pick<AccountItem, CreateFields> {
  id?: number;
  location: { id?: number } | null;
  type: ItemType | null;
  hauler: ItemType | null;
}

export type AccountDetailsResponse = AccountDetailsItem | null | undefined;

export type AccountsResponse = ListResponseType<AccountItem> & Pageable;

export type AccountRelated = ListResponseType<AccountLookup> & Pageable;

export type AccountChildren = AccountRelated;

export interface RequestAccountsParams extends RequestPaginationParams {
  filters?: Partial<AccountsFilter>;
}

export type AccountsFilter = {
  'q.name': string;
  'q.accountNumber': string;
  'q.naicsCode': string;
  'q.naicsDescription': string;
  'q.watershed': string;
  'q.agencies'?: string;
  'q.active': boolean;
  'q.activeMode': string;
  'q.type.id': string;
  'q.types'?: string;
  'q.zone.id'?: string;
  'q.zones'?: string;
  'q.hauler.id': string;
  'q.haulers'?: string;
  'q.location.id': string;
  'q.typeOfBusiness': string;
  'q.usaAddress': string;
  'q.populationDensity': string;
  'q.jurisdiction.id'?: string;
  'q.jurisdictions'?: string;
  'q.extensionField1.id'?: string;
  'q.extensionFields1'?: string;
  'q.extensionField2.id'?: string;
  'q.extensionFields2'?: string;
  'q.location.reference': string;
  'q.reference': string;
};

export interface AccountContactsItem {
  id: number;
  name: string;
  contacts: ItemType;
  type: ItemType;
}

export interface AccountHaulerItem {
  id: number;
  name: string;
  shortName: string;
  state: ItemType;
  createdAt: Date;
}

export type AccountContactsResponse = ListResponseType<AccountContactsItem>;
export type AccountHaulerResponse = ListResponseType<AccountHaulerItem>;
export type AccountGroupType = 'parent' | 'relatedTo' | null;

export enum AccountsTypes {
  'Commercial' = 1,
  'Multifamily' = 2,
  'NonLocalEntity' = 3,
  'Other' = 4,
}

export type AccountsGroupParams = {
  generatorId: number;
  relatedGeneratorIds: GridRowId[];
};

export type CratedRelationType = {
  generatorId: number;
  relatedId: number;
};
