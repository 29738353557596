import { action, computed, makeObservable, observable } from 'mobx';
import { ImportFileValue, ImportValueItem, ImportValueOption } from 'src/api/api-types/import';
import { ImportApi } from 'src/api/import';
import { ItemType } from 'src/types';

export class ImportMergeColumn {
  api = new ImportApi();

  readonly id: number;
  readonly name: string;
  readonly type: ItemType;

  @observable values: ImportValueItem[] = [];
  @observable options: ImportValueOption[] = [];
  @observable openedSelectId?: number;

  constructor({
                id,
                name,
                type,
                values,
                options
              }: ImportFileValue) {
    makeObservable(this);
    this.id = id;
    this.name = name;
    this.type = type;
    this.values = values;
    this.options = options;
  }

  @computed get isAllValuesMerged() {
    return this.values.every(({selectedOptionId, templateValue}) => {
      const option = this.options.find(x => x.id === selectedOptionId);
      if (!option && !templateValue) return false;
      if (option?.valueOption && !templateValue) return false;
      return true;
    });
  }

  @action chooseOpenedValue = (openedId?: number) => this.openedSelectId = openedId;

  @action setDestinationSelectedOption = (valueId: number, selectedOptionId: string | null) => {
    const value = this.values.find(({id}) => id === valueId);
    if (!value) return;

    const option = this.options.find(x => x.id === selectedOptionId);
    if (!option) {
      return;
    }

    if (option.value) {
      value.templateValue = option.value;
    }
    value.valueTypeId = option.valueTypeId;
    value.selectedOptionId = selectedOptionId;
  };

  @action setDestinationValue = (valueId: number, data: string) => {
    const value = this.values.find(({id}) => id === valueId);
    if (!value) return;
    if(value.valueTypeId === 0) value.valueTypeId = 1;
    value.templateValue = String(data);
  };
}
