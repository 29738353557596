import { AccountsApi } from '../../api/account';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  AccountDetailsItem,
  AccountItem,
  AccountsFilter,
  AccountsResponse,
} from '../../api/api-types/accounts';
import { TableFilter } from '../filter/table-filter';
import { accountFilterNameMap, AccountsExportColumnNameMap, AccountsExportNameMapType } from '../filter/constants';
import { ApiReq, emptyValue } from '../../api';
import { GridRowId } from '@mui/x-data-grid-pro';
import { Account } from './account';
import { AccountModification } from './account-modification';
import {
  accountDefaultValue,
  defaultFilterField,
  visibilityDefaultState,
} from '../../pages/Accounts/helpers/common';
import { AxiosResponse } from 'axios';
import { GeneratorDetailsResponse } from '../../api/api-types/generators';
import { ExportDataType } from '../../api/exportTypes';
import { columns } from 'src/pages/Accounts/helpers/gridColumns';
import { TablePagination } from '../pagination/pagination';
import { AccountsSetUp } from './accounts-set-up';
import { GridColumnsManager } from '../grid-columns-manager/grid-columns-manager';
import { returnIds } from '../../utils/common';
import { lookupsStore } from '../../stores/lookups';
import { AsyncExportEntity } from '../export/AsyncExportEntity';
import { ExportTypes } from 'src/types';

//todo need refactoring create one store and component for Accounts same realization like in src/components/AssetsTable
export class AccountsModel {
  api = new AccountsApi({ prefix: '' });

  @observable isCreation = false;
  @observable loading: boolean = false;

  @observable selectedAccounts: Array<Account> = [];
  @observable accountsSetUp = new AccountsSetUp();
  @observable accountsReq: ApiReq<AccountsResponse> = emptyValue;
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable.ref accounts: IObservableArray<Account> = observable([]);
  @observable.ref chosenAccount?: AccountModification;
  @observable pagination = new TablePagination<AccountsResponse>({
    requestRef: this.accountsReq,
  });
  @observable.ref columnsManager;
  @observable filterModel?: TableFilter<AccountsFilter, AccountItem>;

  constructor() {
    makeObservable(this);
    this.pagination.setFetchingCallBack(this.fetchAccounts);
    this.accountsSetUp.setFetchingCallBack(this.fetchAccounts);
    this.accountsSetUp.setRemoveItemCallBack(this.removeSelectedItem);
    this.accountsSetUp.setResetItemsCallBack(this.removeSelectedItems);

    this.columnsManager = new GridColumnsManager<AccountItem>({
      columns,
      columnsMap: this.columnsVisibleMap,
      visibilityModel: visibilityDefaultState,
    });
  }

  @computed get filter() {
    this.filterModel ??= new TableFilter<AccountsFilter, AccountItem>(
      this.columnsManager?.gridColumns,
      defaultFilterField(),
      accountFilterNameMap,
      (column, filters) => this.api.getAccountsFilterOptions({ filters, column })
    );
    return this.filterModel;
  }

  @computed get columnsVisibleMap() {
    return {
      jurisdiction: {
        jurisdiction: false,
      },
    };
  }

  @computed get columnsWithoutHidden() {
    return this.columnsManager.hiddenColumns;
  }

  @computed get isInitCallPending() {
    return (
      this.accountsReq.state !== 'fulfilled' &&
      lookupsStore.haulersLookupsReq.state !== 'fulfilled' &&
      lookupsStore.accountTypeLookupReq.state !== 'fulfilled' &&
      lookupsStore.mergedExtensionFields1Req.state !== 'fulfilled' &&
      lookupsStore.mergedExtensionFields2Req.state !== 'fulfilled'
    );
  }

  @computed get accountsData() {
    if (this.accountsReq.state !== 'fulfilled' || !this.accountsReq.value) {
      return [];
    }
    return this.accountsReq.value.data?.items || [];
  }

  @action fetchAccounts = async () => {
    this.accountsReq = this.api.getAccountsData({
      ...this.pagination.serialize,
      filters: this.filter.filterParams,
    });
    await this.accountsReq;

    runInAction(() => {
      if (
        this.accountsReq.state !== 'fulfilled' ||
        this.accountsReq.value?.data === null
      )
        return;
      this.accounts.replace(
        this.accountsData.map(account => {
          return new Account(account);
        }),
      );
      this.pagination.setPagination(this.accountsReq.value?.data);
    });
    return this.accountsReq;
  };

  @action createNewAccount = (data?: Partial<AccountDetailsItem>) => {
    this.isCreation = true;
    this.chosenAccount = new AccountModification(data || accountDefaultValue);
  };

  @action setSelectedAccounts = (items: GridRowId[]) => {
    const chosenAccounts = this.accounts.filter(({ id }) => items.includes(id));
    const accounts = [
      ...new Set([...this.selectedAccounts, ...chosenAccounts]),
    ].filter(({ id }) => items.includes(id));
    this.selectedAccounts = accounts;
    this.accountsSetUp.setGroupedItems(accounts);
  };

  @action removeSelectedItem = (selectedId: number) => {
    const accounts = this.selectedAccounts.filter(
      ({ id }) => id !== selectedId,
    );
    this.selectedAccounts = accounts;
    this.accountsSetUp.setGroupedItems(accounts);
  };

  @action removeSelectedItems = () => {
    this.selectedAccounts = [];
    this.accountsSetUp.setGroupedItems([]);
  };

  @action finishCreation = async () => {
    this.isCreation = false;
    this.removeTargetAccount();
    await this.fetchAccounts();
  };

  @action cancelCreation = () => {
    this.isCreation = false;
    this.removeTargetAccount();
  };

  @action afterCreationGenerator = async (
    reqResponse?: AxiosResponse<GeneratorDetailsResponse>,
  ) => {
    if (!reqResponse?.data) return;
    const generatorRes = reqResponse?.data;
    if (this.isCreation && generatorRes) {
      this.chosenAccount?.setGenerator({
        id: generatorRes.id,
        name: generatorRes.usaAddress,
      });
    }
  };

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    if (Number(data.mode) === ExportTypes.ALL) this.exportEntity.setExportWithUnactiveRecords(true);
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncReportByType(
        this.exportEntity.exportParams<
          AccountsResponse,
          AccountsFilter,
          AccountItem
        >(
          this.columnsManager.hiddenColumns.map(el => AccountsExportColumnNameMap[el as AccountsExportNameMapType] || el),
          this.filter,
          this.pagination,
          returnIds(this.selectedAccounts)
        ),
        data.type,
      )
    );
  };

  @action removeTargetAccount = () => (this.chosenAccount = undefined);

  @action resetStore = () => {
    this.pagination.resetPagination();
    this.filter.resetFilters();
    this.filter.setSearchOpen(false);
    this.accountsReq = emptyValue;
    this.accounts.replace([]);
    this.removeSelectedItems();
  };
}
