import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExcelIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.17138 0C2.62662 0 2.18091 0.45 2.18091 1V15C2.18091 15.55 2.62662 16 3.17138 16H13.0761C13.6208 16 14.0665 15.55 14.0665 15V4L10.1047 0H3.17138Z"
      fill="#E2E5E7"
    />
    <path
      d="M11.0952 4H14.0666L10.1047 0V3C10.1047 3.55 10.5504 4 11.0952 4Z"
      fill="#B0B7BD"
    />
    <path d="M14.0664 7L11.095 4H14.0664V7Z" fill="#CAD1D8" />
    <path
      d="M12.0856 13C12.0856 13.275 11.8627 13.5 11.5903 13.5H0.695185C0.422807 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422807 7.5 0.695185 7.5H11.5903C11.8627 7.5 12.0856 7.725 12.0856 8V13Z"
      fill="#84BD5A"
    />
    <path
      d="M3.67698 10.1935L4.36585 9.322C4.57186 9.05 4.97201 9.3975 4.74619 9.6575C4.50897 9.9415 4.25937 10.2495 4.02166 10.565L4.82939 11.5805C5.04729 11.8805 4.61198 12.168 4.39804 11.872L3.66905 10.9285L2.95245 11.8925C2.75039 12.184 2.3027 11.8565 2.52853 11.5845L3.32437 10.565C3.07477 10.249 2.8331 9.9415 2.59192 9.6575C2.34282 9.3575 2.80586 9.05 2.98811 9.3305L3.67698 10.1935Z"
      fill="white"
    />
    <path
      d="M5.31812 9.47349C5.31812 9.34149 5.42905 9.22949 5.56771 9.22949C5.69449 9.22949 5.80146 9.34199 5.80146 9.47349V11.4765H6.8786C7.26637 11.4765 7.27479 12 6.8786 12H5.56821C5.42954 12 5.31861 11.9005 5.31861 11.7565V9.47349H5.31812Z"
      fill="white"
    />
    <path
      d="M7.62923 9.83347C7.72035 9.05747 8.88019 8.91797 9.42693 9.34197C9.69634 9.56147 9.41108 9.90947 9.17337 9.73347C8.88019 9.54597 8.21509 9.45797 8.13189 9.87297C8.02492 10.529 9.74735 10.1535 9.71961 11.217C9.69188 12.2325 8.2349 12.256 7.68816 11.8005C7.55693 11.6925 7.56138 11.517 7.6327 11.4085C7.73571 11.305 7.85011 11.269 7.9853 11.381C8.30968 11.6045 9.14564 11.7725 9.20457 11.2045C9.15356 10.6135 7.50641 10.969 7.62923 9.83347Z"
      fill="white"
    />
    <path
      d="M11.5904 13.5H2.18091V14H11.5904C11.8627 14 12.0856 13.775 12.0856 13.5V13C12.0856 13.275 11.8627 13.5 11.5904 13.5Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
export default ExcelIcon;
