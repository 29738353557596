import styled from '@emotion/styled';

import { MinervaLogo as Logo } from '../../MinervaLogo';

const RootContainer = styled('div')({
  padding: 30,
  '& > h3': {
    fontSize: '1.5rem',
    padding: '30px 0',
  },

  '& > h4': {
    fontSize: '1rem',
  },

  '& > button': {
    marginTop: 30,
  },
});
const MinervaLogo = styled(Logo)({
  width: 167,
  height: 57,
});

export { RootContainer, MinervaLogo };
