import * as React from 'react';
import { useContext } from 'react';

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { observer } from 'mobx-react-lite';
import AccountChildren from 'src/components/Accounts/AccountChildren';
import { accountsStoreCtx } from 'src/pages/Accounts/store';
import { isPending } from 'src/utils/common';

import {
  accountDetailsStore,
  accountDetailsStoreCtx,
} from '../../../../AccountDetails/store';

import Menu from './Menu';

function Relations({ id }: { id: number }) {
  const { clearAccount, account } = useContext(accountDetailsStoreCtx);

  const { fetchAccounts } = useContext(accountsStoreCtx);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    (async () => {
      await accountDetailsStore.fetchAccountDetails(id);
      await accountDetailsStore.account?.fetchRelatedAccounts();
    })();
  };

  const handleClose = () => {
    clearAccount();
    setAnchorEl(null);
  };

  const deleteHandler = async (id: number) => {
    await account?.deleteRelatedAccount(id);
    await fetchAccounts();
  };

  return (
    <Box sx={{ width: '100%', p: 0 }}>
      <IconButton
        id="isRelated"
        aria-controls={open ? 'isRelated' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label="isRelated"
        size="small"
        sx={{ p: '2px' }}
        onClick={handleClick}>
        <KeyboardDoubleArrowRightIcon
          sx={{ width: '20px', height: '20px', cursor: 'pointer' }}
          color="success"
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        title="Related To">
        {account && (
          <>
            {isPending(account.fetchRelatedReq) && <LinearProgress />}
            <AccountChildren
              list={
                account.relatedAccounts.length > 0
                  ? account.relatedAccounts
                  : []
              }
              deleteHandler={deleteHandler}
            />
          </>
        )}
      </Menu>
    </Box>
  );
}

export default observer(Relations);
