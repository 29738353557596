import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { FormProvider, useForm } from 'react-hook-form';
import CollapseFilter from 'src/components/Collapsible/CollapseFilter';
import { WithStore } from 'src/utils/store';

import GeneratorsIcon from '../../../../assets/icons/Filters/GeneratorsIcon';
import { ServiceHistoryStore } from '../../store';

import Actions from './components/Actions';
import HaulerField from './components/Fields/HaulerField';
import AccountField from './components/Fields/AccountField';
import GeneratorField from './components/Fields/GeneratorField';
import { SearchProvider } from 'src/components/SearchPanel/context';
import { FilterFields } from 'src/api/api-types/services';

const Filter = (data: WithStore<{}, ServiceHistoryStore>) => {
  const {
    filter,
    fetchServiceHistory,
    pagination: { resetPagination },
  } = data.store;
  const { isSearchOpen, setSearchOpen, resetFilters } = filter;

  const methods = useForm();

  const dataRequest = () => {
    resetPagination();
    setSearchOpen(false);
    return fetchServiceHistory();
  };

  const clear = async () => {
    await resetFilters();
    await dataRequest();
    methods.reset();
  };

  const submit = async () => await dataRequest();

  const back = () => setSearchOpen(false);

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      elevation={5}
      open={isSearchOpen}
      PaperProps={{
        style: {
          top: '82px',
        },
      }}
      sx={{
        position: 'absolute',
        minWidth: '400px',
        zIndex: 1201,
      }}>
      <FormProvider {...methods}>
        <AppBar
          position="static"
          sx={{
            minWidth: '400px',
            width: '100%',
            color: theme => theme.palette.text.primary,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <Typography variant="h5">Search Panel</Typography>
          <CloseIcon onClick={back} sx={{ cursor: 'pointer' }} />
        </AppBar>
        <div
          style={{
            padding: 16,
            height: 'calc(100vh - 221px)',
            paddingBottom: 100,
            overflowY: 'auto',
          }}>
          <CollapseFilter
            open
            title="Filters"
            Icon={GeneratorsIcon}
            disableCollapse>
            <Grid container direction="column">
            <SearchProvider<FilterFields> filterCtx={filter.filter}>
              <HaulerField />
              <AccountField />
              <GeneratorField />
            </SearchProvider>
            </Grid>
          </CollapseFilter>
        </div>
        <Divider />
        <Actions clear={clear} submit={submit} />
      </FormProvider>
    </Drawer>
  );
};

export default observer(Filter);