import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import {
  EFGDetailsRequired,
  EFGItemResponse,
  FoodGeneratorsFilter,
} from 'src/api/api-types/food-generators';
import { FoodGeneratorsApi } from 'src/api/foodGenerators';
import { EFGItem } from 'src/models/edible-food/efg-item';
import { GridRowId } from '@mui/x-data-grid-pro';

import { TableFilter } from '../filter/table-filter';
import { columns } from 'src/pages/EdibleFoodGenerators/helpers/gridColumns';
import { EFGExportColumnsNameMap, EFGHiddenColumnsType, foodGeneratorsNameMap } from '../filter/constants';
import { defaultEFGSearch } from 'src/pages/EdibleFoodGenerators/helpers/common';
import {
  OnStartEditType,
  OnUpdateAsyncType,
} from '../../components/Table/types';
import { EfgDetailsItem } from './efg-details-Item';
import { lookupsStore } from '../../stores/lookups';
import { TablePagination } from '../pagination/pagination';
import { GridColumnsManager } from '../grid-columns-manager/grid-columns-manager';
import { visibilityDefaultState } from '../../pages/EdibleFoodGenerators/helpers/common';
import { authStore } from 'src/stores/auth';
import { AsyncExportEntity } from '../export/AsyncExportEntity';
import { returnIds } from 'src/utils/common';
import { ExportDataType } from 'src/api/exportTypes';

export class EfgModel {
  api = new FoodGeneratorsApi({ prefix: '' });

  @observable isCreation = false;
  @observable loading: boolean = false;
  @observable deleteModal = false;
  @observable editeModal = false;

  @observable foodGeneratorsReq: ApiReq<EFGItemResponse> = emptyValue;
  @observable selectedEFG: Array<EFGItem> = [];
  @observable.ref foodGenerators: IObservableArray<EFGItem> = observable([]);
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable.ref chosenFood?: EfgDetailsItem;
  @observable pagination = new TablePagination<EFGItemResponse>({
    requestRef: this.foodGeneratorsReq,
  });
  @observable columnsManager;
  @observable filterModel?: TableFilter<FoodGeneratorsFilter, EFGItem>;

  constructor() {
    makeObservable(this);

    this.columnsManager = new GridColumnsManager<EFGItem>({
      columns,
      columnsMap: this.columnsVisibleMap,
      visibilityModel: visibilityDefaultState,
    });
    this.pagination.setFetchingCallBack(this.fetchEFG);
  }

  @computed get filter() {

    this.filterModel ??= new TableFilter<FoodGeneratorsFilter, EFGItem>(
      this.columnsManager?.gridColumns,
      defaultEFGSearch,
      foodGeneratorsNameMap,
      (column, filters) => this.api.getFoodGeneratorsFilterOptions({ filters, column })
    );
    return this.filterModel;
  }

  @computed get columnsVisibleMap() {
    const hiddenColumns = {
      info: false,
      description: false
    }
    return {
      ...(!authStore.fCfg.isEFRPPhase2 && {
        admin: hiddenColumns,
        jurisdiction: hiddenColumns,
        hauler: hiddenColumns,
        agency: hiddenColumns,
      })
    };
  }

  @computed get isInitialPending() {
    return (
      this.foodGeneratorsReq.state !== 'fulfilled' &&
      lookupsStore.tiersLookupsReq.state !== 'fulfilled' &&
      lookupsStore.edibleFoodGeneratorsTypesReq.state !== 'fulfilled' &&
      lookupsStore.jurisdictionsReq.state !== 'fulfilled'
    );
  }



  @computed get foodGeneratorData() {
    if (
      this.foodGeneratorsReq.state !== 'fulfilled' ||
      !this.foodGeneratorsReq.value
    ) {
      return [];
    }
    return this.foodGeneratorsReq.value.data?.items || [];
  }

  @action selectEfg = (id: number) => {
    const needle = this.foodGenerators.find(item => item.id === id);
    if (needle) {
      this.chosenFood = new EfgDetailsItem(needle as any);
      return needle;
    }
    return undefined;
  }

  @action fetchEFG = async () => {
    this.foodGeneratorsReq = this.api.getFoodGeneratorsData({
      ...this.pagination.serialize,
      filters: this.filter.filterParams,
    });

    await this.foodGeneratorsReq;

    runInAction(() => {
      if (
        this.foodGeneratorsReq.state !== 'fulfilled' ||
        this.foodGeneratorsReq.value?.data === null
      )
        return;
      this.foodGenerators.replace(
        this.foodGeneratorData.map(foodGenerator => {
          return new EFGItem(foodGenerator);
        }),
      );
      this.pagination.setPagination(this.foodGeneratorsReq.value?.data);
    });
    return this.foodGeneratorsReq;
  };

  @action setSelectedFoods = (items: GridRowId[]) => {
    const chosenFood = this.foodGenerators.filter(({ id }) => items.includes(id));
    this.selectedEFG = [
      ...new Set([...chosenFood, ...this.selectedEFG]),
    ].filter(({ id }) => items.includes(id));
  };

  @action removeSelectedFoods = () => {
    this.selectedEFG = [];
  };

  @action toggleDeleteModal = (force: boolean) => {
    this.deleteModal = force;
  };
  @action toggleEditeModal = (force: boolean) => {
    this.editeModal = force;
    this.isCreation = !this.isCreation;
  };

  @action startEditEfgAction = ({ rowData }: OnStartEditType<EFGItem>) => {
    const edibleFoodGeneratorType =
      lookupsStore?.edibleFoodGeneratorsTypes.find(
        ({ id }) => id === rowData?.type.id,
      );
    this.chosenFood = new EfgDetailsItem({
      ...rowData,
      edibleFoodGeneratorType,
      edibleFoodGeneratorTier: rowData?.tier,
      contact: {
        fullName: rowData?.contact?.fullName,
        phone: rowData?.contact?.phone,
        email: rowData?.contact?.email,
      },
    } as EFGDetailsRequired);
  };

  @action editEfgAction = async ({ newRow }: OnUpdateAsyncType) => {
    if (!this.chosenFood) return;
    this.chosenFood.setEntity({
      name: newRow?.name,
      contact: {
        fullName: newRow?.fullName,
        phone: newRow?.phone,
        email: newRow?.email,
      },
    });
    await this.chosenFood?.editDetails();
    this.removeChosenFood();
    await this.fetchEFG();
  };

  @action deleteEFG = async () => {
    await Promise.all(
      this.selectedEFG.map(async ({ id }) => {
        this.selectEfg(Number(id));
        await this.chosenFood?.deleteEFG();
      }),
    );
    this.chosenFood = undefined;
    this.removeSelectedFoods();
    await this.fetchEFG();
  };

  @action removeChosenFood = () => {
    this.chosenFood = undefined;
  };

  @action cancelCreation = () => {
    this.isCreation = false;
    this.removeChosenFood();
  };

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncReportByType(
        this.exportEntity.exportParams<
          EFGItemResponse,
          FoodGeneratorsFilter,
          EFGItem
        >(
          this.columnsManager.hiddenColumns.map(
            el => EFGExportColumnsNameMap[el as EFGHiddenColumnsType] || el,
          ),
          this.filter,
          this.pagination,
          returnIds(this.selectedEFG)
        ),
        data.type,
      )
    );
  };

  @action resetStore = () => {
    this.pagination.resetPagination();
    this.filter.resetFilters();
    this.filter.setSearchOpen(false);
    this.removeSelectedFoods();
  };
}
