import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { AddressBookApi } from 'src/api/AddressBook';
import { ContactType, ContactsExportParams, ContactsResponse, relatedContactData } from 'src/api/api-types/address-book';
import { RequestPaginationParams } from 'src/types';
import { PAGINATION_DEFAULT_STATE } from 'src/utils/constants';

import { storeFactory } from '../../utils/store';
import { Contact } from './models/contact';
import { TablePagination } from "../../models/pagination/pagination";
import { AccountContactsItem } from 'src/api/api-types/accounts';
import { contactsColumns } from 'src/pages/AddressBook/helpers/contactsColumns';
import { GridColumnsManager } from 'src/models/grid-columns-manager/grid-columns-manager';
import { GridRowId } from '@mui/x-data-grid-pro';
import { ExportDataType } from 'src/api/exportTypes';
import { AsyncExportEntity } from 'src/models/export/AsyncExportEntity';
import { TableFilter } from 'src/models/filter/table-filter';
import { ContactExportColumnsNameMap } from 'src/models/filter/constants';
class AddressBookStore {
  api = new AddressBookApi();

  @observable deleteContactModal: boolean = false;

  @observable requestPagination: RequestPaginationParams = PAGINATION_DEFAULT_STATE;
  @observable contactsReq: ApiReq<ContactsResponse> = emptyValue;
  @observable contactDetailReq?: any;
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable chosenContact?: Contact;
  @observable isContactEdible: boolean = false;
  @observable isCreation: boolean = false;
  @observable relatedContact?: relatedContactData;
  @observable selectedContacts: Array<GridRowId> = [];
  @observable columnsManager;
  @observable pagination = new TablePagination<ContactsResponse>({
    requestRef: this.contactsReq,
  });
  @observable filter = new TableFilter<Partial<ContactsExportParams>, ContactType>(
    contactsColumns,
    {},
    {}
  );
  constructor() {
    makeObservable(this);
    this.pagination.setFetchingCallBack(this.fetchContacts);
    this.columnsManager = new GridColumnsManager<
      AccountContactsItem
    >({
      columns: contactsColumns,
      columnsMap: {},
      visibilityModel: {},
    });
  }

  @computed get contactsData() {
    if (this.contactsReq.state !== 'fulfilled' || !this.contactsReq.value) {
      return [];
    }

    return this.contactsReq.value.data?.items || [];
  }

  @action fetchContacts = async () => {
    this.contactsReq = this.api.getContacts({
      ...this.pagination.serialize,
      'q.relatedTo.id': this.relatedContact?.relatedId,
      'q.relatedToType.id': this.relatedContact?.relatedTypeId,
    });
    await this.contactsReq;
    if (this.contactsReq.state !== 'fulfilled' || this.contactsReq.value?.data === null) return;
    this.pagination.setPagination(this.contactsReq.value?.data);
    return this.contactsReq;
  };

  @action fetchContactDetail = async (id: number) => {
    const res = await this.api.getContactDetail(id);
    runInAction(() => {
      this.chosenContact = new Contact(res);
    });
  };

  @action createNewContact = (defaultData?: Partial<ContactType>) => {
    this.chosenContact = new Contact({
      fullName: '',
      type: null,
      relatedTo: null,
      email: '',
      phone: '',
      ...defaultData,
    });
    this.isCreation = true;
  };

  @action modifyContact = async (entity: ContactType) => {
    this.chosenContact = new Contact(entity);
    await this.chosenContact.editContact();
    await this.fetchContacts();
  };

  @action deleteContact = (entity: ContactType) => {
    this.chosenContact = new Contact(entity);
    this.toggleDeleteModal();
  };

  @action confirmDelete = async () => {
    await this.chosenContact?.deleteContact();
    await this.fetchContacts();
    this.toggleDeleteModal();
  };

  @action cancelCreation = () => {
    this.isCreation = false;
    this.chosenContact = undefined;
  };

  @action changeContactEdibleState = () => this.isContactEdible = !this.isContactEdible;

  @action resetAddressBookStates = () => {
    this.isContactEdible = false;
    this.chosenContact = undefined;
  };

  @action setRelatedToContact = (relatedData: relatedContactData) => this.relatedContact = relatedData;

  @action toggleDeleteModal = () => this.deleteContactModal = !this.deleteContactModal;


  @action setSelectedContacts = (items: GridRowId[]) =>
    this.selectedContacts = items;

  @action removeSelectedContacts = () => {
    if (this.selectedContacts.length === 0) return;
    this.selectedContacts = [];
  };


  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncReportByType(
        this.exportEntity.exportParams<
          ContactsResponse,
          Partial<ContactsExportParams>,
          ContactType
        >(
          ContactExportColumnsNameMap(this.columnsManager.hiddenColumns),
          this.filter,
          this.pagination,
          this.selectedContacts
        ),
        data.type,
      )
    );
  };
}

export const { store: addressBookStore, storeCtx: addressBookStoreCtx } =
  storeFactory(AddressBookStore, 'addressBook');
