import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TotalPoundsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x="0.5" width="40" height="40" rx="20" fill="#E5E9ED" />
    <path
      d="M23.358 24.3226H22.4347V22.7841H23.3865C23.5876 22.7841 23.7593 22.815 23.9013 22.8767C24.0457 22.9384 24.157 23.0286 24.2351 23.1474C24.3133 23.2661 24.3523 23.4109 24.3523 23.5818C24.3523 23.8263 24.2694 24.0115 24.1037 24.1374C23.938 24.2608 23.6895 24.3226 23.358 24.3226Z"
      fill="#767D86"
    />
    <path
      d="M23.2444 21.559H22.4347V20.163H23.2728C23.519 20.163 23.7226 20.2235 23.8836 20.3446C24.0445 20.4633 24.125 20.6355 24.125 20.861C24.125 21.0106 24.0871 21.1376 24.0114 21.242C23.938 21.3441 23.8351 21.4225 23.7025 21.4771C23.6323 21.506 23.5566 21.5272 23.4752 21.5408C23.4028 21.553 23.3258 21.559 23.2444 21.559Z"
      fill="#767D86"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2252 11.2621C16.2252 11.6984 16.2905 12.1193 16.4119 12.5157H13.3412C12.1422 12.5157 11.1121 13.3694 10.8862 14.5504L8.54555 26.7852C8.24986 28.3311 9.43116 29.7647 11.0007 29.7647H29.9993C31.5689 29.7647 32.7501 28.3311 32.4544 26.7852L30.1139 14.5504C29.8879 13.3695 28.8577 12.5157 27.6587 12.5157H24.538C24.6594 12.1193 24.7249 11.6984 24.7249 11.2621C24.7249 8.90821 22.822 7 20.475 7C18.1279 7 16.2252 8.90821 16.2252 11.2621ZM18.2751 11.2621C18.2751 11.726 18.4146 12.1571 18.654 12.5157H22.3961C22.6353 12.1571 22.775 11.726 22.775 11.2621C22.775 10.0159 21.7676 9.0057 20.525 9.0057C19.2823 9.0057 18.2751 10.0159 18.2751 11.2621ZM14.7477 18.6103V25.9037H19.6623V24.3083H16.7221V18.6103H14.7477ZM20.4604 18.6103V25.9037H23.8836C24.4115 25.9037 24.866 25.8147 25.2471 25.6367C25.6283 25.4586 25.9218 25.2141 26.1277 24.903C26.3361 24.592 26.4402 24.2371 26.4402 23.8382C26.4402 23.494 26.3646 23.1948 26.213 22.9408C26.0615 22.6844 25.8579 22.4838 25.6023 22.3389C25.3465 22.1917 25.0625 22.1122 24.75 22.1003V22.0291C25.0317 21.9721 25.2779 21.87 25.4886 21.7229C25.6992 21.5733 25.8626 21.3881 25.9786 21.1673C26.097 20.9465 26.1562 20.7019 26.1562 20.4336C26.1562 20.0633 26.0591 19.7416 25.865 19.4685C25.6733 19.1956 25.388 18.9842 25.0092 18.8347C24.6328 18.6851 24.1677 18.6103 23.6137 18.6103H20.4604Z"
      fill="#767D86"
    />
  </SvgIcon>
);
export default TotalPoundsIcon;
