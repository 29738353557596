import React from 'react';

import {  MenuItem, ListItemIcon } from '@mui/material';
import { ExportFileType } from 'src/api/exportTypes';
import FileIcon from 'src/components/File/FileIcon';

interface Props {
  handleAction: (type: ExportFileType) => void;
}

const ExportTypesMenuItems: React.FC<Props> = ({ handleAction }: Props) => {
  return (
    <>
      <MenuItem
        id="xlsx"
        onClick={() =>
          handleAction(ExportFileType.XLSX)
        }
      >
        <ListItemIcon>
          <FileIcon type="xlsx" />
        </ListItemIcon>
        Excel
      </MenuItem>
      <MenuItem
        id="csv"
        onClick={() =>
          handleAction(ExportFileType.CSV)
        }
      >
        <ListItemIcon>
          <FileIcon type="csv" />
        </ListItemIcon>
        CSV
      </MenuItem>
    </>
  );
};

export default ExportTypesMenuItems;
