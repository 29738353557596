import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
const NotRecyclingIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect width="48" height="48" rx="24" fill="#FFD9D9" />
    <path
      d="M36.1618 23.6281C36.1618 23.6281 37.8639 25.3251 37.0816 27.1384C36.2993 28.9517 33.7303 32.425 33.7303 32.425C33.2988 30.2841 32.3679 28.2753 31.0134 26.5622L29.4805 27.4028L31.1297 20.9531L37.33 22.8034L36.1618 23.6281Z"
      fill="#F03738"
    />
    <path
      d="M32.98 33.8004C32.7143 34.236 32.3576 34.609 31.9342 34.8937C31.5109 35.1784 31.0309 35.3681 30.5273 35.4499C28.7935 35.7459 24.9824 35.5291 24.9824 35.5291L25.0511 29.7773L31.7219 29.8566C32.51 31.0238 32.9466 32.3925 32.98 33.8004Z"
      fill="#F03738"
    />
    <path
      d="M17.1649 14.7695C17.1649 14.7695 17.6935 12.4275 19.6492 12.1262C21.605 11.8248 25.9025 12.1579 25.9025 12.1579C24.3205 13.6621 23.116 15.5187 22.3874 17.5766L23.9097 18.4278L17.5666 20.4631L15.8223 14.2355L17.1649 14.7695Z"
      fill="#F03738"
    />
    <path
      d="M27.4506 12.0823C27.9608 12.0757 28.4663 12.1794 28.9325 12.3866C29.3988 12.5937 29.8147 12.8993 30.1517 13.2824C31.3252 14.5935 33.1647 17.9399 33.1647 17.9399L28.2594 20.9374L24.7812 15.2437C25.3524 13.9575 26.2784 12.8609 27.4506 12.0823Z"
      fill="#F03738"
    />
    <path
      d="M18.0234 35.4716C18.0234 35.4716 15.6871 36.0373 14.5506 34.4143C13.4141 32.7913 11.8125 28.7946 11.8125 28.7946C13.863 29.545 16.0613 29.8022 18.2296 29.5453L18.3089 27.8008L22.9446 32.5851L18.1344 36.9043L18.0234 35.4716Z"
      fill="#F03738"
    />
    <path
      d="M11.0558 27.4422C10.823 26.9888 10.6912 26.4904 10.6693 25.9811C10.6474 25.4719 10.7359 24.964 10.9289 24.4923C11.5896 22.8535 13.7727 19.7344 13.7727 19.7344L18.6304 22.8059L15.0678 28.4466C13.6619 28.502 12.2697 28.1535 11.0558 27.4422Z"
      fill="#F03738"
    />
  </SvgIcon>
);
export default NotRecyclingIcon;