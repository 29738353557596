import { action, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import {
  SubCategoryDetail,
  SubCategoryDetailType,
} from 'src/api/api-types/implementation-record';
import { ImplementationRecordApi } from 'src/api/implementationRecord';
import { SubCategory } from './subCategory';

export class Category {
  api = new ImplementationRecordApi();

  readonly id: number;
  readonly name: string;
  @observable subCategories: IObservableArray<SubCategory> = observable([]);
  @observable.ref subCategoryDetailReq?: ApiReq<SubCategoryDetail> = emptyValue;

  @observable isOpen: boolean = false;
  @observable.ref chosenSubCategory?: SubCategory = undefined;


  constructor(data: SubCategoryDetailType) {
    makeObservable(this);
    this.id = data.id;
    this.name = data.name;

    this.subCategories.replace(data.subCategories.map(subCategory => {
      return new SubCategory(subCategory)
    }))
    this.isOpen = true;
  }

  selectSubCategory(id: number) {
    const needle = this.subCategories.find(item => item.id === id);
    if (needle) {
      this.chosenSubCategory = needle;
      return needle;
    }
    return undefined;
  }


  @action fetchSubCategoryDetails = async (id: number) => {
    this.subCategoryDetailReq = this.api.getRecordSubCategoryDetails(id);
    const res = await this.subCategoryDetailReq
    runInAction(() => {
      this.subCategories.replace(this.subCategories.map(subCategory => {
        if (subCategory.id === id && res.data) return new SubCategory({
          ...subCategory,
          ...res.data
        })
        return subCategory
      }));
    })
  };



  @action toggleCollapse = () => {
    this.isOpen = !this.isOpen;
  };

  @action collapse = () => {
    this.isOpen = false;
  };

  @action expand = () => {
    this.isOpen = true;
  };
}
