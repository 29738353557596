import { ApiClient } from '.';
import {
  RequestFoodGeneratorsParams,
  FoodGeneratorDetailsResponse,
  AssignedFoodRecoveriesResponse,
  UpdateAssignedFoodRecoveries,
  EFGItemResponse,
  MarkingEFGType,
  EFGTypesMeta,
  EFGDetailsType,
  EfgTierData,
  EFGTransferringInfoTypeResponse,
  EFGTransferringInfoType,
  UpdateEFGTransferringInfoType,
  FoodGeneratorsFilter,
} from './api-types/food-generators';
import { dotNameKeyToObject } from '../utils/string';
import { AsyncExportResponse, ExportParams, ItemType, ListResponseType, Pageable, ParamsWithFilters } from '../types';
import { FullDescriptionResponse } from './api-types/food-generators';

export class FoodGeneratorsApi extends ApiClient {
  getFoodGeneratorsData({
    page,
    pageSize,
    filters,
  }: RequestFoodGeneratorsParams) {
    return this.requestPost<
      EFGItemResponse,
      ParamsWithFilters<RequestFoodGeneratorsParams>
    >('edible-food-generators/list', {
      q: dotNameKeyToObject(filters),
      page: {
        num: page,
        size: pageSize,
      },
    });
  }

  async getFoodGeneratorsFilterOptions({ filters, column }: { filters: Partial<FoodGeneratorsFilter>, column: string }) {
    const filterMap: { [key: string]: string } = {
      jurisdiction: 'jurisdiction.name',
      type: 'type.name',
      tier: 'tier.name'
    };
    const filterColumn = filterMap[column] ?? column;
    const response = await this.requestPost<
      ListResponseType<ItemType> & Pageable,
      ParamsWithFilters<FoodGeneratorsFilter> & { column: string }
    >('edible-food-generators/list/filter-options', {
      q: dotNameKeyToObject(filters),
      page: {
        num: 1,
        size: 100000,
      },
      column: filterColumn,
    });

    return response.data!.items;
  }

  getEFGTypesWithMeta(name?: string) {
    return this.requestGet<EFGTypesMeta[]>('edible-food-generators/types', {
      ...(!!name && { name }),
    });
  }

  getAssignedFoodRecoveries(id: number) {
    return this.requestGet<AssignedFoodRecoveriesResponse>(
      `edible-food-generators/${id}/assigned-food-recoveries`,
    );
  }

  getEFGTier(data?: EfgTierData) {
    return this.requestGet<ItemType>(
      'edible-food-generators/calculate-tier',
      data,
    );
  }

  getFoodGeneratorsDetails(id: number) {
    return this.requestGet<FoodGeneratorDetailsResponse>(
      `edible-food-generators/${id}`,
    );
  }

  getEFGTransferringInfo(id: number) {
    return this.requestGet<EFGTransferringInfoTypeResponse[]>(
      `edible-food-generators/${id}/transferring-info`,
    );
  }

  updateEFGTransferringInfo(
    EFGid: number,
    data: UpdateEFGTransferringInfoType[],
  ) {
    return this.requestPut<
      EFGTransferringInfoType,
      UpdateEFGTransferringInfoType[]
    >(`edible-food-generators/${EFGid}/transferring-info`, data);
  }

  updateFoodGenerator(data: MarkingEFGType) {
    return this.requestPut<EFGDetailsType, MarkingEFGType>(
      `edible-food-generators/${data.id}`,
      data,
    );
  }

  updateAssignFoodGenerator(data: UpdateAssignedFoodRecoveries) {
    return this.requestPut<
      FoodGeneratorDetailsResponse,
      Partial<UpdateAssignedFoodRecoveries>
    >(`edible-food-generators/${data.id}/food-recovery/assign`, {
      foodRecovery: data.foodRecovery,
    });
  }

  deleteEFG(accountId: number) {
    return this.requestDelete<FoodGeneratorDetailsResponse, unknown>(
      `edible-food-generators/${accountId}`,
    );
  }

  deleteGeneratorFoodRecoveries(data: UpdateAssignedFoodRecoveries) {
    return this.requestPut<
      FoodGeneratorDetailsResponse,
      Partial<UpdateAssignedFoodRecoveries>
    >(`locations/${data.id}/food-recovery/revoke`, {
      foodRecovery: data.foodRecovery,
    });
  }

  deleteFoodRecoveries(data: UpdateAssignedFoodRecoveries) {
    return this.requestPut<
      FoodGeneratorDetailsResponse,
      Partial<UpdateAssignedFoodRecoveries>
    >(`edible-food-generators/${data.id}/food-recovery/revoke`, {
      foodRecovery: data.foodRecovery,
    });
  }

  getFullDescription(id: number) {
    return this.requestGet<FullDescriptionResponse>(`edible-food-generators/${id}/description`);
  }

  getAsyncReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `edible-food-generators/export/${exportType}?flowType=2`,
      params,
    );
  }
}
