import { lazy } from 'react';

import { efgStore } from 'src/pages/EdibleFoodGenerators/store';
import { efrStore } from 'src/pages/EdibleFoodRecovery/store';
import { lookupsStore } from 'src/stores/lookups';
import { MinervaRoute } from 'src/utils/router';

export const EdibleFoodRoute: MinervaRoute<'edible-food'> = {
  name: 'edible-food',
  path: '/edible-food',
  comp: () => lazy(() => import('./index')),
  activate: async () => {
    efrStore.EFRModel.filter.resetFilters();
    efgStore.filter.resetFilters();

    await Promise.all([
      efgStore.fetchEFG(),
      efrStore.EFRModel.fetchFoods(),
      lookupsStore.fetchEdibleFoodGeneratorsTypes(),
      lookupsStore.fetchFoodType(),
    ]);
  },
  deactivate: () => {
    efrStore.EFRModel.resetStore();
    efgStore.resetStore();
    lookupsStore.resetJurisdictions();
  },
};
