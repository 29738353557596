import { styled } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';

interface Props {
  name: string;
}

const Container = styled('div')(({ theme }) => ({
  textTransform: 'capitalize',
  borderRadius: 4,
  backgroundColor: theme.palette.minervaColors.secondaryBlue,
  padding: theme.spacing(0.25, 1),
  ...(theme.typography.hint as CSSProperties),
}));

const CategoryItem = ({ name }: Props) => <Container>{name}</Container>;
export default CategoryItem;
