import { lazy, Suspense, useContext } from 'react';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { createImportInstance } from 'src/components/File/ImportUpload';
import { ImportSteps } from 'src/pages/Import/type';
import { isPending } from 'src/utils/common';

import { importsStoreCtx } from '../../store';

import { BreadcrumbsComp } from './Breadcrumbs';

export const { importUploadStore, ImportDropZone, UploadPreview } =
  createImportInstance();

export const views = {
  [ImportSteps.Loading]: lazy(() => import('../Loading')),
  [ImportSteps.StartImport]: lazy(() => import('../PrepareImport')),
  [ImportSteps.Upload]: lazy(() => import('../UploadImport')),
  [ImportSteps.MatchingColumns]: lazy(() => import('../MatchingColumns')),
  [ImportSteps.MatchingValues]: lazy(() => import('../MatchingValues')),
  [ImportSteps.MatchingContainers]: lazy(() => import('../MatchingContainers')),
  [ImportSteps.Complete]: lazy(() => import('../ImportComplete')),
};

export default observer(() => {
  const { importStep, currentSession } = useContext(importsStoreCtx);

  const Component = views[importStep as ImportSteps] || null;

  return (
    <Box
      sx={{
        p: 2,
        height: '65vh',
        display: 'grid',
        gridTemplateRows: '10% 90%',
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: theme => theme.spacing(2, 1),
        }}>
        <Typography variant="h4">Import</Typography>
        {importStep !== ImportSteps.StartImport && (
          <Stack spacing={2}>
            <BreadcrumbsComp />
          </Stack>
        )}
      </Box>
      <Box
        sx={{
          height: '70vh',
          overflow: 'hidden',
        }}>
        {isPending(currentSession?.sessionDataReq) && <LinearProgress />}
        <Suspense fallback={<LinearProgress />}>
          {Component && <Component />}
        </Suspense>
      </Box>
    </Box>
  );
});
