import { createStyles, makeStyles } from '@mui/styles';
import { theme } from 'src/utils/theme/minerva-main';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiCardContent-root': {
        paddingTop: 0,
      },
      margin: '4px 2px',
      borderRadius: 4,
    },
    title: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '80%',
      paddingRight: theme.spacing(1),
    },
    textarea: {
      width: '100%',
      padding: 12,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      border: `1px solid #c4c4c4`,
    },
    cardActions: {
      display: 'flex',
    },
    cardContent: {
      '& .MuiTextField-root': {
        margin: 0,
      },
      '& .MuiOutlinedInput-input': {
        padding: 14,
      },
      '& .MuiTypography-gutterBottom': {
        marginTop: '0.35em',
      },
    },
  }),
);
