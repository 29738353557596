import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

import { clientsStore } from './store';

export const ClientsRoute: MinervaRoute<'clients'> = {
  name: 'clients',
  path: '/clients',
  comp: () => lazy(() => import('./index')),
  activate: async () => {
    await clientsStore.fetchClients();
  },
  deactivate: () => clientsStore.resetStore(),
};
