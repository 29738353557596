import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';

import { routerStore } from '../../stores/router';
import { userStore } from '../../stores/user';

import { importsStore } from './store';
import { ImportStepsParams } from './type';

export const ImportRoute: MinervaRoute<'import', ImportStepsParams> = {
  name: 'import',
  path: '/import&step',
  comp: () => lazy(() => import('./index')),
  activate: async () => {
    if (!userStore.isAdmin) {
      routerStore?.goTo({ name: 'index' });
    }

    await importsStore.getLastActiveSession();
  },
};
