import { makeObservable } from 'mobx';
import { EfgModel } from 'src/models/edible-food/efg-model';

import { storeFactory } from '../../utils/store';

export class EFGStore extends EfgModel {
  constructor() {
    super();
    makeObservable(this);
  }
}

export const { store: efgStore, storeCtx: EFGStoreCtx } = storeFactory(
  EFGStore,
  'efgStore',
);
