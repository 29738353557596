import {
  action,
  makeObservable, observable,
} from 'mobx';
import { Matrix } from 'src/models/matrix/matirx';
import { OrganicWasteMaterials } from 'src/models/organic-waste-materials/organic-waste-materials';

export class ComplianceMatrixStore {
  @observable.ref complianceMatrix?: Matrix;
  @observable.ref organicWasteMaterials = new OrganicWasteMaterials();

  constructor() {
    makeObservable(this);
  }

  @action fetchMatrix = (id: number) => {
    this.complianceMatrix = new Matrix(id)
  }

  @action loadMatrix = (id: number) => {
    if (!this.complianceMatrix) {
      this.fetchMatrix(id)
    }
  }

  @action fetchOrganicMaterials = async (id:number) => {
    await this.organicWasteMaterials.getOrganicMaterials(id)
  }

  @action loadOrganicMaterials = async (id: number) => {
    if (!this.organicWasteMaterials) {
      await this.fetchOrganicMaterials(id)
    }
  }
}
