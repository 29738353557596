import { useState } from 'react';

import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react-lite';
import CategoryItem from 'src/components/CategoryItem';
import ColoredItemType from 'src/components/TypesElement/СoloredTypeItem';
import { AssetModification } from 'src/models/assets/asset-modification';

import { useAssetsTableStore } from '../../AssetsTableProvider';

export const AssetsTypeInfo = observer(
  ({ asset }: { asset?: AssetModification }) => {
    const [open, setOpen] = useState(false);
    const { chosenAsset } = useAssetsTableStore();

    if (!chosenAsset && !asset) return null;

    const assetInfo = asset ? asset : chosenAsset;

    return (
      <div onClick={() => setOpen(prev => !prev)} style={{ cursor: 'pointer' }}>
        <Typography variant="body2" color="dodgerblue">
          Show more
        </Typography>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid container direction="column" spacing={2} sx={{ py: 2 }}>
            <Grid item container justifyContent="space-between">
              <Grid>
                <ColoredItemType
                  flow="asset"
                  id={assetInfo?.type?.id || 0}
                  name={assetInfo?.type?.name ? assetInfo.type?.name : 'None'}
                />
              </Grid>
              <Grid>
                <Typography variant="subtitle2">Type</Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid>
                <CategoryItem
                  name={
                    assetInfo?.category?.name
                      ? assetInfo.category?.name
                      : 'None'
                  }
                />
              </Grid>
              <Grid>
                <Typography variant="subtitle2">Category</Typography>
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid>
                <CategoryItem
                  name={
                    assetInfo?.subCategory?.name
                      ? assetInfo.subCategory?.name
                      : 'None'
                  }
                />
              </Grid>
              <Grid>
                <Typography variant="subtitle2">Asset Sub-Category</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </div>
    );
  },
);

export default AssetsTypeInfo;
