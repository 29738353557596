import { useContext, useEffect } from 'react';

import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import Autocomplete from 'src/components/Autocomplete';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { isPending } from 'src/utils/common';
import { useSearchPanel } from 'src/components/SearchPanel/context';


const HaulerField = observer(() => {
  //todo refactor this component basically use HaulerField.tsx and throw handlers of useSearchPanel to the HaulerField
  const { filterFields, setFilterValue } = useSearchPanel() || {};
  const { haulersLookupsReq, haulersLookups, fetchHaulersLookup } =
    useContext(lookupsStoreCtx);

  const { watch } = useFormContext();
  const value = watch('q.hauler.id');

  useEffect(() => {
    if (!haulersLookups.length || !setFilterValue) return;
    setFilterValue({
      ...filterFields,
      'q.hauler.id': value?.id || '',
    });
  }, [value, filterFields, setFilterValue, haulersLookups]);

  return (
    <Grid container direction="column">
      <Grid sx={{ mb: 2 }}>
        <Typography variant="body2" color="grey">
          Hauler
        </Typography>
        <Autocomplete
          name="q.hauler.id"
          options={haulersLookups}
          request={fetchHaulersLookup}
          placeholder="Look for haulers..."
          loading={isPending(haulersLookupsReq)}
          asyncSearch
        />
      </Grid>
    </Grid>
  );
});

export default HaulerField;
