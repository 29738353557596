import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import AlertIcon from 'src/assets/icons/File/AlertIcon';
import CheckCircleIcon from 'src/assets/icons/File/CheckCircleIcon';
import DoneCircleIcon from 'src/assets/icons/File/DoneCircleIcon';
import ErrorIcon from 'src/assets/icons/File/ErrorIcon';
import { UploadFile } from 'src/models/file/upload-file';
import { FileStatus } from 'src/types';

import { useStyles } from './styles';

interface Props {
  fileItem: UploadFile;
}

const FileInfoStatus = ({ fileItem }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation('files');

  if (fileItem.error) {
    return (
      <>
        <AlertIcon fontSize="inherit" />
        <Typography className={classes.statusText} color="textSecondary">
          {fileItem.errorLabel}
        </Typography>
      </>
    );
  }

  if (fileItem.status === FileStatus.DONE) {
    return (
      <>
        <DoneCircleIcon fontSize="inherit" />
        <Typography className={classes.statusText} color="textSecondary">
          Uploaded
        </Typography>
      </>
    );
  }

  if (fileItem.status === FileStatus.LOADING) {
    return (
      <>
        <CircularProgress
          color="primary"
          size={16}
          variant="determinate"
          value={fileItem.percentage}
        />
        <Typography className={classes.statusText} color="textSecondary">
          {fileItem.percentage}%
        </Typography>
      </>
    );
  }

  if (fileItem.status === FileStatus.FAILED) {
    return (
      <>
        <ErrorIcon fontSize="inherit" />
        <Typography className={classes.statusText} color="textSecondary">
          {t(fileItem.errorLabel)}
        </Typography>
      </>
    );
  }

  return (
    <>
      <CheckCircleIcon fontSize="inherit" />
      <Typography className={classes.statusText} color="textSecondary">
        Waiting
      </Typography>
    </>
  );
};

export default observer(FileInfoStatus);
