import * as yup from 'yup';

import { CreateEfrType, FoodFilterType } from '../../../api/api-types/foods';
import { ClientTypes } from '../../../api/api-types/lookups';

export const initialFoodsData: CreateEfrType = {
  name: '',
  type: null,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  contactName: '',
  phone: '',
  email: '',
  totalPounds: 0,
  annualCapacity: 0,
  generatorId: null,
};

export const validationSchema = yup
  .object({
    name: yup.string().label('Name').required().max(255),
    addressLine1: yup.string().label('Address').required(),
    city: yup.string().label('City').required(),
    zipCode: yup.string().label('ZIP / Postal Code').required(),
    type: yup.mixed().label('Type').required(),
    state: yup.string().label('State').required(),
  })
  .required();

export const defaultEFRSearch: FoodFilterType = {
  'q.name': '',
  'q.reference': '',
  'q.usaAddress': '',
};

export const defaultFilterField = (permissions?: number) => {
  if (!permissions) return defaultEFRSearch;
  if (permissions === ClientTypes.Jurisdiction) {
    delete defaultEFRSearch['q.jurisdiction.id'];
    return defaultEFRSearch;
  }
  return defaultEFRSearch;
};
