import { useContext } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react-lite';
import { lookupsStoreCtx } from '../../../../../stores/lookups';
import { isPending } from '../../../../../utils/common';
import { usersStoreCtx } from '../../../store';
import {
  checkedIcon,
  icon,
} from '../../CreateModal/FormFields/ConnectToFields/JurisdictionAutocomplete';
import { useAutocompletStyles } from '../styles';

const EditJurisdiction = () => {
  const { autocompleteMain } = useAutocompletStyles();
  const { allJurisdictionsReq, allJurisdictions } =
    useContext(lookupsStoreCtx);
  const { chosenUser, isAdminOrHauler } = useContext(usersStoreCtx);
  if (!chosenUser) return null;

  return (
    <Autocomplete
      fullWidth
      disabled={!isAdminOrHauler}
      value={chosenUser?.jurisdictions || []}
      onChange={(_, data) => {
        chosenUser?.setEntity({ jurisdictions: data });
      }}
      className={autocompleteMain}
      multiple
      limitTags={2}
      size="small"
      options={allJurisdictions}
      getOptionLabel={option => option?.name || ''}
      isOptionEqualToValue={(option, value) => `${option.id}` === `${value.id}`}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} key={`${option.id}-${option.name}`}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          <ListItemText primary={option.name} />
        </MenuItem>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={`Select jurisdictions...`}
          sx={{
            display: 'flex',
            maxHeight: '100px',
            overflow: 'auto',
            justifyContent: 'top',
          }}
          InputProps={{
            ...params.InputProps,
            style: {
              background: 'white',
              zIndex: 999999,
              display: 'flex',
              maxHeight: '100px',
              overflow: 'auto',
              justifyContent: 'top',
            },
            endAdornment: (
              <>
                {isPending(allJurisdictionsReq) && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default observer(EditJurisdiction);
