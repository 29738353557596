import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NothingToShowIcon from 'src/assets/icons/Generator/NothingToShowIcon';

const NothingToShow = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: theme => theme.spacing(4),
    }}>
    <NothingToShowIcon sx={{ width: 216, height: 212 }} />
    <Typography variant="h6" sx={{ fontWeight: 600 }}>
      Nothing to show here yet
    </Typography>
  </Box>
);

export default NothingToShow;
