import { AccountItem } from '../../api/api-types/accounts';
import { ItemType } from '../../types';

export class Account {
  readonly id: number;
  readonly name: string;
  readonly agency: ItemType | null;
  readonly accountNumber: string;
  readonly typeOfBusiness: string;
  readonly naicsCode: string | null;
  readonly naicsDescription: string | null;
  readonly populationDensity: string | null;
  readonly watershed: string | null;
  readonly isActive: boolean;
  readonly isParent: boolean;
  readonly isRelated: boolean;
  readonly canEdit: boolean;
  readonly accountAddress: ItemType;
  readonly location: ItemType | null;
  readonly zone: ItemType | null;
  readonly numberOfUnits: number | null;
  readonly type: ItemType | null;
  readonly origin: ItemType | null;
  readonly jurisdiction: ItemType | null;
  readonly hauler: ItemType | null;
  readonly createdAt: Date | string | null;
  readonly createdBy: ItemType | null;
  readonly reference: string;
  readonly parent: ItemType | null;
  readonly parentId: number | null;
  readonly extensionField1: ItemType | null;
  readonly extensionField2: ItemType | null;
  readonly dataDate: string;

  constructor({
    id,
    name,
    agency,
    accountNumber,
    typeOfBusiness,
    naicsCode,
    isActive,
    isParent,
    canEdit,
    accountAddress,
    location,
    type,
    origin,
    numberOfUnits,
    jurisdiction,
    hauler,
    createdAt,
    createdBy,
    reference,
    parent,
    parentId,
    isRelated,
    extensionField1,
    extensionField2,
    naicsDescription,
    populationDensity,
    watershed,
    zone,
    dataDate,
  }: AccountItem) {
    this.id = id;
    this.name = name || '';
    this.agency = agency || null;
    this.accountNumber = accountNumber || '';
    this.typeOfBusiness = typeOfBusiness || '';
    this.naicsCode = naicsCode || '';
    this.naicsDescription = naicsDescription || '';
    this.populationDensity = populationDensity || '';
    this.watershed = watershed || '';
    this.createdAt = createdAt || null;
    this.isActive = isActive;
    this.isParent = isParent;
    this.isRelated = isRelated;
    this.canEdit = canEdit;
    this.type = type || null;
    this.zone = zone || null;
    this.numberOfUnits = numberOfUnits;
    this.parent = parent || null;
    this.parentId = parentId || null;
    this.extensionField1 = extensionField1 || null;
    this.extensionField2 = extensionField2 || null;
    this.origin = origin || '';
    this.reference = reference || '';
    this.jurisdiction = jurisdiction ? jurisdiction : { id: 0, name: '' };
    this.hauler = hauler ? hauler : { id: 0, name: '' };
    this.hauler = hauler ? hauler : { id: 0, name: '' };
    this.accountAddress = accountAddress ? accountAddress : { id: 0, name: '' };
    this.location = location ? location : { id: 0, name: '' };
    this.createdBy = createdBy ? createdBy : { id: 0, name: '' };
    this.dataDate = dataDate;
  }
}
