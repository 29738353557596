import { lazy } from 'react';

import { GeneratorParams } from 'src/types';
import { MinervaRoute } from 'src/utils/router';

import { lookupsStore } from '../../stores/lookups';

import { complianceMatrixStore } from './components/ComplianceMatrix';
import { generatorEFRStore } from './components/EFR/store';
import { serviceHistoryStore } from './components/ServiceHistory';
import { generatorDetailsStore } from './store';

export const GeneratorDetailsRoute: MinervaRoute<
  'generator',
  Partial<GeneratorParams>
> = {
  name: 'generator',
  path: '/generator/:id',
  comp: () => lazy(() => import('./index')),
  activate: async (_, params) => {
    if (!params?.id) return;
    serviceHistoryStore.filter.resetFilters();
    serviceHistoryStore.filter.setPersistedFilters({
      'q.location.id': params?.id,
    });
    complianceMatrixStore.fetchMatrix(params?.id);

    await Promise.all([
      generatorDetailsStore.pageInitCall(params?.id),
      complianceMatrixStore.fetchOrganicMaterials(params?.id),
      lookupsStore.fetchAccountsWithEFG(params.id),
      generatorDetailsStore.services.fetchServices(),
      lookupsStore.fetchAccountsIsNotEFG(String(params?.id)),
      lookupsStore.fetchEdibleFoodGeneratorsTypes(),
      lookupsStore.fetchFoodType(),
    ]);
  },
  deactivate: () => {
    generatorDetailsStore.reset();
    generatorEFRStore.efrModel.filter.resetPersistedFilters();
    generatorEFRStore.efrModel.removeSelectedFoods();
    lookupsStore.resetJurisdictions();
    
  },
};
