import { useContext, useEffect } from 'react';

import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import Autocomplete from 'src/components/Autocomplete';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { isPending } from 'src/utils/common';
import { useSearchPanel } from 'src/components/SearchPanel/context';

const GeneratorField = observer(() => {
  const { filterFields, setFilterValue } = useSearchPanel() || {};
  const { generatorsLookupsReq, generatorsLookups, fetchGeneratorsLookups } =
    useContext(lookupsStoreCtx);
  const { watch } = useFormContext();
  const value = watch('q.generator.id');

  useEffect(() => {
    if (!generatorsLookups.length || !setFilterValue) return;
    setFilterValue({
      ...filterFields,
      'q.location.id': value?.id || '',
    });
  }, [value, filterFields, generatorsLookups, setFilterValue]);

  return (
    <Grid container direction="column">
      <Grid sx={{ mb: 2 }}>
        <Typography variant="body2" color="grey">
          Generator Address
        </Typography>
        <Autocomplete
          name="q.generator.id"
          options={generatorsLookups}
          request={fetchGeneratorsLookups}
          placeholder="Look for generators..."
          loading={isPending(generatorsLookupsReq)}
          asyncSearch
        />
      </Grid>
    </Grid>
  );
});

export default GeneratorField;
