import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import {
  AccountChildren,
  AccountDetailsItem,
  AccountDetailsResponse,
  AccountsTypes,
  AccountRelated,
} from 'src/api/api-types/accounts';
import { AccountsApi } from 'src/api/account';
import { ItemType, RequestPaginationParams } from '../../types';
import { Account } from './account';
import { AddressItem, GeneratorDetailsResponse, ShortGeneratorItem, matchAddressData } from 'src/api/api-types/generators';
import { AccountLookup } from 'src/api/api-types/lookups';
import { debounce, isNull } from 'lodash';
import { DEBOUNCE_DURATION } from 'src/utils/constants';

export class AccountModification {
  api = new AccountsApi({ prefix: '' });

  readonly canEdit: boolean;
  readonly isParent: boolean;
  readonly reference: string;
  readonly origin: ItemType | null;
  readonly createdAt: Date | string | null;
  readonly createdBy: ItemType | null;
  readonly accountNumber: string;
  
  readonly extensionField1: ItemType | null;
  readonly extensionField2: ItemType | null;
  readonly address: AddressItem | null;
  readonly isActive: boolean;

  @observable id?: number;
  @observable name: string;
  @observable nickname: string;
  @observable naicsCode: string;
  @observable numberOfUnits: number | null;
  @observable location: Partial<ShortGeneratorItem> | null;
  @observable type: ItemType | null;
  @observable hauler: ItemType | null;
  @observable parentClass?: Account;
  @observable addressLine1: string;
  @observable addressLine2: string;
  @observable city: string;
  @observable state: string;
  @observable zipCode: string;
  @observable jurisdiction: ItemType | null;

  @observable defaultState: Partial<AccountDetailsItem>;
  @observable description?: string;
  @observable typeOfBusiness: string;
  @observable parent: AccountLookup | null;
  @observable parentId: number | null;
  @observable addressGeneratorId?: number;
  
  @observable paginationForChildren: RequestPaginationParams = {
    page: 1,
    pageSize: 999,
    totalCount: 0,
  };

  @observable paginationForRelated: RequestPaginationParams = {
    page: 1,
    pageSize: 999,
    totalCount: 0,
  };

  @observable.ref accountUpdateReq: ApiReq<AccountDetailsResponse> = emptyValue;
  @observable.ref updateDetailsReq: ApiReq<AccountDetailsResponse> = emptyValue;

  @observable.ref createReq: ApiReq<AccountDetailsResponse> = emptyValue;
  @observable.ref fetchChildrenReq: ApiReq<AccountChildren> = emptyValue;
  @observable.ref fetchRelatedReq: ApiReq<AccountRelated> = emptyValue;
  @observable.ref deleteChildrenRequest: ApiReq<AccountDetailsResponse> =
    emptyValue;
  @observable.ref deleteParentRequest: ApiReq<AccountDetailsResponse> =
    emptyValue;
  @observable.ref deleteRelatedRequest: ApiReq<AccountDetailsResponse> =
    emptyValue;
  @observable.ref jurisdictionByAddressReq: ApiReq<ItemType | string> =
    emptyValue;

  @observable.ref locationByIDReq: ApiReq<GeneratorDetailsResponse> =
    emptyValue;

  constructor(props: Partial<AccountDetailsItem>) {
    const {
      id,
      name,
      nickname,
      naicsCode,
      location,
      numberOfUnits,
      type,
      hauler,
      description,
      typeOfBusiness,
      canEdit,
      reference,
      origin,
      createdAt,
      createdBy,
      accountNumber,
      jurisdiction,
      parent,
      parentId,
      isParent,
      extensionField1,
      extensionField2,
      address,
      addressLine1,
      addressLine2,
      city,
      state,
      zipCode,
      isActive,
    } = props;
    makeObservable(this);
    this.id = id;
    this.name = name || '';
    this.description = description || '';
    this.naicsCode = naicsCode || '';
    this.addressLine1 = addressLine1 || ''; 
    this.addressLine2 = addressLine2 || ''; 
    this.city = city || ''; 
    this.state = state || ''; 
    this.zipCode = zipCode || ''; 
    this.accountNumber = accountNumber || '';
    this.location = location || null;
    this.numberOfUnits =  numberOfUnits !== undefined && !isNull(numberOfUnits) ? numberOfUnits : null;
    this.type = type || null;
    this.hauler = hauler || null;
    this.defaultState = props;
    this.typeOfBusiness = typeOfBusiness || '';
    this.canEdit = canEdit || false;
    this.reference = reference || '';
    this.origin = origin || null;
    this.createdAt = createdAt || null;
    this.createdBy = createdBy ? createdBy : { id: 0, name: '' };
    this.jurisdiction = jurisdiction ?? null;
    this.parent = parent || null;
    this.parentId = parentId || null;
    this.extensionField1 = extensionField1 || null;
    this.extensionField2 = extensionField2 || null;
    this.isParent = isParent!;
    this.address = address || null;
    this.isActive = isActive || false;
    this.nickname = nickname || '';
    this.addressGeneratorId = undefined;
  }

  @action setEntity = ({
    id,
    name,
    naicsCode,
    location,
    type,
    hauler,
    numberOfUnits,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    nickname,
    jurisdiction,
    addressGeneratorId
  }: Partial<AccountDetailsItem>) => {
    this.id = id;
    this.name = name || this.name;
    this.naicsCode = naicsCode || this.naicsCode;
    this.location = !!location ? location : this.location;
    this.type = type || this.type;
    this.hauler = hauler || this.hauler;
    this.numberOfUnits = numberOfUnits !== undefined && !isNull(numberOfUnits) ? numberOfUnits : null;
    this.addressLine1 = addressLine1 || this.addressLine1;
    this.addressLine2 = addressLine2 || this.addressLine2;
    this.city = city || this.city;
    this.state = state || this.state;
    this.zipCode = zipCode || this.zipCode;
    this.nickname = nickname || this.nickname;
    this.jurisdiction = jurisdiction || this.jurisdiction;
    this.addressGeneratorId = addressGeneratorId || this.addressGeneratorId;
  };

  @computed get serialized() {
    return {
      id: this.id!,
      name: this.name,
      naicsCode: this.naicsCode || '',
      numberOfUnits: this.numberOfUnits,
      location: this.location,
      hauler: this.hauler,
      parentClass: this.parentClass,
      typeOfBusiness: this.typeOfBusiness || '',
      type: this.type || null,
      nickname: this.nickname || '',
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      jurisdiction: this.jurisdiction
    };
  }

  @computed get accountsChildren() {
    if (
      this.fetchChildrenReq.state !== 'fulfilled' ||
      !this.fetchChildrenReq.value
    ) {
      return [];
    }
    return this.fetchChildrenReq.value.data?.items || [];
  }

  @computed get relatedAccounts() {
    if (
      this.fetchRelatedReq.state !== 'fulfilled' ||
      !this.fetchRelatedReq.value
    ) {
      return [];
    }
    return this.fetchRelatedReq.value.data?.items || [];
  }

  @computed get childrenPagination(): RequestPaginationParams {
    if (
      this.fetchChildrenReq.state !== 'fulfilled' ||
      !this.fetchChildrenReq.value
    )
      return this.paginationForChildren;
    return {
      page: this.fetchChildrenReq.value.data?.pagination.num!,
      pageSize: this.fetchChildrenReq.value.data?.pagination.size!,
      totalCount: this.fetchChildrenReq.value.data?.totalCount,
      totalPages: this.fetchChildrenReq.value.data?.totalPages,
    };
  }

  @computed get relatedToPagination(): RequestPaginationParams {
    if (
      this.fetchRelatedReq.state !== 'fulfilled' ||
      !this.fetchRelatedReq.value
    )
      return this.paginationForRelated;
    return {
      page: this.fetchRelatedReq.value.data?.pagination.num!,
      pageSize: this.fetchRelatedReq.value.data?.pagination.size!,
      totalCount: this.fetchRelatedReq.value.data?.totalCount,
      totalPages: this.fetchRelatedReq.value.data?.totalPages,
    };
  }

  @action createAccount = async () => {
    if (isNull(this?.type?.id)) return;
    this.createReq = this.api.creteAccount(this.serialized);
    return this.createReq;
  };

  @action setDescription = (value: AccountModification['description']) =>
    (this.description = value);

  @action submitDescription = async () => {
    if (this.id) {
      this.accountUpdateReq = this.api.updateAccount({
        description: this.description || '',
        id: this.id,
      });
      await this.accountUpdateReq;
      runInAction(() => {
        if (this.accountUpdateReq.state === 'fulfilled') {
          this.description = this.accountUpdateReq.value.data?.description;
        }
      });
    }
  };

  @action setType = (type: ItemType) => {
    this.type = type;
    if (type.id !== AccountsTypes.Multifamily) this.setNumberOfUnits(null);
  };

  @action setGenerator = (generator: ItemType) => {
    this.location = generator;
  };

  @action setTypeOfBusiness = (type: string) => (this.typeOfBusiness = type);

  @action setNAICSCode = (code: string) => (this.naicsCode = code);

  @action setNumberOfUnits = (number: number | null) =>
    (this.numberOfUnits = number);

  @action editDetails = async () => {
    this.accountUpdateReq = this.api.updateAccountDetails(this.serialized);
    await this.accountUpdateReq;
    runInAction(() => {
      if (this.accountUpdateReq.state === 'fulfilled') {
        this.defaultState = this.accountUpdateReq.value.data!;
      }
    });
    return this.accountUpdateReq;
  };

  @action resetFields = () => {
    this.type = this.defaultState.type ?? this.type;
    this.typeOfBusiness =
      this.defaultState.typeOfBusiness ?? this.typeOfBusiness;
    this.naicsCode = this.defaultState.naicsCode ?? this.naicsCode;
    this.numberOfUnits = this.defaultState.numberOfUnits ?? this.numberOfUnits;
  };

  @action fetchAccountChildren = async () => {
    this.fetchChildrenReq = this.api.getAccountChildrens({
      id: this.id!,
      ...this.paginationForChildren,
    });

    await this.fetchChildrenReq;
  };

  @action fetchRelatedAccounts = async () => {
    this.fetchRelatedReq = this.api.getRelatedAccount({
      id: this.id!,
      ...this.paginationForRelated,
    });

    await this.fetchRelatedReq;
  };

  @action changeChildrenPageAction = async (page: number) => {
    runInAction(() => {
      this.paginationForChildren.page = page;
    });
    await this.fetchAccountChildren();
  };

  @action changeRelatedPageAction = async (page: number) => {
    runInAction(() => {
      this.paginationForRelated.page = page;
    });
    await this.fetchRelatedAccounts();
  };

  @action deleteAccountChildren = async (childId: number) => {
    if (!this.id) return;
    this.deleteChildrenRequest = this.api.deleteAccountChildren(
      this.id,
      childId,
    );
    await this.deleteChildrenRequest;
    this.fetchAccountChildren();
  };

  @action deleteRelatedAccount = async (relatedId: number) => {
    if (!this.id) return;
    this.deleteRelatedRequest = this.api.deleteRelatedAccount(
      this.id,
      relatedId,
    );
    await this.deleteRelatedRequest;
    this.fetchRelatedAccounts();
  };

  @action deleteAccountParent = async () => {
    if (!this.id) return;
    this.deleteParentRequest = this.api.deleteAccountParent(this.id);
    await this.deleteParentRequest;
    runInAction(() => {
      this.parent = null;
      this.parentId = null;
    });
  };

  @action calculateJurisdictionByAddress = debounce(
    async (data: matchAddressData) => {
      this.jurisdictionByAddressReq = this.api.getJurisdictionByAddress(data);
      await this.jurisdictionByAddressReq;
    },
    DEBOUNCE_DURATION,
  );

  @action fetchLocationByID = async (id: number) => {
    this.locationByIDReq = this.api.getLocationDetails(id);
    return await this.locationByIDReq;
  }
  
}
