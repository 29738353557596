import { ItemType } from '../../types';
import { ContactAddress } from './address-book';

export enum ClientTypes {
  'Hauler' = 1,
  'Jurisdiction' = 2,
  'Agency' = 3,
  'Administrator' = 5,
}

export interface MergedLookup extends Omit<ItemType, 'id'> {
  id: string | number;
  name: string;
}

export interface AccountLookup extends ItemType {
  accountNumber: string;
}

export interface CategoriesLookup extends ItemType {
  hasSubCategories?: boolean;
  description?: string;
}

export interface CategoriesLookupReq {
  type?: number;
}

export interface TypesLookupReq {
  type: number;
  name?: string;
}

export interface FoodRecoveryTypesReq {
  name?: string;
}

export interface SubCategoriesLookupReq {
  id: number;
  typeId?: number;
}

export interface Address extends Omit<ContactAddress, 'id'>  {
  formattedAddress: string;
  reference: string;
}

export interface GeneratorAddresses {
  addresses: Address[];
  error: string;
  status: string;
}

export const LOOKUP_BLANK_ID = 'blank';
