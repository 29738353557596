import { GridColumnGroupingModel, GridColDef } from '@mui/x-data-grid-pro';

export const groupColumns: GridColDef[] = [
  {
    field: 'foodAndSoiledPaperGenerates',
    headerName: 'Food & Food Soiled Paper Generates',
    headerClassName: 'food',
    renderHeader: () => 'Generates',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'foodAndSoiledPaperRecycling',
    headerName: 'Food & Food Soiled Paper Recycling',
    headerClassName: 'food',
    renderHeader: () => 'Recycles',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'greenMaterialGenerates',
    headerName: 'Green Material Generates',
    headerClassName: 'greenMaterial',
    renderHeader: () => 'Generates',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'greenMaterialRecycling',
    headerName: 'Green Material Recycles',
    headerClassName: 'greenMaterial',
    renderHeader: () => 'Recycles',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'landscapeAndPrunningGenerates',
    headerName: 'Landscape&Pruning Generates',
    headerClassName: 'landscape',
    renderHeader: () => 'Generates',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'landscapeAndPrunningRecycling',
    headerName: 'Landscape&Pruning Recycles',
    headerClassName: 'landscape',
    renderHeader: () => 'Recycles',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'lumberGenerates',
    headerName: 'Lumber Generates',
    headerClassName: 'lumber',
    renderHeader: () => 'Generates',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'lumberRecycling',
    headerName: 'Lumber Recycles',
    headerClassName: 'lumber',
    renderHeader: () => 'Recycles',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'manureGenerates',
    headerName: 'Manure Generates',
    headerClassName: 'manure',
    renderHeader: () => 'Generates',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'manureRecycling',
    headerName: 'Manure Recycles',
    headerClassName: 'manure',
    renderHeader: () => 'Recycles',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'textilesGenerates',
    headerName: 'Organic Textiles & Carpets Generates',
    headerClassName: 'textiles',
    renderHeader: () => 'Generates',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'textilesRecycling',
    headerName: 'Organic Textiles & Carpets Recycles',
    headerClassName: 'textiles',
    renderHeader: () => 'Recycles',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'paperProductsGenerates',
    headerName: 'Paper Products Generates',
    headerClassName: 'products',
    renderHeader: () => 'Generates',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'paperProductsRecycling',
    headerName: 'Paper Products Recycles',
    headerClassName: 'products',
    renderHeader: () => 'Recycles',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'printingAndWritingPaperGenerates',
    headerName: 'Printing & Writing Paper Generates',
    headerClassName: 'printing',
    renderHeader: () => 'Generates',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'printingAndWritingPaperRecycling',
    headerName: 'Printing & Writing Paper Recycles',
    headerClassName: 'printing',
    renderHeader: () => 'Recycles',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'woodGenerates',
    headerName: 'Wood Generates',
    headerClassName: 'wood',
    renderHeader: () => 'Generates',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'woodRecycling',
    headerName: 'Wood Recycles',
    headerClassName: 'wood',
    renderHeader: () => 'Recycles',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'nonOrganicGenerates',
    headerName: 'Non-organic Generates',
    headerClassName: 'nonOrganic',
    renderHeader: () => 'Non-organic Generates',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
  {
    field: 'nonOrganicRecycling',
    headerName: 'Non-organic Recycles',
    headerClassName: 'nonOrganic',
    renderHeader: () => 'Recycles',
    type: 'boolean',
    minWidth: 100,
    filterable: true,
  },
];

export const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Food & Food Soiled Paper',
    headerAlign: 'center',
    children: [
      {
        field: 'foodAndSoiledPaperGenerates',
        headerAlign: 'center',
      },
      {
        field: 'foodAndSoiledPaperRecycling',
        headerAlign: 'center',
      },
    ],
  },
  {
    groupId: 'Green Material',
    headerAlign: 'center',
    children: [
      { field: 'greenMaterialGenerates', headerAlign: 'center' },
      { field: 'greenMaterialRecycling', headerAlign: 'center' },
    ],
  },
  {
    groupId: 'Landscape & Pruning Waste',
    children: [
      { field: 'landscapeAndPrunningGenerates', headerAlign: 'center' },
      { field: 'landscapeAndPrunningRecycling', headerAlign: 'center' },
    ],
  },
  {
    groupId: 'Lumber',
    headerAlign: 'center',
    children: [
      { field: 'lumberGenerates', headerAlign: 'center' },
      { field: 'lumberRecycling', headerAlign: 'center' },
    ],
  },
  {
    groupId: 'Manure',
    headerAlign: 'center',
    children: [
      { field: 'manureGenerates', headerAlign: 'center' },
      { field: 'manureRecycling', headerAlign: 'center' },
    ],
  },
  {
    groupId: 'Organic Textiles & Carpets',
    headerAlign: 'center',
    children: [
      { field: 'textilesGenerates', headerAlign: 'center' },
      { field: 'textilesRecycling', headerAlign: 'center' },
    ],
  },
  {
    groupId: 'Paper Products',
    headerAlign: 'center',
    children: [
      { field: 'paperProductsGenerates', headerAlign: 'center' },
      { field: 'paperProductsRecycling', headerAlign: 'center' },
    ],
  },
  {
    groupId: 'Printing & Writing Paper',
    headerAlign: 'center',
    children: [
      { field: 'printingAndWritingPaperGenerates' },
      { field: 'printingAndWritingPaperRecycling' },
    ],
  },
  {
    groupId: 'Wood',
    headerAlign: 'center',

    children: [
      {
        field: 'woodGenerates',
        headerAlign: 'center',
      },
      { field: 'woodRecycling', headerAlign: 'center' },
    ],
  },
  {
    groupId: 'Non-organic Recyclables',
    headerAlign: 'center',
    children: [
      { field: 'nonOrganicGenerates', headerAlign: 'center' },
      { field: 'nonOrganicRecycling', headerAlign: 'center' },
    ],
  },
];
