import { action, computed, makeObservable, observable } from 'mobx';

import { withoutEmptyValues } from 'src/utils/common';

export class Filter<T> {
  @observable isSearchOpen = false;
  @observable filterFields: T;
  @observable persistedFilter: T | {} = {};
  readonly originFields: T;

  constructor(fields: T) {
    makeObservable(this);

    this.originFields = fields;
    this.filterFields = {
      ...fields,
    };
  }

  @computed get filterParams() {
    const { ...filters } = this.filterFields;
    const { ...persisted } = this.persistedFilter;

    return withoutEmptyValues({ ...filters, ...persisted });
  }

  @action setFilterValue = (partialFilterUpdate: Partial<T>) => {
    const keys = Object.keys(partialFilterUpdate) as (keyof T)[];
    keys.forEach(key => {
      this.filterFields[key] = partialFilterUpdate[key]!;
    });
  };

  @action setSearchOpen = (isSearchOpen?: boolean) => {
    this.isSearchOpen =
      isSearchOpen !== undefined ? isSearchOpen : !this.isSearchOpen;
  };

  @action resetFilters = () => {
    this.setFilterValue(this.originFields);
  };

  //Todo find better approach for Persisted flow.
  @action setPersistedFilters = (persisted: Partial<T>) => {
    const keys = Object.keys(persisted) as (keyof T)[];
    keys.forEach(key => {
      (this.persistedFilter as T)[key] = persisted[key]!;
    });
  };
  @action resetPersistedFilters = () => (this.persistedFilter = {});
}
