import { Component, ErrorInfo } from "react";

import SystemError from '../Error/SystemError';

class HandleSystemError extends Component<any, {hasError: boolean}>{
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // TODO [AZ] Use external logger
    // eslint-disable-next-line no-console
    console.error(error);
    // eslint-disable-next-line no-console
    console.info(info);
  }

  render() {
    if (this.state.hasError) {
      return <SystemError />;
    }

    return this.props.children;
  }
}

export default (HandleSystemError);
