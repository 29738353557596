import { GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid';

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      components={{
        ColumnMenuPinningItem: null,
      }}
    />
  );
};

export default CustomColumnMenu;
