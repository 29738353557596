import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UploadLabelIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1.89062 13V15.4C1.89062 16.2837 2.60697 17 3.49063 17H14.6906C15.5743 17 16.2906 16.2837 16.2906 15.4V13"
      stroke="#767D86"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.89062 9L9.09062 12.2L12.2906 9"
      stroke="#767D86"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.08984 1V12.2"
      stroke="#767D86"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default UploadLabelIcon;
