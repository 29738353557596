import { useContext, HTMLAttributes } from 'react';
import * as React from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Chip, InternalStandardProps as StandardProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { observer } from 'mobx-react-lite';
import { Controller, useFormContext } from 'react-hook-form';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { isPending } from 'src/utils/common';

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  onOpen?: (event: React.SyntheticEvent) => void;
}

const FoodWillingType = observer(
  (
    props: StandardProps<
      HTMLAttributes<HTMLDivElement>,
      'defaultValue' | 'onChange' | 'children'
    > &
      Props,
  ) => {
    const { control, formState } = useFormContext();
    const { foodWillingTypes, foodWillingTypesReq } =
      useContext(lookupsStoreCtx);

    const loading = isPending(foodWillingTypesReq);

    return (
      <>
        <Typography variant="body1">Food Types of Recoverable Food</Typography>
        <Controller
          control={control}
          name="foodWillingTypes"
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              sx={{
                p: 1,
              }}
              getOptionLabel={option => option?.name || ''}
              isOptionEqualToValue={(option, value) =>
                `${option.id}` === `${value.id}`
              }
              onChange={(_, data) => {
                field.onChange(data);
                return data;
              }}
              disableCloseOnSelect
              options={foodWillingTypes}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    sx={{ borderRadius: 1 }}
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderOption={(props, option, { selected }) => (
                <MenuItem {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  <ListItemText primary={option.name} />
                </MenuItem>
              )}
              loading={loading}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Select type of recoverable food "
                  sx={{ p: 0 }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  error={!!formState.errors[field.name]?.message}
                  helperText={formState.errors[field.name]?.message}
                />
              )}
              {...props}
            />
          )}
        />
      </>
    );
  },
);

export default FoodWillingType;
