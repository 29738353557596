import { makeObservable, observable } from 'mobx';
import { storeFactory } from '../../utils/store';
import { EfrModel } from '../../models/edible-food/efr-model';
export class EFRStore {
  @observable EFRModel = new EfrModel();
  constructor() {
    makeObservable(this);
  }
}

export const { store: efrStore, storeCtx: efrStoreCtx } = storeFactory(
  EFRStore,
  'efrStore',
);
