import { useContext, useEffect, useRef, useState } from 'react';

import Paper from '@mui/material/Paper';
import { observer } from 'mobx-react-lite';
import ServiceHistoryIcon from 'src/assets/icons/Generator/ServiceHistoryIcon';
import PrimaryCollapse from 'src/components/Collapsible/Primary';
import { createServiceHistory } from 'src/components/ServiceHistory';

import { accountDetailsStoreCtx } from '../../store';

export const { ServiceHistoryTable, serviceHistoryStore } =
  createServiceHistory();

export default observer(() => {
  const { isCollapseAll, resetAllCollapseState } = useContext(
    accountDetailsStoreCtx,
  );
  const [isOpen, setOpen] = useState(false);
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current && isCollapseAll !== undefined) {
      setOpen(isCollapseAll);
    }
    if (isCollapseAll) serviceHistoryStore.fetchServiceHistory();
    didMountRef.current = true;
  }, [isCollapseAll]);

  const onCollapseDetailClick = () => {
    resetAllCollapseState();
    setOpen(prevState => !prevState);
    if (!isOpen) serviceHistoryStore.fetchServiceHistory();
  };
  return (
    <Paper>
      <PrimaryCollapse
        title="Service History"
        isOpen={isOpen}
        handlerClick={onCollapseDetailClick}
        titleIcon={<ServiceHistoryIcon sx={{ width: 40, height: 40 }} />}>
        <ServiceHistoryTable />
      </PrimaryCollapse>
    </Paper>
  );
});
