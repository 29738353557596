import { GRID_CHECKBOX_SELECTION_FIELD } from '@mui/x-data-grid/colDef/gridCheckboxSelectionColDef';
import { observer } from 'mobx-react-lite';
import Table from 'src/components/Table';
import { isPending } from 'src/utils/common';
import { WithStore } from 'src/utils/store';
import { ServiceHistoryStore } from '../../store';
import Filter from '../Filter';
import ServicesHistoryToolbar from '../ServicesHistoryToolbar';
import { ExportFileType } from 'src/api/exportTypes';
import { ExportTypes } from 'src/types';
import { EXPORT_DEFAULT_OPTIONS } from 'src/utils/constants';
import { authStore } from 'src/stores/auth';
import ExportLoaderOverlay from 'src/components/ExportModal/LoaderOverlay';

export default observer((data: WithStore<{}, ServiceHistoryStore>) => {
  const {
    pagination: {
      page,
      pageSize,
      totalCount,
      totalPages,
      onPaginationModelChange,
    },
    servicesHistoryData,
    filter,
    servicesHistoryReq,
    exportEntity,
    exportAction,
    columnsManager: {
      gridColumns,
      columnVisibilityModel,
      setColumnVisibilityModel,
      setHiddenColumns
    },
    selectedServices,
    setSelectedServices
  } = data.store;
  
  const openFilter = () => {
    filter.filter.setSearchOpen();
  };

  const handleExport = (type: ExportFileType) => {
    setHiddenColumns(columnVisibilityModel);
    exportAction({
      ...EXPORT_DEFAULT_OPTIONS,
      fileName: "ServiceHistory",
      mode: ExportTypes.QUICK,
      type
    });
  };

  return (
    <div style={{ display: 'flex' }}>
      <Table
        disableColumnMenu
        inlineCreate
        openSearchAction={openFilter}
        checkboxSelection
        rowSelectionModel={selectedServices}
        onColumnVisibilityModelChange={newModel =>
          setColumnVisibilityModel(newModel)
        }
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_FIELD, 'generatorId', 'minervaId'],
          },
        }}
        slots={{
          toolbar: (props) => <ServicesHistoryToolbar 
            {...props} 
            quickExportAction={authStore.fCfg.isExportServiceHistory ? handleExport: undefined}
          />,
        }}
        {...{
          getRowId: ({ id }) => id,
          rows: servicesHistoryData.slice(),
          columns: gridColumns,
          paginationModel: {
            page: page - 1,
            pageSize,
          },
          totalPages,
          loading: isPending(servicesHistoryReq),
          onPaginationModelChange,
          rowCount: totalCount,
          columnVisibilityModel,
          onRowSelectionModelChange: selectionModel =>
            setSelectedServices(selectionModel),
        }}
        autoHeight
        filteredColumns={filter.filteredColumns}
      />
      <Filter {...data} />
      <ExportLoaderOverlay loading={exportEntity.loading} />
    </div>
  );
});
