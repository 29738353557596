import { lazy, PropsWithChildren, useMemo } from 'react';

import { RouteName } from 'src/routes';

import { ResponsiveFirstWrapper } from '../../utils/firstScreen';

interface Props {
  currentAccount: {} | null;
  currentRoute: RouteName;
  LayoutInnerBar?: () => JSX.Element | null;
}

export default ({
  children,
  currentAccount,
  currentRoute,
  LayoutInnerBar,
}: PropsWithChildren<Props>) => (
  <ResponsiveFirstWrapper<Props>
    getLazyDesktopComponent={useMemo(() => lazy(() => import('./desktop')), [])}
    getLazyMobileComponent={useMemo(() => lazy(() => import('./mobile')), [])}
    innerProps={{ currentAccount, currentRoute, LayoutInnerBar }}>
    {children}
  </ResponsiveFirstWrapper>
);
