import { FC } from 'react';

import Card from '@mui/material/Card';
import { CardProps } from '@mui/material/Card/Card';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import GreenIcon from '../../../assets/icons/Matrix/state/GreenIcon';
import GreyIcon from '../../../assets/icons/Matrix/state/GreyIcon';
import OrangeIcon from '../../../assets/icons/Matrix/state/OrangeIcon';
import RedIcon from '../../../assets/icons/Matrix/state/RedIcon';

export const stateMap: { [key: string]: JSX.Element } = {
  0: <RedIcon sx={{ width: 48, height: 48 }} />,
  1: <RedIcon sx={{ width: 48, height: 48 }} />,
  2: <GreyIcon sx={{ width: 48, height: 48 }} />,
  3: <OrangeIcon sx={{ width: 48, height: 48 }} />,
  4: <GreenIcon sx={{ width: 48, height: 48 }} />,
};

interface Props {
  title?: string;
  subTitle?: string | React.ReactNode;
  stateId?: number;
  props?: CardProps;
  loading?: boolean;
  sx?: any;
}

const MatrixCard: FC<Props> = ({
  title = '',
  subTitle = '',
  stateId,
  children,
  loading,
  props,
}) => {
  return (
    <Card sx={{ minWidth: 240, width: '100%', minHeight: 110 }} {...props}>
      <CardHeader
        sx={{
          alignItems: 'flex-start',
        }}
        avatar={
          loading ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : stateId ? (
            stateMap[stateId]
          ) : null
        }
        title={
          loading ? (
            <Skeleton variant="rectangular" width={200} height={16} />
          ) : (
            <Typography variant="body2">{title}</Typography>
          )
        }
        subheader={
          loading ? (
            <Skeleton
              variant="rectangular"
              width={200}
              height={16}
              sx={{ my: 2 }}
            />
          ) : (
            <Typography variant="subtitle1" color="textSecondary">
              {subTitle}
            </Typography>
          )
        }
      />
      {children}
    </Card>
  );
};

export default MatrixCard;
