import { useContext, useEffect, useRef, useState } from 'react';

import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import { observer } from 'mobx-react-lite';
import CollapsibleTitle from 'src/components/Collapsible/components/CollapsibleTitle';

import MatrixIcon from '../../../../assets/icons/Generator/MatrixIcon';
import { createComplianceMatrix } from '../../../../components/ComplianceMatrix';
import { generatorDetailsStoreCtx } from '../../store';

export const {
  complianceMatrixStore,
  ComplianceMatrix,
  ShortPreview,
  Materials,
} = createComplianceMatrix();

export default observer(() => {
  const { isCollapseAll, resetAllCollapseState } = useContext(
    generatorDetailsStoreCtx,
  );
  const [isOpen, setOpen] = useState(false);
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current && isCollapseAll !== undefined) {
      setOpen(isCollapseAll);
    }
    didMountRef.current = true;
  }, [isCollapseAll]);

  const onCollapseDetailClick = () => {
    resetAllCollapseState();
    setOpen(prevState => !prevState);
  };

  return (
    <Paper>
      <CollapsibleTitle
        title="Compliance Matrix"
        isOpen={isOpen}
        handlerClick={onCollapseDetailClick}
        titleIcon={<MatrixIcon sx={{ width: 40, height: 40 }} />}
      />
      {!isOpen && <ShortPreview />}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <ComplianceMatrix />
      </Collapse>
    </Paper>
  );
});
