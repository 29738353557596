import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventMessageUtils,
  EventType,
  InteractionStatus,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  when,
} from 'mobx';

import { storeFactory } from '../utils/store';
import { authenticationParameters, msalConfig } from '../config/auth-config';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { removeUserTimezone } from 'src/utils/date';
import debounce from 'lodash/debounce';
import { userStore } from './user';
import { ApiReq, emptyValue } from '../api';
import { FeaturesConfigurationApi } from '../api/configuration';
import {
  FeaturesConfiguration,
  PortalConfiguration,
} from '../api/api-types/configuration';
import { FeaturesConfigurationModel } from 'src/models/features-configuration/features-configuration';

const invalidAccountErrorCodes = ['no_tokens_found'];

// Only authentication-related requests
// Do not add domain requests here
class AuthStore {
  configuration = new FeaturesConfigurationApi();

  @observable msalInstance: PublicClientApplication;
  @observable currentAccounts = observable([] as AccountInfo[]);
  @observable status: InteractionStatus | null = null;
  @observable authResult: AuthenticationResult | null = null;
  @observable isReady: boolean = false;
  @observable.ref featuresConfigurationReq: ApiReq<FeaturesConfiguration> =
    emptyValue;
  @observable.ref portalConfigurationReq: ApiReq<PortalConfiguration> =
    emptyValue;
  @observable.ref featuresConfiguration = new FeaturesConfigurationModel();

  constructor() {
    makeObservable(this);
    this.msalInstance = new PublicClientApplication(msalConfig);

    try {
      this.msalInstance.handleRedirectPromise();
    } catch (err) {
      // todo [AZ] replace with logger
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  @computed get fCfg() {
    return this.featuresConfiguration;
  }

  @computed get portalConfiguration() {
    if (
      this.portalConfigurationReq.state !== 'fulfilled' ||
      !this.portalConfigurationReq.value
    ) {
      return undefined;
    }
    return this.portalConfigurationReq.value.data;
  }

  @computed get currentAccount() {
    return this.currentAccounts.length > 0 ? this.currentAccounts[0] : null;
  }

  @computed get isLoggedIn() {
    return this.currentAccount !== null;
  }

  @computed get isRedirectInProgress() {
    return this.status === InteractionStatus.HandleRedirect;
  }

  @computed get token() {
    if (!this.authResult) {
      // TODO [AZ] replace with logger
      // eslint-disable-next-line no-console
      console.error('missing token');
      return '';
    }

    return this.authResult.accessToken;
  }

  @computed get columnsVisibleMap() {
    return {
      admin: {
        jurisdictions: this.fCfg.userConnectedTo,
        haulers: this.fCfg.userConnectedTo,
        agencies: this.fCfg.userConnectedTo,
        employer: this.fCfg.isEditUserEmployer,
      },
      jurisdiction: {
        jurisdictions: this.fCfg.userConnectedTo,
        haulers: this.fCfg.userConnectedTo,
        agencies: this.fCfg.userConnectedTo,
        client: false,
      },
    };
  }

  @action ensureCurrentAccount = () => {
    this.currentAccounts.replace(this.msalInstance.getAllAccounts());

    this.msalInstance.addEventCallback((message: EventMessage) => {
      switch (message.eventType) {
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.LOGOUT_FAILURE:
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_FAILURE:
          this.currentAccounts.replace(this.msalInstance.getAllAccounts());
          this.status =
            EventMessageUtils.getInteractionStatusFromEvent(message);
          break;
      }
    });
  };

  @action setAuthResult = async () => {
    if (!this.currentAccount) {
      // not authorized, unauth routes will be used
      return;
    }

    try {
      this.authResult = await this.msalInstance.acquireTokenSilent({
        scopes: [`${process.env.REACT_APP_CONFIG_Scopes}`],
        account: this.currentAccount!,
      });
    } catch (err: any) {
      // TODO [AZ] replace with logger
      // eslint-disable-next-line no-console
      console.error(err);

      // this happens when you logged in into multiple accounts;
      // and since we are always using the first account from msalInstance.getAllAccounts() -
      // we might select the one which does not have an access into our system
      // Note: this code should be removed when multi-user login is available
      if (invalidAccountErrorCodes.includes(err.errorCode)) {
        await this.msalInstance.logout();
      }

      return this.msalInstance.loginRedirect({
        ...authenticationParameters,
        prompt: 'select_account',
      });
    }
  };
  @action idleTimer = async () => {
    const timeCheckInterval = 5000;
    const defaultSessionTime = 3600;
    const timeSessionLimit = !process.env.REACT_APP_SESSION_TIME
      ? defaultSessionTime
      : process.env.REACT_APP_SESSION_TIME;
    let startTime = new Date();
    let timeTik = setInterval(timeCheck, timeCheckInterval);

    const resetTimer = debounce(() => {
      startTime = new Date();
    }, 1000);

    window.onmousemove = resetTimer; // catches mouse movements
    window.onmousedown = resetTimer; // catches mouse movements
    window.onclick = resetTimer; // catches mouse clicks
    window.onscroll = resetTimer; // catches scrolling
    window.onkeypress = resetTimer; //catches keyboard actions
    window.addEventListener('touchstart', resetTimer, false);
    window.addEventListener('touchmove', resetTimer, false);
    window.addEventListener('touchend', resetTimer, false);

    const logout = () => {
      this.msalInstance.loginRedirect({
        ...authenticationParameters,
        account: this.currentAccount!,
      });
      window.removeEventListener('touchstart', resetTimer, false);
      window.removeEventListener('touchmove', resetTimer, false);
      window.removeEventListener('touchend', resetTimer, false);
      clearInterval(timeTik);
    };

    function timeCheck() {
      const res = differenceInSeconds(
        removeUserTimezone(new Date()),
        removeUserTimezone(new Date(startTime)),
      );
      if (res >= timeSessionLimit) {
        return logout();
      }
    }
  };

  @action getFeaturesConfiguration = async () => {
    this.featuresConfigurationReq =
      this.configuration.getFeaturesConfiguration();
    this.portalConfigurationReq = this.configuration.getPortalConfiguration();

    const res = await this.featuresConfigurationReq;
    const portalRes = await this.portalConfigurationReq;
    runInAction(() => {
      if (res?.data && portalRes?.data) {
        this.featuresConfiguration = new FeaturesConfigurationModel({
          ...res?.data,
          portalConfiguration: portalRes?.data,
        });
      }
    });
  };

  @action logout = () => {
    this.msalInstance.logoutRedirect({
      account: this.currentAccount,
    });
    if (this.fCfg.isHaulerWorkspace) {
      userStore.reset();
    }
  };

  // critical calls before first render
  @action onAppStart = async () => {
    this.ensureCurrentAccount();
    await when(() => this.status === InteractionStatus.None);
    await this.setAuthResult();
    if (this.currentAccount && !userStore.userData) {
      await userStore.fetchUser();
      await this.getFeaturesConfiguration();
      await userStore.checkJurisdiction();
      // this.idleTimer()
    }
  };
}

export const { store: authStore, storeCtx: authStoreCtx } = storeFactory(
  AuthStore,
  'auth',
);
