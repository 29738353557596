import {
  ComplianceMatrix,
  MatrixResponse,
  Sb1383OrganicItem,
  Sb1383TextCard,
  Sb1383WaiverItems
} from '../../api/api-types/matrix';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { MatrixApi } from '../../api/matrix';
import { ApiReq, emptyValue } from '../../api';
import { MatrixItem } from './matirx-item';

export interface SB1383Type {
  sb1383SlcpYardWaste: MatrixItem;
  sb1383SlcpFoodWaste: MatrixItem;
  sb1383SlcpOrganicWaste: MatrixItem;
  sb1383SlcpNonOrganicWaste: MatrixItem;
  sb1383SlcpSubjectToComplianceReview: MatrixItem;
  sb1383SlcpCollectionSystem: MatrixItem;
  sb1383SlcpWaiver: Sb1383TextCard;
  sb1383SlcpWaiverItems: Sb1383WaiverItems[];
  sb1383SlcpWaiverItemsTooltip: Sb1383WaiverItems[];
  sb1383SlcpRecycling: Sb1383TextCard;
  sb1383SlcpOrganicItems: Sb1383OrganicItem[];
}

type ExcludeFields =
  | 'location'
  | 'isActual'
  | 'ab341McrTotal'
  | 'sb1383SlcpCollectionSystemTotal'
  | 'sb1383SlcpManuallyConfiguredTotal'
  | 'sb1383SlcpOrganicItems'
  | 'sb1383SlcpWaiver'
  | 'sb1383SlcpWaiverItems'
  | 'sb1383SlcpWaiverItemsTooltip'
  | 'sb1383SlcpRecycling'
  | 'ab1826MoreTotal';

export type MatrixLinkType = {
  [key in keyof Omit<ComplianceMatrix, ExcludeFields>]: MatrixItem;
};
export class Matrix {
  readonly api = new MatrixApi();

  @observable id;
  @observable.ref matrixReq: ApiReq<MatrixResponse> = emptyValue;

  @observable matrix?: ComplianceMatrix;
  @observable sb1383?: SB1383Type;
  @observable ab1826?: MatrixLinkType;
  @observable ab341?: MatrixLinkType;
  @observable isActual?: boolean;

  constructor(id: number) {
    makeObservable(this);
    this.id = id;
    (async () => this.getMatrix(id))();
  }

  creation(
    obj: ComplianceMatrix,
    name: keyof Omit<ComplianceMatrix, ExcludeFields>,
  ): MatrixLinkType {
    return {
      [name]: new MatrixItem({
        ...obj[name]!,
        locationId: this.id,
        getMatrix: this.getMatrix,
      }),
    } as MatrixLinkType;
  }
  @action updateActual = (val?: boolean) => {
    //todo need refactoring remove and rebuild to privet state
    if (typeof val !== 'undefined') {
      this.isActual = val;
    } else {
      this.isActual = !this.isActual;
    }
  };
  @action getMatrix = async (id: number) => {
    this.matrixReq = this.api.getMatrixData(id);
    await this.matrixReq;
    runInAction(() => {
      if (this.matrixReq.state === 'fulfilled' && this.matrixReq?.value.data) {
        this.matrix = this.matrixReq?.value.data;
        this.isActual = this.matrix?.isActual;
        this.ab1826 = {
          ...this.creation(this.matrixReq.value.data, 'ab1826More'),
          ...this.creation(
            this.matrixReq.value.data,
            'ab1826MoreRequiredToRecycle',
          ),
        };
        this.ab341 = {
          ...this.creation(this.matrixReq.value.data, 'ab341Mcr'),
          ...this.creation(
            this.matrixReq.value.data,
            'ab341McrRequiredToRecycle',
          ),
        };
        this.sb1383 = {
          ...this.creation(this.matrixReq.value.data, 'sb1383SlcpFoodWaste'),
          ...this.creation(
            this.matrixReq.value.data,
            'sb1383SlcpNonOrganicWaste',
          ),
          ...this.creation(this.matrixReq.value.data, 'sb1383SlcpOrganicWaste'),
          ...this.creation(this.matrixReq.value.data, 'sb1383SlcpYardWaste'),
          ...this.creation(
            this.matrixReq.value.data,
            'sb1383SlcpSubjectToComplianceReview',
          ),
          ...this.creation(
            this.matrixReq.value.data,
            'sb1383SlcpCollectionSystem',
          ),
          ...this.creation(
            this.matrixReq.value.data,
            'sb1383SlcpSubjectToComplianceReview',
          ),
          sb1383SlcpOrganicItems:
            this.matrixReq.value.data.sb1383SlcpOrganicItems,
          sb1383SlcpWaiver: this.matrixReq.value.data.sb1383SlcpWaiver,
          sb1383SlcpWaiverItems: this.matrixReq.value.data.sb1383SlcpWaiverItems.slice(0, 3),
          sb1383SlcpWaiverItemsTooltip: this.matrixReq.value.data.sb1383SlcpWaiverItems.slice(3, this.matrixReq.value.data.sb1383SlcpWaiverItems.length),
          sb1383SlcpRecycling: this.matrixReq.value.data.sb1383SlcpRecycling,
        };
      }
    });
    return this.matrixReq;
  };
}
