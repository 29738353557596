import { NotFoundRoute } from './pages/404/route';
import { UnauthenticatedAutoLoginRoute } from './pages/AutoLogin/route';
import { LoginRoute, UnauthenticatedHomepage } from './pages/Login/route';

export const routesUnauth = [
  UnauthenticatedAutoLoginRoute,
  LoginRoute,
  NotFoundRoute,
  UnauthenticatedHomepage,
] as const;
