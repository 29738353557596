import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import {
  GeneratorDetailsResponse,
} from 'src/api/api-types/generators';

import { storeFactory } from '../../utils/store';
import { GeneratorsApi } from '../../api/generator';
import { GeneratorModification } from 'src/models/generators/generator-modification';
import { EfgDetailsItem } from 'src/models/edible-food/efg-details-Item';
import { EfgTierData, EFGTypesMeta, GeneratorsMarkingEFGFormType } from '../../api/api-types/food-generators';
import { AccountsModel } from '../../models/account/accounts-model';
import { EfgModel } from '../../models/edible-food/efg-model';
import { ServicesModel } from '../../models/servise/service-model';
import { AssetsModel } from '../../models/assets/assets-model';
import { AccountItem } from '../../api/api-types/accounts';
import { ItemType } from '../../types';
import { debounce } from '@mui/material';
import { FoodGeneratorsApi } from '../../api/foodGenerators';

class GeneratorDetailsStore {
  api = new FoodGeneratorsApi({ prefix: '' });
  apiGenerator = new GeneratorsApi({ prefix: '' });

  @observable accounts = new AccountsModel();
  @observable efgModel = new EfgModel();
  @observable services = new ServicesModel();
  @observable assets = new AssetsModel();

  @observable isCollapseAll?: boolean;
  @observable isMarking: boolean = false;
  @observable isOpenLeftBar: boolean = true;
  @observable generator?: GeneratorModification;
  @observable edibleFood?: EfgDetailsItem = undefined;
  @observable generatorDetailsReq: ApiReq<GeneratorDetailsResponse> = emptyValue;

  @observable.ref efgTypesMetaReq: ApiReq<EFGTypesMeta[]> = emptyValue;
  @observable.ref efgTierReq: ApiReq<ItemType> = emptyValue;
  @observable tierCalculationData?: EfgTierData;

  @observable firstAccounts: AccountItem[] = [];
  @observable firstAccountsLoading: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @computed get efgTypesMeta() {
    if (
      this.efgTypesMetaReq.state !== 'fulfilled' ||
      !this.efgTypesMetaReq.value
    )
      return [];
    return this.efgTypesMetaReq.value.data || [];
  }

  @computed get efgTier() {
    if (
      this.efgTierReq.state !== 'fulfilled' ||
      !this.efgTierReq.value
    )
      return null;
    return this.efgTierReq.value.data;
  }

  @computed get generatorDetails() {
    if (this.generatorDetailsReq.state !== 'fulfilled' || !this.generatorDetailsReq.value) {
      return undefined;
    }
    return this.generatorDetailsReq.value.data;
  }

  @action fetchGeneratorDetails = async (id: number) => {
    this.generatorDetailsReq = this.apiGenerator.getGeneratorDetails(id);
    await this.generatorDetailsReq;
    runInAction(() => {
      if (this.generatorDetails) {
        this.generator = new GeneratorModification(this.generatorDetails);
      }
    });
    return this.generatorDetailsReq;
  };

  @action initEFG = () => {
    runInAction(() => {
      if (!!this.generator) {
        this.edibleFood = new EfgDetailsItem({
          id: this.generator.id || 0,
          name: this.generator.nickname || '',
          edibleFoodGeneratorType: null,
          edibleFoodGeneratorTier: null,
          sqFeet: null,
          seats: null,
          onSiteFoodFacility: false,
          jurisdiction: this.generator.jurisdiction,
          beds: null,
          isEfg: true,
          rooms: null,
          contact: {
            city: this.generator.city,
            state: this.generator.state,
            zipCode: this.generator.zipCode,
            addressLine1: this.generator.addressLine1,
            addressLine2: this.generator.addressLine2,
          },
          description: this.generator.description || '',
          descriptionTruncated: false
        });
      }
    });
  };
  @action fetchEfgTier = debounce(async (data: EfgTierData) => {
    this.tierCalculationData = { ...this.tierCalculationData, ...data };
    this.efgTierReq = this.api.getEFGTier(this.tierCalculationData);
    await this.efgTierReq;
    return this.efgTierReq;
  }, 1500);

  @action saveMarkedEFG = async (data: GeneratorsMarkingEFGFormType) => {
    this.edibleFood = new EfgDetailsItem({
      id: data.accountId.id,
      name: data.name,
      edibleFoodGeneratorType: data.edibleFoodGeneratorType,
      edibleFoodGeneratorTier: null,
      sqFeet: data.sqFeet,
      seats: data.seats,
      onSiteFoodFacility: data.onSiteFoodFacility,
      beds: data.beds,
      isEfg: true,
      rooms: data.rooms,
      contact: {
        fullName: data.fullName,
        email: data.email,
        phone: data.phone,
        zipCode: data.zipCode,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
      },
      description: data.description,
      descriptionTruncated: false
    });
    await this.edibleFood?.editDetails();
    this.toggleMarkingDialog();
    await this.efgModel.fetchEFG();
    if (!this.generator?.id) return;
    await this.fetchGeneratorDetails(this.generator?.id);
  };

  @action collapseLeftBar = (force?: boolean) => {
    if (force !== undefined) {
      this.isOpenLeftBar = force;
    } else {
      this.isOpenLeftBar = !this.isOpenLeftBar;
    }
  };

  @action collapseAll = (force?: boolean) => {
    if (force !== undefined) {
      this.isCollapseAll = force;
    } else {
      this.isCollapseAll = !this.isCollapseAll;
    }
  };

  @action toggleMarkingDialog = () => {
    this.isMarking = !this.isMarking;
  };

  @action initAccounts = async () => {
    await this.accounts.fetchAccounts();
  };

  @action initGeneralInfo = async () => {
    this.firstAccountsLoading = !this.firstAccountsLoading;
    this.accounts.pagination.setPagination({
      totalPages: this.accounts.pagination.totalPages,
      totalCount: this.accounts.pagination.totalCount,
      pagination: {
        size: 4,
        num: 1,
      },
    });
    await this.initAccounts();
    runInAction(() => {
      if (this.accounts.accountsReq.state === 'fulfilled') {
        this.firstAccounts = this.accounts.accountsData;
      }
    });
    this.accounts.pagination.resetPagination();
    this.firstAccountsLoading = !this.firstAccountsLoading;
  };

  @action resetAllCollapseState = () => this.isCollapseAll = undefined;

  @action pageInitCall = async (generatorId: number) => {
    this.services.filter.setPersistedFilters({
      'q.location.id': generatorId,
    });
    this.assets.filter.setPersistedFilters({
      'q.parentLocation.id': generatorId,
    });
    //Todo find better approach for Persisted flow.
    this.efgModel.filter.setPersistedFilters({
      'q.location.id': String(generatorId),
    });
    this.accounts.filter.setPersistedFilters({
      'q.location.id': String(generatorId),
    });
    await Promise.all([
      await this.fetchGeneratorDetails(generatorId),
      await this.initGeneralInfo(),
    ]);
  };
  @action fetchEFGTypesMeta = async (name?: string) => {
    this.efgTypesMetaReq = this.api.getEFGTypesWithMeta(name);
    await this.efgTypesMetaReq;
    return this.efgTypesMetaReq;
  };


  @action reset = () => {
    this.isMarking = false;
    this.edibleFood = undefined;
    this.accounts.resetStore();
    this.resetAllCollapseState();
    this.services.resetStore();
    this.efgModel.filter.resetPersistedFilters();
    this.efgModel.resetStore();
    this.assets.resetStore();
  };
}

export const {
  store: generatorDetailsStore,
  storeCtx: generatorDetailsStoreCtx,
} = storeFactory(
  GeneratorDetailsStore,
  'generatorDetails',
);
