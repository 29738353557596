import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const NoFilesIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="300"
    height="136"
    viewBox="0 0 300 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.4828 135.331H290.304L290.732 99.5332C293.141 40.1633 227.684 -5.05964 173.339 18.4278L108.259 44.3497L93.9773 34.8258C59.0477 9.85778 6.93808 40.1314 11.1065 82.9985C11.1054 82.9973 14.4828 135.331 14.4828 135.331Z"
      fill="#DCECFD"
    />
    <path
      d="M3.60156 135.33V76.1211H32.7569V101.493H133.341V76.9873H205.12V101.493H299.999V135.403L3.60156 135.33Z"
      fill="#F1F8FE"
    />
    <path
      d="M211.78 50.611L207.991 51.3382C207.658 51.4022 207.317 51.411 206.981 51.3644L187.255 48.5469L186.574 52.3366L204.429 57.9774C206.472 58.7673 210.112 58.1769 212.26 58.1051L211.78 50.611Z"
      fill="#F4D2C6"
    />
    <path
      d="M208.694 50.097L207.809 59.1491L213.921 58.4082L212.259 48.9766L208.694 50.097Z"
      fill="#EDA19D"
    />
    <path
      d="M187.257 48.5483C187.257 48.5483 184.516 45.6567 184.227 45.9827C183.937 46.3086 184.908 47.6832 184.732 47.7926C184.556 47.902 178.928 47.7197 179.001 48.1904C179.073 48.6611 180.117 51.9916 180.983 52.2093C181.849 52.427 186.579 52.3381 186.579 52.3381L187.257 48.5483Z"
      fill="#F4D2C6"
    />
    <path
      d="M223.52 47.0703L210.594 50.2435L211.159 59.1497L222.931 57.9085L223.52 47.0703Z"
      fill="#3C7ED7"
    />
    <path
      d="M238.306 128.307L238.502 129.924L234.87 133.708C234.659 133.929 234.516 134.207 234.46 134.508C234.404 134.809 234.437 135.12 234.556 135.402L243.709 131.527L241.399 126.996L238.306 128.307Z"
      fill="#152E60"
    />
    <path
      d="M212.027 72.4883L204.941 90.2939C203.147 92.1278 204.346 127.223 204.022 130.254H209.341L215.157 95.7762L228.04 75.7093L212.027 72.4883Z"
      fill="#324D90"
    />
    <path
      d="M219.519 88.9825L218.775 79.8438C218.775 79.8438 210.455 94.0716 210.318 96.4173C210.18 98.7629 207.02 131.195 207.02 131.195H210.863C211.98 125.749 216.103 92.9216 219.516 88.9825"
      fill="#2A437D"
    />
    <path
      d="M218.195 72.7305L220.011 95.0051C219.17 96.6476 236.851 128.224 237.581 130.255L242.291 127.774L231.907 95.7984L233.655 76.2672L218.195 72.7305Z"
      fill="#324D90"
    />
    <path
      d="M218.742 77.2492C218.742 77.2492 223.542 84.1767 234.81 86.2363L235.385 78.194L219.26 76.082L218.748 77.2514"
      fill="#2A437D"
    />
    <path
      d="M204.876 130.254L204.43 131.82L199.62 133.891C199.34 134.011 199.101 134.212 198.933 134.468C198.765 134.723 198.676 135.023 198.676 135.329H208.609L208.233 130.254H204.876Z"
      fill="#152E60"
    />
    <path
      d="M233.39 41.2426C229.918 48.0425 221.832 44.434 225.536 37.2009C228.576 30.0055 236.437 34.837 233.39 41.2426Z"
      fill="#F4D2C6"
    />
    <path
      d="M223.711 38.2913L232.203 38.7301V36.4961L225.639 34.3477L223.711 38.2913Z"
      fill="#EDA19D"
    />
    <path
      d="M233.541 40.8906C233.541 40.8906 229.316 48.8349 229.045 51.6501C229.045 51.6501 225.981 51.2022 223.711 49.1426C223.711 49.1426 228.626 42.9764 229.045 41.2291L233.541 40.8906Z"
      fill="#F4D2C6"
    />
    <path
      d="M227.057 44.6641C227.057 44.6641 227.98 45.0824 229.649 44.6641C229.649 44.6641 228.36 46.7385 226.195 45.8734L227.057 44.6641Z"
      fill="#EDA19D"
    />
    <path
      d="M225.969 35.3757C225.969 35.3757 227.749 36.7047 231.062 37.8832C231.619 40.5047 228.53 41.6263 232.156 43.6277C234.199 41.5567 239.03 36.9498 233.102 33.5133C230.408 31.8367 227.761 32.3143 225.969 35.3757Z"
      fill="#59445A"
    />
    <path
      d="M232.001 40.9589C231.443 42.2001 229.515 41.2917 230.108 40.0676C230.667 38.8218 232.595 39.7337 232.001 40.9589Z"
      fill="#F4D2C6"
    />
    <path
      d="M227.691 32.6586L233.579 42.3205C241.195 38.647 232.829 27.9137 227.691 32.6586Z"
      fill="#4292F3"
    />
    <path
      d="M230.705 37.603C225.679 35.9207 223.991 36.5271 221.727 31.0938L227.692 32.6575L230.705 37.603Z"
      fill="#324D90"
    />
    <path
      d="M235.727 35.1607C235.374 35.4616 234.269 33.9799 234.655 33.7234C235.008 33.4237 236.113 34.9054 235.727 35.1607Z"
      fill="#324D90"
    />
    <path
      d="M235.754 57.2853C236.051 51.1647 232.123 47.2792 226.934 46.9202C222.914 46.1121 218.776 48.8954 217.101 53.5309L209.438 74.7581L234.689 79.837L235.754 57.2853Z"
      fill="#4292F3"
    />
    <path
      d="M226.344 46.832L227.081 48.4779C227.948 50.028 229.822 49.1332 231.102 48.0516L230.132 47.4612L226.344 46.832Z"
      fill="white"
    />
    <path
      d="M226.343 46.8314L224.689 47.7432C224.154 48.0065 223.796 47.383 224.072 46.9328C224.816 46.7519 225.587 46.7174 226.343 46.8314Z"
      fill="white"
    />
    <path
      d="M227.701 64.1507L228.722 65.2563L234.581 57.3359C234.581 57.3359 230.298 67.9518 228.99 68.2778C227.681 68.6038 222.602 67.8937 222.602 67.8937L227.701 64.1507Z"
      fill="#3C7ED7"
    />
    <path
      d="M222.064 58.2288L221.531 57.7136L227.702 52.3281L214.626 60.3898L213.828 62.6009L218.348 61.6242L222.064 58.2288Z"
      fill="#3C7ED7"
    />
    <path
      d="M222.064 58.2305L218.354 61.6259L196.82 66.2762L197.601 69.8482L219.794 68.7084C222.54 68.7221 225.61 65.6162 227.707 64.1493L222.064 58.2305Z"
      fill="#F4D2C6"
    />
    <path
      d="M220.047 58.4058L224.287 67.6061L229.224 63.3114L222.602 56.6289L220.047 58.4058Z"
      fill="#EDA19D"
    />
    <path
      d="M197.023 66.3346C197.023 66.3346 193.287 64.9725 193.173 65.3931C193.06 65.8137 194.535 66.6104 194.421 66.7882C194.308 66.966 189.194 69.3208 189.468 69.7095C189.741 70.0981 192.155 72.6125 193.025 72.4198C193.896 72.2272 198.329 69.7072 198.329 69.7072L197.023 66.3346Z"
      fill="#F4D2C6"
    />
    <path
      d="M157.987 30.0183L134.35 44.6644L123.547 15.9899L147.184 1.34375L157.987 30.0183Z"
      fill="#F3AC4A"
    />
    <path
      d="M175.046 29.198L157.989 30.0152L147.188 1.33952L164.244 0.523438L175.046 29.198Z"
      fill="#F08F3C"
    />
    <path
      d="M137.969 10.59L134.788 12.563L133.703 9.68389L136.884 7.71094L137.969 10.59Z"
      fill="#FDF1E6"
    />
    <path
      d="M159.763 3.6784L155.978 3.83113L154.895 0.952051L158.675 0.789062L159.763 3.6784Z"
      fill="#FDF1E6"
    />
    <path
      d="M134.348 44.6637L151.999 43.1068L175.046 29.1992L157.99 30.0164L134.348 44.6637Z"
      fill="#152E60"
    />
    <path
      d="M103.016 63.3649C103.016 63.3649 113.57 49.0345 137.9 32.8633L139.345 35.4164C139.345 35.4164 122.031 45.1079 103.016 63.3649Z"
      fill="white"
    />
    <path
      d="M144.207 51.3827C144.207 51.3827 165.543 42.0308 180.014 38.6092L179.344 34.1641C179.344 34.1641 162.905 39.8516 144.207 51.3827Z"
      fill="white"
    />
    <path
      d="M99.6992 74.6663C99.6992 74.6663 112.051 61.8746 128.808 50.3047L130.137 52.9661C130.137 52.9661 119.42 58.5612 99.6992 74.6663Z"
      fill="white"
    />
    <path
      d="M111.372 100.129H82.3008V135.403H111.372V100.129Z"
      fill="#F3AC4A"
    />
    <path
      d="M99.0817 100.129H94.5898V103.552H99.0817V100.129Z"
      fill="#FDF1E6"
    />
    <path
      d="M126.556 100.129H111.371V135.403H126.556V100.129Z"
      fill="#F08F3C"
    />
    <path
      d="M121.704 100.129H117.855V103.552H121.704V100.129Z"
      fill="#FDF1E6"
    />
    <path
      d="M31.6239 114.086H14.0547V135.403H31.6239V114.086Z"
      fill="#F3AC4A"
    />
    <path
      d="M24.1951 114.086H21.4805V116.155H24.1951V114.086Z"
      fill="#FDF1E6"
    />
    <path d="M40.8017 114.086H31.625V135.403H40.8017V114.086Z" fill="#F08F3C" />
    <path d="M37.8684 114.086H35.543V116.155H37.8684V114.086Z" fill="#FDF1E6" />
    <path
      d="M27.5302 92.8398H9.96094V114.157H27.5302V92.8398Z"
      fill="#F3AC4A"
    />
    <path
      d="M20.1014 92.8398H17.3867V94.9085H20.1014V92.8398Z"
      fill="#FDF1E6"
    />
    <path
      d="M36.7041 92.8398H27.5273V114.157H36.7041V92.8398Z"
      fill="#F08F3C"
    />
    <path
      d="M33.7707 92.8398H31.4453V94.9085H33.7707V92.8398Z"
      fill="#FDF1E6"
    />
    <path
      d="M27.5281 113.914H14.0547V114.255H27.5281V113.914Z"
      fill="#F08F3C"
    />
    <path
      d="M80.958 67.043L82.6739 72.3897L101.173 79.079L99.1593 81.7005C98.3762 81.3791 77.1618 78.965 77.3411 77.9928C75.2983 76.6935 75.372 74.0435 75.4243 71.9087L76.6057 67.9069L80.958 67.043Z"
      fill="#EDA19D"
    />
    <path
      d="M68.0111 130.254L68.456 131.826L73.2668 133.897C73.5472 134.017 73.7861 134.218 73.9539 134.473C74.1218 134.729 74.2112 135.029 74.211 135.335H64.2773L64.6541 130.26L68.0111 130.254Z"
      fill="#152E60"
    />
    <path
      d="M41.9217 125.338L41.5574 126.923L44.7725 131.07C44.9599 131.312 45.0726 131.603 45.0966 131.908C45.1206 132.214 45.0549 132.519 44.9076 132.788L36.2109 127.968L38.9823 123.707L41.9217 125.338Z"
      fill="#152E60"
    />
    <path
      d="M82.1373 35.9023C82.4142 36.3047 83.2234 39.2328 81.9455 40.6108L80.957 36.6215L82.1373 35.9023Z"
      fill="#1A1949"
    />
    <path
      d="M72.2917 38.821C70.9866 46.6046 80.4039 48.3473 81.9485 40.6093C83.2502 32.8269 73.8329 31.0831 72.2917 38.821Z"
      fill="#F4D2C6"
    />
    <path
      d="M82.3002 38.6753C78.3542 42.3135 74.9132 38.1168 73.9883 34.9141L81.6772 36.1838L82.3002 38.6753Z"
      fill="#EDA19D"
    />
    <path
      d="M72.371 38.4336C72.371 38.4336 71.1703 47.6293 69.7188 50.1505C69.7188 50.1505 72.556 51.6231 75.6849 51.2561C75.6849 51.2561 75.2457 43.134 75.9379 41.4175L72.371 38.4336Z"
      fill="#F4D2C6"
    />
    <path
      d="M75.5522 45.4928C75.5522 45.4928 74.5308 45.2876 73.3812 43.9336C73.3812 43.9336 73.2234 46.4468 75.5522 47.0247V45.4928Z"
      fill="#EDA19D"
    />
    <path
      d="M75.2551 34.988C75.4821 38.9248 74.3472 39.6611 71.8209 42.0307C70.9686 38.9533 69.0427 36.2246 73.1113 33.7422L75.2551 34.988Z"
      fill="#59445A"
    />
    <path
      d="M72.1136 39.1839C71.8242 40.8024 74.2699 41.2549 74.5763 39.6398C74.8827 38.0247 72.4177 37.5666 72.1136 39.1839Z"
      fill="#F4D2C6"
    />
    <path
      d="M74.4968 35.5218C74.4968 35.5218 77.7165 37.7033 79.656 38.4499L78.2136 36.6C78.2136 36.6 81.0361 38.4237 83.8268 38.549C83.8268 38.549 83.9005 34.3638 80.6593 32.7829L82.5886 33.11C80.0918 31.2738 74.254 28.3799 72.6016 34.1107L74.4968 35.5218Z"
      fill="#59445A"
    />
    <path
      d="M75.2432 36.0128C75.2432 36.0128 73.2708 35.0315 72.5989 34.1094C72.6035 34.1094 71.5719 35.1785 75.2432 36.0128Z"
      fill="#1A1949"
    />
    <path
      d="M79.277 53.3477L82.1608 66.8016L76.0312 68.0189L79.277 53.3477Z"
      fill="#3C7ED7"
    />
    <path
      d="M79.1353 59.336C80.8751 53.4638 77.9891 48.7497 73.0296 47.1574C69.3185 45.409 64.6382 47.121 61.9077 51.2253L50.4453 70.4876L72.7459 80.9918L79.1353 59.336Z"
      fill="#4292F3"
    />
    <path
      d="M78.3872 61.8787C78.1316 61.7523 77.9201 61.5512 77.7805 61.3018C77.641 61.0523 77.5799 60.7663 77.6053 60.4813L78.4394 51.2753C77.7744 49.8928 76.8664 49.3229 76.8664 49.3229L75.5852 59.5342C75.5722 59.6368 75.5351 59.7349 75.4768 59.8202C75.4185 59.9055 75.3408 59.9756 75.2501 60.0247C75.1594 60.0737 75.0584 60.1003 74.9554 60.1022C74.8524 60.1041 74.7504 60.0813 74.658 60.0357C65.2985 55.3865 68.1709 58.3887 69.5952 46.4883C69.0504 46.5121 68.5096 46.5927 67.9814 46.7288C67.0735 49.0699 66.1758 55.1107 64.0456 56.7007C58.8478 62.5364 47.079 68.3549 47.7361 77.7706H47.7509C49.1604 106.41 50.756 98.8964 37.9727 124.481L42.0469 126.839L57.1466 108.994C59.7478 106.258 60.5842 101.471 61.5114 97.9379C62.7393 100.787 64.0082 127.123 64.3361 130.789L68.8757 131.043C69.0187 129.804 74.1246 101.408 73.7954 100.924L72.7536 80.9905H72.7638H72.7536C72.7343 80.9723 78.3895 61.8981 78.3872 61.8787Z"
      fill="#324D90"
    />
    <path
      d="M61.5117 97.9322L62.4355 85.5781C72.0821 100.232 65.6756 118.279 64.3365 130.783L61.5117 97.9322Z"
      fill="#2A437D"
    />
    <path
      d="M49.0488 69.102C48.4813 80.1795 72.9235 76.3418 75.0378 76.0455L56.1986 71.2254L54.3453 64.3867L49.0488 69.102Z"
      fill="#2A437D"
    />
    <path
      d="M67.1032 58.4643C66.5028 59.8013 64.4271 58.8233 65.0661 57.5058C65.6664 56.1688 67.7421 57.1467 67.1032 58.4643Z"
      fill="#2A437D"
    />
    <path
      d="M76.7634 63.0118C76.163 64.3476 74.0873 63.3708 74.7262 62.0521C75.3266 60.7163 77.4 61.693 76.7634 63.0118Z"
      fill="#2A437D"
    />
    <path
      d="M66.8829 50.4336L63.2195 64.1896L53.9531 62.244C53.9531 62.244 55.5806 49.5412 67.9781 46.7305L66.8829 50.4336Z"
      fill="#4292F3"
    />
    <path
      d="M70.9297 46.5285C70.9297 46.5285 73.086 50.2089 75.5827 48.3795C75.5759 48.3795 72.5821 46.2071 70.9297 46.5285Z"
      fill="#F4D2C6"
    />
    <path
      d="M70.9316 46.5293L73.4284 48.7963C70.8181 50.1059 70.6354 48.6379 70.0078 46.4815C70.3166 46.4675 70.6259 46.4835 70.9316 46.5293Z"
      fill="white"
    />
    <path
      d="M73.4258 48.7949L74.3121 49.8994C75.3437 50.8556 75.8817 49.6384 76.2267 48.7904C76.0178 48.641 75.8007 48.5036 75.5764 48.3789L73.4258 48.7949Z"
      fill="white"
    />
    <path
      d="M61.8695 63.9054L61.2725 66.4026L80.4714 73.8591L80.0413 76.9091L58.7474 73.7588C57.4368 73.5643 56.2337 72.9199 55.3427 71.9351C52.8698 69.6351 54.3032 65.2332 54.6493 62.3906L61.8695 63.9054Z"
      fill="#F4D2C6"
    />
    <path
      d="M62.6014 65.448L53.1523 65.595L53.6131 61.2148L62.8148 62.3307L62.6014 65.448Z"
      fill="#EDA19D"
    />
    <path
      d="M81.4862 42.1352C81.4862 42.1352 77.808 41.2416 77.1974 41.6793C76.5869 42.1169 77.3972 45.3391 78.2574 45.7437C78.5807 45.8946 78.9412 45.9461 79.2936 45.8917C79.646 45.8374 79.9745 45.6796 80.2378 45.4383C80.5783 45.1237 81.4862 42.1352 81.4862 42.1352Z"
      fill="#59445A"
    />
    <path
      d="M80.661 42.9363L78.7227 42.6172C78.7227 42.6172 79.7395 43.4857 80.661 42.9363Z"
      fill="white"
    />
    <path
      d="M100.263 78.7497C100.263 78.7497 105.695 77.6989 105.618 78.5218C105.588 78.825 103.615 79.4336 103.615 79.4336C103.615 79.4336 107.681 80.5916 107.681 80.8971C107.681 81.2025 104.798 83.7283 103.432 83.2405C102.065 82.7526 99.1602 81.6972 99.1602 81.6972L100.263 78.7497Z"
      fill="#EDA19D"
    />
    <path
      d="M80.4731 73.8652C80.4731 73.8652 84.6631 73.1289 84.8458 73.5711C85.0285 74.0133 82.7156 74.4681 82.761 74.6972C82.8064 74.9263 87.1871 76.3772 86.9987 76.7682C86.8103 77.1591 83.4487 78.8198 82.8529 78.6534C82.2571 78.487 79.3711 76.8149 79.3711 76.8149L80.4731 73.8652Z"
      fill="#F4D2C6"
    />
    <path d="M276.25 111.082H252.094V135.342H276.25V111.082Z" fill="#BEDBFA" />
    <path
      d="M266.124 111.082H262.219V113.733H266.124V111.082Z"
      fill="#CBE3FC"
    />
    <path
      d="M273.098 86.8242H248.941V111.084H273.098V86.8242Z"
      fill="#BEDBFA"
    />
    <path
      d="M262.972 86.8242H259.066V89.4753H262.972V86.8242Z"
      fill="#CBE3FC"
    />
    <path
      opacity="0.25"
      d="M273.096 111.082L252.094 115.503V111.082H273.096Z"
      fill="#3C7ED7"
    />
    <path
      d="M282.332 86.8242H273.098V111.084H282.332V86.8242Z"
      fill="#D4E8FC"
    />
    <path d="M285.395 111.082H276.16V135.342H285.395V111.082Z" fill="#D4E8FC" />
    <path
      d="M0 135.404C98.0714 135.023 200.524 135.02 298.589 135.404C200.518 135.789 98.0658 135.786 0 135.404Z"
      fill="#152E60"
    />
  </SvgIcon>
);
export default NoFilesIcon;
