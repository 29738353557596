import { lazy } from 'react';

import { MinervaRoute } from 'src/utils/router';


import { accountsStore } from './store';

export const AccountsRoute: MinervaRoute<'accounts'> = {
  name: 'accounts',
  path: '/accounts',
  comp: () => lazy(() => import('./index')),
  activate: async () => {
    accountsStore.filter.resetFilters();
    accountsStore.filter.resetPersistedFilters();

    await accountsStore.fetchAccounts();
  },
  deactivate: () => accountsStore?.resetStore(),
};