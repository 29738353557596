import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GreenIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_1813_80279)">
      <circle cx="24.25" cy="24" r="24" fill="#DDF5EB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.248 12C13.1435 12 12.248 12.8954 12.248 14V34C12.248 35.1046 13.1435 36 14.248 36H34.248C35.3526 36 36.248 35.1046 36.248 34V14C36.248 12.8954 35.3526 12 34.248 12H14.248Z"
        fill="#57CC99"
      />
      <path
        d="M33.2782 19.8161L30.4375 17L21.3677 26.1493L18.0954 22.9054L15.2793 25.7461L21.3926 31.8063L33.2782 19.8161Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1813_80279">
        <rect width="48" height="48" fill="white" transform="translate(0.25)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
export default GreenIcon;
