import { styled, Theme } from '@mui/material';
import { CSSProperties } from '@mui/styled-engine';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ReportStatus } from 'src/types';

const statusColor = (status: ReportStatus, theme: Theme) => {
  switch (status) {
    case 'closed':
      return theme.palette.success.main;
    case 'warning':
      return theme.palette.warning.main;
    case 'info':
      return theme.palette.info.main;
  }
};

const Container = styled('div')<{ status: ReportStatus }>(
  ({ theme, status }) => ({
    textTransform: 'capitalize',
    borderRadius: 4,
    padding: theme.spacing(0.25, 1),
    color: theme.palette.minervaColors.primaryWhite,
    backgroundColor: statusColor(status, theme),
    ...(theme.typography.hint as CSSProperties),
  }),
);

const TableStatus = ({ row: { status } }: GridRenderCellParams) => (
  <Container status={status}>{status}</Container>
);

export default TableStatus;
