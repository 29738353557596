import { ClientTypes } from 'src/api/api-types/lookups';
import { UserItem, UserProfileResponse, UsersFilter } from 'src/api/api-types/user';
import * as yup from 'yup';

import { ColumnsVisibilityModel } from '../../../api/api-types/gridColumnsManager';
import { authStore } from '../../../stores/auth';
import { userColumns } from './gridColumns';
import { TableFilter } from 'src/models/filter/table-filter';

export const initialUserData: UserItem = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  active: true,
  lastLoggedIn: null,
  client: null,
  clientType: null,
  createdAt: new Date(),
  createdBy: null,
  updatedAt: new Date(),
  updatedBy: null,
  jurisdictions: [],
  haulers: [],
  agencies: [],
  employer: '',
};

export const UsersTabs = {
  member: '1',
  guest: '2',
};

export enum UserTypes {
  Hauler = 1,
  Jurisdiction = 2,
  Agency = 3,
  Administrator = 5,
}

export const ADMIN_USER_ID = 1;

export const generateInitialUser = (
  type: number,
  { clientType, client }: UserProfileResponse,
) => {
  switch (type) {
    case ClientTypes.Jurisdiction:
      return {
        ...initialUserData,
        clientType,
        client,
      };
    default:
      return initialUserData;
  }
};

const formValidationObj = {
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  clientType: yup
    .object()
    .label('User Type')
    .typeError('User Type is required'),
  client: yup
    .object()
    .label('Client')
    .typeError('Client  is required')
    .when('clientType', (clientType, schema) => {
      return clientType?.id !== UserTypes.Administrator
        ? schema.required()
        : schema.nullable();
    }),
  ...(authStore.fCfg.userConnectedTo && {
    jurisdictions: yup
      .array()
      .label('Jurisdictions')
      .typeError('Jurisdictions  is required')
      .when('clientType', (clientType, schema) => {
        return clientType?.id !== UserTypes.Jurisdiction
          ? schema.min(1)
          : schema.nullable();
      }),
    haulers: yup
      .array()
      .label('Haulers')
      .typeError('Haulers  is required')
      .when('clientType', (clientType, schema) => {
        return clientType?.id !== UserTypes.Hauler
          ? schema.min(1)
          : schema.nullable();
      }),
    agencies: yup
      .array()
      .label('Agencies')
      .typeError('Agencies  is required')
      .when('clientType', (clientType, schema) => {
        return clientType?.id === UserTypes.Administrator
          ? schema.min(1)
          : schema.nullable();
      }),
  }),
};

export const memberValidationSchema = (
  domainName: string = `${process.env.REACT_APP_Minerva_Domain}`,
) =>
  yup.object({
    email: yup
      .string()
      .label('Email')
      .required()
      .test(
        'isCorrectDomain',
        `Enter correct email with @${domainName} domain.`,
        value => {
          const emailName = value?.split('@')[0];
          const domain = value?.split('@')[1];
          return !!emailName && domain === domainName;
        },
      ),
    ...formValidationObj,
  });

export const guestValidationSchema = yup.object({
  email: yup.string().email().label('Email').required(),
  ...formValidationObj,
});

export const userColVisibleModel = {
  createdBy: false,
  createdAt: false,
  agencies: false,
  jurisdictions: false,
  haulers: false,
};

export const visibilityDefaultState: Partial<ColumnsVisibilityModel> = {
  admin: {
    ...userColVisibleModel,
  },
  hauler: {
    ...userColVisibleModel,
  },
};

export const mainFilters = {
  'q.firstName': '',
  'q.lastName': '',
  'q.email': '',
  'q.active': '',
};

export const adminFilters = {
  'q.clientTypeIds': '',
};

export const conversFilterParams = (filter: TableFilter<UsersFilter, UserItem>): TableFilter<UsersFilter, UserItem> => {
  const params = filter.filterParams;
  const res = {
    ...params,
    ...(!!params['q.clientTypeIds'] && {'q.clientTypeIds': [params['q.clientTypeIds'].id]}),
    ...(!!params['q.active'] && {'q.active': params['q.active'].state})
    
  };
  const t =  new TableFilter<UsersFilter, UserItem>(
    userColumns,
    {...mainFilters, ...adminFilters},
    {},
  );
  t.setFilterValue(res)
  return t;
}