import { ApiClient } from './';
import {
  FoodCreationReq,
  FoodRecoveryItem,
  RequestFoodsParams,
  DeleteResponse,
  FoodDetailsResponse,
  EfrAddressType,
  creteEFRdata,
  EFRCreationType,
  EFRResponse,
  FoodFilters,
} from './api-types/foods';
import {
  AsyncExportResponse,
  ExportParams,
  FullDescriptionResponse,
  ItemType,
  ListResponseType,
  Pageable,
  ParamsWithFilters,
} from '../types';
import { dotNameKeyToObject } from '../utils/string';
import { matchAddressData } from './api-types/generators';

export class FoodsApi extends ApiClient {
  getFoods({ page, pageSize, filters }: RequestFoodsParams) {
    return this.requestPost<EFRResponse, ParamsWithFilters<RequestFoodsParams>>(
      `food-recoveries/list`,
      {
        q: dotNameKeyToObject(filters),
        page: {
          num: page,
          size: pageSize,
        },
      },
    );
  }

  async getFoodsFilterOptions({ filters, column }: { filters: Partial<FoodFilters>, column: string }) {
    const filterMap: { [key: string]: string } = {
      jurisdiction: 'jurisdiction.name',
      type: 'type.name'
    };
    const filterColumn = filterMap[column] ?? column;
    const response = await this.requestPost<
      ListResponseType<ItemType> & Pageable,
      ParamsWithFilters<FoodFilters> & { column: string }
    >('food-recoveries/list/filter-options', {
      q: dotNameKeyToObject(filters),
      page: {
        num: 1,
        size: 100000,
      },
      column: filterColumn,
    });

    return response.data!.items;
  }

  getFoodDetails(id: number) {
    return this.requestGet<FoodDetailsResponse>(`food-recoveries/${id}`);
  }

  updateFood(item: Partial<EFRCreationType>) {
    return this.requestPut<FoodDetailsResponse, Partial<EFRCreationType>>(
      `food-recoveries/${item.id}`,
      item,
    );
  }

  updateFoodAddress(item: EfrAddressType & { id: number }) {
    return this.requestPut<
      FoodDetailsResponse,
      EfrAddressType & { id: number }
    >(`food-recoveries/${item.id}/address`, item);
  }

  updateDescription(item: Partial<FoodCreationReq & { id: number }>) {
    return this.requestPut<FoodDetailsResponse, Partial<FoodCreationReq>>(
      `food-recoveries/${item.id}/description`,
      item,
    );
  }

  createFood(item: creteEFRdata) {
    return this.requestPost<FoodRecoveryItem, creteEFRdata>(
      `food-recoveries`,
      item,
    );
  }

  deleteFood(id: number) {
    return this.requestDelete<DeleteResponse>(`food-recoveries/${id}`);
  }

  activateFood(id: number) {
    return this.requestPut<any, void>(`food-recoveries/${id}/reactivate`);
  }

  getJurisdictionByAddress(data: matchAddressData) {
    return this.requestGet<ItemType>(
      'clients/jurisdictions/match-address',
      data,
    );
  }

  getFullDescription(id: number) {
    return this.requestGet<FullDescriptionResponse>(
      `food-recoveries/${id}/description`,
    );
  }

  getReportByType(params: ExportParams, type: string) {
    return this.requestExportPost<Blob, ExportParams>(
      `food-recoveries/export/${type}`,
      params,
    );
  }

  getAsyncReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `food-recoveries/export/${exportType}?flowType=2`,
      params,
    );
  }
}
