import { createContext, PropsWithChildren, useContext } from 'react';

import { once } from 'lodash';

import { AssetsStore } from '../../pages/Assets/store';

export interface AssetsTableProps {
  assetsTableCtx: AssetsStore;
}

export const createAssetsTableCtx = once(() =>
  createContext<AssetsTableProps | undefined>(undefined),
);

export const AssetsTableProvider = ({
  children,
  assetsTableCtx,
}: PropsWithChildren<AssetsTableProps>) => {
  const AssetsTableContext = createAssetsTableCtx();
  return (
    <AssetsTableContext.Provider value={{ assetsTableCtx }}>
      {children}
    </AssetsTableContext.Provider>
  );
};

/* Hook to use store in any functional component */
export const useAssetsTableStore = () => {
  const context = useContext(createAssetsTableCtx());

  if (!context) {
    throw new Error(
      'useAssetsTableStore must be used within a AssetsTableProvider',
    );
  }

  return context.assetsTableCtx;
};
