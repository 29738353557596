import { PropsWithChildren } from 'react';

const VisuallyHidden = ({ children }: PropsWithChildren<{}>) => {
  return (
    <span
      style={{
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        width: 1,
        // https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
        whiteSpace: 'nowrap',
        wordWrap: 'normal',
      }}>
      {children}
    </span>
  );
};

export default VisuallyHidden;
