import {
  SubCategoryDetail,
  RecordCategoriesResponse,
  RecordPeriod,
  SubCategoryNote,
  LegalSectionDetail,
} from './api-types/implementation-record';
import { AssetsApi } from './assets';
export class ImplementationRecordApi extends AssetsApi {
  getImplementationRecordPeriods() {
    return this.requestGet<RecordPeriod[]>('implementation-record/periods');
  }

  getImplementationRecordCategories(periodId: number) {
    return this.requestGet<RecordCategoriesResponse>(
      'implementation-record/categories',
      {
        'q.period.id': periodId,
      },
    );
  }

  getRecordSubCategoryDetails(id: number) {
    return this.requestGet<SubCategoryDetail>(
      `implementation-record/sub-categories/${id}`,
    );
  }

  getSubCategoryNote(id: number) {
    return this.requestGet<SubCategoryNote>(
      `implementation-record/sub-categories/${id}/notes`,
    );
  }

  updateSubCategoryNote(id: number, note: SubCategoryNote) {
    return this.requestPut<SubCategoryDetail, SubCategoryNote>(
      `implementation-record/sub-categories/${id}/notes`,
      note,
    );
  }

  getLegalSectionDetail(id: number) {
    return this.requestGet<LegalSectionDetail>(
      `implementation-record/legal-sections/${id}`,
    );
  }
}
