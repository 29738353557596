import { AssetCreationType, AssetFiltersType } from 'src/api/api-types/assets';
import * as yup from 'yup';

import { ColumnsVisibilityModel } from '../../../api/api-types/gridColumnsManager';
import { ExportTypes } from '../../../types';

export const initialAssetsData: AssetCreationType = {
  shortText: '',
  client: { id: 0, name: '' },
  totalAttachments: 0,
  createdAt: new Date(),
  id: 0,
  description: '',
  dateIssued: new Date(),
};

export const validationSchema = yup
  .object({
    type: yup.mixed().label('Type').required(),
    relatedToType: yup.object().label('Related to').nullable(),
    relatedTo: yup.object().label('Related to').nullable(),
    dateIssued: yup
      .string()
      .label('Date issued')
      .nullable()
      .when('client', {
        is: (val: string) => val === '2' || val === '3',
        then: yup.string().required().typeError('Date issued to is required'),
      }),
    description: yup.string().label('Description').optional().max(100_000),
    category: yup
      .number()
      .required()
      .label('Category')
      .typeError('Category  is required'),
    subCategory: yup.string().optional().label('Sub-Category').nullable(),
  })
  .required();

export const validationIRSchema = yup
  .object({
    shortText: yup.string().label('Asset title').required(),
    description: yup.string().label('Description').optional().max(100_000),
  })
  .required();

export const exportDefaultValue = {
  fileName: '',
  mode: ExportTypes.WITH_FILTERS,
};

export const defaultVisibilityColumns = {
  accountType: false,
  generator: false,
  createdAt: false,
  createdBy: false,
  description: false,
  shortText: false,
};

export const visibilityDefaultState: Partial<ColumnsVisibilityModel> = {
  admin: {
    jurisdiction: false,
    ...defaultVisibilityColumns,
  },
  hauler: {
    hauler: false,
    ...defaultVisibilityColumns,
  },
};

export const defaultAssetsSearch = {
  'q.jurisdiction.id': '',
  'q.generator.accountNumber': '',
  'q.generator.name': '',
  'q.generator.reference': '',
  'q.generator.usaAddress': '',
  'q.parentLocation.reference': '',
} as AssetFiltersType;

export const DEFAULT_POPUP_AREA_WIDTH = 380;
