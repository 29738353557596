import { makeObservable } from 'mobx';
import { AssetsModel } from 'src/models/assets/assets-model';
import { storeFactory } from 'src/utils/store';

export class AssetsStore extends AssetsModel {
  constructor() {
    super();
    makeObservable(this);
  }
}

export const { store: assetsStore, storeCtx: assetsStoreCtx } = storeFactory(
  AssetsStore,
  'assets',
);
