import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PDFIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.17138 0C2.62662 0 2.18091 0.45 2.18091 1V15C2.18091 15.55 2.62662 16 3.17138 16H13.0761C13.6208 16 14.0665 15.55 14.0665 15V4L10.1047 0H3.17138Z"
      fill="#E2E5E7"
    />
    <path
      d="M11.0952 4H14.0666L10.1047 0V3C10.1047 3.55 10.5504 4 11.0952 4Z"
      fill="#B0B7BD"
    />
    <path d="M14.0664 7L11.095 4H14.0664V7Z" fill="#CAD1D8" />
    <path
      d="M12.0856 13C12.0856 13.275 11.8627 13.5 11.5903 13.5H0.695185C0.422807 13.5 0.199951 13.275 0.199951 13V8C0.199951 7.725 0.422807 7.5 0.695185 7.5H11.5903C11.8627 7.5 12.0856 7.725 12.0856 8V13Z"
      fill="#FB3640"
    />
    <path
      d="M2.35852 9.47351C2.35852 9.34151 2.46153 9.19751 2.62743 9.19751H3.54213C4.05717 9.19751 4.52071 9.54551 4.52071 10.2125C4.52071 10.8445 4.05717 11.1965 3.54213 11.1965H2.88099V11.7245C2.88099 11.9005 2.77006 12 2.62743 12C2.49669 12 2.35852 11.9005 2.35852 11.7245V9.47351ZM2.88099 9.70101V10.697H3.54213C3.80758 10.697 4.01755 10.4605 4.01755 10.2125C4.01755 9.93301 3.80758 9.70101 3.54213 9.70101H2.88099Z"
      fill="white"
    />
    <path
      d="M5.29644 12C5.1657 12 5.02307 11.928 5.02307 11.7525V9.48152C5.02307 9.33802 5.1657 9.23352 5.29644 9.23352H6.20321C8.0128 9.23352 7.97318 12 6.23887 12H5.29644ZM5.54604 9.72152V11.5125H6.20321C7.27242 11.5125 7.31997 9.72152 6.20321 9.72152H5.54604Z"
      fill="white"
    />
    <path
      d="M8.61494 9.75341V10.3889H9.62472C9.76735 10.3889 9.90998 10.5329 9.90998 10.6724C9.90998 10.8044 9.76735 10.9124 9.62472 10.9124H8.61494V11.7519C8.61494 11.8919 8.51639 11.9994 8.37772 11.9994C8.2034 11.9994 8.09692 11.8919 8.09692 11.7519V9.48091C8.09692 9.33741 8.2039 9.23291 8.37772 9.23291H9.76784C9.94217 9.23291 10.0452 9.33741 10.0452 9.48091C10.0452 9.60891 9.94217 9.75291 9.76784 9.75291H8.61494V9.75341Z"
      fill="white"
    />
    <path
      d="M11.5904 13.5H2.18091V14H11.5904C11.8627 14 12.0856 13.775 12.0856 13.5V13C12.0856 13.275 11.8627 13.5 11.5904 13.5Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
export default PDFIcon;
