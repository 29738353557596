import { Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { ActiveFilterType } from '../../models/filter/constants';

import ActiveInactiveIcon from './ActiveInactiveIcon';
import { useActiveInactiveStore } from './ActiveInactiveProvider';

const ActiveInactiveComponent = () => {
  const { activeMode, fetchCall, handleActiveEntityCall } =
    useActiveInactiveStore();
  if (
    activeMode === undefined ||
    fetchCall === undefined ||
    handleActiveEntityCall === undefined
  ) {
    return null;
  }

  const handleActiveClick = () => {
    handleActiveEntityCall();
    return fetchCall();
  };

  return (
    <Tooltip
      title={
        activeMode === ActiveFilterType.ActiveOnly
          ? 'Show Inactive'
          : 'Hide inactive'
      }>
      <div>
        <ActiveInactiveIcon
          activeMode={activeMode}
          handleClick={handleActiveClick}
        />
      </div>
    </Tooltip>
  );
};

export default observer(ActiveInactiveComponent);
