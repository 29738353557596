import { ApiClient } from '.';
import {
  ComplianceMatrix,
  MatrixResponse,
  OptionsResponse,
  UpdateMatrixRequest,
} from './api-types/matrix';

export class MatrixApi extends ApiClient {
  getMatrixData(id: number) {
    return this.requestGet<MatrixResponse>(`compliance-matrix/${id}`);
  }

  getUserOptions(ruleSetId?: number) {
    return this.requestGet<OptionsResponse>('compliance-matrix/user-options', {
      'complianceStatusItemType.id': ruleSetId,
    });
  }

  updateMatrix(entity: ComplianceMatrix) {
    return this.requestPut<MatrixResponse, ComplianceMatrix>(
      `compliance-matrix/${entity.location.id}`,
      entity,
    );
  }
  updateMatrixOptions(entity: UpdateMatrixRequest) {
    return this.requestPut<MatrixResponse, UpdateMatrixRequest>(
      `compliance-matrix/${entity.location.id}/user-options`,
      entity,
    );
  }
}
