import { GridRowId } from '@mui/x-data-grid-pro';
import {
  AsyncExportResponse,
  ExportParams,
  ItemType,
  ListResponseType,
  Pageable,
  ParamsWithFilters,
  RequestPaginationParams,
} from 'src/types';
import { dotNameKeyToObject } from 'src/utils/string';
import { ApiClient } from '.';
import {
  CratedRelationType,
  AccountChildren,
  AccountContactsResponse,
  AccountCreationType,
  AccountDetailsResponse,
  AccountEditFields,
  AccountHaulerResponse,
  AccountsGroupParams,
  AccountsResponse,
  RequestAccountsParams,
  AccountsFilter,
} from './api-types/accounts';
import { GeneratorDetailsResponse, matchAddressData } from './api-types/generators';

export class AccountsApi extends ApiClient {
  getAccountsData({ page, pageSize, filters }: RequestAccountsParams) {
    return this.requestPost<
      AccountsResponse,
      ParamsWithFilters<AccountsFilter>
    >('generators/list', {
      q: dotNameKeyToObject(filters),
      page: {
        num: page,
        size: pageSize,
      },
    });
  }

  async getAccountsFilterOptions({ filters, column }: { filters: Partial<AccountsFilter>, column: string }) {
    const filterMap: { [key: string]: string } = {
      hauler: 'hauler.name',
      jurisdiction: 'jurisdiction.name',
      agency: 'agency.name',
      type: 'type.name',
      zone: 'zone.name',
      extensionField1: 'extensionField1.name',
      extensionField2: 'extensionField2.name'
    };
    const filterColumn = filterMap[column] ?? column;
    const response = await this.requestPost<
      ListResponseType<ItemType> & Pageable,
      ParamsWithFilters<AccountsFilter> & { column: string }
    >('generators/list/filter-options', {
      q: dotNameKeyToObject(filters),
      page: {
        num: 1,
        size: 100000,
      },
      column: filterColumn,
    });

    return response.data!.items;
  }

  getAccountDetails(id: number) {
    return this.requestGet<AccountDetailsResponse>(`generators/${id}`);
  }

  getAccountContacts(locationId: number) {
    return this.requestGet<AccountContactsResponse>(
      `generators/short?q.location.id=${locationId}`,
      {
        'page.num': 1,
        'page.size': 100,
      },
    );
  }

  getAccountHaulers(locationId: number) {
    return this.requestGet<AccountHaulerResponse>(
      `clients/haulers?q.location.id=${locationId}`,
      {
        'page.num': 1,
        'page.size': 100,
      },
    );
  }

  creteAccount(account: AccountCreationType) {
    return this.requestPost<AccountDetailsResponse, AccountCreationType>(
      'generators',
      account,
    );
  }

  updateAccount({ description, id }: { description: string; id: number }) {
    return this.requestPut<
      AccountDetailsResponse,
      Partial<AccountDetailsResponse>
    >(`generators/${id}/description`, {
      id,
      description,
    });
  }

  updateAccountDetails(data: AccountEditFields) {
    return this.requestPut<AccountDetailsResponse, AccountEditFields>(
      `generators/${data.id}`,
      data,
    );
  }

  createAccountParentGroup(
    parentGeneratorId: number,
    generatorIds: GridRowId[],
  ) {
    return this.requestPost<any, any>('generators/parent-child-group', {
      parentGeneratorId,
      generatorIds,
    });
  }

  createAccountRelatedGroup(
    generatorId: number,
    relatedGeneratorIds: GridRowId[],
  ) {
    return this.requestPost<AccountsResponse, AccountsGroupParams>(
      'generators/create-relation',
      {
        generatorId,
        relatedGeneratorIds,
      },
    );
  }

  createRelatedAccount(generatorId: number, relatedId: number) {
    return this.requestPost<AccountsResponse, CratedRelationType>(
      `generators/${generatorId}/add-related`,
      {
        generatorId,
        relatedId,
      },
    );
  }

  getAccountChildrens({
    page,
    id,
    pageSize,
  }: { id: number } & RequestPaginationParams) {
    return this.requestGet<AccountChildren>(`generators/${id}/children`, {
      'page.num': page,
      'page.size': pageSize,
    });
  }

  getRelatedAccount({
    page,
    id,
    pageSize,
  }: { id: number } & RequestPaginationParams) {
    return this.requestGet<AccountChildren>(`generators/${id}/related-list`, {
      'page.num': page,
      'page.size': pageSize,
    });
  }

  deleteAccountChildren(generatorId: number, childrenId: number) {
    return this.requestDelete<AccountDetailsResponse>(
      `generators/${generatorId}/child/${childrenId}`,
    );
  }

  deleteRelatedAccount(generatorId: number, relatedId: number) {
    return this.requestDelete<AccountDetailsResponse>(
      `generators/${generatorId}/remove-related/${relatedId}`,
    );
  }

  deleteAccountParent(generatorId: number) {
    return this.requestDelete<AccountDetailsResponse>(
      `generators/${generatorId}/parent`,
    );
  }

  getAsyncReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `generators/export/${exportType}?flowType=2`,
      params,
    );
  }
  
  getJurisdictionByAddress(data: matchAddressData) {
    return this.requestGet<ItemType>(
      'clients/jurisdictions/match-address',
      data,
    );
  }

  getLocationDetails(id: number) {
    return this.requestGet<GeneratorDetailsResponse>(`locations/${id}`);
  }

  manageGenerator<T>(generatorId: number, data: T) {
    return this.requestPut<AccountDetailsResponse, T>(
      `generators/${generatorId}/location`,
      data,
    );
  }
}
