import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ContactTypeField } from 'src/pages/AddressBook/components/Contacts/components/TableEditFields/ContactTypeField';

import ContactActionMenu from '../components/Contacts/components/ContactActionMenu';
import { EmailReportLink } from 'src/components/Link/EmailReportLink';

export const contactsColumns: GridColDef[] = [
  {
    field: 'fullName',
    headerName: 'Full Name',
    flex: 1,
    editable: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    editable: true,
    renderCell: ({ value }: GridRenderCellParams) => (
      <EmailReportLink to={value}>{value}</EmailReportLink>
    ),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 150,
    editable: true,
  },
  {
    field: 'addressLine1',
    headerName: 'Address Line 1',
    flex: 1,
    editable: true,
  },
  {
    field: 'addressLine2',
    headerName: 'Address Line 2',
    flex: 1,
    editable: true,
  },
  {
    field: 'city',
    headerName: 'City',
    width: 100,
    editable: true,
  },
  {
    field: 'state',
    headerName: 'State',
    width: 70,
    editable: true,
  },
  {
    field: 'zipCode',
    headerName: 'Zip',
    width: 80,
    editable: true,
  },
  {
    field: 'type',
    headerName: 'Contact Type',
    width: 120,
    editable: true,
    renderCell: ({ value }) => value.name || '',
    renderEditCell: ({ id, value }) => (
      <ContactTypeField id={id} value={value} />
    ),
  },
  {
    field: 'Actions',
    type: 'actions',
    disableColumnMenu: true,
    width: 50,
    align: 'center',
    renderCell: ({ row }) => <ContactActionMenu contact={row} />,
  },
];
