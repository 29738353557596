import { SyntheticEvent, useContext } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { isPending } from 'src/utils/common';
import { DEBOUNCE_DURATION } from 'src/utils/constants';

import { usersStoreCtx } from '../../../store';
import {
  checkedIcon,
  icon,
} from '../../CreateModal/FormFields/ConnectToFields/JurisdictionAutocomplete';
import { useAutocompletStyles } from '../styles';

const EditAgency = () => {
  const { autocompleteMain } = useAutocompletStyles();
  const { agenciesLookup, agenciesLookupReq, fetchAgenciesLookup } =
    useContext(lookupsStoreCtx);
  const { isAdmin, chosenUser } = useContext(usersStoreCtx);
  if (!chosenUser) return null;

  const onInputChange = debounce(
    async (_: SyntheticEvent, value: string, reason: string) => {
      if (reason === 'input' || reason === 'clear') {
        await fetchAgenciesLookup(value, '2');
      }
    },
    DEBOUNCE_DURATION,
  );

  return (
    <Autocomplete
      fullWidth
      disabled={!isAdmin}
      value={chosenUser?.agencies || []}
      onChange={(_, data) => {
        chosenUser?.setEntity({ agencies: data });
      }}
      placeholder={`Select agencies...`}
      className={autocompleteMain}
      onInputChange={onInputChange}
      multiple
      limitTags={2}
      size="small"
      options={agenciesLookup}
      getOptionLabel={option => option?.name || ''}
      isOptionEqualToValue={(option, value) => `${option.id}` === `${value.id}`}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} key={`${option.id}-${option.name}`}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          <ListItemText primary={option.name} />
        </MenuItem>
      )}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={`Select agencies...`}
          sx={{
            display: 'flex',
            maxHeight: '100px',
            overflow: 'auto',
            justifyContent: 'top',
          }}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            style: {
              background: 'white',
              zIndex: 999999,
              display: 'flex',
              maxHeight: '100px',
              overflow: 'auto',
              justifyContent: 'top',
            },
            endAdornment: (
              <>
                {isPending(agenciesLookupReq) && (
                  <CircularProgress color="inherit" size={20} />
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default observer(EditAgency);
