export const serviceHistoryVisibilityModel = {
  generatorId: false,
  jurisdiction: false,
  startDate: false,
  accountType: false,
  location: false,
  containerType: false,
  containerSize: false,
  unitOfMeasure: false,
  weeklyFrequency: false,
  schedule: false,
  weeklyVolumeCubicYards: false,
  onCall: false,
  site: false,
  containerGroup: false,
};

export const defaultFilterFields = {
  'q.location.id': '',
  'q.generator.id': '',
  'q.hauler.id': '',
  'q.jurisdictions': '',
  'q.accountTypes': '',
  'q.haulers': '',
  'q.serviceWasteTypes': '',
  'q.unitsOfMeasure': '',
};