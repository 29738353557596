import { ExportParams, AsyncExportResponse } from 'src/types';
import { ApiClient } from '.';
import {
  ContactCreationReq,
  ContactType,
  ContactsResponse,
  RequestContactsParams,
} from './api-types/address-book';

const mockDetails = [
  {
    id: 1,
    firstName: 'Adam',
    lastName: 'Arber',
    address: {
      id: 28,
      addressLine1: '1310 Sperring Rd, Sonoma, CA 95476',
      addressLine2: '',
      city: 'Castle Rock',
      state: 'CA',
      zipCode: '92868-3356',
    },
    type: {
      id: 30,
      name: 'Client',
    },
    relatedTo: {
      id: 31,
      name: 'Some Hauler Name',
    },
    email: 'adam.arber@gmail.com',
    phone: '+440234569',
  },
  {
    id: 2,
    firstName: 'Arsen',
    lastName: 'Black',
    address: {
      id: 28,
      addressLine1: '1310 Sperring Rd, Sonoma, CA 95476',
      addressLine2: '',
      city: 'Castle Rock',
      state: 'CA',
      zipCode: '92868-3356',
    },
    type: {
      id: 30,
      name: 'Client',
    },
    relatedTo: {
      id: 31,
      name: 'Some Hauler Name',
    },
    email: 'adam.arber@gmail.com',
    phone: '+440234569',
  },
];

export class AddressBookApi extends ApiClient {
  getContacts({ page, pageSize, ...params }: RequestContactsParams) {
    return this.requestGet<ContactsResponse>('contacts', {
      'page.num': page,
      'page.size': pageSize,
      ...params,
    });
  }

  getContactDetail(id: number) {
    const contactDetail = mockDetails.find(item => item.id === id);
    return new Promise<any>(res =>
      setTimeout(() => {
        return res(contactDetail!);
      }, 500),
    );
  }

  createContact(contact: ContactCreationReq) {
    return this.requestPost<ContactType, Partial<ContactCreationReq>>(
      `contacts`,
      contact,
    );
  }

  editContact(id: number, contact: ContactCreationReq) {
    return this.requestPut<ContactType, Partial<ContactCreationReq>>(
      `contacts/${id}`,
      contact,
    );
  }

  deleteContact(id: number) {
    return this.requestDelete<ContactType, Partial<ContactCreationReq>>(
      `contacts/${id}`,
    );
  }

  getAsyncReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `contacts/export/${exportType}?flowType=2`,
      params,
    );
  }
}
