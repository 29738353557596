import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AssetDetailsIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.9773 2.60156C13.861 2.60156 14.5773 3.31791 14.5773 4.20156V15.4016C14.5773 16.2852 13.861 17.0016 12.9773 17.0016H3.37734C2.49369 17.0016 1.77734 16.2852 1.77734 15.4016V4.20156C1.77734 3.31791 2.49369 2.60156 3.37734 2.60156"
      stroke="#767D86"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5766 1H5.77656C5.33473 1 4.97656 1.35817 4.97656 1.8V3.4C4.97656 3.84183 5.33473 4.2 5.77656 4.2H10.5766C11.0184 4.2 11.3766 3.84183 11.3766 3.4V1.8C11.3766 1.35817 11.0184 1 10.5766 1Z"
      stroke="#767D86"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default AssetDetailsIcon;
