import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import {
  EfrDetailsType,
  FoodDetailsResponse,
} from 'src/api/api-types/foods';

import { storeFactory } from '../../utils/store';
import { FoodsApi } from '../../api/foods';
import { servicesStore } from '../Services/store';
import { EfrDetailsItem } from '../../models/edible-food/efr-details-item';
import { AssetsModel } from '../../models/assets/assets-model';

class FoodDetailsStore {
  apiFood = new FoodsApi({ prefix: '' });

  @observable isCollapseAll?: boolean;
  @observable isOpenLeftBar: boolean = true;
  @observable isFilterOpen = false;

  @observable assets = new AssetsModel();

  @observable.ref modificationFood?: EfrDetailsItem;

  @observable foodDetailsReq: ApiReq<FoodDetailsResponse> = emptyValue;

  constructor() {
    makeObservable(this);
  }

  @computed get foodDetails() {
    if (this.foodDetailsReq.state !== 'fulfilled' || !this.foodDetailsReq.value) {
      return undefined;
    }
    return this.foodDetailsReq.value.data;
  }


  @action fetchFoodDetails = async (id: number) => {
    this.foodDetailsReq = this.apiFood.getFoodDetails(id);
    await this.foodDetailsReq;
    runInAction(() => {
      if (this.foodDetails) {
        this.modificationFood = new EfrDetailsItem(this.foodDetails);
      }
    });
    return this.foodDetailsReq;
  };

  @action collapseLeftBar = (force?: boolean) => {
    if (force !== undefined) {
      this.isOpenLeftBar = force;
    } else {
      this.isOpenLeftBar = !this.isOpenLeftBar;
    }
  };

  @action collapseAll = (force?: boolean) => {
    if (force !== undefined) {
      this.isCollapseAll = force;
    } else {
      this.isCollapseAll = !this.isCollapseAll;
    }
  };

  @action resetAllCollapseState = () => this.isCollapseAll = undefined;

  @action setSearchOpen = (isFilterOpen?: boolean) => {
    this.isFilterOpen = isFilterOpen !== undefined ? isFilterOpen : !this.isFilterOpen;
  };

  @action loadAssets = (async () => {
    this.assets.filter.resetFilters();
    this.assets.filter.setFilterValue({
      'q.parentLocation.id': 0,
    });
    await this.assets.fetchAssets();
  });

  @action saveEditFood = async (data: EfrDetailsType) => {
    this.modificationFood?.setEntity(data);
    await this.modificationFood?.editFood();
  };

  @action loadServices = (async () => {
    servicesStore.filter.setFilterValue({});
    await servicesStore.fetchServices();
  });

  @action cancelCreation = () => {
    if (!!this.foodDetails) {
      this.modificationFood = new EfrDetailsItem(this.foodDetails);
    }
  };

  @action resetFoodDetails = () => {
    this.modificationFood = undefined;
  };
}

export const { store: foodDetailsStore, storeCtx: foodDetailsStoreCtx } = storeFactory(
  FoodDetailsStore,
  'foodDetails',
);
