import { ExportOptionsType } from '../pages/Dashboard/helpers/common';
import { ExportTypes } from '../types';

export type ExportDashboardFormType = {
  type: ExportDataType['type'];
  dashboard?: ExportOptionsType[];
};

export type ExportDataType = {
  fileName: string;
  type: ExportFileType;
  mode: ExportTypes;
};

export enum ExportFileType {
  XLSX = 'xlsx',
  CSV = 'csv',
}
