import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';

import { Filter } from './index';
import { ItemType, ItemValueType } from '../../types';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';
import { isEmpty, omit, pick } from 'lodash';

import { ServicesLookUpsType } from '../../pages/Services/components/Filter';
import { GeneratorsLookUpsType } from 'src/pages/Dashboard/store';
import { FoodGeneratorsLookUpsType } from '../../pages/EdibleFoodGenerators/components/Filter';
import { FoodRecoveryLookUpsType } from '../../pages/EdibleFoodRecovery/components/Filter';
import { AssetsLookUpsType } from 'src/api/api-types/assets';
import {
  exceptionValues,
  removeFields,
  withoutEmptyValues,
} from '../../utils/common';
import { AccountsLookUpsType } from '../../pages/Accounts/components/Filter';
import { ActiveFilterType } from './constants';

export type LookupsKeysType =
  | AssetsLookUpsType
  | AccountsLookUpsType
  | GeneratorsLookUpsType
  | FoodGeneratorsLookUpsType
  | FoodRecoveryLookUpsType
  | ServicesLookUpsType;

export class TableFilter<T, G extends GridValidRowModel> {
  @observable filter: Filter<T>;
  @observable activeFilterCell: string | null = null;
  @observable filterFields: T;
  @observable gridColumns: GridColDef<G>[] = [];
  @observable isSelectedAll: boolean = true;
  @observable activeMode: ActiveFilterType = ActiveFilterType.ActiveOnly;
  @observable formContext: IObservableArray<ItemValueType> = observable([]);
  @observable filterNameMap: Record<string, keyof T>;
  @observable lookups: LookupsKeysType = {};
  @observable appliedFilter: IObservableArray<ItemValueType> = observable([]);
  private readonly fetchLookups?: (column: string, filters: { [key: string]: any }) => Promise<ItemType[]>;
  readonly isDynamic: boolean;
  private applyingStack: string[] = [];

  constructor(
    gridColumns: GridColDef[],
    defaultFilterField: T,
    filterNameMap: Record<string, keyof T>,
    fetchLookups?: (column: string, filters: { [key: string]: any }) => Promise<ItemType[]>
  ) {
    makeObservable(this);
    this.gridColumns = gridColumns.filter(
      ({ disableColumnMenu }) =>
        typeof disableColumnMenu === 'undefined' || !disableColumnMenu,
    );
    this.filter = new Filter<T>(defaultFilterField);
    this.filterFields = this.filter.filterFields;
    this.filterNameMap = filterNameMap;
    this.fetchLookups = fetchLookups;
    this.isDynamic = !!fetchLookups;
  }

  @computed get lastField() {
    if (this.formContext.length <= 0) return undefined;
    return this.formContext.at(-1);
  }

  @computed get listOptions() {
    if (this.formContext.length <= 0) return [];
    const selected = this.formContext.map(value => value.name);
    if (selected.length > 1) {
      return this.gridColumns.filter(item => !selected.includes(item.field));
    }
    return this.gridColumns;
  }

  @computed get filterParams() {
    //Todo find better approach for Persisted flow.
    const { ...filters } = this.filterFields;
    const { ...persisted } = this.filter.persistedFilter;

    return withoutEmptyValues({ ...filters, ...persisted });
  }

  @computed get activeFilters() {
    return withoutEmptyValues(this.filterFields);
  }

  @computed get filteredColumns() {
    return this.gridColumns
      .filter(x => Object.keys(this.activeFilters).some(key => key === (this.filterNameMap[x.field] ?? `q.${x.field}`)))
      .map(x => x.field);
  }

  @computed get haveActiveFilters() {
    const differenceToPersistedField = removeFields(
      this.filterFields,
      Object.keys(this.filter.persistedFilter),
    );
    const preparedValues = {
      ...differenceToPersistedField,
      ...exceptionValues,
    };
    return !isEmpty(withoutEmptyValues(preparedValues));
  }

  @computed get isSearchOpen() {
    return this.filter.isSearchOpen;
  }

  @computed get newField() {
    return {
      id: !this.lastField ? 0 : Number(this.lastField?.id) + 1,
      name: '',
      value: null,
    };
  }

  @computed get selectedNames() {
    if (!this.formContext) return [];
    return this.formContext.map(item => item.name);
  }

  @action applyContextFilters = (data: ItemValueType[], currentColumn?: string | null) => {
    const updatedItems: ItemValueType[] = [];
    data.forEach(item => {
      if (!!item.name) {
        const value = item.value?.id ?? item.value;
        const itemLookups = this.lookups[item.name as keyof LookupsKeysType];

        let isSelectedAll = !!value && Array.isArray(value)
          ? value.length === itemLookups?.length
          : false;

        if (this.isDynamic) {
          if (item.name === currentColumn) {
            this.applyingStack = this.applyingStack.filter(x => x !== item.name);
            
            if (!isSelectedAll) {
              this.applyingStack.push(currentColumn);
            }
          } else {
            isSelectedAll = false;
          }
        }

        if (!!this.filterNameMap[item.name]) {
          if (isSelectedAll) {
            delete this.filter.filterFields[this.filterNameMap[item.name]];
          } else {
            this.filter.filterFields[this.filterNameMap[item.name]] = value;
            updatedItems.push(item);
          }
        } else {
          if (isSelectedAll) {
            delete this.filter.filterFields[`q.${item.name}` as keyof T];
          } else {
            this.filter.filterFields[`q.${item.name}` as keyof T] = value;
            updatedItems.push(item);
          }
        }
      }
    });

    if (this.isDynamic) {
      this.lookups = pick(this.lookups, currentColumn ?? '');
    }

    this.linkContext(updatedItems);
  };

  @action removeContextFilter = (key: keyof T) => {
    if (this.filter.filterFields[key] !== null)
      this.filter.setFilterValue({ ...this.filter.filterFields, [key]: null });
  };

  @action setFilterValue = (
    partialFilterUpdate: Partial<T & { 'q.activeMode'?: string }>,
  ) => {
    const keys = Object.keys(partialFilterUpdate) as (keyof T)[];
    keys.forEach(key => {
      this.filter.filterFields[key] = partialFilterUpdate[key]!;
    });
    if (this.isDynamic) {
      this.lookups = pick(this.lookups, keys);
    }
  };
  @action handleActiveEntity = () => {
    this.activeMode =
      this.activeMode === ActiveFilterType.ActiveOnly
        ? ActiveFilterType.Inactive
        : ActiveFilterType.ActiveOnly;

    this.filter.setFilterValue({
      ...this.filter.filterFields,
      'q.activeMode': this.activeMode,
    });
    if (this.isDynamic) {
      this.lookups = {};
    }
  };

  @action reqDataByFilters = (callback: () => Promise<unknown>) => {
    return callback();
  };
  @action setLookups = (lookUpsData: LookupsKeysType) => {
    this.lookups = { ...this.lookups, ...lookUpsData };
  };
  //Todo find better approach for Persisted flow.
  @action setPersistedFilters = (persisted: Partial<T>) => {
    this.filter.setPersistedFilters(persisted);
    if (this.isDynamic) {
      this.lookups = {};
    }
  };
  @action resetPersistedFilters = () => {
    this.filter.resetPersistedFilters();
    if (this.isDynamic) {
      this.lookups = {};
    }
  }

  @action resetAppliedFilters = () => {
    this.setAppliedFilters([]);
    if (this.isDynamic) {
      this.lookups = {};
    }
  };

  @action resetFilterValues = () => {
    if (!this.filter.filterFields) return;

    const keys = Object.keys(this.filter.filterFields) as (keyof T)[];
    keys.map(
      key => (this.filter.filterFields[key] = null as unknown as T[keyof T]),
    );
    this.applyingStack = [];
  };

  @action openFilter = async (name: string) => {
    const newFiled = {
      id: 0,
      name,
      value: null,
    };
    this.activeFilterCell = name;
    if (isEmpty(this.formContext)) {
      this.addNewContextField(newFiled);
    } else {
      if (this.formContext.at(-1)?.name === '') {
        runInAction(() => this.formContext.replace([newFiled]));
      }
      if (this.selectedNames.includes(name)) return;
      runInAction(() =>
        this.formContext.replace([...this.formContext, newFiled]),
      );
    }
  };

  @action setSearchOpen = (isSearchOpen?: boolean) => {
    this.filter.isSearchOpen =
      isSearchOpen !== undefined ? isSearchOpen : !this.filter.isSearchOpen;
  };

  @action linkContext = (hook: ItemValueType[]) => {
    runInAction(() => this.formContext.replace(hook));
  };

  @action setAppliedFilters = (data: ItemValueType[]) => {
    runInAction(() => {
      this.appliedFilter.replace(data);
      if (this.isDynamic) {
        this.lookups = {};
      }
      this.applyingStack = [];
    });
  };

  @action addNewContextField = (data: ItemValueType) => {
    runInAction(() => this.formContext.replace([]));
    this.updatedContextField(data);
  };

  @action updatedContextField = (data: any) => {
    const existingItem = this.formContext.find(item => item.id === data.id);

    const updatedField = !!existingItem ? { ...existingItem, ...data } : data;
    const existFields = toJS(this.formContext).filter(item => {
      return item.id !== data.id;
    });

    runInAction(() => this.formContext.replace([...existFields, updatedField]));
  };

  @action closeCellFilter = () => {
    this.setAppliedFilters([]);
  };

  @action resetFilters = () => {
    runInAction(() => this.formContext.replace([]));
    this.setSearchOpen(false);
    this.closeCellFilter();
    this.activeMode = ActiveFilterType.ActiveOnly;
    this.resetFilterValues();
    if (this.isDynamic) {
      this.lookups = {};
    }
  };

  @action refreshLookups = async (column: string) => {
    if (!this.fetchLookups) {
      return;
    }
    if (this.lookups[column as keyof LookupsKeysType]) {
      return;
    }

    const filterName = this.filterNameMap[column] ?? `q.${column}`;
    const filtersToUse = omit(this.filterParams, filterName);
    const lookups = await this.fetchLookups(column, filtersToUse);
    this.setLookups({ [column]: lookups });
  }

  @action resetLookups = () => {
    if (this.isDynamic) {
      this.lookups = {};
    }
  }

  lookupsLoaded = (column: string) => !!this.lookups[column as keyof LookupsKeysType];
}
