import { useContext } from 'react';
import * as React from 'react';

import { AutocompleteValue } from '@mui/base/AutocompleteUnstyled/useAutocomplete';
import { Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import {
  icon,
  checkedIcon,
} from 'src/pages/EdibleFoodRecoveryDetails/components/FoodWillingType';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { ItemType } from 'src/types';
import { isPending } from 'src/utils/common';

import { containersStoreCtx } from '../../store';

export const EditWasteMaterialTypes = observer(
  ({ value }: GridRenderCellParams) => {
    const { wasteMaterialTypes, wasteMaterialTypesReq } =
      useContext(lookupsStoreCtx);
    const { chosenContainer } = useContext(containersStoreCtx);

    const handleChange = (
      _: React.SyntheticEvent,
      data: AutocompleteValue<ItemType, {}, {}, {}>,
    ) => chosenContainer?.setWasteMaterialTypes(data);

    const loading = isPending(wasteMaterialTypesReq);
    return (
      <Autocomplete
        open
        multiple
        fullWidth
        value={chosenContainer?.wasteMaterialTypes}
        defaultValue={value}
        onChange={handleChange}
        getOptionLabel={option => option?.name || ''}
        isOptionEqualToValue={(option, value) =>
          `${option.id}` === `${value.id}`
        }
        limitTags={1}
        disableCloseOnSelect
        options={wasteMaterialTypes}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip
              size="small"
              sx={{ borderRadius: 1 }}
              label={option.name}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            <ListItemText primary={option.name} />
          </MenuItem>
        )}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Select waste material types"
            sx={{ p: 0, border: 0 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  },
);
