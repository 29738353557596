import {action, computed, IObservableArray, makeObservable, observable} from 'mobx';
import {FileStatus} from 'src/types';
import {FileSignType, FileUploadType, UploadData} from 'src/api/api-types/files';
import {AssetModification} from 'src/models/assets/asset-modification';
import {isPending} from 'src/utils/common';
import { UploadFile } from 'src/models/file/upload-file';

export class FileUploadDialogStore {
  @observable files: IObservableArray<UploadFile> = observable([]);
  @observable isOpen: boolean = false;
  @observable fileUploadPlan: FileSignType = 'AssetAttachment';
  @observable asset?: AssetModification;

  constructor(fileUploadPlan?: FileSignType) {
    makeObservable(this);

    this.fileUploadPlan = fileUploadPlan || 'AssetAttachment';
  }

  @computed get isValid() {
    return this.files.every(file => file.canBeSent);
  }

  @computed get isSelectedFiles() {
    return this.files.length > 0;
  }

  @computed get isUploading() {
    return this.files.some(({status}) => status === FileStatus.PENDING);
  }

  @computed get allUploadsDone() {
    return (
      this.files.length > 0 &&
      this.files.every(({status}) => status === FileStatus.DONE)
    );
  }

  @computed get currentlyUploadingReq() {
    return this.files.find(file => isPending(file.uploadReq))?.uploadReq;
  }

  @computed get toolbarTitle() {
    if (this.isUploading) {
      return 'Processing...';
    }

    if (this.allUploadsDone) {
      return 'Files were successfully uploaded';
    }

    return 'Upload Files';
  }

  @action dependingToAsset = (asset: AssetModification) => {
    this.asset = asset;
  };

  @action openDialog = () => {
    this.isOpen = true;
  };

  @action openWithDependedAsset = (asset: AssetModification) => {
    this.dependingToAsset(asset);
    this.openDialog();
  }

  @action closeDialog = () => {
    this.isOpen = false;
  };

  @action appendFiles = (files: FileList, type: FileUploadType) => {
    let category: number | undefined = 0;
    switch (type) {
      case FileUploadType.file:
        category = 0;
        break;
    }

    this.files.replace(
      [...this.files.slice(),
        ...[...files].map(
          item =>
            new UploadFile({
              file: item,
              fileUploadPlan: this.fileUploadPlan,
              assetId: this.asset?.id ?? 0,
              category
            }),
        ),
      ],
    );

    if (this.files.length > 0) {
      this.openDialog();
    }
  };

  @action upload = async ({params, linkAction, customToken}: UploadData) => {
    if (this.files.length <= 0 || !this.isValid) return;
    await Promise.allSettled(this.files.map(file => {
      if (file.status === FileStatus.DONE) return file;
      return (async () => {
        if(!!customToken)
          await file.uploadWithCustomToken(params, customToken);
        else 
          await file.upload(params);
          await linkAction(file.linkFileDTO);
      })();
    }));
  };

  @action clear = () => this.files.clear();

  @action removeFile = (file: UploadFile) => this.files.remove(file);
}
