import { SyntheticEvent, useContext } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';
import { lookupsStoreCtx } from 'src/stores/lookups';

import { AccountLookup } from '../../../../api/api-types/lookups';

interface SelectAccountProps {
  id: number | string;
  generatorId: string | null;
  value: AccountLookup;
  field: string;
}

export const SelectAccountComponent = observer(
  ({ id, field, value, generatorId }: SelectAccountProps) => {
    if (generatorId === null) return null;
    const apiRef = useGridApiContext();
    const { accountsLookups, fetchAccountsLookups, accountsLookupsReq } =
      useContext(lookupsStoreCtx);
    const onOpen = () => {
      (async () => {
        await fetchAccountsLookups('', generatorId);
      })();
    };

    const onInputChange = debounce(
      async (_: SyntheticEvent, value: string, reason: string) => {
        if (reason === 'input') {
          await fetchAccountsLookups(value, generatorId);
        }
      },
      300,
    );

    const handleChange = (_event: SyntheticEvent, value: any) => {
      const newType = { id: value?.id, name: value?.name };
      apiRef.current.setEditCellValue({
        id,
        field: field,
        value: value === null ? null : newType,
      });
    };

    return (
      <Autocomplete
        id="SelectAccountComponent"
        sx={{ width: 300 }}
        onOpen={onOpen}
        defaultValue={!!value ? value : null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option.accountNumber}
        options={accountsLookups}
        onChange={handleChange}
        loading={accountsLookupsReq.state === 'pending'}
        onInputChange={onInputChange}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {accountsLookupsReq.state === 'pending' ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    );
  },
);
