import { ReactNode, useContext, useEffect } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GridRowId, useGridApiContext } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { ItemType } from 'src/types';
import { isPending } from 'src/utils/common';

interface ClientTypeProps {
  isNew?: boolean;
  id: GridRowId;
  field: string;
  value: ItemType;
}

export const ClientTypeSelectCell = observer(
  ({ isNew, id, field, value }: ClientTypeProps) => {
    const {
      clientTypes,
      clientTypesReq: jurisdictionsReq,
      fetchClientTypes,
    } = useContext(lookupsStoreCtx);
    const apiRef = useGridApiContext();

    const changeCellHandler = (
      fieldName: string,
      cellValue: ItemType | null,
    ) => {
      return apiRef.current.setEditCellValue({
        id,
        field: fieldName,
        value: cellValue,
      });
    };

    useEffect(() => {
      if (clientTypes.length === 0) fetchClientTypes();
    }, [fetchClientTypes, clientTypes.length]);

    const handleChange = async (_: SelectChangeEvent, child: ReactNode) => {
      const {
        props: { value, children },
      } = child as ReactNode & { props: { value: number; children: string } };
      await changeCellHandler(field, { id: value, name: children });
      await changeCellHandler('client', null);
    };

    return (
      <Select
        value={String(value?.id ?? '')}
        fullWidth
        disabled={!isNew}
        onChange={handleChange}>
        {clientTypes.length > 0 ? (
          clientTypes.map(({ id, name }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem key={value?.id} value={value?.id}>
            {isPending(jurisdictionsReq) ? (
              <CircularProgress />
            ) : (
              value?.name ?? ''
            )}
          </MenuItem>
        )}
      </Select>
    );
  },
);
