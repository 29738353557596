import { User } from '../models/user';
import { observer } from 'mobx-react-lite';

interface Props {
  user: User
}

export default observer(({ user }: Props) => {
  return (
    <>
      {user.active ? 'Yes' : 'No'}
    </>
  )
});
