import compose from 'lodash/fp/compose';
import omitBy from 'lodash/fp/omitBy';
import isInteger from 'lodash/isInteger';
import toNumber from 'lodash/toNumber';
import { IPromiseBasedObservable } from 'mobx-utils';

import { LOOKUP_BLANK_ID } from '../api/api-types/lookups';
import { ItemType } from '../types';

import { formattedUSDate } from './date';

const isEmpty = (value?: any): boolean => {
  if (value === undefined) return true;
  if (value === null) return true;

  if (typeof value === 'string') {
    return value.trim() === '';
  }

  return false;
};

export const isInt = compose(isInteger, toNumber);

export const withoutEmptyValues = omitBy(isEmpty);
export const exceptionValues = {
  'q.activeMode': '',
};

export const nameOf = <T>(key: keyof T, _instance?: T): keyof T => {
  return key;
};

export const isFulfilled = <T>(request: IPromiseBasedObservable<T>) =>
  request?.state === 'fulfilled';

export const isPending = <T>(request?: IPromiseBasedObservable<T>) =>
  request?.state === 'pending';

export const exportNameGenerator = (
  defaultName: string,
  type: string,
  filename?: string,
) => `${filename || defaultName} - ${formattedUSDate(new Date())}.${type}`;

export const removeBlankFromLookup = <T extends ItemType>(items: T[]) =>
  items.filter(({ id }) => !String(id).includes(LOOKUP_BLANK_ID));

export const monthInMilliseconds = 2419200000;

export const returnIds = <T extends { id: number }>(array: T[]) =>
  array.map(({ id }) => id);

export const chartHeightRatio = () => {
  return window.innerHeight * 0.4;
};

export function removeFields(
  obj: Record<string, any>,
  fieldsToRemove: string[],
): Record<string, any> {
  const result: Record<string, any> = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && !fieldsToRemove.includes(key)) {
      result[key] = obj[key];
    }
  }

  return result;
}
