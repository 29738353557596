import { GridCellParams, GridRowId } from '@mui/x-data-grid-pro';
import {
  action,
  computed,
  IObservableArray,
  IReactionDisposer,
  makeObservable,
  observable, reaction,
  runInAction,
} from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { ClientTypes } from 'src/api/api-types/lookups';
import { UserCreationType, UserItem, UserProfileResponse, UsersFilter, UsersResponse } from 'src/api/api-types/user';
import { UsersApi } from 'src/api/users';
import { Filter } from 'src/models/filter';
import { userStore } from 'src/stores/user';
import { storeFactory } from 'src/utils/store';
import { generateInitialUser, UserTypes, visibilityDefaultState, mainFilters, adminFilters, conversFilterParams } from './helpers/common';
import { jurisdictionEditStates, userColumns } from './helpers/gridColumns';
import { User } from './models/user';
import { CreateUserType } from './components/CreateModal';
import { TablePagination } from '../../models/pagination/pagination';
import { ModifyUser } from './models/modify-user';
import { OnStartEditType } from '../../components/Table/types';
import { lookupsStore } from '../../stores/lookups';
import { GridColumnsManager } from '../../models/grid-columns-manager/grid-columns-manager';
import { returnIds } from 'src/utils/common';
import { authStore } from '../../stores/auth';
import { AsyncExportEntity } from 'src/models/export/AsyncExportEntity';
import { ExportDataType } from 'src/api/exportTypes';
import { TableFilter } from 'src/models/filter/table-filter';
import { UserHiddenColumnsType, UsersExportColumnNameMap } from 'src/models/filter/constants';

class UsersStore {
  private authColumnsReactionDisposer: IReactionDisposer | null = null;
  api = new UsersApi({ prefix: '' });
  @observable.ref userProfileDataReq: ApiReq<UserProfileResponse> = emptyValue;
  @observable.ref userDataReq: ApiReq<UsersResponse> = emptyValue;
  @observable.ref users: IObservableArray<User> = observable<User>([]);
  @observable.ref chosenUser?: ModifyUser = undefined;
  @observable checkedUsers: IObservableArray<ModifyUser> = observable<ModifyUser>([]);
  @observable pagination = new TablePagination<UsersResponse>({ requestRef: this.userDataReq });
  @observable columnsManager = new GridColumnsManager<UserItem>({
    columns: userColumns,
    columnsMap: authStore.columnsVisibleMap,
    visibilityModel: visibilityDefaultState,
  });
  @observable filterModel?: Filter<UsersFilter>;
  @observable filter = new TableFilter<UsersFilter, UserItem>(
    userColumns,
    { ...mainFilters, ...adminFilters },
    {},
  );
  @observable isEditEmployersBulk = false;
  @observable isCreation: boolean = false;
  @observable selectedUserType: UserTypes | null = null;
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();

  constructor() {
    makeObservable(this);
    this.pagination.setFetchingCallBack(this.fetchUsersList);

    this.authColumnsReactionDisposer = reaction(
      () => [authStore.columnsVisibleMap],
      () => {
        this.columnsManager = new GridColumnsManager<UserItem>({
          columns: userColumns,
          columnsMap: authStore.columnsVisibleMap,
          visibilityModel: visibilityDefaultState,
        });
      },
    );
  }

  selectUser = (id: number) => {
    const needle = this.users.find(item => item.id === id);
    if (needle) {
      this.chosenUser = new ModifyUser(needle);
      this.setUserType(needle.clientType?.id as UserTypes);
      return needle;
    }
    return undefined;
  };

  @computed get userData() {
    if (this.userDataReq.state !== 'fulfilled' || !this.userDataReq.value)
      return null;

    return this.userDataReq.value.data;
  }

  @computed get usersListData(): Array<UserItem> {
    if (this.userDataReq.state !== 'fulfilled' || !this.userDataReq.value) return [];
    return this.userDataReq.value.data?.items || [];
  }

  @computed get isAdminOrJurisdiction() {
    if (!this.selectedUserType) return false;
    return this?.selectedUserType === UserTypes.Jurisdiction || this?.selectedUserType === UserTypes.Agency ||
      this?.selectedUserType === UserTypes.Administrator;
  }

  @computed get isAdminOrHauler() {
    if (!this.selectedUserType) return false;
    return this?.selectedUserType === UserTypes.Hauler || this?.selectedUserType === UserTypes.Agency ||
      this?.selectedUserType === UserTypes.Administrator;
  }

  @computed get isAdmin() {
    if (!this.selectedUserType) return false;
    return this?.selectedUserType === UserTypes.Administrator;
  }

  @action fetchUsersList = async () => {
    this.userDataReq = this.api.getUsersData({
      ...this.pagination.serialize,
      filters: this.filter!.filterParams,
    });
    await this.userDataReq;
    runInAction(() => {
      if (this.userDataReq.state !== 'fulfilled' || this.userDataReq.value?.data === null) return;
      this.pagination.setPagination(this.userDataReq?.value?.data);
      this.users.replace(this.usersListData.map(userData => new User(userData)));
    });
    return this.userDataReq;
  };

  @action setEditEmployersBulkModal = (val: boolean) => this.isEditEmployersBulk = val;

  @action setCheckedUsers = (items: GridRowId[]) => {
    if (!items.length) this.checkedUsers.replace([]);
    const t = this.usersListData.filter((e) => items.includes(e.id));
    this.checkedUsers.replace([...t.map((el) => new ModifyUser(el))]);
  };

  @action onStartEditUser = async ({ id }: OnStartEditType<User>) => {
    await Promise.all([
      await lookupsStore.fetchAgenciesLookup(undefined, '2'),
      await lookupsStore.fetchAllJurisdictionsLookups(),
      await lookupsStore.fetchAllHaulersLookup(),
    ]);
    if (!id) return;
    this.selectUser(Number(id));
  };

  @action setUserType = (type: UserTypes) => {
    this.selectedUserType = type;
  };
  @action handleCreateUser = () => {
    if (userStore.userData && userStore.clientType) {
      this.chosenUser = new ModifyUser(generateInitialUser(userStore.clientType?.id, userStore.userData));
      (async () => {
        await this.chosenUser?.getUserAllowedEmails();
        this.isCreation = true;
      })();
    }
  };

  @action modifyUser = async (entity: UserCreationType, old: UserCreationType) => {
    try {
      if (!!this.chosenUser) {
        await this.chosenUser?.setEntity({
          firstName: entity.firstName,
          lastName: entity.lastName,
          employer: entity.employer,
        });
        await this.chosenUser?.editInfo();
        if (!entity?.email?.includes(old?.email)) this.chosenUser?.editEmail();
        await this.fetchUsersList();
      }
    } catch (e) {
      throw e;
    }
  };

  @action userMenuActionHandler = async (id: number, action: string) => {
    this.selectUser(id);
    if (action === 'state') await this.chosenUser?.changeStatus();
    if (action === 'reset') await this.chosenUser?.resetPassword();
  };

  @action removeNewUser = () => {
    this.chosenUser = undefined;
  };
  @action isCellEditable = (params: GridCellParams) => {
    if (userStore.clientType?.id === ClientTypes.Jurisdiction && jurisdictionEditStates[params.field]) {
      return jurisdictionEditStates[params.field].state;
    }
    return true;
  };

  @action resetStore = () => {
    this.pagination.resetPagination();
    this.filter.resetFilters();
    this.filter.setSearchOpen(false);
  };

  @action finishCreation = async () => {
    this.isCreation = false;
    this.removeNewUser();
    return this.fetchUsersList();
  };

  @action createNewUser = async (entity: CreateUserType) => {
    await this.chosenUser?.setEntity(entity);
    await this.chosenUser?.createUser()
      .then(() => this.finishCreation())
      .catch(e => {
        throw e;
      });
  };

  @action modifyEmployerBulk = async (text: string) => {
    if (!this.checkedUsers.length) return;
    try {
      // TO DO: Redo if after the API update the related endpoint will be able to accept an array of user's ids
      const dto = {
        ids: returnIds(this.checkedUsers),
        employer: text,
      };
      const res = await this.api.requestPut('/users/employer', dto);
      if (res.status === 200) {
        this.setCheckedUsers([]);
        this.fetchUsersList();
      }
    } catch (e) {
      throw e;
    }
  };

  @action cancelCreation = () => this.isCreation = false;

  @action stopAuthColumnsReaction = () => {
    if (this.authColumnsReactionDisposer) {
      this.authColumnsReactionDisposer();
      this.authColumnsReactionDisposer = null;
    }
  };

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncReportByType(
        this.exportEntity.exportParams<
          UsersResponse,
          UsersFilter,
          UserItem
        >(
          this.columnsManager.hiddenColumns.map(
            el => UsersExportColumnNameMap[el as UserHiddenColumnsType] || el,
          ),
          conversFilterParams(this.filter),
          this.pagination,
          returnIds(this.checkedUsers)
        ),
        data.type,
      )
    );
  };
}

export const { store: usersStore, storeCtx: usersStoreCtx } = storeFactory(
  UsersStore,
  'users',
);
