import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    lng: 'en',
    ns: ['common', 'task', 'lineItems'],
    defaultNS: 'main',
    fallbackLng: 'en',
    whitelist: ['en', 'da'],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {},
      da: {},
    },
  });

export default i18n;
