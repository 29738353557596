import { ApiClient } from './';
import {
  ClientCreationType,
  ClientInfoResponse,
  ClientLogoResponse,
  ClientsItem,
  ClientsResponse,
  ClientStatusRequest,
  RequestClientsParams,
} from './api-types/clients';
import { dotNameKeyToString } from '../utils/string';
import { LinkedFileResponse, UploadClientLogoResponse } from './api-types/files';
import { AsyncExportResponse, DownloadTokenResponse, ExportParams } from 'src/types';

export class ClientsApi extends ApiClient {
  getClients({ page, pageSize, filters }: RequestClientsParams) {
    return this.requestGet<ClientsResponse>(`clients`, {
      'page.num': page,
      'page.size': pageSize,
      ...dotNameKeyToString(filters),
    });
  }

  getHaulers({ page, pageSize }: RequestClientsParams) {
    return this.requestGet<ClientsResponse>('clients/haulers', {
      'page.num': page,
      'page.size': pageSize,
    });
  }

  updateClient(item: Partial<ClientCreationType>) {
    return this.requestPut<ClientInfoResponse, Partial<ClientCreationType>>(
      `clients/${item.id}`,
      item,
    );
  }

  createClient(item: Partial<ClientsItem>) {
    return this.requestPost<ClientInfoResponse, Partial<ClientsItem>>(
      `clients`,
      item,
    );
  }

  changeClientStatusAction({ id, active }: ClientStatusRequest) {
    return this.requestPut<ClientInfoResponse, {}>(
      `clients/${id}/${active ? 'activate' : 'deactivate'}`,
      {},
    );
  }

  getUploadLogoToken(clientId: number) {
    return this.requestGet<DownloadTokenResponse>(
      `clients/${clientId}/logo/upload-token`,
    );
  }

  getLogoUrlByToken(token: string) {
    return this.requestFiles<Blob>(`files`, { token });
  }

  getDownloadLogoToken(clientId: number) {
    return this.requestGet<DownloadTokenResponse>(
      `clients/${clientId}/logo/download-token`,
    );
  }
  
  getLogo(clientId: number) {
    return this.requestGet<ClientLogoResponse>(
      `clients/${clientId}/logo`,
    );
  }

  getLogoUrl(token: string) {
    return this.requestFiles<Blob>(`files`, { token });
  }

  deleteLogo(clientId: number) {
    return this.requestDelete(
      `clients/${clientId}/logo`,
    );
  }
  linkLogoToClient(clientId: number, dto: UploadClientLogoResponse ) {
    const mappedDto = {    
      file: {
          id: dto.id,
      }
    };
    return this.requestPost<LinkedFileResponse, typeof mappedDto>(
      `clients/${clientId}/logo`,
      mappedDto,
    );
  }

  getAsyncHaulersReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `clients/haulers/export/${exportType}?flowType=2`,
      params,
    );
  }

  getAsyncReportByType(params: ExportParams, exportType: string) {
    return this.requestPost<AsyncExportResponse, ExportParams>(
      `clients/export/${exportType}?flowType=2`,
      params,
    );
  }
}
