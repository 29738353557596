import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const JPGIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M4.17162 0C3.62686 0 3.18115 0.45 3.18115 1V15C3.18115 15.55 3.62686 16 4.17162 16H14.0763C14.6211 16 15.0668 15.55 15.0668 15V4L11.1049 0H4.17162Z"
      fill="#E2E5E7"
    />
    <path
      d="M12.0952 4H15.0666L11.1047 0V3C11.1047 3.55 11.5504 4 12.0952 4Z"
      fill="#B0B7BD"
    />
    <path d="M15.0666 7L12.0952 4H15.0666V7Z" fill="#CAD1D8" />
    <path
      d="M13.0857 13C13.0857 13.275 12.8628 13.5 12.5905 13.5H1.69531C1.42293 13.5 1.20007 13.275 1.20007 13V8C1.20007 7.725 1.42293 7.5 1.69531 7.5H12.5905C12.8628 7.5 13.0857 7.725 13.0857 8V13Z"
      fill="#50BEE8"
    />
    <path
      d="M4.6038 9.47344C4.6038 9.13744 5.12676 9.13744 5.12676 9.47344V11.0524C5.12676 11.6804 4.82962 12.0604 4.14471 12.0604C3.80796 12.0604 3.52716 11.9679 3.28152 11.6484C3.07947 11.4044 3.45981 11.0529 3.66582 11.3084C3.83222 11.5164 4.01051 11.5644 4.18433 11.5564C4.4057 11.5484 4.60033 11.4479 4.60429 11.0524V9.47344H4.6038Z"
      fill="white"
    />
    <path
      d="M5.82263 9.47339C5.82263 9.34139 5.92564 9.19739 6.09204 9.19739H7.00674C7.52178 9.19739 7.98532 9.54539 7.98532 10.2124C7.98532 10.8444 7.52178 11.1964 7.00674 11.1964H6.3456V11.7244C6.3456 11.9004 6.23467 11.9999 6.09204 11.9999C5.9613 11.9999 5.82263 11.9004 5.82263 11.7244V9.47339ZM6.3456 9.70088V10.6969H7.00674C7.27218 10.6969 7.48216 10.4604 7.48216 10.2124C7.48216 9.93288 7.27218 9.70088 7.00674 9.70088H6.3456Z"
      fill="white"
    />
    <path
      d="M10.8129 11.6925C10.5316 11.9285 10.187 12.0285 9.83881 12.0285C9.00682 12.0285 8.41699 11.549 8.41699 10.597C8.41699 9.78998 9.03901 9.16248 9.87397 9.16248C10.187 9.16248 10.5311 9.26998 10.7768 9.51398C11.018 9.75348 10.6698 10.1095 10.4326 9.89798C10.286 9.75398 10.08 9.64598 9.87397 9.64598C9.39508 9.64598 8.93204 10.0335 8.93204 10.597C8.93204 11.189 9.3198 11.549 9.83881 11.549C10.08 11.549 10.286 11.4765 10.4326 11.369V10.8775H9.83881C9.4862 10.8775 9.52186 10.3895 9.83881 10.3895H10.6307C10.7773 10.3895 10.9115 10.5015 10.9115 10.6255V11.477C10.9115 11.549 10.8803 11.616 10.8129 11.6925Z"
      fill="white"
    />
    <path
      d="M12.5906 13.5H3.18115V14H12.5906C12.863 14 13.0858 13.775 13.0858 13.5V13C13.0858 13.275 12.863 13.5 12.5906 13.5Z"
      fill="#CAD1D8"
    />
  </SvgIcon>
);
export default JPGIcon;
