import CSVIcon from 'src/assets/icons/FileIcons/CSVIcon';
import DefaultIcon from 'src/assets/icons/FileIcons/DefaultIcon';
import XlsIcon from 'src/assets/icons/FileIcons/ExcelIcon';
import JpgIcon from 'src/assets/icons/FileIcons/JPGIcon';
import PdfIcon from 'src/assets/icons/FileIcons/PDFIcon';
import PngIcon from 'src/assets/icons/FileIcons/PNGIcon';
import PptIcon from 'src/assets/icons/FileIcons/PPTIcon';
import TxtIcon from 'src/assets/icons/FileIcons/TextIcon';
import DocIcon from 'src/assets/icons/FileIcons/WordIcon';

interface Props {
  type: string;
}

const FileTypeIcon = ({ type }: Props) => {
  switch (type) {
    case 'doc':
    case 'docx':
    case 'application/msword':
      return <DocIcon />;
    case 'xls':
    case 'xlsx':
    case 'application/vnd.ms-excel':
      return <XlsIcon />;
    case 'txt':
    case 'text/plain':
      return <TxtIcon />;
    case 'pdf':
    case 'application/pdf':
      return <PdfIcon />;
    case 'ppt':
    case 'application/vnd.ms-powerpoint':
      return <PptIcon />;
    case 'jpg':
    case 'jpeg':
    case 'image/jpeg':
      return <JpgIcon />;
    case 'png':
    case 'image/png':
      return <PngIcon />;
    case 'csv':
      return <CSVIcon />;
    default:
      return <DefaultIcon />;
  }
};

export default FileTypeIcon;
