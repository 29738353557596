import { ApiClient } from '.';
import { LinkFileDto } from './api-types/files';
import {
  DestinationValuesLookup,
  ImportContainerValue,
  ImportFileColumn,
  ImportFileValue,
  InitializeData,
  LinkedFile,
  MergeColumnsData,
  MergeContainersData,
  MergedContainersData,
  MergeValuesData,
  Session,
} from './api-types/import';
import { format } from 'date-fns';
import { IMPORT_DATE_FORMAT } from '../utils/constants';
import { AsyncExportResponse } from 'src/types';

export class ImportApi extends ApiClient {
  getSessionState(id: number) {
    return this.requestGet<Session>(`import/session/${id}`);
  }

  createImportSession(createData: InitializeData) {
    return this.requestPost<Session, InitializeData>('import/session', {
      ...createData,
      dataDate: format(new Date(createData.dataDate), IMPORT_DATE_FORMAT),
    });
  }

  linkFileToSession(id: number, file: LinkFileDto) {
    return this.requestPost<Session, LinkedFile>(`import/session/${id}/file`, {
      id,
      file,
    });
  }

  getLastActiveSession() {
    return this.requestGet<Session>('import/session/last-active');
  }

  cancelSession(id: number) {
    return this.requestPut<Session, {}>(`import/session/${id}/cancel`);
  }

  getSourceColumns(id: number) {
    return this.requestGet<ImportFileColumn[]>(
      `import/session/${id}/file-columns`,
    );
  }

  getColumnsTemplates(id: number) {
    return this.requestGet<ImportFileColumn[]>(
      `import/session/${id}/template-columns`,
    );
  }

  mergeImportColumns(data: MergeColumnsData) {
    return this.requestPut<Session, MergeColumnsData>(
      `import/session/${data.id}/file-columns`,
      data,
    );
  }

  getValuesForMerge(id: number) {
    return this.requestGet<ImportFileValue[]>(
      `import/session/${id}/matching-values`,
    );
  }

  getValueLookup(id: number) {
    return this.requestGet<DestinationValuesLookup>(
      `import/matching-values/${id}/lookups`,
    );
  }

  mergeImportValues(data: MergeValuesData) {
    return this.requestPut<Session, MergeValuesData>(
      `import/${data.id}/matching-values`,
      data,
    );
  }

  getContainersForMerge(id: number) {
    return this.requestGet<ImportContainerValue[]>(
      `import/session/${id}/containers`,
    );
  }

  mergeImportContainers({ id, data }: MergeContainersData) {
    return this.requestPut<Session, MergedContainersData>(
      `import/session/${id}/containers`,
      data,
    );
  }

  confirmImport(id: number) {
    return this.requestPost<Session, {}>(`import/session/${id}/confirm`, {});
  }

  exportBadRows(id: number) {
    return this.requestPost<AsyncExportResponse, {}>(
      `import/session/${id}/skipped-rows/export/xlsx?flowType=2`,
      {},
    );
  }


  finishImport(id: number) {
    return this.requestPost<Session, {}>(`import/session/${id}/finish`, {});
  }
}
