import { useContext } from 'react';

import { TablePagination } from '@mui/material';
import { TablePaginationProps } from '@mui/material/TablePagination/TablePagination';
import { authStoreCtx } from 'src/stores/auth';

import { FooterProps } from './CustomFooter';

export const pageOptions = [100, 250, 500, 1000];
export const prevPageOptions = [25, 50, 100, 250];

type ExceptionFieldsType = 'count' | 'onPageChange' | 'page' | 'rowsPerPage';

type TablePaginationPropsType = Omit<FooterProps, 'totalPages'> &
  Omit<TablePaginationProps, ExceptionFieldsType> &
  Partial<Pick<TablePaginationProps, ExceptionFieldsType>>;

const CustomTablePagination = ({
  paginationModel,
  rowCount,
  onPaginationModelChange,
  ...props
}: TablePaginationPropsType) => {
  const { fCfg } = useContext(authStoreCtx);
  const { page, pageSize } = paginationModel;

  return (
    <TablePagination
      {...props}
      component="div"
      sx={{
        ...(props.sx || {}),
        '& .MuiSelect-icon': {
          width: '2em',
        },
      }}
      count={rowCount || 0}
      page={rowCount <= 0 ? 0 : page}
      onPageChange={(_, page) =>
        onPaginationModelChange(
          { page, pageSize },
          {
            reason: 'setPaginationModel',
          },
        )
      }
      rowsPerPage={!!pageSize ? pageSize : pageOptions[0]}
      rowsPerPageOptions={fCfg.isNewPagination ? pageOptions : prevPageOptions}
      onRowsPerPageChange={e =>
        onPaginationModelChange(
          { page, pageSize: Number(e.target.value) },
          {
            reason: 'setPaginationModel',
          },
        )
      }
    />
  );
};

export default CustomTablePagination;
