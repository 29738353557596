import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Link } from 'src/components/Link';
import { formattedUSDateTime } from 'src/utils/date';
import { UserItem } from '../../../api/api-types/user';
import CommaSeparated from '../../../components/CommaSeparated';
import { GRID_CHECKBOX_SELECTION_COL } from '../../../components/Table/constants';
import { sortArrayLengthFn } from '../../../utils/sorting';
import { EditNameInputCell } from '../components/EditUser/EditNameInputCell';
import EditAgency from '../components/Table/EditComponents/EditAgency';
import EditHaulers from '../components/Table/EditComponents/EditHaulers';
import EditJurisdiction from '../components/Table/EditComponents/EditJurisdiction';
import UserActionMenu from '../components/UserActionMenu';
import UserActiveStatusCell from '../components/UserActiveStatusCell';
import { EmailReportLink } from 'src/components/Link/EmailReportLink';

export const userColumns: GridColDef<UserItem>[] = [
  GRID_CHECKBOX_SELECTION_COL,
  {
    field: 'active',
    headerName: 'Active',
    flex: 1,
    renderCell: ({ row }: GridRenderCellParams) => (
      <UserActiveStatusCell user={row} />
    ),
  },
  {
    field: 'firstName',
    headerName: 'First name',
    editable: true,
    flex: 1,
    renderEditCell: EditNameInputCell,
    preProcessEditCellProps: params => {
      const hasError = params.props.value.length === 0;
      return {
        ...params.props,
        error: hasError,
      };
    },
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    editable: true,
    flex: 1,
    renderEditCell: EditNameInputCell,
    preProcessEditCellProps: params => {
      const hasError = params.props.value.length === 0;
      return {
        ...params.props,
        error: hasError,
      };
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 2,
    renderCell: ({ value }: GridRenderCellParams) => (
      <EmailReportLink to={value}>{value}</EmailReportLink>
    ),
  },
  {
    field: 'clientType',
    headerName: 'User Type',
    flex: 1,
    valueGetter: ({ value }) => value?.name ?? '',
  },
  {
    field: 'employer',
    headerName: 'Employer',
    flex: 1,
    minWidth: 250,
    editable: true,
    renderEditCell: EditNameInputCell
  },
  {
    field: 'client',
    headerName: 'Client',
    flex: 1,
    valueGetter: ({ value }) => value?.name ?? '',
    renderCell: ({ value }: GridRenderCellParams) => (
      <Link to="index">{value}</Link>
    ),
  },
  {
    field: 'agencies',
    headerName: 'Agencies',
    minWidth: 250,
    editable: true,
    sortComparator: sortArrayLengthFn,
    renderCell: ({ value }) => <CommaSeparated types={value} />,
    renderEditCell: () => <EditAgency />,
  },
  {
    field: 'jurisdictions',
    headerName: 'Jurisdictions',
    editable: true,
    minWidth: 250,
    flex: 1,
    sortComparator: sortArrayLengthFn,
    renderCell: ({ value }) => <CommaSeparated types={value} />,
    renderEditCell: () => <EditJurisdiction />,
  },
  {
    field: 'haulers',
    headerName: 'Haulers',
    flex: 1,
    editable: true,
    minWidth: 250,
    sortComparator: sortArrayLengthFn,
    renderCell: ({ value }) => <CommaSeparated types={value} />,
    renderEditCell: () => <EditHaulers />,
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    flex: 1,
    valueGetter: ({ row }: GridRenderCellParams) => row.createdBy?.name || '',
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    flex: 2,
    valueGetter: ({ value }: GridRenderCellParams) =>
      formattedUSDateTime(value),
  },
  {
    field: 'lastLoggedIn',
    headerName: 'Last Login',
    flex: 2,
    valueGetter: ({ value }: GridRenderCellParams) =>
      formattedUSDateTime(value),
  },
  {
    field: 'Actions',
    type: 'actions',
    disableColumnMenu: true,
    width: 50,
    align: 'center',
    renderCell: ({ row }: GridRenderCellParams) => (
      <UserActionMenu user={row} />
    ),
  },
];

export const jurisdictionEditStates: any = {
  clientType: {
    state: false,
  },
  client: {
    state: false,
  },
};
