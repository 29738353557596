import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Button,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { observer } from 'mobx-react-lite';
import FileIcon from 'src/components/File/FileIcon';
import { AssetModification } from 'src/models/assets/asset-modification';
import { isPending } from 'src/utils/common';

import { fileStore } from '../AddAttachments';

import AttachmentsMenu from './AttachmentsMenu';

interface AttachmentsListType {
  onClose: () => void;
  asset?: AssetModification;
  uploadHandler: (id: number) => void;
  chosenAsset: AssetModification | undefined;
  isIR?: boolean;
  onDeletion?: () => void;
}

const AttachmentsList = observer(
  ({
    asset,
    uploadHandler,
    onClose,
    chosenAsset,
    isIR,
    onDeletion,
  }: AttachmentsListType) => {
    if (!asset || !asset.attachments) return null;
    const {
      id,
      attachments: { files, attachmentsBulkDownload, deleteAttachmentReq },
    } = asset;

    const bulkDownloadHandler = async () => {
      await attachmentsBulkDownload();
    };

    const uploadFilesHandler = () => {
      uploadHandler(id);
      fileStore.fileDialogStore.openDialog();
      onClose && onClose();
    };

    return (
      <Grid container width={isIR ? 370 : 'auto'}>
        <List
          sx={{
            width: '100%',
            maxHeight: 200,
            overflowY: 'scroll',
          }}>
          <AttachmentsListLoading asset={asset} />
          {files?.slice().map(({ id, fileName, filesReq }) => (
            <MenuItem key={id}>
              <ListItemIcon sx={{ minWidth: 35 }}>
                <FileIcon type={fileName.split('.').pop()!} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: isIR ? 250 : 300,
                  },
                }}
                primary={fileName}
              />
              <AttachmentsMenu
                fileId={id}
                onClose={onClose}
                downloadPending={isPending(filesReq)}
                deletePending={isPending(deleteAttachmentReq)}
                chosenAsset={chosenAsset}
                onDeletion={onDeletion}
              />
            </MenuItem>
          ))}
        </List>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            borderTop: '1px solid',
            borderColor: 'text.disabled',
          }}>
          <Button
            variant="text"
            color="primary"
            disabled={
              !chosenAsset?.canUpdate || !chosenAsset?.isRelatedEntityActive
            }
            startIcon={<UploadIcon />}
            onClick={uploadFilesHandler}>
            Upload Files
          </Button>
          <Button
            variant="text"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={bulkDownloadHandler}>
            Download All
          </Button>
        </Grid>
      </Grid>
    );
  },
);

const AttachmentsListLoading = ({ asset }: { asset: AssetModification }) => {
  if (asset?.attachments?.attachmentsReq?.state !== 'fulfilled') {
    return (
      <>
        <Skeleton
          variant="text"
          height={60}
          sx={{
            m: theme => theme.spacing(1, 2),
          }}
        />
        <Skeleton
          variant="text"
          height={60}
          sx={{
            m: theme => theme.spacing(1, 2),
          }}
        />
        <Skeleton
          variant="text"
          height={60}
          sx={{
            m: theme => theme.spacing(1, 2),
          }}
        />
      </>
    );
  }
  return null;
};

export default AttachmentsList;
