import { useState, MouseEvent, SyntheticEvent } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgress, IconButton, Menu, MenuItem } from '@mui/material';
import DeleteIcon from 'src/assets/icons/Actions/DeleteIcon';
import UploadLabelIcon from 'src/assets/icons/File/UploadLabelIcon';
import { AssetModification } from 'src/models/assets/asset-modification';

interface Props {
  onClose?: () => void;
  downloadPending: boolean;
  deletePending: boolean;
  fileId: number;
  chosenAsset: AssetModification | undefined;
  onDeletion?: () => void;
}

export default ({
  onClose,
  downloadPending,
  deletePending,
  fileId,
  chosenAsset,
  onDeletion,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  if (!chosenAsset) return null;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleClose = () => {
    onClose && onClose();
    setAnchorEl(null);
  };

  const handleAction = (e: SyntheticEvent) => {
    if (e.currentTarget?.id.includes('download')) {
      (async () => {
        await chosenAsset.attachments.download(fileId);
      })();
    }
    if (e.currentTarget?.id.includes('delete')) {
      (async () => {
        await chosenAsset.attachments.deleteFile(fileId);
        !!onDeletion && (await onDeletion());
      })();
    }
  };

  return (
    <div>
      <IconButton
        aria-label="mores"
        id="file-buttons"
        aria-controls={open ? 'menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem id="download" onClick={handleAction}>
          {downloadPending ? (
            <CircularProgress size={20} />
          ) : (
            <UploadLabelIcon sx={{ p: theme => theme.spacing(0.5) }} />
          )}
          Download
        </MenuItem>
        <MenuItem
          disabled={
            !chosenAsset?.canUpdate || !chosenAsset.isRelatedEntityActive
          }
          id="delete"
          onClick={handleAction}>
          {deletePending ? (
            <CircularProgress size={20} />
          ) : (
            <DeleteIcon sx={{ p: theme => theme.spacing(0.5) }} />
          )}
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};
