import { createContext, PropsWithChildren, useContext } from 'react';

import { once } from 'lodash';

import { ActiveFilterType } from '../../models/filter/constants';

export interface ActiveInactiveProps {
  activeMode?: ActiveFilterType;
  fetchCall?: () => void;
  handleActiveEntityCall?: () => void;
}

export const createActiveInactiveCtx = once(() =>
  createContext<ActiveInactiveProps>({
    activeMode: undefined,
    fetchCall: undefined,
    handleActiveEntityCall: undefined,
  }),
);

export const ActiveInactiveProvider = ({
  children,
  activeMode,
  fetchCall,
  handleActiveEntityCall,
}: PropsWithChildren<ActiveInactiveProps>) => {
  const ActiveInactiveContext = createActiveInactiveCtx();

  return (
    <ActiveInactiveContext.Provider
      value={{
        activeMode,
        fetchCall,
        handleActiveEntityCall,
      }}>
      {children}
    </ActiveInactiveContext.Provider>
  );
};

/* Hook to use store in any functional component */
export const useActiveInactiveStore = () =>
  useContext(createActiveInactiveCtx());
