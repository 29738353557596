import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { memo } from 'react';
import Button, { Props as ButtonProps } from 'src/components/Buttons/Button';

interface Props extends ButtonProps {
  open: boolean;
  loading?: boolean;
  closeAction: () => void;
  submitAction: () => void;
  title: string;
  description: string;
}

export const DeleteModal = memo(({
  open,
  title,
  description,
  submitAction,
  closeAction,
  asyncAction,
}: Props) => {
  return (
    
    <Dialog open={open} onClose={closeAction}>
      <DialogTitle>
        <Typography variant="h4" component="div">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 300 }}>{description}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          sx={{ m: 2, py: 1 }}
          onClick={closeAction}>
          <Typography>Cancel</Typography>
        </Button>
        <Button
          variant="contained"
          color="error"
          size="small"
          asyncAction={asyncAction}
          sx={{ m: 2, py: 1 }}
          onClick={submitAction}>
          <Typography>Delete</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
});
