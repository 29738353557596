import { initRouter, IRoute, RouterStore } from 'mobx-router6';
import { ClientTypes } from 'src/api/api-types/lookups';

import {
  adminRoutes,
  agencyRoutes,
  haulerRoutes,
  jurisdictionRoutes,
  RoutesUnion,
} from '../routes';
import { routesUnauth } from '../routes.unauth';
import { ItemType } from '../types';
import {
  RouteFeatures,
  RouteFeaturesConfigType,
} from '../api/api-types/configuration';
import { HaulerWorkspaceRoute } from '../pages/HaulerWorkspace/route';
import { indexOf } from 'lodash';
import { FeaturesConfigurationModel } from '../models/features-configuration/features-configuration';

interface createProps {
  authenticated: boolean;
  selectedJurisdiction: boolean;
  client: ItemType | null;
  featuresConfiguration: FeaturesConfigurationModel;
}

type RouteModType = 'auth' | 'unauth';

export let routerStore: RouterStore<IRoute> | null = null;
let currentRouteMode: RouteModType = 'unauth';

const generateClientRoutes = (
  client: ItemType,
  featuresConfiguration: RouteFeaturesConfigType,
) => {
  const routes: { [key: number]: RoutesUnion[] } = {
    [ClientTypes.Jurisdiction]: [...jurisdictionRoutes],
    [ClientTypes.Hauler]: [...haulerRoutes],
    [ClientTypes.Administrator]: [...adminRoutes],
    [ClientTypes.Agency]: [...agencyRoutes],
  };
  return routes[client?.id].filter(({ name }) => {
    const availableRouteState = featuresConfiguration?.[name as RouteFeatures];
    if (typeof availableRouteState === 'boolean') return availableRouteState;
    return true;
  });
};

export const createRouter = ({
  authenticated = false,
  client,
  selectedJurisdiction,
  featuresConfiguration,
}: createProps) => {
  if (routerStore) {
    const isAuthStoreReady =
      currentRouteMode === 'auth' && authenticated && !!client;
    const isUnAuthStoreReady = currentRouteMode === 'unauth' && !authenticated;

    if (isAuthStoreReady || isUnAuthStoreReady) {
      return routerStore;
    }

    routerStore.router.stop();
  }

  if (authenticated && client) {
    if (
      !featuresConfiguration.isRollupPhase1 &&
      !selectedJurisdiction &&
      !(
        indexOf(
          [ClientTypes.Administrator, ClientTypes.Jurisdiction],
          client.id,
        ) > -1
      )
    ) {
      routerStore = initRouter<any>([HaulerWorkspaceRoute]);
      routerStore.router.start();
      currentRouteMode = 'auth';
      return routerStore;
    }

    const routes = generateClientRoutes(
      client,
      featuresConfiguration.featuresRouterMap,
    );
    routerStore = initRouter<any>(routes);
    routerStore.router.start();

    currentRouteMode = 'auth';

    return routerStore;
  }

  routerStore = initRouter<any>(routesUnauth);
  routerStore.router.start();
  currentRouteMode = 'unauth';

  return routerStore;
};
