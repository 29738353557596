import { CSSProperties } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Typography } from '@mui/material';
import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

interface GroupItemProps {
  name: string;
  deleteAction?: () => void;
  onClick: () => void;
}

export const TextWrapper = styled(ListItemText)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '11rem',
  cursor: 'pointer',
  color: theme.palette.primary.main,
  ...(theme.typography.link as CSSProperties),
}));

export default ({ onClick, name, deleteAction }: GroupItemProps) => {
  return (
    <MenuItem sx={{ p: 0 }}>
      <TextWrapper onClick={onClick} sx={{ p: 1 }}>
        <Typography variant="link" noWrap textOverflow="ellipsis">
          {name}
        </Typography>
      </TextWrapper>

      {deleteAction && (
        <IconButton onClick={deleteAction} size="small">
          <DeleteOutlineIcon color="disabled" />
        </IconButton>
      )}
    </MenuItem>
  );
};
