import { lazy } from 'react';
import { MinervaRoute } from 'src/utils/router';
import { containersStore } from './store';

export const ContainersRoute: MinervaRoute<'containers'> = {
  name: 'containers',
  path: '/containers',
  comp: () => lazy(() => import('./index')),
  activate: async () => await containersStore.fetchContainersList(),
  deactivate: () => containersStore.resetStore(),
};
