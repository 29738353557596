import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';


import { storeFactory } from 'src/utils/store';
import { FoodGeneratorsApi } from 'src/api/foodGenerators';
import { ApiReq, emptyValue } from 'src/api';
import {
  EfgTierData,
  EFGTypesMeta,
  FoodGeneratorDetailsResponse,
  MarkingEFGFormType,
} from 'src/api/api-types/food-generators';
import { EfgDetailsItem } from 'src/models/edible-food/efg-details-Item';
import { ItemType } from 'src/types';
import { debounce } from '@mui/material';
import { EfgModel } from 'src/models/edible-food/efg-model';


export class AccountEFGStore {
  api = new FoodGeneratorsApi({ prefix: '' });

  @observable foodGenerators: IObservableArray<EfgDetailsItem> = observable([]);

  @observable deleteModal: boolean = false;
  @observable isMarking: boolean = false;
  @observable accountId?: number;
  @observable edibleFood?: EfgDetailsItem;
  @observable efgModel = new EfgModel();

  @observable.ref detailsReq: ApiReq<FoodGeneratorDetailsResponse> = emptyValue;
  @observable.ref efgTypesMetaReq: ApiReq<EFGTypesMeta[]> = emptyValue;
  @observable.ref efgTierReq: ApiReq<ItemType> = emptyValue;
  @observable tierCalculationData?: EfgTierData;

  constructor() {
    makeObservable(this);
  }

  @computed get isMarkedEFG() {
    return this.edibleFood?.isEfg || false;
  }

  @computed get efgTypesMeta() {
    if (
      this.efgTypesMetaReq.state !== 'fulfilled' ||
      !this.efgTypesMetaReq.value
    )
      return [];
    return this.efgTypesMetaReq.value.data || [];
  }

  @computed get efgTier() {
    if (
      this.efgTierReq.state !== 'fulfilled' ||
      !this.efgTierReq.value
    )
      return null;
    return this.efgTierReq.value.data;
  }

  @action saveMarkedEFG = async (data: MarkingEFGFormType) => {
    this.edibleFood = new EfgDetailsItem({
      id: data.id,
      name: data.name,
      edibleFoodGeneratorType: data.edibleFoodGeneratorType,
      edibleFoodGeneratorTier: null,
      sqFeet: data.sqFeet,
      seats: data.seats,
      onSiteFoodFacility: data.onSiteFoodFacility,
      beds: data.beds,
      isEfg: true,
      rooms: data.rooms,
      contact: {
        fullName: data.fullName,
        email: data.email,
        phone: data.phone,
        zipCode: data.zipCode,
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
      },
      description: data.description,
      descriptionTruncated: false,
    });
    await this.edibleFood?.editDetails();
    this.isMarking = false;
    this.removeEdibleFood();
    if (!!this.accountId) {
      return this.fetchDetails(this.accountId);
    }
  };
  @action savingBehaviour = async (data: MarkingEFGFormType) => {
    if (!data.isEfg) {
      return this.removeMarkedEFG();
    } else {
      return this.saveMarkedEFG(data);
    }
  };


  @action toggleMarkingDialog = () => {
    this.isMarking = !this.isMarking;
  };
  @action setAccountId = (accountId: number) => {
    this.accountId = accountId;
  };

  @action fetchDetails = async (accountId: number | string) => {
    this.removeEdibleFood();
    if (accountId) {
      this.setAccountId(Number(accountId));
    }
    this.detailsReq = this.api.getFoodGeneratorsDetails(Number(accountId));
    await this.detailsReq;
    runInAction(() => {
      if (this.detailsReq.state === 'fulfilled' && this.detailsReq.value?.data) {
        const data = this.detailsReq?.value.data;
        if (this.efgTypesMeta.length > 0 && data?.edibleFoodGeneratorType !== null) {
          const type = this.efgTypesMeta.find(
            (item) => item.id === data?.edibleFoodGeneratorType?.id,
          );
          this.edibleFood = new EfgDetailsItem({
            ...data,
            edibleFoodGeneratorType: !!type
              ? type
              : data.edibleFoodGeneratorType,
          });
        } else {
          this.edibleFood = new EfgDetailsItem(data);
        }
      }
    });
    return this.detailsReq;
  };

  @action removeMarkedEFG = async () => {
    if (!this.accountId) return;

    this.detailsReq = this.api.deleteEFG(this.accountId);
    await this.detailsReq;
    return this.fetchDetails(this.accountId);
    //todo this requestingCall need to level up of scope from this store and revoke EFR state
  };
  @action fetchEFGTypesMeta = async (name?: string) => {
    this.efgTypesMetaReq = this.api.getEFGTypesWithMeta(name);
    await this.efgTypesMetaReq;
    return this.efgTypesMetaReq;
  };

  @action fetchEfgTier = debounce(async (data: EfgTierData) => {
    this.tierCalculationData = { ...this.tierCalculationData, ...data };
    this.efgTierReq = this.api.getEFGTier(this.tierCalculationData);
    await this.efgTierReq;
    return this.efgTierReq;
  }, 1500);

  @action resetTierCalculationData = () => this.tierCalculationData = undefined;

  @action removeEdibleFood = () => this.edibleFood = undefined;

  @action reset = () => {
    this.foodGenerators.clear();
    this.removeEdibleFood();
    this.deleteModal = false;
    this.isMarking = false;
    this.accountId = undefined;
    this.detailsReq = emptyValue;
  };
}

export const { store: accountEFGStore, storeCtx: accountDetailsEFGStoreCtx } = storeFactory(
  AccountEFGStore,
  'accountEFG',
);
