import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { EFGItem } from 'src/models/edible-food/efg-item';
import { FoodGeneratorsApi } from 'src/api/foodGenerators';
import { ApiReq, emptyValue } from 'src/api';
import {
  EFGTransferringInfoType,
  TransferringInfoType,
  UpdateEFGTransferringInfoType,
} from 'src/api/api-types/food-generators';

export class EFGTransferringInfo {
  readonly api = new FoodGeneratorsApi();

  @observable showInfo = false;
  @observable isEditing = false;
  @observable EFGItem: EFGItem | null = null;

  @observable.ref dataInfoList: IObservableArray<EFGTransferringInfoType> = observable([]);
  @observable.ref newEFGInfoData: IObservableArray<TransferringInfoType> = observable([]);

  @observable.ref getEfgInfoReq: ApiReq<EFGTransferringInfoType[]> = emptyValue;
  @observable.ref updateEfgInfoReq: ApiReq<UpdateEFGTransferringInfoType> = emptyValue;

  constructor() {
    makeObservable(this);
  }

  @action openInfo = () => {
    this.showInfo = true;
  };

  @action closeInfo = () => {
    this.showInfo = false;
  };

  @action startEditing = () => {
    this.isEditing = true;
  };

  @action stopEditing = () => {
    this.isEditing = false;
  };

  @action setEFGItem = (item: EFGItem) => {
    this.EFGItem = new EFGItem(item);
  };

  @computed get EFGTransferringInfoData() {
    if (
      this.getEfgInfoReq.state !== 'fulfilled' ||
      !this.getEfgInfoReq.value
    ) {
      return [];
    }
    return this.getEfgInfoReq.value.data || [];
  }

  @action getEFGInfo = async (id: number) => {
    this.getEfgInfoReq = this.api.getEFGTransferringInfo(id);
    await this.getEfgInfoReq;

    runInAction(() => {
      this.dataInfoList.replace(this.EFGTransferringInfoData.map((item) => ({
        ...item,
        id: item.period.id,
        nameData: item.period.name,
        numberOfTransfers: item.numberOfTransfers || '',
        totalPoundsTransferred: item.totalPoundsTransferred || '',
      })));
    });

    return this.getEfgInfoReq;
  };

  @action updatedItem = (item: TransferringInfoType) => {
    runInAction(() => {
      const index = this.newEFGInfoData.findIndex((existingItem) => existingItem.id === item.id);

      if (index === -1) {
        this.newEFGInfoData.push(item);
      } else {
        this.newEFGInfoData[index] = item;
      }
    });
  };

  @computed get serializeData() {
    return this.newEFGInfoData.map((item) => ({
      period: {
        id: Number(item.id),
      },
      numberOfTransfers: item.numberOfTransfers || 0,
      typesOfFoodTransferred: item.typesOfFoodTransferred || '',
      totalPoundsTransferred: item.totalPoundsTransferred || 0,
    }) as UpdateEFGTransferringInfoType);
  };

  @action uploadData = async () => {
    if (!this.EFGItem) {
      return;
    }
    this.updateEfgInfoReq = this.api.updateEFGTransferringInfo(this.EFGItem.id, this.serializeData);

    await this.updateEfgInfoReq;
    return this.updateEfgInfoReq;
  };

  @action saveInfo = async () => {

    if (!this.EFGItem) {
      return;
    }
    await this.uploadData();
    if (this.updateEfgInfoReq.state === 'fulfilled') {
      this.resetEFGInfoData();
    }
  };

  @action resetEFGInfoData = () => {
    this.EFGItem = null;
    this.isEditing = false;
    this.showInfo = false;
    this.getEfgInfoReq = emptyValue;
    this.updateEfgInfoReq = emptyValue;
    this.newEFGInfoData = observable([]);
  };
}
