import { startOfTomorrow } from 'date-fns';
import { IObservableArray, toJS } from 'mobx';
import {
  DestinationValuesLookup,
  ImportContainerValue,
  ImportValueItem,
  MergedColumns,
} from 'src/api/api-types/import';
import { ItemValueType } from 'src/types';
import * as yup from 'yup';

import { ImportMergeColumn } from '../models/import-merge-column';
import { ImportSteps } from '../type';

export const breadcrumbItems = [
  { title: 'Upload', key: ImportSteps.Upload },
  { title: 'Matching Columns', key: ImportSteps.MatchingColumns },
  { title: 'Matching Values', key: ImportSteps.MatchingValues },
  { title: 'Containers', key: ImportSteps.MatchingContainers },
  { title: 'Complete', key: ImportSteps.Complete },
];

export const prepareMergedColumnsData = (data: ItemValueType[]) =>
  data.reduce((acc, curr) => {
    if (typeof curr?.value?.id === 'number')
      return [
        ...acc,
        {
          template: { id: Number(curr?.id) },
          source: { id: Number(curr?.value.id) },
        },
      ];
    return acc;
  }, [] as MergedColumns[]);

export const prepareMergedValuesData = (
  data: IObservableArray<ImportMergeColumn>,
) =>
  data.reduce(
    (acc, curr) => [...acc, ...toJS(curr.values)],
    [] as ImportValueItem[],
  );

export const prepareMergedContainersData = (data: ImportContainerValue[]) =>
  data.map(({ id, value }) => ({
    id,
    wasteMaterialTypes: value || [],
  }));

export const MIN_IMPORT_DATA_DATE = new Date('01/01/2017');

const baseValidationSchema = {
  hauler: yup.object().nullable().label('Hauler').required(),
  dataDate: yup
    .date()
    .nullable()
    .max(startOfTomorrow(), 'Data date must be before future')
    .required('Data date is required'),
};

export const validationSchema = yup.object(baseValidationSchema);
export const adminValidationSchema = yup.object({
  ...baseValidationSchema,
  jurisdiction: yup.object().nullable().label('Jurisdiction').required(),
});

export const prepareDestinationLookupOptions = (
  options: DestinationValuesLookup,
) => {
  const { items, ignoreAvailable, overThresholdAvailable, withNumberInput } = options;
  const resultOptions = [];
  if (ignoreAvailable) {
    resultOptions.push({ id: 'ignored', name: 'Ignored' });
  }
  if (overThresholdAvailable) {
    resultOptions.push({ id: 'overThreshold', name: 'OverThreshold' });
  }
  if (withNumberInput) {
    resultOptions.push({ id: 'enterData', name: 'Enter number' });
  }
  return [
    ...resultOptions,
    ...items,
  ];
};
