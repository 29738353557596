import { SyntheticEvent, useContext } from 'react';

import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { observer } from 'mobx-react-lite';
import { EFGTypesMeta } from 'src/api/api-types/food-generators';
import { StyledTextField } from 'src/components/FormTextFields/style';
import { OptionDescription } from 'src/components/Lookups/styles';
import { lookupsStoreCtx } from 'src/stores/lookups';

import { useEfgEdit } from '../efgEditContext';

export const EditEfgType = observer(() => {
  const { edibleFoodGeneratorsTypes } = useContext(lookupsStoreCtx);
  const { chosenFood } = useEfgEdit();
  if (!chosenFood) return null;

  const handleChangeTypes = async (
    _e: SyntheticEvent,
    value: EFGTypesMeta | null,
  ) => {
    chosenFood?.setEntity({ edibleFoodGeneratorType: value });
  };

  return (
    <Autocomplete
      fullWidth
      onChange={handleChangeTypes}
      getOptionLabel={option => option.name}
      value={chosenFood?.edibleFoodGeneratorType as EFGTypesMeta}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      options={edibleFoodGeneratorsTypes}
      renderInput={params => (
        <StyledTextField {...params} variant="outlined" size="medium" />
      )}
      renderOption={(props, option) => (
        <OptionDescription
          key={option.id}
          placement="right"
          disableHoverListener={!option?.description}
          title={option.description}>
          <Box component="li" {...props} key={option.id}>
            {option?.name}
          </Box>
        </OptionDescription>
      )}
    />
  );
});
