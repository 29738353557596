import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Link } from 'src/components/Link';
import MaterialType from 'src/components/MaterialType';
import { ScheduleComponent } from 'src/components/Schedule/ScheduleComponent';
import { ScheduleHeader } from 'src/components/Schedule/ScheduleHeader';
import { formattedDateOnly, formattedUSDateTime } from 'src/utils/date';

import { ServiceHistory } from '../../../models/servise/service-history';

export const historyColumns: GridColDef<ServiceHistory>[] = [
  {
    field: 'generatorId',
    headerName: 'Generator ID',
    width: 150,
    renderCell: ({ value, row }: GridRenderCellParams) => (
      <Link to="generator" params={{ id: row.location.id }}>
        {value}
      </Link>
    ),
  },
  {
    field: 'minervaId',
    headerName: 'Minerva ID',
    minWidth: 150,
    renderCell: ({ value, row }: GridRenderCellParams) => (
      <Link to="account" params={{ id: row.generator.id }}>
        {value}
      </Link>
    ),
  },
  {
    field: 'jurisdiction',
    headerName: 'Jurisdiction',
    width: 120,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'hauler',
    headerName: 'Hauler',
    width: 90,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'accountNumber',
    headerName: 'Account #',
    minWidth: 110,
  },
  {
    field: 'recordId',
    headerName: 'Route ID',
    minWidth: 110,
    disableColumnMenu: true,
  },
  {
    field: 'dataDate',
    headerName: 'Data date',
    minWidth: 140,
    filterable: false,
    renderCell: ({ value }: GridRenderCellParams) => formattedDateOnly(value),
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    minWidth: 180,
    renderCell: ({ value }: GridRenderCellParams) =>
      formattedUSDateTime(value) ?? ''
  },
  {
    field: 'activated',
    headerName: 'Activated',
    minWidth: 180,
    renderCell: ({ value }: GridRenderCellParams) =>
      formattedDateOnly(value) ?? '',
  },
  {
    field: 'deactivated',
    headerName: 'Deactivated',
    minWidth: 180,
    renderCell: ({ value }: GridRenderCellParams) =>
      formattedDateOnly(value) ?? '',
  },
  {
    field: 'accountType',
    headerName: 'Account Type',
    flex: 1,
    minWidth: 150,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'generator',
    headerName: 'Account Name',
    minWidth: 200,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'location',
    headerName: 'Account address',
    width: 450,
    valueGetter: ({ value }) => value.name ?? '',
  },
  {
    field: 'containerQuantity',
    headerName: 'Quantity',
    width: 175,
  },
  {
    field: 'container',
    headerName: 'Container ID',
    width: 210,
    valueGetter: ({ value }) => value.name,
  },
  {
    field: 'serviceWasteType',
    headerName: 'Material',
    width: 145,
    valueGetter: ({ value }) => (value ? value?.name : ''), 
    renderCell: (data: GridRenderCellParams) =>
      <MaterialType type={data.row[data.field]} />
  },
  {
    field: 'containerType',
    headerName: 'Type',
    width: 145,
  },
  {
    field: 'containerSize',
    headerName: 'Size',
    width: 145,
  },
  {
    field: 'unitOfMeasure',
    headerName: 'Unit',
    width: 145,
    valueGetter: ({ value }) => value.name,
  },
  {
    field: 'weeklyFrequency',
    headerName: 'Weekly Pickups',
    width: 145,
  },
  {
    field: 'schedule',
    disableColumnMenu: true,
    renderCell: ({ value, row }) => (
      <ScheduleComponent
        schedule={value}
        scheduleDefined={row.scheduleDefined}
      />
    ),
    renderHeader: () => <ScheduleHeader />,
    width: 200,
  },
  {
    field: 'weeklyVolumeCubicYards',
    headerName: 'Weekly Volume',
    width: 145,
  },
  {
    field: 'onCall',
    headerName: 'On call',
    width: 145,
    valueGetter: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    field: 'site',
    headerName: 'Site',
    width: 145,
  },
  {
    field: 'containerGroup',
    headerName: 'Container Group',
    width: 145,
  },
];
