export enum ImportSteps {
  StartImport = 'start-import',
  Upload = 'upload',
  MatchingColumns = 'matching-columns',
  MatchingValues = 'matching-values',
  MatchingContainers = 'matching-containers',
  Complete = 'complete',
  Loading = 'loading',
}

export interface ImportStepsParams {
  step: ImportSteps;
}
