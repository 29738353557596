import { useContext, useEffect, useRef } from 'react';

import { observer } from 'mobx-react-lite';
import { FileUploadType } from 'src/api/api-types/files';

import { createFileDialogInstance } from '../../../../../components/File/FileUploadDialog';
import { implementationRecordsStoreCtx } from '../../../store';

export const fileStore = createFileDialogInstance('AssetAttachment');
const { fileDialogStore, AttachmentsDialog } = fileStore;

export const AddAttachments = observer(() => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const { targetCategory } = useContext(implementationRecordsStoreCtx);
  const { chosenSubCategory } = targetCategory || {};

  useEffect(() => {
    if (chosenSubCategory?.chosenAsset) {
      fileDialogStore.dependingToAsset(chosenSubCategory?.chosenAsset);
    }
  }, [chosenSubCategory?.chosenAsset]);

  const handleClose = () => {
    fileDialogStore.clear();
  };

  const handleClearAll = () => {
    handleClose();
  };

  const handleDone = async () => {
    fileDialogStore.clear();
    await chosenSubCategory?.getAssetsList();
  };
  return (
    <AttachmentsDialog
      uploadInputRef={uploadInputRef}
      onDone={handleDone}
      onClear={handleClearAll}
      onClose={handleClose}
      type={FileUploadType.file}
      linkAction={chosenSubCategory?.chosenAsset?.linkFile!}
      asset={chosenSubCategory?.chosenAsset!}
    />
  );
});

export default AddAttachments;
