import * as yup from 'yup';

import { AccountsFilter } from '../../../api/api-types/accounts';
import { ColumnsVisibilityModel } from '../../../api/api-types/gridColumnsManager';
import { userStore } from '../../../stores/user';
import { ExportTypes } from '../../../types';

export const accountDefaultValue = {
  name: '',
  accountNumber: '',
  generatorId: null,
  addressLine1: '',
  city: '',
  zipCode: '',
  state: '',
};

export const validationSchema = (
  isAdmin: boolean
) => yup.object({
  name: yup.string().label('Name').required(),
  location: yup.string().label('Address').required(),
  type: yup.string().label('Type').required(),
  numberOfUnits: yup
    .number()
    .label('Number Of Units')
    .optional()
    .nullable()
    .typeError('Please write numbers.'),
  addressLine2: yup.string().label('Address'),
  city: yup.string().label('City').required(),
  zipCode: yup.string().label('ZIP / Postal Code').required(),
  state: yup.string().label('State').required(),
  ...(isAdmin && {jurisdiction: yup.mixed().nullable().label('Jurisdiction').required()}) 
});

export const exportDefaultValue = {
  fileName: '',
  mode: ExportTypes.WITH_FILTERS,
};

export const defaultVisibilityColumns = {
  numberOfUnits: false,
  typeOfBusiness: false,
  naicsCode: false,
  naicsDescription: false,
  zone: false,
  extensionField1: false,
  extensionField2: false,
  populationDensity: false,
  watershed: false,
};

export const visibilityDefaultState: Partial<ColumnsVisibilityModel> = {
  admin: {
    jurisdiction: false,
    ...defaultVisibilityColumns,
  },
  hauler: {
    hauler: false,
    ...defaultVisibilityColumns,
  },
};

export const defaultAccountsSearch = {
  'q.jurisdiction.id': '',
  'q.accountNumber': '',
  'q.name': '',
  'q.reference': '',
  'q.usaAddress': '',
  'q.location.reference': '',
} as AccountsFilter;

export const defaultFilterField = () => {
  if (userStore.isJurisdiction) {
    delete defaultAccountsSearch['q.jurisdiction.id'];
    return defaultAccountsSearch;
  }
  return defaultAccountsSearch;
};
