import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const OrangeIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_1813_80249)">
      <circle cx="24.5" cy="24" r="24" fill="#FDEDD1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 12C13.3954 12 12.5 12.8954 12.5 14V34C12.5 35.1046 13.3954 36 14.5 36H34.5C35.6046 36 36.5 35.1046 36.5 34V14C36.5 12.8954 35.6046 12 34.5 12H14.5Z"
        fill="#F5CB5C"
      />
      <path d="M26.5 16H22.5V25H26.5V16Z" fill="white" />
      <path d="M26.5 28H22.5V32H26.5V28Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_1813_80249">
        <rect width="48" height="48" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
export default OrangeIcon;
