import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CollapseClose = (props: SvgIconProps) => (
  <SvgIcon
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M22.3333 1H3.66667C2.19391 1 1 2.19391 1 3.66667V22.3333C1 23.8061 2.19391 25 3.66667 25H22.3333C23.8061 25 25 23.8061 25 22.3333V3.66667C25 2.19391 23.8061 1 22.3333 1Z"
      stroke="#767D86"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.66797 13H18.3346"
      stroke="#767D86"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default CollapseClose;
