import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DefaultIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2.48096 1C2.48096 0.721617 2.70731 0.5 2.97143 0.5H9.69619L13.3666 4.20571V15C13.3666 15.2784 13.1402 15.5 12.8761 15.5H2.97143C2.70731 15.5 2.48096 15.2784 2.48096 15V1Z"
      fill="white"
      stroke="#B0B7BD"
    />
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08983 7.5606C7.76852 7.50549 7.4543 7.69016 7.34612 7.99769C7.30946 8.10189 7.19528 8.15664 7.09108 8.11999C6.98689 8.08333 6.93213 7.96915 6.96879 7.86495C7.14169 7.37343 7.6439 7.07827 8.15746 7.16636C8.67097 7.25444 9.04609 7.70002 9.04538 8.22102C9.04529 8.61662 8.75122 8.88589 8.52203 9.03868C8.40133 9.11915 8.28206 9.17864 8.19375 9.21789C8.14931 9.23764 8.11199 9.25258 8.08532 9.26274C8.07197 9.26783 8.06124 9.27174 8.05356 9.27447L8.04437 9.27769L8.04158 9.27864L8.04066 9.27895L8.04017 9.27911C8.04011 9.27913 8.04005 9.27915 7.97799 9.09298L8.04017 9.27911C7.93538 9.31404 7.822 9.25745 7.78707 9.15266C7.75216 9.04794 7.8087 8.93475 7.91336 8.89975L7.97679 9.08936C7.91356 8.89968 7.91341 8.89973 7.91336 8.89975L7.91422 8.89945L7.91956 8.89758C7.9246 8.89579 7.93253 8.89291 7.94293 8.88895C7.96376 8.88101 7.9943 8.86881 8.03129 8.85237C8.10584 8.81924 8.2037 8.77016 8.30015 8.70586C8.50521 8.56916 8.64538 8.4042 8.64538 8.22085C8.64586 7.89484 8.41115 7.61572 8.08983 7.5606Z"
        fill="#141E30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00005 10.4476C8.11051 10.4476 8.20005 10.3581 8.20005 10.2476V10.1029C8.20005 9.99241 8.11051 9.90287 8.00005 9.90287C7.88959 9.90287 7.80005 9.99241 7.80005 10.1029V10.2476C7.80005 10.3581 7.88959 10.4476 8.00005 10.4476Z"
        fill="#141E30"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 6.10471C6.51148 6.10471 5.30479 7.31141 5.30479 8.79995C5.30479 10.2885 6.51148 11.4952 8.00002 11.4952C9.48856 11.4952 10.6953 10.2885 10.6953 8.79995C10.6953 7.31141 9.48856 6.10471 8.00002 6.10471ZM4.90479 8.79995C4.90479 7.0905 6.29057 5.70471 8.00002 5.70471C9.70948 5.70471 11.0953 7.0905 11.0953 8.79995C11.0953 10.5094 9.70948 11.8952 8.00002 11.8952C6.29057 11.8952 4.90479 10.5094 4.90479 8.79995Z"
        fill="#141E30"
      />
    </g>
    <path
      d="M10.8953 4H13.8667L9.90479 0V3C9.90479 3.55 10.3505 4 10.8953 4Z"
      fill="#B0B7BD"
    />
    <path d="M13.8664 7L10.895 4H13.8664V7Z" fill="#CAD1D8" />
  </SvgIcon>
);
export default DefaultIcon;
