import {
  AssetAttachmentsResponse,
  AssetsResponse,
} from 'src/api/api-types/assets';
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { ApiReq, emptyValue } from '../../api';
import { AttachmentFile } from 'src/models/file';
import { AssetsApi } from '../../api/assets';

interface AttachmentsInitialData {
  id: number;
  totalAttachments: number;
}

export class AssetAttachments {
  readonly assetId: number;
  readonly api = new AssetsApi();

  @observable totalAttachments: number;
  @observable.ref files: IObservableArray<AttachmentFile> = observable([]);
  @observable.ref attachmentsReq: ApiReq<AssetAttachmentsResponse> = emptyValue;
  @observable.ref deleteAttachmentReq: ApiReq<AssetsResponse> = emptyValue;

  constructor({ id, totalAttachments }: AttachmentsInitialData) {
    makeObservable(this);

    this.assetId = id;
    this.totalAttachments = totalAttachments;
  }

  @computed get attachmentsList() {
    if (
      this.attachmentsReq.state !== 'fulfilled' ||
      !this.attachmentsReq.value
    ) {
      return [];
    }

    return this.attachmentsReq.value.data || [];
  }

  @action setTotalAttachments = (value: number) => {
    this.totalAttachments = value;
  };

  @action getAttachmentsList = async () => {
    this.attachmentsReq = this.api.getAttachments(this.assetId);
    await this.attachmentsReq;
    runInAction(() => {
      this.files.replace(
        this.attachmentsList.map(file => {
          return new AttachmentFile(file);
        }),
      );
      this.setTotalAttachments(this.attachmentsList.length);
    });
    return this.attachmentsReq;
  };

  @action deleteFile = async (attachmentId: number) => {
    this.deleteAttachmentReq = this.api.deleteAttachment(
      this.assetId,
      attachmentId,
    );
    await this.deleteAttachmentReq;
    await this.getAttachmentsList();
    return this.deleteAttachmentReq;
  };

  @action attachmentsBulkDownload = async () => {
    const downloads = this.files.map(async file => {
      await file.getFileDownloadToken(file.id, this.assetId);
      await file.download();
    });
    return Promise.allSettled(downloads);
  };

  @action download = async (fileId: number) => {
    const selectedFile = this.files.find(item => item.id === fileId);
    if (!selectedFile) return;
    await selectedFile.getFileDownloadToken(fileId, this.assetId);
    await selectedFile.download();
  };
}
