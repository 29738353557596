import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { GeneratorItem } from 'src/api/api-types/generators';
import ActiveInactiveIcon from 'src/assets/icons/ActiveInactive/ActiveInactiveIcon';
import ActiveInactive from 'src/components/ActiveInactive';
import { Link } from 'src/components/Link';
import { GRID_CHECKBOX_SELECTION_COL } from 'src/components/Table/constants';
import { formattedUSDate } from 'src/utils/date';

import { groupColumns } from './groupColumns';

export const columns: GridColDef<GeneratorItem>[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL,
    type: 'string',
    disableColumnMenu: true,
  },
  {
    field: 'isActive',
    headerName: 'Inactive',
    sortable: false,
    filterable: false,
    headerAlign: 'center',
    disableColumnMenu: true,
    renderHeader: () => <ActiveInactive />,
    align: 'center',
    width: 45,
    renderCell: ({ value }) => (!value ? <ActiveInactiveIcon /> : null),
  },
  {
    field: 'reference',
    disableColumnMenu: true,
    headerName: 'Generator ID',
    headerAlign: 'center',
    align: 'center',
    minWidth: 150,
    flex: 1,
    maxWidth: 175,
    renderCell: ({ value, row }: GridRenderCellParams) => (
      <Link to="generator" params={{ id: row.id }}>
        {value}
      </Link>
    ),
  },

  {
    field: 'accountMinervaId',
    disableColumnMenu: true,
    headerName: 'Minerva ID',
    minWidth: 150,
    flex: 1,
    maxWidth: 175,
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ value, row }: GridRenderCellParams) =>
      !!row.accountId && (
        <Link to="account" params={{ id: row.accountId }}>
          {value}
        </Link>
      ),
  },
  {
    field: 'agency',
    sortable: false,
    filterable: true,
    headerName: 'Agency',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    maxWidth: 175,
    minWidth: 100,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'nickname',
    disableColumnMenu: true,
    headerName: 'Nickname',
    minWidth: 150,
    flex: 1,
    maxWidth: 200,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'jurisdiction',
    filterable: true,
    headerName: 'Jurisdiction',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 120,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'hauler',
    filterable: true,
    headerName: 'Hauler',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    maxWidth: 175,
    minWidth: 100,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'accountNumber',
    disableColumnMenu: true,
    headerName: 'Account #',
    minWidth: 150,
    flex: 1,
    maxWidth: 175,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'types',
    filterable: true,
    headerName: 'Generator Type',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    minWidth: 170,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'accountType',
    filterable: true,
    headerName: 'Account Type',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    minWidth: 120,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'accountName',
    disableColumnMenu: true,
    headerName: 'Account Name',
    minWidth: 250,
    flex: 1,
    maxWidth: 375,
  },
  {
    field: 'accountAddress',
    disableColumnMenu: true,
    headerName: 'Account Address',
    minWidth: 280,
    flex: 1,
    maxWidth: 500,
    valueGetter: ({ value }) => value?.name ?? '',
  },
  {
    field: 'statusDate',
    headerName: 'Status Date',
    flex: 2,
    disableColumnMenu: true,
    valueGetter: ({ value }) => formattedUSDate(value),
  },
  {
    field: 'subjectTo',
    filterable: true,
    headerName: 'Subject To',
    type: 'boolean',
    minWidth: 50,
    flex: 1,
    maxWidth: 125,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'recycling',
    filterable: true,
    headerName: 'Recycling',
    type: 'boolean',
    minWidth: 50,
    flex: 1,
    maxWidth: 125,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'collectionSystem',
    filterable: true,
    headerName: 'System',
    width: 100,
    valueGetter: ({ value }) => (value ? value?.name : ''),
  },
  {
    field: 'waiver',
    filterable: true,
    headerName: 'Waiver',
    type: 'boolean',
    minWidth: 50,
    flex: 1,
    maxWidth: 125,
  },
  {
    field: 'deMinimisWaiver',
    filterable: true,
    headerName: 'De-minimis Waiver',
    type: 'boolean',
    minWidth: 50,
  },
  {
    field: 'spaceWaiver',
    filterable: true,
    headerName: 'Space Waiver',
    type: 'boolean',
    minWidth: 50,
  },
  {
    field: 'departmentWaiver',
    filterable: true,
    headerName: 'Department Waiver',
    type: 'boolean',
    minWidth: 50,
  },
  ...groupColumns,
  {
    field: 'isCefg',
    filterable: true,
    headerName: 'CEFG',
    type: 'boolean',
    minWidth: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'moreRequired',
    filterable: true,
    headerName: 'MORe Required To',
    type: 'boolean',
    minWidth: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'moreNotRecycling',
    filterable: true,
    headerName: 'MORe Not Recycling',
    type: 'boolean',
    minWidth: 100,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'mcrRequired',
    filterable: true,
    headerName: 'MCR Required To',
    minWidth: 100,
    type: 'boolean',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'mcrNotRecycling',
    filterable: true,
    headerName: 'MCR Not Recycling',
    type: 'boolean',
    minWidth: 100,
    headerAlign: 'center',
    align: 'center',
  },
];

type VisibilityModelType<T> = {
  [Property in keyof T]: boolean;
};

export const columnVisibilityModel: VisibilityModelType<
  Partial<GeneratorItem>
> = {
  accountType: false,
  jurisdiction: false,
  deMinimisWaiver: false,
  spaceWaiver: false,
  departmentWaiver: false,
  isCefg: false,
  moreRequired: false,
  moreNotRecycling: false,
  mcrRequired: false,
  mcrNotRecycling: false,
};

export default columns;
