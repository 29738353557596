import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';

interface Props {
  open: boolean;
  theme?: Theme;
}

const RootContainer = styled('div')<Props>(({ open }) => ({
  borderLeft: '2px solid #65AFFF',
  borderBottom: open ? '1px solid rgba(20, 30, 48, 0.3)' : 'none',
  padding: 10,
  marginTop: 10,
}));

const StyledPanel = styled('div')({
  display: 'grid',
  gridTemplateColumns: '25px 1fr 20px',
  alignItems: 'center',
  cursor: 'pointer',
});

const StyledButton = styled(IconButton)<Props>(({ theme }) => ({
  transition: theme?.transitions.create('transform', {
    duration: theme?.transitions.duration.shortest,
  }),
  height: 24,
}));

export { RootContainer, StyledPanel, StyledButton };
