import { ScheduleType, ServiceItem } from 'src/api/api-types/services';
import { ItemType } from '../../types';

export class Service {
  readonly id: number;
  readonly generatorId: string;
  readonly minervaId: string;
  readonly accountNumber: string;
  readonly containerQuantity: number;
  readonly weeklyFrequency: number;
  readonly weeklyContainerQuantity: number;
  readonly weeklyVolumeCubicYards: number;
  readonly onCall: boolean;
  readonly compactor: boolean;
  readonly shared: boolean;
  readonly split: boolean;
  readonly site: string;
  readonly schedule: ScheduleType;
  readonly scheduleDefined: boolean;
  readonly containerSize: number;
  readonly createdAt: string;
  readonly generator: ItemType;
  readonly location: ItemType;
  readonly hauler: ItemType;
  readonly jurisdiction: ItemType;
  readonly accountType: ItemType;
  readonly container: ItemType;
  readonly serviceWasteType: ItemType;
  readonly containerType: string;
  readonly containerGroup: string;
  readonly unitOfMeasure: ItemType;
  readonly dataDate: string;
  readonly startDate: string;
  readonly recordId: string;

  constructor({
    id,
    generatorId,
    minervaId,
    accountNumber,
    containerQuantity,
    weeklyFrequency,
    weeklyContainerQuantity,
    weeklyVolumeCubicYards,
    createdAt,
    onCall,
    compactor,
    shared,
    split,
    site,
    schedule,
    scheduleDefined,
    containerSize,
    generator,
    location,
    serviceWasteType,
    containerType,
    unitOfMeasure,
    hauler,
    jurisdiction,
    accountType,
    containerGroup,
    container,
    dataDate,
    startDate,
    recordId
  }: ServiceItem) {
    this.id = id;
    this.generatorId = generatorId;
    this.minervaId = minervaId;
    this.accountNumber = accountNumber;
    this.containerQuantity = containerQuantity;
    this.weeklyFrequency = weeklyFrequency;
    this.weeklyContainerQuantity = weeklyContainerQuantity;
    this.weeklyVolumeCubicYards = weeklyVolumeCubicYards;
    this.createdAt = createdAt || '';
    this.onCall = onCall;
    this.compactor = compactor;
    this.shared = shared;
    this.split = split;
    this.schedule = schedule;
    this.site = site || '';
    this.scheduleDefined = scheduleDefined;
    this.containerSize = containerSize;
    this.generator = generator;
    this.location = location;
    this.serviceWasteType = serviceWasteType;
    this.hauler = hauler;
    this.jurisdiction = jurisdiction;
    this.accountType = accountType;
    this.container = container;
    this.containerType = containerType;
    this.containerGroup = containerGroup;
    this.unitOfMeasure = unitOfMeasure;
    this.dataDate = dataDate;
    this.startDate = startDate;
    this.recordId = recordId;
  }
}
