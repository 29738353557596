import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { ApiReq, emptyValue } from 'src/api';
import { ContainerItem, ContainersFilter, ContainersResponse } from 'src/api/api-types/containers';
import { ContainersApi } from 'src/api/containers';

import { userStore } from 'src/stores/user';
import { storeFactory } from 'src/utils/store';
import { Container } from './models/container';
import { ContainerModification } from './models/container-modification';
import { TablePagination } from "../../models/pagination/pagination";
import { lookupsStore } from "../../stores/lookups";
import { OnStartEditType } from "../../components/Table/types";
import { GridRowId } from '@mui/x-data-grid-pro';
import { columns } from './helpers/gridColumns';
import { GridColumnsManager } from 'src/models/grid-columns-manager/grid-columns-manager';
import { AsyncExportEntity } from 'src/models/export/AsyncExportEntity';
import { ExportDataType } from 'src/api/exportTypes';
import { TableFilter } from 'src/models/filter/table-filter';
import { ContainersExportColumnsNameMap, ContainersNameMapType } from 'src/models/filter/constants';


const mainFilters = {
  'q.name': '',
  'q.hauler.id': '',
};

const adminFilters = {
  'q.jurisdiction.id': '',
};

class ContainersStore {
  api = new ContainersApi({ prefix: '' });

  @observable.ref containersDataReq: ApiReq<ContainersResponse> = emptyValue;
  @observable.ref containers = observable<Container>([]);
  @observable.ref chosenContainer?: ContainerModification;
  @observable selectedContainers: Array<GridRowId> = [];
  @observable columnsManager;
  @observable filter = new TableFilter<ContainersFilter, ContainerItem>(
    columns,
    mainFilters,
    {},
  );
  @observable exportEntity: AsyncExportEntity = new AsyncExportEntity();
  @observable pagination = new TablePagination<ContainersResponse>({
    requestRef: this.containersDataReq,
  });

  constructor() {
    makeObservable(this);
    this.columnsManager = new GridColumnsManager<
      ContainerItem
    >({
      columns: columns,
      columnsMap: {},
      visibilityModel: {}
    });
    reaction(
      () => [userStore.isAdmin],
      () => {
        if (userStore.isAdmin) this.filter = new TableFilter(
          columns,
          {
            ...mainFilters,
            ...adminFilters,
          }, {});
      },
    );
    this.pagination.setFetchingCallBack(this.fetchContainersList);
  }

  @computed get containersListData(): Array<ContainerItem> {
    if (this.containersDataReq.state !== 'fulfilled' || !this.containersDataReq.value) return [];
    return this.containersDataReq.value.data?.items || [];
  }


  @action fetchContainersList = async () => {
    this.containersDataReq = this.api.getContainersData({
      ...this.pagination.serialize,
      filters: this.filter.filterParams,
    });
    await this.containersDataReq;
    runInAction(() => {
      if (
        this.containersDataReq.state !== 'fulfilled' ||
        this.containersDataReq.value?.data === null
      )
        return;
      this.containers.replace(this.containersListData.map(container => new Container(container)));
      this.pagination.setPagination(this.containersDataReq.value?.data);
    });
    return this.containersDataReq;
  };

  @action onStartEditAction = async ({ rowData }: OnStartEditType<Container>) => {
    if (!rowData) return;
    this.chosenContainer = new ContainerModification(rowData)
    await lookupsStore.fetchWasteMaterialTypes()
  }

  @action modifyContainer = async () => {
    try {
      if (!this.chosenContainer) return;
      await this.chosenContainer?.updateWasteMaterialTypes();
      await this.fetchContainersList();
    } catch (error) {
      throw error;
    }
  };

  @action setSelectedContainers = (items: GridRowId[]) => {
    this.selectedContainers = items;
  };

  @action removeSelectedContainers = () => {
    if (this.selectedContainers.length === 0) return;
    this.selectedContainers = [];
  };

  @action exportAction = async (data: ExportDataType) => {
    if (!data.type) return;
    this.exportEntity.setParams(data);
    await this.exportEntity.asyncExportAction(
      this.api.getAsyncReportByType(
        this.exportEntity.exportParams<
          ContainersResponse,
          ContainersFilter,
          ContainerItem
        >(
          this.columnsManager.hiddenColumns.map(
            el => ContainersExportColumnsNameMap[el as ContainersNameMapType] || el,
          ),
          this.filter,
          this.pagination,
          this.selectedContainers
        ),
        data.type,
      )
    );
  };

  @action resetStore = () => {
    this.removeSelectedContainers();
    this.pagination.resetPagination();
    this.filter.resetFilters();
    this.filter.setSearchOpen(false);
  };
}

export const { store: containersStore, storeCtx: containersStoreCtx } =
  storeFactory(ContainersStore, 'containers');
