import { CreatedByType, UserItem } from 'src/api/api-types/user';

import { ItemType } from '../../../types';

export class User {
  readonly lastLoggedIn: Date | string | null;
  readonly createdAt: Date | string;
  readonly updatedAt: Date | string;
  readonly updatedBy: CreatedByType | null;
  readonly defaultState: UserItem;
  readonly createdBy: CreatedByType | null;
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly client: ItemType | null;
  readonly clientType: ItemType | null;
  readonly active: boolean;
  readonly jurisdictions: ItemType[] | null = [];
  readonly agencies: ItemType[] | null = [];
  readonly haulers: ItemType[] | null = [];
  readonly allowedEmailDomain?: string;
  readonly employer: string;

  constructor(item: UserItem) {
    const {
      id,
      firstName,
      lastName,
      email,
      active,
      lastLoggedIn,
      clientType,
      client,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      jurisdictions,
      haulers,
      agencies,
      employer
    } = item;

    this.id = id;
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.email = email || '';
    this.active = active || false;
    this.lastLoggedIn = lastLoggedIn || null;
    this.client = client || null;
    this.jurisdictions = jurisdictions || null;
    this.haulers = haulers || null;
    this.agencies = agencies || null;
    this.clientType = clientType ?? null;
    this.createdAt = createdAt || new Date();
    this.createdBy = createdBy || null;
    this.updatedAt = updatedAt || new Date();
    this.updatedBy = updatedBy || null;
    this.defaultState = item || null;
    this.employer = employer || '';
  }
}
