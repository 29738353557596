import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { ItemType } from 'src/types';
import { theme } from 'src/utils/theme/minerva-main';

const { success, text, minervaColors } = theme.palette;

interface MaterialTypeProps {
  type: ItemType;
}

const materialColor: { [key: string]: string } = {
  1: success.main,
  2: text.secondary,
  3: minervaColors.blue2!,
};

const MaterialContainer = styled(Box)<{ type: number }>(({ type }) => ({
  backgroundColor: materialColor[type],
  color: minervaColors.primaryWhite,
  marginInline: theme.spacing(1),
  width: '100%',
  textAlign: 'center',
  borderRadius: 10,
}));

export default ({ type: { id, name } }: MaterialTypeProps) => {
  return <MaterialContainer type={id}>{name}</MaterialContainer>;
};
