import { useEffect, useState, useRef } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { StyledTextArea } from 'src/components/StyledTextArea';
import { GRID_ROW_HEIGHT } from 'src/components/Table/constants';
import { TextWrapper } from 'src/pages/EdibleFoodGenerators/components/EfgComponents/styles';
import { useEfgEdit } from 'src/pages/EdibleFoodGenerators/components/efgEditContext';
import { RenderCellComponentProps } from 'src/types';
import { isPending } from 'src/utils/common';
import { DEFAULT_TEXTAREA_POPUP_WIDTH } from 'src/utils/constants';

const DescriptionCell = observer(
  ({ id, hasFocus, row }: RenderCellComponentProps) => {
    const ref = useRef(null);
    const { chosenFood, selectEfg, removeChosenFood } = useEfgEdit();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);
    const openPopover = () => {
      selectEfg(Number(id));
      !open && setAnchorEl(ref.current);
    };

    useEffect(() => {
      if (hasFocus) {
        setAnchorEl(ref.current);
        if (chosenFood?.descriptionTruncated) chosenFood?.getFullDescription();
      }
    }, [hasFocus, ref, chosenFood]);

    const onClose = () => {
      removeChosenFood();
      setAnchorEl(null);
    };

    return (
      <>
        <Grid container flexDirection="row" wrap="nowrap">
          <Typography
            sx={{
              height: GRID_ROW_HEIGHT,
              alignItems: 'center',
              display: 'flex',
            }}
            ref={ref}
            noWrap>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {row?.description}
            </span>
          </Typography>
          <Grid item>
            {row.descriptionTruncated && (
              <IconButton size="small" onClick={openPopover}>
                <VisibilityIcon sx={{ cursor: 'pointer' }} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        {hasFocus && !!chosenFood && (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            // safari fix
            PaperProps={{
              sx: {
                zIndex: 1,
              },
            }}>
            <TextWrapper>
              {isPending(chosenFood?.fullDescriptionReq) ? (
                <LinearProgress sx={{ width: DEFAULT_TEXTAREA_POPUP_WIDTH }} />
              ) : (
                <StyledTextArea
                  readOnly
                  value={chosenFood?.description}
                  minRows={5}
                  maxRows={7}
                  sx={{ width: DEFAULT_TEXTAREA_POPUP_WIDTH }}
                />
              )}
            </TextWrapper>
          </Popover>
        )}
      </>
    );
  },
);

export default DescriptionCell;
