import { useContext, useEffect } from 'react';

import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import Autocomplete from 'src/components/Autocomplete';
import { lookupsStoreCtx } from 'src/stores/lookups';
import { isPending } from 'src/utils/common';
import { useSearchPanel } from 'src/components/SearchPanel/context';

const AccountField = observer(() => {
  const { filterFields, setFilterValue } = useSearchPanel() || {};
  const { accountsLookupsReq, accountsLookups, fetchAccountsLookups } =
    useContext(lookupsStoreCtx);

  const { watch } = useFormContext();
  const value = watch('q.account.id');

  useEffect(() => {
    if (!accountsLookups.length || !setFilterValue) return;
    setFilterValue({
      ...filterFields,
      'q.generator.id': value?.id || '',
    });
  }, [value, filterFields, setFilterValue, accountsLookups]);

  return (
    <Grid container direction="column">
      <Grid sx={{ mb: 2 }}>
        <Typography variant="body2" color="grey">
          Account
        </Typography>
        <Autocomplete
          name="q.account.id"
          options={accountsLookups}
          request={fetchAccountsLookups}
          placeholder="Look for accounts..."
          loading={isPending(accountsLookupsReq)}
          asyncSearch
        />
      </Grid>
    </Grid>
  );
});

export default AccountField;
