//Trigger pipeline 2
import React from 'react';

import { LicenseInfo } from '@mui/x-license-pro';
import ReactDOM from 'react-dom';

import App from './App';
import AppError from './components/Error';
import reportWebVitals from './reportWebVitals';
import { authStore } from './stores/auth';

LicenseInfo.setLicenseKey(
  '1f4099bfe22f48c3cb581b8512fbc5a3Tz00NjM3MSxFPTE2ODc5NDQwMjE4NzUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

authStore
  .onAppStart()
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root'),
    );
  })
  .catch(() => {
    ReactDOM.render(
      <React.StrictMode>
        <AppError />
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
