import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ExportIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="24"
    height="26"
    viewBox="0 0 24 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M1.33203 19V22.6C1.33203 23.9255 2.40655 25 3.73203 25H20.532C21.8575 25 22.932 23.9255 22.932 22.6V19"
      stroke="#767D86"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M7.33203 13L12.132 17.8L16.932 13"
      stroke="#767D86"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.1348 1V17.8"
      stroke="#767D86"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
export default ExportIcon;
