import { ChangeEvent, useEffect, useState, useCallback, useRef } from 'react';

import { Grid, Popover, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';

import { StyledTextArea } from '../../../StyledTextArea';
import { GRID_ROW_HEIGHT } from '../../../Table/constants';
import { useAssetsTableStore } from '../../AssetsTableProvider';

import { TextWrapper } from './styles';
import { DEFAULT_POPUP_AREA_WIDTH } from '../../helpers/common';

interface AssetTextProps {
  id: number | string;
  field: string;
  hasFocus: boolean;
}

export const AssetText = observer(({ id, field, hasFocus }: AssetTextProps) => {
  const apiRef = useGridApiContext();
  const ref = useRef(null);
  const { chosenAsset } = useAssetsTableStore();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const changeCellHandler = useCallback(
    (fieldName: string, cellValue?: string) => {
      return apiRef.current.setEditCellValue({
        id,
        field: fieldName,
        value: cellValue,
      });
    },
    [apiRef, id],
  );

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    chosenAsset?.setEntity({ [field]: event.target.value });

  const onBlur = () => changeCellHandler(field, chosenAsset?.shortText);
  const openPopover = () => !open && setAnchorEl(ref.current);

  useEffect(() => {
    if (hasFocus) setAnchorEl(ref.current);
  }, [hasFocus, ref]);

  return (
    <>
      <Grid onClick={openPopover} overflow="hidden" textOverflow="ellipsis">
        <Typography
          sx={{
            height: GRID_ROW_HEIGHT,
            width: '100%',
          }}
          ref={ref}
          noWrap>
          {chosenAsset?.shortText}
        </Typography>
      </Grid>
      {hasFocus && chosenAsset && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <TextWrapper>
            <StyledTextArea
              onChange={handleChange}
              onBlur={onBlur}
              value={chosenAsset?.shortText}
              minRows={5}
              maxRows={7}
              sx={{ width: DEFAULT_POPUP_AREA_WIDTH }}
            />
          </TextWrapper>
        </Popover>
      )}
    </>
  );
});
