import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ContactShortType } from 'src/api/api-types/address-book';
import { FoodRecoveryItem } from 'src/api/api-types/foods';
import { DescriptionCell } from 'src/components/EFRcomponents/DescriptionCell';
import { EmailReportLink } from 'src/components/Link/EmailReportLink';
import { GRID_CHECKBOX_SELECTION_COL } from 'src/components/Table/constants';
import ColoredItemType from 'src/components/TypesElement/СoloredTypeItem';
import { nameOf } from 'src/utils/common';

export const columns: GridColDef[] = [
  GRID_CHECKBOX_SELECTION_COL,
  {
    field: nameOf<FoodRecoveryItem>('reference'),
    headerName: 'Minerva ID',
    flex: 1,
    minWidth: 150,
    disableColumnMenu: true,
  },
  {
    field: nameOf<FoodRecoveryItem>('jurisdiction'),
    headerName: 'Jurisdiction',
    editable: true,
    minWidth: 150,
    flex: 1,
    renderCell: ({ value }) => value?.name,
  },
  {
    field: nameOf<FoodRecoveryItem>('type'),
    headerName: 'EFR Type',
    editable: true,
    flex: 1,
    minWidth: 200,
    renderCell: ({ value }: GridRenderCellParams) => (
      <ColoredItemType id={value.id} name={value.name} flow="account" />
    ),
  },
  {
    field: nameOf<FoodRecoveryItem>('name'),
    disableColumnMenu: true,
    headerName: 'Name',
    minWidth: 150,
    flex: 2,
  },
  {
    field: nameOf<FoodRecoveryItem>('usaAddress'),
    disableColumnMenu: true,
    headerName: 'Address',
    flex: 1,
    minWidth: 150,
  },
  {
    field: nameOf<FoodRecoveryItem>('contact'),
    disableColumnMenu: true,
    headerName: 'Contact Name',
    flex: 1,
    minWidth: 150,
    renderCell: ({ value }) => value?.fullName,
  },
  {
    field: nameOf<ContactShortType>('phone'),
    disableColumnMenu: true,
    headerName: 'Phone',
    flex: 1,
    minWidth: 150,
    renderCell: ({ row }) => row?.contact?.phone,
  },
  {
    field: nameOf<ContactShortType>('email'),
    disableColumnMenu: true,
    headerName: 'Email',
    flex: 1,
    minWidth: 150,
    renderCell: ({ row }: GridRenderCellParams) => (
      <EmailReportLink to={row?.contact?.email}>
        {row?.contact?.email}
      </EmailReportLink>
    ),
  },
  {
    field: nameOf<FoodRecoveryItem>('totalPounds'),
    disableColumnMenu: true,
    headerName: 'Total Pounds',
    flex: 1,
    minWidth: 150,
  },
  {
    field: nameOf<FoodRecoveryItem>('annualCapacity'),
    disableColumnMenu: true,
    headerName: 'Annual Capacity',
    flex: 1,
    minWidth: 150,
  },
  {
    field: 'description',
    headerName: 'Description',
    disableColumnMenu: true,
    width: 390,
    editable: true,
    renderCell: ({ id, hasFocus, row }) => (
      <DescriptionCell id={id} hasFocus={hasFocus} row={row} />
    ),
  },
];
