import { createTheme } from '@mui/material/styles';
import { PaletteColor, TypeText } from '@mui/material/styles/createPalette';

type ColorType =
  | 'primary'
  | 'secondary'
  | 'text'
  | 'action'
  | 'error'
  | 'success'
  | 'minervaColors';
type ColorOptionType =
  | 'main'
  | 'light'
  | 'dark'
  | 'contrastText'
  | 'primary'
  | 'secondary'
  | 'disabled';

export type WithMinervaColors<T> = Omit<T, 'color'> & {
  color?: ColorType;
  colorOption?: ColorOptionType;
};

declare module '@mui/material/styles' {
  interface Palette {
    minervaColors: Palette['primary'];
  }

  interface PaletteOptions {
    minervaColors: PaletteOptions['primary'];
  }

  interface PaletteColor {
    blue2?: string;
    blue3?: string;
    blue4?: string;
    blue5?: string;
    blue6?: string;
    blue7?: string;
    blue8?: string;
    blue9?: string;
    veryLightGrey?: string;
    bgColor?: string;
    bgColor1?: string;
    primaryOrange?: string;
    primaryRed?: string;
    primaryYellow?: string;
    primaryViolet?: string;
    secondaryOrange?: string;
    secondaryGreen?: string;
    secondaryGrey?: string;
    secondaryBlue?: string;
    secondaryYellow?: string;
    secondaryViolet?: string;
    secondaryRed?: string;
    primaryWhite?: string;
    liteText?: string;
  }

  interface SimplePaletteColorOptions {
    blue2?: string;
    blue3?: string;
    blue4?: string;
    blue5?: string;
    blue6?: string;
    blue7?: string;
    blue8?: string;
    blue9?: string;
    veryLightGrey?: string;
    bgColor?: string;
    bgColor1?: string;
    primaryOrange?: string;
    primaryYellow?: string;
    primaryViolet?: string;
    primaryRed?: string;
    secondaryBlue?: string;
    secondaryOrange?: string;
    secondaryGreen?: string;
    secondaryGrey?: string;
    secondaryYellow?: string;
    secondaryViolet?: string;
    primaryWhite?: string;
    secondaryRed?: string;
    liteText?: string;
  }

  interface TypographyVariants {
    tab: React.CSSProperties;
    bodySmall: React.CSSProperties;
    link: React.CSSProperties;
    hint: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    tab: React.CSSProperties;
    bodySmall: React.CSSProperties;
    link: React.CSSProperties;
    hint: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tab: true;
    bodySmall: true;
    link: true;
    hint: true;
  }
}

export const getMainColor = (
  color: ColorType,
  colorOption: ColorOptionType = 'main',
) => (theme.palette[color] as PaletteColor & TypeText)[colorOption];

export const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: 48,
      lineHeight: 1.25,
      letterSpacing: 0,
      fontWeight: 700,
      '@media (width<=768)': {
        fontSize: 40,
      },
    },
    h2: {
      fontSize: 40,
      lineHeight: 1.5,
      letterSpacing: 0,
      fontWeight: 700,
      '@media (width<=768)': {
        fontSize: 36,
      },
    },
    h3: {
      fontSize: 32,
      lineHeight: 1.5,
      letterSpacing: 0,
      fontWeight: 700,
    },
    h4: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    h5: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '150%',
    },
    tab: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.3,
      letterSpacing: 0,
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1,
      letterSpacing: 0.1,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.75,
      letterSpacing: 0,
      fontWeight: 400,
    },
    body2: {
      fontSize: 16,
      lineHeight: 1.75,
      letterSpacing: 0,
      fontWeight: 700,
    },
    bodySmall: {
      fontSize: 16,
      lineHeight: 1.1,
      letterSpacing: 0,
      fontWeight: 400,
    },
    link: {
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: 0,
      fontWeight: 600,
    },
    button: {
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: '2%',
      fontWeight: 500,
    },
    caption: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0,
      fontWeight: 500,
    },
    hint: {
      fontSize: 12,
      lineHeight: 1.5,
      letterSpacing: 0,
      fontWeight: 400,
    },
  },
  palette: {
    mode: 'light',
    success: {
      main: '#28CB86',
    },
    info: {
      main: '#047DDB',
    },
    error: {
      main: '#F03738',
    },
    warning: {
      main: '#FFA656',
    },
    primary: {
      main: '#047DDB',
      light: '#55C7F4',
      dark: '#023E8A',
    },
    secondary: {
      main: '#E6F3FD',
      light: '#F3FBFF',
      dark: '#E6ECF3',
      contrastText: '#00060F',
    },
    text: {
      primary: '#00060F',
      secondary: '#767D86',
      disabled: '#E5E9ED',
    },
    minervaColors: {
      main: '#03045E',
      blue2: '#03408C',
      blue3: '#0077B6',
      blue4: '#0096C7',
      blue5: '#00B4D8',
      blue6: '#48CAE4',
      blue7: '#90E0EF',
      blue8: '#ADE8F4',
      blue9: '#CAF0F8',
      veryLightGrey: '#F6F7F9',
      bgColor: '#F6F7F9',
      bgColor1: '#F5F6F7',
      primaryYellow: '#FEE93E',
      primaryViolet: '#5E5ADB',
      primaryOrange: '#FFA656',
      primaryRed: '#FF0056',
      secondaryOrange: '#FFF6EE',
      secondaryBlue: '#EBF8FF',
      secondaryGreen: '#EAF9F1',
      secondaryGrey: 'rgba(118, 125, 134, 0.5)',
      secondaryYellow: '#FFFBD8',
      secondaryViolet: '#EFEFFB',
      secondaryRed: '#FFD9D9',
      primaryWhite: '#FFFFFF',
      liteText: '#8b8b8b',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: 300,
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 12,
          lineHeight: '100%',
          letterSpacing: '0.0004em',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
        }),
      },
    },

    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(1.5, 2),
          borderRadius: 8,
          fontWeight: 600,
          fontSize: 16,
          minWidth: 48,
          textTransform: 'capitalize',
          '&:hover': {
            opacity: '0.7',
          },
          '&:active': {
            opacity: '1',
          },
        }),
        outlined: ({ theme }) => ({
          border: `1px solid ${theme.palette.text.secondary}`,
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: 'grid',
          gridTemplateColumns: '65% 35%',
          alignItems: 'center',
          padding: theme.spacing(2),
          backgroundColor:
            theme.palette.mode === 'light'
              ? theme.palette.minervaColors.primaryWhite
              : 'initial',
          boxShadow: 'none',
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiInputBase-root': {
            ...theme.typography.body1,
            color: 'rgba(118, 125, 134, 0.5)',
            '&.Mui-focused': {
              color: theme.palette.text.primary,
            },
          },

          '& .MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
              borderColor: theme.palette.text.primary,
            },
          },
        }),
      },
    },
  },
});
